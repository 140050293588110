import { Avatar, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Colors } from "../../static/colors";
import { TouchAppTwoTone, BlockTwoTone, ErrorOutline, CancelRounded, CheckCircle } from '@material-ui/icons';
import { useDropzone } from "react-dropzone";
import axios from 'axios';
import { useSelector } from 'react-redux';
import UseAnimations from 'react-useanimations';
import radioButton from 'react-useanimations/lib/radioButton';
import loading from "react-useanimations/lib/loading";
import "./style.css";
import { RButton } from '../ratifikaElements';
import { setTimeout } from 'timers';



interface Props {
  isOpen: boolean;
  closeForm: () => void;
}

export const NewDocumentTxtModal: React.FC<Props> = ({ isOpen, closeForm }) => {
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: ".txt, application/text,charset=utf-8",
    maxFiles: 25,
    maxSize: 2000000,
  });
  return (
    <>
      <Dialog aria-labelledby="simple-dialog-title" open={isOpen} /* onClose={closeForm} */>
        <DialogTitle id="simple-dialog-title"> Envío de documentos en formato TXT</DialogTitle>
        <DialogContent>
          <CardContent>
            <Typography variant="body2" component="p" style={{ color: "#a09d9d" }}>
              Arrastra y suelta aquí tus documentos (Facturas, Boletas, Notas de Crédito, Débito o Guías) que desees enviar
            </Typography>

            <section className="container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {isDragAccept && <TouchAppTwoTone style={{ fontSize: 70 }} />}
                {isDragReject && <BlockTwoTone style={{ fontSize: 70 }} />}
                {!isDragActive && (
                  <p style={{ color: "#a1b3c3", fontSize: "1.5em" }}>
                    Arrastra y suelta tus archivos aquí, o haz clic para seleccionarlos
                  </p>
                )}
                <em style={{ fontSize: 14 }}>
                  (Solo archivos *.txt hasta un límite de 25 documentos a la vez)
                </em>
              </div>
              
              <aside>
              {acceptedFiles.length > 0 && (
                  <ul style={{ paddingInlineStart: "0em" }}>
                    {acceptedFiles.map((file: File, index) => (
                          <UploadFile
                          key={file.name}
                          file={file}
                          timer={index*2000}
                        />
                    ))
                    }
                  </ul>
                )}        
              </aside>
            </section>
            <div />
          </CardContent>
        </DialogContent>
        <DialogActions>
          <Typography variant="body2" component="p" style={{ color: "#a09d9d", marginLeft: 10 }}>
            Documentos permitidos:
          </Typography>
          <Chip avatar={<Avatar>T</Avatar>} label="txt" variant="outlined" />
          {/* <Button size="small" color="primary">
              Descargar ejemplo *.txt
            </Button> */}

          <RButton variant="contained"
           onClick={closeForm} 
           style={{ transform: "rotate(0deg)", marginLeft: 'auto' }}
           >
            Cerrar
          </RButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface PropsFile {
  file: File;
  //session_id: string;
  timer:number
}
interface Result {
  msg: string;
  result: boolean;
}

const UploadFile: React.FC<PropsFile> = ({ file, timer}) => {
  const [msgSend, setMsgSend] = React.useState<Result | null>(null)
  const [processToJson, setProcessToJson] = React.useState<boolean>(false)
  const [loadingInvoice, setLoadingInvoice] = React.useState<boolean>(false);
  const config = useSelector((state: any) => state.config);
  
  
  useEffect(() => {
  async function transformToJson(archivo:File) {
    const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          let txtData: any = reader.result;
        
          setProcessToJson(true);
          /*Transforma de  txt a JSON */
          let res = await axios.post("/api/receive/txt", {
            document: txtData,
            origin: "WEB",
            session_id: config.session_id,
          });
          setProcessToJson(false);
          const toJson = res.data;
         //console.log(toJson);
         
          if (toJson.result) {
            setLoadingInvoice(true);
            const json = toJson.response;
            //enviar Factura...
              let { data } = await axios.post("/api/emission/send", json);
              setLoadingInvoice(false);
              /*valida que tipo de respuesta retorna favorable o no */
              let res: Result
              if (data.procesado) {
                res = {
                  msg: data.mensaje,
                  result: true
                }
              } else {
                res = {
                  msg: `Documento no enviado: ${data.mensaje} `,
                  result: false
                }
              }
              setMsgSend(res);
            /*TODO: REFRESCAR 
            if (data.procesado) {           
             // setTimeout(() => fetch(["emission"]), 2000);
            }*/
          } else {
            const respuesta: Result = {
              msg: `No se pudo transformar el archivo a formato Json. Revisa que el contenido del archivo cumpla con la estructura necesaria`,
              result: false
            }        
            setMsgSend(respuesta);
          }
  
        } catch (e: any) {
          console.log(e);
  
        }
      }
      reader.readAsText(archivo);
  }
  
  setTimeout(()=>transformToJson(file), timer,file);
     
     
  }, [file]);
  return (
    <div className="FileItem">
      <div className="iconContainer">
        {(processToJson ||loadingInvoice) && (
          <UseAnimations
            animation={loading}
            autoplay
            size={32}
            strokeColor={"rgb(38 152 63)"}
            wrapperStyle={{ paddingRight: 5 }}
          />
        )
        }
        {msgSend?.result &&(
          <UseAnimations
            animation={radioButton}
            autoplay
            size={32}
            strokeColor={"rgb(38 152 63)"}
            wrapperStyle={{ paddingRight: 5 }}
          />
        ) }
          {
            msgSend?.result===false &&(
            <ErrorOutline
            style={{
              fontSize: 28,
              color: Colors.alert,
              paddingLeft: 2,
              paddingRight: 8,
            }}
          />
          )            
          }

        
      </div>

      <div>
        <h4
          style={{
            marginBlockEnd: 3,
            fontWeight: 400,
            fontFamily: "sans-serif",
          }}
        >
          {file.name} - {Math.round(file.size / 1024)} Kb
        </h4>
        <ul className="result">
          {processToJson ? (
            <li className="ResultMessage">Transformando archivo a formato Json...</li>
          ) :
            (
              <>
                {loadingInvoice ? (
                  <li style={{listStyle:"none"}}>Enviando archivo...</li>
                ) :
                  (
                    <>
                      {msgSend?.result ? (
                        <Typography style={{ color: "#8f8f8f" }} variant="body2" component="p">
                          <CheckCircle style={{ fontSize: 12 }} /> {msgSend?.msg}
                        </Typography>
                      ) : (
                        <>
                        {msgSend?.result===false?(
                          <>
                          <Typography style={{ color: "#af6262" }} variant="body2" component="p">
                            <CancelRounded style={{ fontSize: 12 }} />{msgSend?.msg}
                          </Typography>
                          <Link style={{color:"primary"}} variant="body2" target='_blank' href="https://drive.google.com/file/d/1gl0BuxRaeHwHy3x2npFgFxG_Xo7MTMw7/view?usp=sharing">
                          Ver ejemplo
                          </Link>
                          </>
                        ):""
                      }
                        </>
                        
                       
                      )}

                    </>
                  )
                }
              </>
            )
          }
        </ul>
      </div>
    </div>
  )
}