import React, { useEffect } from "react";
import { RegisterCompanyModal } from "../../components/registerCompanyModal";
import { ConfigurationModal } from "../../components/configurationModal";
import { NewInvoiceModal } from "../../components/newInvoiceModal";
import { NewSaleReceiptModal } from "../../components/newSaleReceiptModal";
import Footer from "../../components/footer";
import { Reception } from "../reception";
import { Emission } from "../emission";
import {
  AppBar,
  Menu,
  Tabs,
  Tab,
  Typography,
  Box,
  IconButton,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Drawer,
  Divider,
  useMediaQuery,
  Snackbar,
  Backdrop,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  AccountCircle,
  ExpandMore,
  LibraryAdd as AddDocumentIcon,
  DescriptionOutlined,
  ReceiptOutlined,
  FlareOutlined,
  SettingsOutlined as SettingsIcon,
  ExitToAppRounded as ExitIcon,
  FileCopyOutlined,
} from "@material-ui/icons";
import ArchiveIcon from "@material-ui/icons/Archive";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../imgs/logo.white.svg";
import CallToActionIcon from "@material-ui/icons/CallToAction";
import { useSelector, useDispatch } from "react-redux";
import { truncateString } from "../../static/util";
import axios from "axios";
import { setCompanyInfoList } from "../../store/actions/companies";
import { flushStorage } from "../../domain/store/flush";
import { fetch } from "../../store/fetch";
import { setConfigParam } from "../../store/actions/config";
import { CompanyInfo } from "../../store/types/companies";
import { Reports } from "../reports/reportSale";
import { NewDocumentTxtModal } from "../../components/newDocumentTxtModal";
import { NewGuiaRemisionModal } from "../../components/newGuiaModal";
import { NewRetencionModal } from "../../components/newRetencionesModal";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Home: React.FC<{}> = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [openRegisterCompanyModal, setOpenRegisterCompanyModal] =
    React.useState(false);
  const [showIntroForNewCompany, setshowIntroForNewCompany] =
    React.useState(false);
  const [openNewInvoiceModal, setOpenNewInvoiceModal] = React.useState(false);
  const [openNewSaleReceipModal, setOpenNewSaleReceipModal] =
    React.useState(false);
  const [openNewGuiaModal, setOpenNewGuiaModal] = React.useState(false);

  const [openNewRetencion, setOpenNewRetencion] = React.useState(false);
  const [openConfigModal, setOpenConfigModal] = React.useState(false);

  const companies = useSelector((state: any) => state.companiesInfo);
  const config = useSelector((state: any) => state.config);
  const dispatch = useDispatch();
  const [openModalTxt, setOpenModalTxt] = React.useState(false);
  const [disableGuias, setDisableGuias] = React.useState(true);
  const [disableTxt, setDisableTxt] = React.useState(true);
  const [disableRetenciones, setDisableRetenciones] = React.useState(true);
  const [showAlert, setShowAlert] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    if (!config.workspace) {
      setshowIntroForNewCompany(true);
      setOpenRegisterCompanyModal(true);
    }
  }, []);

  useEffect(() => {
    if (config.workspace) {
      setDisableGuias(true);
      setDisableTxt(true);
      setDisableRetenciones(true);
      if (
        companies.find((c: CompanyInfo) => c.ruc === config.workspace) &&
        companies.find((c: CompanyInfo) => c.ruc === config.workspace).customize
      ) {
        if (
          companies.find((c: CompanyInfo) => c.ruc === config.workspace)
            .customize.envioGuias === "YES"
        ) {
          setDisableGuias(false);
        }
        if (
          companies.find((c: CompanyInfo) => c.ruc === config.workspace)
            .customize.envioTxt === "YES"
        ) {
          setDisableTxt(false);
        }
        if (
          companies.find((c: CompanyInfo) => c.ruc === config.workspace)
            .customize.envioRetenciones === "YES"
        ) {
          setDisableRetenciones(false);
        }
      }
    }
  }, [config.workspace]);

  const handleLogout = () => {
    setAnchorEl(null);
    flushStorage(false);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, tab: number) => {
    setSelectedTab(tab);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [anchorElNewMenu, setAnchorElNewMenu] =
    React.useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const theme = useTheme();
  const match800 = useMediaQuery(theme.breakpoints.down(800));

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectCompany = (
    event: React.MouseEvent<HTMLElement>,
    workspace: number
  ) => {
    dispatch(setConfigParam("workspace", workspace));
    setAnchorEl(null);
    dispatch(setConfigParam("emittedPageCurrent", 0));
    fetch(["reception", "emission", "reports"]);
  };

  const handleRegisterNewCompany = () => {
    setAnchorEl(null);
    setOpenRegisterCompanyModal(true);
  };

  const handleOpenConfiguration = () => {
    setAnchorEl(null);
    setOpenConfigModal(true);
  };

  const handleCloseRegisterCompany = async (success: boolean) => {
    if (success) {
      // Actualizo la lista de empresas
      const { data } = await axios.get<CompanyInfo[]>("/api/company/infoList");
      dispatch(setCompanyInfoList(data as CompanyInfo[]));
    }
    setOpenRegisterCompanyModal(false);
  };

  const handleOpenNewMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNewMenu(event.currentTarget);
  };

  const handleCloseNewMenu = () => {
    setAnchorElNewMenu(null);
  };

  const handleAddNewInvoice = () => {
    setAnchorElNewMenu(null);
    setOpenNewInvoiceModal(true);
  };

  const handleAddNewSaleReceip = () => {
    setAnchorElNewMenu(null);
    setOpenNewSaleReceipModal(true);
  };

  const handleAddNewRetencion = () => {
    setAnchorElNewMenu(null);
    setOpenNewRetencion(true);
  };

  const handleAddNewGuia = () => {
    setAnchorElNewMenu(null);
    setOpenNewGuiaModal(true);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleDrawerNumber = (positionTab: number) => {
    setOpenDrawer(false);
    setSelectedTab(positionTab);
  };

  //Modal txt
  const handleOpenModaltxt = () => {
    setAnchorElNewMenu(null);
    setOpenModalTxt(true);
  };

  const handleCloseTxt = () => {
    setOpenModalTxt(false);
    setTimeout(() => fetch(["emission"]), 1000);
  };
  /*Pop Over */

  const openPopOver = () => {
    setShowAlert(true);
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ flexDirection: "row", backgroundColor: "#107C41" }}
      >
        {/* This is to menu responsive */}
        <div style={{ display: match800 ? "flex" : "none" }}>
          <Tooltip title="Abrir ventana" arrow style={{ marginLeft: ".5rem" }}>
            <IconButton
              color="inherit"
              aria-label="open |"
              edge="start"
              onClick={handleDrawerOpen}
              style={{ marginLeft: ".5rem" }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        </div>

        <Drawer anchor="left" open={openDrawer} onClose={handleCloseDrawer}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "3rem",
              background: "rgb(16, 124, 65)",
            }}
          >
            <img
              src={logo}
              alt="ratifika"
              width="20px"
              style={{ width: "2.1rem", height: "2.1rem", margin: ".7rem 0" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: ".2rem",
            }}
          >
            <Tooltip title="Nuevo documento" arrow>
              <IconButton
                edge="start"
                color="inherit"
                disabled={!config.workspace}
                onClick={handleOpenNewMenu}
                /* onClick={handleAddNewInvoice} */
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <AddDocumentIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* New menu */}
          <Menu
            id="lock-menu"
            anchorEl={anchorElNewMenu}
            keepMounted
            open={Boolean(anchorElNewMenu)}
            onClose={handleCloseNewMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleAddNewInvoice} style={{ fontSize: 14 }}>
              <DescriptionOutlined style={{ fontSize: 20, marginRight: 5 }} />{" "}
              Nueva factura
            </MenuItem>
            <MenuItem onClick={handleAddNewSaleReceip} style={{ fontSize: 14 }}>
              <ReceiptOutlined style={{ fontSize: 20, marginRight: 5 }} />
              Nueva boleta
            </MenuItem>
            {/* Muestra opcion nueva guia depende de habilitacion en la bdd */}

            <MenuItem
              onClick={disableGuias ? openPopOver : handleAddNewGuia}
              style={{ fontSize: 14 }}
            >
              <LocalShippingOutlinedIcon
                style={{ fontSize: 20, marginRight: 5 }}
              />
              Nueva guía de remisión
            </MenuItem>

            <MenuItem
              onClick={disableRetenciones ? openPopOver : handleAddNewRetencion}
              style={{ fontSize: 14 }}
            >
              <CallToActionIcon style={{ fontSize: 20, marginRight: 5 }} />
              Nueva Retencion
            </MenuItem>

            {/* Muestra boton subir txt depende de habilitacion en la bdd */}

            <MenuItem
              onClick={disableTxt ? openPopOver : handleOpenModaltxt}
              style={{ fontSize: 14 }}
            >
              <FileCopyOutlined style={{ fontSize: 20, marginRight: 5 }} />
              Cargar txt
            </MenuItem>
          </Menu>
          {/* End new menu */}
          <List>
            <ListItem button onClick={() => handleDrawerNumber(0)}>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary={"VENTAS"} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleDrawerNumber(1)}>
              <ListItemIcon>
                <ArchiveIcon />
              </ListItemIcon>
              <ListItemText primary={"COMPRAS"} />
            </ListItem>
            <Divider />

            <ListItem button onClick={() => handleDrawerNumber(2)}>
              <ListItemIcon>
                <ShowChartIcon />
              </ListItemIcon>
              <ListItemText primary={"REPORTES"} />
            </ListItem>
          </List>
        </Drawer>
        {/* final menu responsive */}

        <div
          style={{ display: match800 ? "none" : "flex", paddingLeft: ".5rem" }}
        >
          <img
            src={logo}
            alt="ratifika"
            width="20px"
            style={{ paddingRight: "20px", paddingLeft: "10px" }}
          />
          <Tooltip title="Nuevo documento" arrow>
            <Button
              color="inherit"
              disabled={!config.workspace}
              onClick={handleOpenNewMenu}
              style={{ marginLeft: 10, marginRight: 10 }}
              startIcon={<AddDocumentIcon />}
            >
              NUEVO
            </Button>
          </Tooltip>
          {/* New menu */}
          <Menu
            id="lock-menu"
            anchorEl={anchorElNewMenu}
            keepMounted
            open={Boolean(anchorElNewMenu)}
            onClose={handleCloseNewMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleAddNewInvoice} style={{ fontSize: 14 }}>
              <DescriptionOutlined style={{ fontSize: 20, marginRight: 5 }} />{" "}
              Nueva factura
            </MenuItem>
            <MenuItem onClick={handleAddNewSaleReceip} style={{ fontSize: 14 }}>
              <ReceiptOutlined style={{ fontSize: 20, marginRight: 5 }} /> Nueva
              boleta
            </MenuItem>
            {/* Muestra opcion nueva guia depende de habilitacion en la bdd */}
            <MenuItem
              onClick={disableGuias ? openPopOver : handleAddNewGuia}
              style={{ fontSize: 14 }}
            >
              <LocalShippingOutlinedIcon
                style={{ fontSize: 20, marginRight: 5 }}
              />
              Nueva guía de remisión
            </MenuItem>
            <MenuItem
              onClick={disableRetenciones ? openPopOver : handleAddNewRetencion}
              style={{ fontSize: 14 }}
            >
              <CallToActionIcon style={{ fontSize: 20, marginRight: 5 }} />
              Nueva retencion
            </MenuItem>
            {/* Muestra boton subir txt depende de habilitacion en la bdd */}

            <MenuItem
              onClick={disableTxt ? openPopOver : handleOpenModaltxt}
              style={{ fontSize: 14 }}
            >
              <FileCopyOutlined style={{ fontSize: 20, marginRight: 5 }} />
              Cargar txt
            </MenuItem>
          </Menu>
          {/* End new menu */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: "white" } }}
          >
            <Tab label="Ventas" {...a11yProps(0)} />
            <Tab label="Compras" {...a11yProps(1)} />
            <Tab
              label={<div style={{ flexDirection: "row" }}>Reportes</div>}
              {...a11yProps(2)}
            />
          </Tabs>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <List style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="lock-menu"
              onClick={handleOpenMenu}
              style={{ paddingTop: 3, paddingBottom: 0 }}
            >
              <AccountCircle style={{ marginRight: 5, fontSize: 35 }} />
              <ListItemText
                primary={
                  <Typography
                    style={{
                      fontSize: 14,
                      fontFamily: "Corbel",
                      lineHeight: 1.2,
                    }}
                  >
                    {config.userName}
                  </Typography>
                }
                secondary={
                  <Typography style={{ fontSize: 10, fontWeight: "bold" }}>
                    {config.workspace !== null
                      ? companies.find(
                          (c: CompanyInfo) => c.ruc === config.workspace
                        ).legal_name
                      : "Registrar una empresa"}
                  </Typography>
                }
              />
              <ExpandMore style={{ marginLeft: 5 }} />
            </ListItem>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {companies.map((c: CompanyInfo) => (
              <MenuItem
                key={c.ruc}
                disabled={c.status === "NEW"}
                selected={c.ruc === config.workspace}
                onClick={(event) => handleSelectCompany(event, c.ruc)}
              >
                {c.status === "NEW" ? (
                  <ListItemText
                    secondary={
                      <span style={{ fontStyle: "italic" }}>
                        Pendiente de validación
                      </span>
                    }
                    primary={truncateString(c.legal_name, 27)}
                  />
                ) : (
                  <ListItemText
                    secondary={c.ruc}
                    primary={truncateString(c.legal_name, 27)}
                  />
                )}
              </MenuItem>
            ))}
            <MenuItem
              style={{
                justifyContent: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
              onClick={handleRegisterNewCompany}
            >
              <Typography
                variant="body1"
                style={{
                  borderWidth: 2,
                  borderColor: "#dadada",
                  borderStyle: "dotted",
                  borderRadius: 10,
                  padding: 8,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Registrar nueva empresa
              </Typography>
            </MenuItem>
            <MenuItem
              disabled={!config.workspace}
              onClick={handleOpenConfiguration}
            >
              <SettingsIcon style={{ fontSize: 20, paddingRight: 5 }} />{" "}
              Configuración
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ExitIcon style={{ fontSize: 20, paddingRight: 5 }} /> Cerrar
              sessión
            </MenuItem>
          </Menu>
        </div>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <Emission />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Reception />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <Reports />
      </TabPanel>

      <Footer />

      <RegisterCompanyModal
        key={Date.now() + 10}
        isOpen={openRegisterCompanyModal}
        openWelcome={showIntroForNewCompany}
        close={handleCloseRegisterCompany}
      />

      <ConfigurationModal
        key={Date.now()}
        isOpen={openConfigModal}
        close={() => setOpenConfigModal(false)}
      />

      {config.workspace && (
        <>
          <NewInvoiceModal
            key={Date.now() + 5}
            isOpen={openNewInvoiceModal}
            close={() => setOpenNewInvoiceModal(false)}
          />
          <NewSaleReceiptModal
            key={Date.now() + 7}
            isOpen={openNewSaleReceipModal}
            close={() => setOpenNewSaleReceipModal(false)}
          />
          <NewDocumentTxtModal
            key={Date.now() + 6}
            isOpen={openModalTxt && !disableTxt}
            closeForm={handleCloseTxt}
          />
          <NewGuiaRemisionModal
            key={Date.now() + 8}
            isOpen={openNewGuiaModal && !disableGuias}
            close={() => setOpenNewGuiaModal(false)}
          />
          <NewRetencionModal
            key={Date.now() + 9}
            isOpen={openNewRetencion && !disableRetenciones}
            close={() => setOpenNewRetencion(false)}
          />
        </>
      )}
      <Backdrop
        open={showAlert}
        onClick={() => {
          setShowAlert(false);
        }}
        style={{ color: "#fff" }}
        className={classes.backdrop}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showAlert}
          onClose={() => {
            setShowAlert(false);
          }}
          autoHideDuration={6000}
          style={{ marginTop: "50px" }}
        >
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity="warning"
          >
            ¡Lo sentimos!
            <br />
            Parece que esta funcionalidad no está incluida en su plan actual. Contacte con un ejecutivo de EXIMIO para más información.
          </Alert>
        </Snackbar>
      </Backdrop>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 999,
      color: "#fff",
    },
  })
);
