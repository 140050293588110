import React from "react";
import { LocationOn } from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Stepper,
  StepLabel,
  StepContent,
  Typography,
  Paper,
  InputLabel,
  TextField,
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { RStep, RRadio } from "../ratifikaElements";
import { Colors } from "../../static/colors";
import newCompanyIllust from "../../assets/imgs/new.office.png";
import welcomeIllust from "../../assets/imgs/check.list.png";
import { direcciones } from "../../static/ubigeo";
import {
  isValidTelephoneNumber,
  isValidRUC,
  validateEmail,
} from "../../static/util";
import { PrivacyPolicy } from "../privacyPolicy";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextStyle from "../textStyle";
import { ButtonPrimary, ButtonSecondary } from "../button";
import { InputNumber, InputPassword, InputText } from "../input";

interface Props {
  isOpen: boolean;
  openWelcome: boolean;
  close: (success: boolean) => void;
}
interface Company {
  addresses: Ubicacion;
  number_type: string;
  number: string;
  legal_name: string;
  email_address: string;
  is_new?: boolean;
  address_line: string;
}
interface Ubicacion {
  code: string;
  departamento: string;
  provincia: string;
  distrito: string;
}

function getSteps() {
  return ["RUC", "Datos de la empresa", "Credenciales SUNAT", "Confirmar"];
}

const RegisterCompanyModal: React.FC<Props> = ({
  isOpen,
  openWelcome,
  close,
}) => {
  const [isOpenWelcome, setIsOpenWelcome] = React.useState(openWelcome);
  const [activeStep, setActiveStep] = React.useState(0);
  const [companyRUC, setCompanyRUC] = React.useState("");
  const [legalName, setLegalName] = React.useState<string>("");
  const [tradeName, setTradeName] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [webSite, setWebSite] = React.useState<string>("");
  const [emailAdress, setEmailAdress] = React.useState<string>("");
  const [addressLine, setAddressLine] = React.useState<string>("");
  const [userSOL, setUserSOL] = React.useState<string>("");
  const [passwordSOL, setPasswordSOL] = React.useState<string>("");
  const [errorMsgRUC, setErrorMsgRUC] = React.useState<string | null>(null);
  const [errorMsgLegalName, setErrorMsgLegalName] = React.useState<
    string | null
  >(null);
  const [errorMsgTradeName, setErrorMsgTradeName] = React.useState<
    string | null
  >(null);
  const [errorMsgPhone, setErrorMsgPhone] = React.useState<string | null>(null);
  const [errorMsgAddressLine, setErrorMsgAddressLine] = React.useState<
    string | null
  >(null);
  const [errorMsgEmail, seterrorMsgEmail] = React.useState<string | null>(null);
  const [hasLegalNameErrors, setHasLegalNameErrors] = React.useState(false);
  const [hasTradeNameErrors, setHasTradeNameErrors] = React.useState(false);
  const [hasPhoneErrors, setHasPhoneErrors] = React.useState(false);
  const [hasAddressLineErrors, setHasAddressLineErrors] = React.useState(false);
  const [hasEmailErrors, sethasEmailErrors] = React.useState(false);
  const [hasUserSolErrors, setHasUserSolErrors] = React.useState(false);
  const [hasPassSolErrors, setHasPassSolErrors] = React.useState(false);
  const [errorMsgUserSOL, setErrorMsgUserSOL] = React.useState<string | null>(
    null
  );
  const [errorMsgPassSOL, setErrorMsgPassSOL] = React.useState<string | null>(
    null
  );
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [errorMsgTryingRegister, setErrorMsgTryingRegister] = React.useState<
    string | null
  >(null);
  const [autocompleteCompany, setAutocompleteCompany] = React.useState<
    Array<Company>
  >([]);
  const [focusComponent, setFocusComponent] = React.useState<boolean>(false);
  const [freezeBuyerInfo, setFreezeBuyerInfo] = React.useState<boolean>(false); // usado para bloquear la info de la company en caso de autocomplete
  const [showCompanyInfo, setShowCompanyInfo] = React.useState<boolean>(false);
  const [direccionUbigeo, setDireccionUbigeo] = React.useState<
    string | undefined
  >("");
  const [selectUbigeo, setSelectUbigeo] = React.useState<Ubicacion | null>(
    null
  );
  const [credencialSUNAT, setCredencialSUNAT] = React.useState<string | null>(
    null
  );

  const steps = getSteps();

  const handleNext = async () => {
    // Validate RUC
    if (activeStep === 0) {
      let hasCompanyErrors = false;
      if (companyRUC.trim() === "") {
        setErrorMsgRUC("Debes especificar un número de RUC");
        return;
      }
      const validStructure: boolean = isValidRUC(companyRUC);
      if (!validStructure) {
        setErrorMsgRUC("Número de RUC incorrecto");
        return;
      }
      const { data } = await axios.get(`/api/company/exist/${companyRUC}`);
      if (!data.result) {
        setErrorMsgRUC(
          "No se ha podido validar el Número de RUC, intente más adelante"
        );
        return;
      }
      if (data.exist) {
        setErrorMsgRUC(
          data.belongs
            ? "Ya tienes una empresa registrada con ese número de RUC"
            : "Ya existe una empresa registrada con ese número de RUC"
        );
        return;
      }
      /*  if (data.companyInfo) {
        // Si existe info de la empresa se agrega al formulario
        setLegalName(data.companyInfo.legal_name || "");
        setTradeName(data.companyInfo.trade_name || "");
        setAddressLine(data.companyInfo.address_line || ""); */
      /*   if (data.companyInfo.ubigeo && ubigeos.find((u) => u.number === data.companyInfo.ubigeo)) {
         
        } */
      //}
      ////Validacion de nombre comp
      if (legalName.trim() === "") {
        setHasLegalNameErrors(true);
        hasCompanyErrors = true;
      } else if (legalName.trim().length <= 3) {
        setHasLegalNameErrors(true);
        setErrorMsgLegalName("Debes especificar un Nombre Legal correcto");
        hasCompanyErrors = true;
      }
      if (tradeName.trim() === "") {
        setHasTradeNameErrors(true);
        hasCompanyErrors = true;
      } else if (tradeName.trim().length <= 3) {
        setHasTradeNameErrors(true);
        setErrorMsgTradeName("Debes especificar un Nombre Comercial correcto");
        hasCompanyErrors = true;
      }

      if (addressLine.trim() === "") {
        setHasAddressLineErrors(true);
        hasCompanyErrors = true;
      } else if (addressLine.trim().length <= 3) {
        setHasAddressLineErrors(true);
        setErrorMsgAddressLine(
          "Debes especificar un Domicilio fiscal correcto"
        );
        hasCompanyErrors = true;
      }

      setErrorMsgRUC(null);

      if (direccionUbigeo === "" || direccionUbigeo === undefined) {
        hasCompanyErrors = true;
      }

      if (!hasCompanyErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    // Validate Company Info
    if (activeStep === 1) {
      let hasCompanyErrors = false;
      if (phoneNumber.trim() === "") {
        setHasPhoneErrors(true);
        hasCompanyErrors = true;
      } else if (!isValidTelephoneNumber(phoneNumber)) {
        setHasPhoneErrors(true);
        setErrorMsgPhone("Debes especificar un número de contacto correcto");
        hasCompanyErrors = true;
      }

      if (emailAdress.trim() === "") {
        sethasEmailErrors(true);
        hasCompanyErrors = true;
      } else if (!validateEmail(emailAdress)) {
        sethasEmailErrors(true);
        seterrorMsgEmail("Debes especificar un correo correcto");
        hasCompanyErrors = true;
      }

      if (!hasCompanyErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    // Validate SOL credentials
    if (activeStep === 2) {
      let hasCredencialsErrors = false;
      if (credencialSUNAT === "true") {
        if (userSOL.trim() === "") {
          setHasUserSolErrors(true);
          hasCredencialsErrors = true;
        } else if (userSOL.length !== 8) {
          setHasUserSolErrors(true);
          setErrorMsgUserSOL("El Usuario SOL debe contener 8 caracteres");
          hasCredencialsErrors = true;
        }
        if (passwordSOL.trim() === "") {
          setHasPassSolErrors(true);
          hasCredencialsErrors = true;
        } else if (passwordSOL.length < 8 || passwordSOL.length > 12) {
          setHasPassSolErrors(true);
          setErrorMsgPassSOL(
            "La Clave SOL debe contener entre 8 y 12 caracteres"
          );
          hasCredencialsErrors = true;
        }
      }

      if (!hasCredencialsErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    // Finalizar
    if (activeStep === 3) {
      let json: any = {
        ruc: companyRUC,
        legal_name: legalName,
        trade_name: tradeName,
        phone_number: phoneNumber,
        address: addressLine,
        ubigeo: direccionUbigeo,
        user_sol: userSOL,
        password_sol: passwordSOL,
        email: emailAdress,
        pag_web: webSite,
      };
      if (credencialSUNAT === "false") {
        json = {
          ...json,
          status: "VAL",
        };
      }
      let { data } = await axios.post("/api/company/add", json);
      if (data.result) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setErrorMsgTryingRegister(
          `Ha ocurrido un error inesperado: ${data.msg}`
        );
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  ///suggestion company
  const getCompanySuggestions = async (value: string): Promise<Company[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results: any[] = [];

    if (Number.isInteger(Number(inputValue))) {
      if (inputLength === 11) {
        /*Consulta datos en SUNAT */
        await axios
          .get<any>(`/api/info/by/ruc/?ruc=${Number(inputValue)}`)
          .then((e) => {
            const data = e.data;
            if (!data.error) {
              setErrorMsgRUC(null);
              results = [
                {
                  legal_name: data.nombre,
                  number: inputValue,
                  number_type: data.tipoDocumento,
                  address_line: data.direccion || null,
                  addresses: {
                    code: data.ubigeo || null,
                    district: data.distrito || null,
                    province: data.provincia || null,
                    department: data.departamento || null,
                  },
                  is_new: true,
                },
              ];
            } else {
              setErrorMsgRUC(data.error);
            }
          });
      }
    }
    return results;
  };
  const getSuggestionValue = (suggestion: Company) => {
    if (suggestion !== null) {
      setAddressLine(suggestion.address_line);
      setCompanyRUC(suggestion.number);
      setLegalName(suggestion.legal_name);
      if (suggestion.addresses.code) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === suggestion.addresses.code
        );
        if (codeUbigeo) {
          setSelectUbigeo(codeUbigeo);
          setDireccionUbigeo(codeUbigeo.code);
        }
      }
      setFocusComponent(true);
    }
    setShowCompanyInfo(true);
    if (errorMsgLegalName) {
      setErrorMsgLegalName(null);
    }
    /*  if (errorMsgIdentification) {
       setErrorMsgIdentification(null);
     } */
    if (errorMsgAddressLine) {
      setErrorMsgAddressLine(null);
    }
    return suggestion.number;
  };

  const renderSuggestion = (suggestion: Company, { query }: any) => {
    if (suggestion.is_new) {
      return (
        <span>
          Registrar como: "
          <strong>{suggestion.legal_name || suggestion.number}</strong>"
        </span>
      );
    }
  };
  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteCompany(await getCompanySuggestions(param.value));
  };

  const onSuggestionsClearRequested = () => {
    setAutocompleteCompany([]);
  };

  return (
    <Dialog
      open={isOpen}
      scroll={"paper"}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => close(activeStep === steps.length)}
      fullWidth={true}
      maxWidth={"md"}
    >
      {isOpenWelcome ? (
        <>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextStyle
              bold={600}
              size="30px"
              sizeMobile="24px"
              margin="0px 0px 10px 0px"
            >
              Bienvenido
            </TextStyle>
            <DialogContentText>
              <TextStyle bold={400} type="h3" margin="0px 0px 10px 0px">
                Antes de comenzar a emitir y recibir documentos es necesario que
                registres al menos una compañía. Puedes completar este paso más
                adelante, pero gran parte de las funcionalidades de Ratifica®
                estarán deshabilitada hasta que completes el proceso de
                registro.
              </TextStyle>
            </DialogContentText>
            <div
              style={{
                display: "flex",
                padding: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={welcomeIllust}
                height={150}
                style={{ padding: 0 }}
                alt="bienvenido"
              />
            </div>
          </DialogContent>
          <DialogActions style={{ padding: 20 }}>
            <ButtonSecondary
              border={Colors.GREEN}
              color={Colors.GREEN}
              onClick={() => close(false)}
              size="14px"
            >
              Registrar luego
            </ButtonSecondary>
            <ButtonPrimary
              onClick={() => setIsOpenWelcome(false)}
              background={Colors.GREEN}
              size="14px"
              color={Colors.WHITE}
            >
              Continuar
            </ButtonPrimary>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-slide-title">
            <TextStyle bold={700} type="h1">
              {"Registrar nueva empresa"}
            </TextStyle>
          </DialogTitle>
          <DialogContent dividers={true} style={{ height: "39em", padding: 5 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                style={{ display: "block" }}
              >
                {steps.map((label, index) => (
                  <RStep key={label}>
                    <StepLabel>
                      <TextStyle bold={700} type="h3">
                        {label}
                      </TextStyle>
                    </StepLabel>
                    <StepContent>
                      {index === 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <TextStyle bold={400} type="h4">
                            Agrega los datos requeridos para la facturación
                          </TextStyle>
                          <div
                            style={{
                              paddingBottom: "10px",
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: "4px",
                            }}
                          >
                            <Autosuggest
                              suggestions={autocompleteCompany}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={{
                                placeholder: "Número de RUC",
                                value: companyRUC,
                                onChange: (
                                  event: React.FormEvent<any>,
                                  { newValue, method }: Autosuggest.ChangeEvent
                                ) => {
                                  setCompanyRUC(newValue);
                                  if (newValue === "") {
                                    setLegalName("");
                                    setCompanyRUC("");
                                  }
                                },
                                ref: React.createRef<HTMLInputElement>(),
                              }}
                            />
                            {errorMsgRUC && (
                              <TextStyle
                                bold={400}
                                type="h4"
                                color={Colors.RED}
                              >
                                {errorMsgRUC}
                              </TextStyle>
                            )}
                          </div>
                          {showCompanyInfo && (
                            <>
                              <InputText
                                value={legalName}
                                name="legalName"
                                onChange={(e) => setLegalName(e.target.value)}
                                placeholder="Nombre legal"
                                disabled={freezeBuyerInfo}
                                helperText={errorMsgLegalName}
                                error={hasLegalNameErrors}
                              />
                              <InputText
                                value={tradeName}
                                name="tradeName"
                                onChange={(e) => {
                                  setTradeName(e.target.value);
                                  if (e.target.value.trim().length > 3) {
                                    setErrorMsgTradeName(null);
                                    setHasTradeNameErrors(false);
                                  }
                                }}
                                placeholder="Nombre comercial"
                                helperText={
                                  errorMsgTradeName ||
                                  "Es el nombre que usaremos para presentarte ante tus clientes"
                                }
                                error={hasTradeNameErrors}
                              />
                              <InputText
                                value={addressLine}
                                name="addressLine"
                                onChange={(e) => {
                                  setAddressLine(e.target.value);
                                  if (e.target.value.trim().length > 5) {
                                    setErrorMsgAddressLine(null);
                                    setHasAddressLineErrors(false);
                                  }
                                }}
                                placeholder="Domicilio fiscal"
                                helperText={errorMsgAddressLine}
                                error={hasAddressLineErrors}
                              />
                              <Autocomplete
                                id="combo-box"
                                options={direcciones}
                                getOptionLabel={(option) =>
                                  `${option.departamento.toUpperCase()}-${option.provincia.toUpperCase()}-${option.distrito.toUpperCase()}`
                                }
                                fullWidth={true}
                                popupIcon={<LocationOn />}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Ubigeo"
                                    placeholder="Buscar..."
                                    variant="outlined"
                                  />
                                )}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <Paper style={{ width: "100%" }}>
                                      <div style={{ padding: "5px" }}>
                                        {option.departamento.toUpperCase()}-
                                        {option.provincia.toUpperCase()}-
                                        {option.distrito.toUpperCase()}
                                        <div>
                                          <small style={{ color: "#777" }}>
                                            {option.code}
                                          </small>
                                        </div>
                                      </div>
                                    </Paper>
                                  </React.Fragment>
                                )}
                                PaperComponent={({ children }) => (
                                  <Card style={{ background: "#f3f3f3" }}>
                                    <CardContent>{children}</CardContent>
                                  </Card>
                                )}
                                size="small"
                                value={selectUbigeo}
                                onChange={(event, value) => {
                                  setSelectUbigeo(value);
                                  setDireccionUbigeo(value?.code);
                                }}
                              />
                              {direccionUbigeo === "" ||
                                (direccionUbigeo === undefined && (
                                  <Typography variant="body2" color="error">
                                    Seleccionar Ubigeo*
                                  </Typography>
                                ))}
                            </>
                          )}
                        </div>
                      )}
                      {index === 1 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <TextStyle bold={400} type="h4">
                            Agrega información adicional de tu empresa
                          </TextStyle>
                          <InputText
                            value={emailAdress}
                            name="emailAdress"
                            onChange={(e) => {
                              setEmailAdress(e.target.value);
                              if (validateEmail(e.target.value)) {
                                seterrorMsgEmail(null);
                                sethasEmailErrors(false);
                              }
                            }}
                            placeholder="Correo"
                            helperText={errorMsgEmail}
                            error={hasEmailErrors}
                          />
                          <InputNumber
                            value={phoneNumber}
                            name="phoneNumber"
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                              if (isValidTelephoneNumber(e.target.value)) {
                                setErrorMsgPhone(null);
                                setHasPhoneErrors(false);
                              }
                            }}
                            placeholder="Teléfono de contacto"
                            helperText={errorMsgPhone}
                            error={hasPhoneErrors}
                            maxLength={9}
                          />
                          <InputText
                            value={webSite}
                            name="webSite"
                            onChange={(e) => {
                              setWebSite(e.target.value);
                            }}
                            placeholder="Página web"
                            helperText={errorMsgAddressLine}
                            error={hasAddressLineErrors}
                          />
                        </div>
                      )}
                      {index === 2 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <TextStyle bold={400} type="h4">
                            ¿Desea enviar documentos desde la Plataforma?
                          </TextStyle>
                          <RadioGroup
                            row
                            value={credencialSUNAT}
                            onChange={(event: any) => {
                              setCredencialSUNAT(event.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="true"
                              control={<RRadio color="primary" />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="false"
                              control={<RRadio color="primary" />}
                              label="NO"
                            />
                          </RadioGroup>
                          {credencialSUNAT === "true" && (
                            <>
                              <TextStyle bold={400} type="h4">
                                Agrega el usuario/clave SOL asociado a la
                                empresa
                              </TextStyle>
                              <InputText
                                value={userSOL}
                                name="userSOL"
                                onChange={(e) => {
                                  setUserSOL(e.target.value);
                                  if (e.target.value.length === 8) {
                                    setErrorMsgUserSOL(null);
                                    setHasUserSolErrors(false);
                                  }
                                }}
                                placeholder="Usuario SOL"
                                helperText={errorMsgUserSOL}
                                error={hasUserSolErrors}
                              />
                              <InputPassword
                                value={passwordSOL}
                                name="passwordSOL"
                                onChange={(e) => {
                                  setPasswordSOL(e.target.value);
                                  if (
                                    e.target.value.length >= 8 &&
                                    e.target.value.length <= 12
                                  ) {
                                    setErrorMsgPassSOL(null);
                                    setHasPassSolErrors(false);
                                  }
                                }}
                                placeholder="Clave SOL"
                                error={hasPassSolErrors}
                                helperText={errorMsgPassSOL}
                              />
                            </>
                          )}
                        </div>
                      )}
                      {index === 3 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ marginTop: 20 }}>
                            <TextStyle bold={700} size="35px" sizeMobile="24px">
                              Confirmar
                            </TextStyle>
                            <TextStyle bold={400} type="h3">
                              Al continuar con el registro de la empresa, usted
                              reconoce y acepta nuestra{" "}
                              <span
                                onClick={() => {
                                  setOpenPrivacyPolicy(true);
                                }}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                política de privacidad
                              </span>
                            </TextStyle>
                          </div>
                          {errorMsgTryingRegister !== "" && (
                            <InputLabel
                              style={{
                                color: "#f44336",
                                fontSize: "0.75rem",
                                marginLeft: 14,
                                marginTop: 8,
                              }}
                            >
                              {errorMsgTryingRegister}
                            </InputLabel>
                          )}
                        </div>
                      )}
                      <div style={{ marginBottom: 5, marginTop: 25 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            maxWidth: "250px",
                          }}
                        >
                          {activeStep !== 0 && (
                            <ButtonSecondary
                              onClick={handleBack}
                              border={Colors.GREEN}
                              color={Colors.GREEN}
                              size="14px"
                              width="100px"
                            >
                              Atras
                            </ButtonSecondary>
                          )}
                          <ButtonPrimary
                            onClick={handleNext}
                            background={Colors.GREEN}
                            color={Colors.WHITE}
                            size="14px"
                            width="100px"
                          >
                            {activeStep === steps.length - 1
                              ? "Registrar"
                              : "Continuar"}
                          </ButtonPrimary>
                        </div>
                      </div>
                    </StepContent>
                  </RStep>
                ))}
              </Stepper>
            </div>
            {activeStep === steps.length && (
              <Paper square elevation={0} style={{ paddingLeft: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextStyle bold={700} size="35px" sizeMobile="24px">
                    ¡Registro exitoso!
                  </TextStyle>
                  <TextStyle bold={400} type="h3">
                    Se ha registrado exitosamente la empresa, por motivos de
                    seguridad nuestro equipo validará la cuenta a continuación.
                    De ser necesario será contactado a fin de validar su
                    información.
                  </TextStyle>

                  <div
                    style={{
                      display: "flex",
                      padding: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={newCompanyIllust}
                      style={{ height: 150 }}
                      alt="nueva empresa"
                    />
                  </div>
                </div>
              </Paper>
            )}
          </DialogContent>
          <DialogActions style={{ padding: 20 }}>
            {activeStep === steps.length ? (
              <ButtonSecondary
                onClick={() => close(true)}
                size="14px"
                width="100px"
                border={Colors.GREEN}
                color={Colors.GREEN}
              >
                Cerrar
              </ButtonSecondary>
            ) : (
              <ButtonSecondary
                onClick={() => close(false)}
                size="14px"
                width="180px"
                border={Colors.GREEN}
                color={Colors.GREEN}
              >
                Registrar luego
              </ButtonSecondary>
            )}
          </DialogActions>
        </>
      )}
      <PrivacyPolicy
        isOpen={openPrivacyPolicy}
        close={() => setOpenPrivacyPolicy(false)}
      />
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default RegisterCompanyModal;
