export interface Configuration extends configReport, configReception{
  session_id: string;
  token: string | null;
  loading: boolean | null;
  workspace: string | null;
  dateType: string;
  emissionStartDate: string | null;
  emissionEndDate: string | null;
  docType: string | null;
  docState: string | null;
  emittedRowsPerPage: number;
  emittedPageTotal: number;
  emittedPageCurrent: number;
  emittedFilterSerie: string | null;
  emittedFilterInitSeq: number | null;
  emittedFilterEndSeq: number | null;
  emittedClientId: number|null;
  emittedClientName:string | null;
}
interface configReport{
  typeDate: string;
  reportStartDate: string | null;
  reportEndDate: string | null;
  typeDoc: string | null;
  stateDoc: string | null;
  reportFilterSerie: string | null;
  reportFilterInitSeq: number | null;
  reportFilterEndSeq: number | null;
  reportTotal: number | null;
  emittedClientReport: number | null;
  emittedClientReportName:string | null;

}
interface configReception{
  receptiondateType: string;
  receptionStartDate: string | null;
  receptionEndDate: string | null;
  receptiondocType: string | null;
  receptiondocState: string | null;
  receptionRowsPerPage: number;
  receptionPageTotal: number;
  receptionPageCurrent: number;
  receptionFilterSerie: string | null;
  receptionFilterInitSeq: number | null;
  receptionFilterEndSeq: number | null;
  receptionProveedorId: number|null; 
  receptionProveedorName: string|null; 
}

export const SET_CONFIG_PARAM = "SET_CONFIG_PARAM";

interface GetConfigAction {
  type: typeof SET_CONFIG_PARAM;
  payload: any;
}

export type ConfigActionTypes = GetConfigAction;
