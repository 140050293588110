import { IconButton, InputAdornment, Radio, RadioProps, TextField, withStyles } from "@material-ui/core";
import React from "react";
import { Colors } from "../../static/colors";
import { useStyles } from "./styled";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { es } from "date-fns/locale";

interface IInputProps {
  placeholder: string;
  name: string;
  value: any;
  onChange: (e: any, value?: any) => void;
  error?: boolean;
  helperText?: string | null;
  maxLength?: number;
  disabled?: boolean;
  onBlur?: () => void;
  onFocus?: (e?: any) => void;
  dataOpenPayCard?: string;
  onKeyUp?: (e?: any) => void;
  minDate?: any;
  maxDate?: any;
}

export const InputText: React.FC<IInputProps> = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  maxLength = 100,
  disabled = false,
  onBlur,
  onFocus,
  dataOpenPayCard,
  onKeyUp,
}) => {
  const classes = useStyles({
    color: Colors.TEXT,
    colorFocus: Colors.GREEN,
  });

  return (
    <TextField
      label={placeholder}
      variant="filled"
      className={classes.customTextField}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      autoComplete="new-text"
      inputProps={{ maxLength }}
      disabled={disabled}
      onBlur={onBlur}
      onFocus={onFocus}
      data-openpay-card={dataOpenPayCard}
      onKeyUp={onKeyUp}
    />
  );
};

export const InputLetter: React.FC<IInputProps> = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  maxLength = 100,
  disabled = false,
  onBlur,
  onFocus,
  dataOpenPayCard,
}) => {
  const onlyLetters = (e: any) => {
    e.target.value = e.target.value.replace(/[^a-zA-ZñÑ\s]/g, "");
  };

  const classes = useStyles({
    color: Colors.TEXT,
    colorFocus: Colors.GREEN,
  });

  return (
    <TextField
      label={placeholder}
      variant="filled"
      className={classes.customTextField}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      autoComplete="new-letter"
      inputProps={{ maxLength }}
      disabled={disabled}
      onInput={(e) => onlyLetters(e)}
      onBlur={onBlur}
      onFocus={onFocus}
      data-openpay-card={dataOpenPayCard}
    />
  );
};

export const InputNumber: React.FC<IInputProps> = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  maxLength = 100,
  disabled = false,
  onBlur,
  onFocus,
  dataOpenPayCard,
}) => {
  const onlyNumbers = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const classes = useStyles({
    color: Colors.TEXT,
    colorFocus: Colors.GREEN,
  });

  return (
    <TextField
      label={placeholder}
      variant="filled"
      className={classes.customTextField}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      autoComplete="new-number"
      inputProps={{ maxLength }}
      disabled={disabled}
      onInput={(e) => onlyNumbers(e)}
      onBlur={onBlur}
      onFocus={onFocus}
      data-openpay-card={dataOpenPayCard}
    />
  );
};

export const InputNumberDecimal: React.FC<IInputProps> = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  maxLength = 100,
  disabled = false,
  onBlur,
  onFocus,
  dataOpenPayCard,
}) => {
  const onlyNumbers = (e: any) => {
    e.target.value = e.target.value
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*)\./g, '$1')
    .replace(/(^\.+)/, '');;
  };

  const classes = useStyles({
    color: Colors.TEXT,
    colorFocus: Colors.GREEN,
  });

  return (
    <TextField
      label={placeholder}
      variant="filled"
      className={classes.customTextField}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      autoComplete="new-number"
      inputProps={{ maxLength }}
      disabled={disabled}
      onInput={(e) => onlyNumbers(e)}
      onBlur={onBlur}
      onFocus={onFocus}
      data-openpay-card={dataOpenPayCard}
    />
  );
};

export const InputPassword: React.FC<IInputProps> = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  maxLength = 100,
  disabled = false,
  onBlur,
  onFocus,
}) => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const classes = useStyles({
    color: Colors.TEXT,
    colorFocus: Colors.GREEN,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <TextField
      label={placeholder}
      variant="filled"
      className={classes.customTextField}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      autoComplete="new-password"
      inputProps={{ maxLength }}
      disabled={disabled}
      type={values.showPassword ? "text" : "password"}
      onBlur={onBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onFocus={onFocus}
    />
  );
};

export const InputDate: React.FC<IInputProps> = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  disabled = false,
  onBlur,
  onFocus,
  minDate = null,
  maxDate = null,
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(value);
  const [focus, setFocus] = React.useState(false);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onChange(name, date);
  };

  const classes = useStyles({
    color: !focus ? Colors.TEXT : Colors.GREEN,
    colorFocus: Colors.GREEN,
    border: !focus ? 1 : 2,
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        label={placeholder}
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        className={classes.customInputDate}
        autoOk
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
          setFocus(true);
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
          setFocus(false);
        }}
        disabled={disabled}
        error={error}
        helperText={helperText}
        name={name}
        minDate={minDate}
        maxDate={maxDate}
      />
    </MuiPickersUtilsProvider>
  );
};

export const GreenRadio = withStyles({
  root: {
    color: Colors.TEXT,
    '&$checked': {
      color: Colors.GREEN,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
