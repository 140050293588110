import { withStyles } from "@material-ui/core";
import { Colors, Sizes } from "src/infrastructure/static/colors";
import styled from "styled-components";
import MuiAccordion from "@material-ui/core/Accordion";
import { sizeSidebar } from "src/static/sizeSidebar";

interface MainContainerProps {
  active: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  background-color: #4b59f7;
  height: 50px;
  width: 100vw;

  padding: ${(props) =>
    !props.active ? '200px' : 'calc(var(--nav-width) + 2rem)'};
  transition: 2s;
  padding-top: calc(var(--header-height) + 2rem);
  padding-right: 1px;

  @media only screen and (max-width: 768px) {
    padding: ${(props) => (props.active ? '0px' : '78px')};
    padding-top: 50px;
  }
`;

export const Sidebar = styled.div<{ isOpen: boolean }>`
  min-height: 90vh;
  height: 100%;
  border-right: 1px solid ${Colors.PURPLE};
  padding: 10px 20px;
  margin-right: 1px;
  transition: border-right 0.5s ease, transform 0.5s ease;
  transform: translateX(${(props) => (props.isOpen ? '0' : '-100%')});
  position: relative;

  @media ${Sizes.TABLET} {
    display: none;
  }
`;

export const ToggleButton = styled.button<{ isOpen: boolean }>`
  position: absolute;
  top: 4%;
  right: ${(props) => (props.isOpen ? '0' : '-40px')}; /* Ajusta la posición cuando está cerrado */
  background-color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: ${Colors.PURPLE};
  z-index: 2;
`;

export const ContainerLayout = styled.div`
  /* padding: 50px 20px; */
  height: 50px;
`;

export const Accordion = withStyles({
  root: {
    boxShadow: "none",
    padding: 0,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);
