import React from "react";
import { Checkbox } from "@material-ui/core";
import { ContarinerCheckbox, useStyles } from "./styled";
import TextStyle from "../textStyle";
import { Colors } from "../../static/colors";

interface ICheckboxProps {
  value: boolean;
  onChange: (any?: any) => void;
  name: string;
  renderText: () => void;
  color: string;
  error?: boolean;
  helperText?: string;
  margin?: string;
}

const CustomCheckbox: React.FC<ICheckboxProps> = ({
  name,
  onChange,
  value = false,
  renderText,
  color,
  error,
  helperText,
  margin,
}) => {
  const classes = useStyles({ color });

  return (
    <>
      <ContarinerCheckbox margin={margin}>
        <Checkbox
          checked={value}
          onChange={onChange}
          name={name}
          className={classes.customCheckbox}
        />
        {renderText()}
      </ContarinerCheckbox>
      {error && (
        <TextStyle color={Colors.RED} bold={400} type="h5">
          {helperText}
        </TextStyle>
      )}
    </>
  );
};

export default CustomCheckbox;
