export const Colors = {
  primary: "#21a366",
  secundary: "#107c41",
  primaryLigth: "#d4f7e7",
  textOlive: "#2e4646",
  iconOlive: "#37503d",
  voided: "#6d6d6d",
  pending: "#ffa14e",
  successful: "#1c6c36",
  alert: "#ff1c37",
  border: "#e4e1e1",
  blueAlternative: "#149a8e",
  blueAlternativeSecundary: "#14759a",
};
