import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { afterLogin } from "../../domain/store/afterLogin";
import { Home } from "../../views/home";
import axios from "axios";
import Login from "../views/Login";
import Consult from "../views/Consult";
import ResetPassword from "../views/ResetPassword";
import ConsultResult from "../views/ConsultResult";
import Reports from "../views/Reports";
import NewInvoice from "../views/NewInvoice";
import Reception from "../views/Reception";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Emission from "../views/Emission";
import NewRetention from "../views/NewRetention";
import NewGuieRemission from "../views/NewGuieRemission";
import Setting from "../views/Setting";
import Integrations from "../views/Integration";

export const Routes = () => {
  const config = useSelector((state: any) => state.config);
  const canAccess = config.token !== null && config.loading === false;
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Login}>
          {canAccess && <Redirect to="/ventas" />}
        </Route>
        <Route
          path="/callback/:token/:email/:name"
          render={({ match, location }) => (
            <AuthCallback
              email={match.params.email}
              name={match.params.name}
              token={match.params.token}
            />
          )}
        />
        <Route
          path="/reset-password/:token"
          render={({ match, location }) => (
            <ResetPassword token={match.params.token} />
          )}
        />
        <Route
          path="/consultar-resultado/:doctype/:serie/:correlative/:total_value/:ruc_emisor/:emission_date/:ruc_receptor?"
          component={ConsultResult}
        />
        <Route path={"/consultar"} component={Consult} />
        <PrivateRoute path="/home" component={Home} canAccess={canAccess} />
        <PrivateRoute
          path="/reportes"
          component={Reports}
          canAccess={canAccess}
        />
        <PrivateRoute
          path="/compras"
          component={Reception}
          canAccess={canAccess}
        />
        <PrivateRoute
          path="/ventas"
          component={Emission}
          canAccess={canAccess}
        />
        <PrivateRoute
          path="/nueva-factura-boleta"
          component={NewInvoice}
          canAccess={canAccess}
        />
        <PrivateRoute
          path="/nueva-retencion"
          component={NewRetention}
          canAccess={canAccess}
        />
        <PrivateRoute
          path="/nueva-guia"
          component={NewGuieRemission}
          canAccess={canAccess}
        />
        <PrivateRoute
          path="/configuracion/misdatos"
          component={Setting}
          canAccess={canAccess}
        />
        <PrivateRoute
          path="/configuracion/integraciones"
          component={Integrations}
          canAccess={canAccess}
        />
      </Switch>
      <ToastContainer />
    </div>
  );
};

interface Props {
  path: string;
  component: any;
  canAccess: boolean;
}

const PrivateRoute: React.FC<Props> = ({ path, component, canAccess }) => {
  if (canAccess) {
    return <Route path={path} component={component} />;
  } else {
    // Redirect to login page
    return (
      <Route exact path={path}>
        <Redirect to="/" />
      </Route>
    );
  }
};

interface CallabackProps {
  name: string;
  email: string;
  token: string;
}

const AuthCallback: React.FC<CallabackProps> = ({ name, email, token }) => {
  useEffect(() => {
    async function validateToken() {
      const { data } = await axios.post(`/api/validate/${token}`);
      if (data.result) {
        afterLogin(token, name, email, false);
      }
    }
    validateToken();
  }, []);

  return <Redirect to="/" />;
};
