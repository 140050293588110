import { Colors } from "../../static/colors";
import styled from "styled-components";

interface IBPrimary {
  background: string;
  color: string;
  width?: string;
  height?: string;
  size: string;
}

interface IBSecondary {
  border: string;
  color: string;
  width?: string;
  height?: string;
  size: string;
}

export const BPrimary = styled.button<IBPrimary>`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: 700;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "40px"};
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  outline: none;
  margin: 10px 0px;
  cursor: pointer;
  transition: all 0.5s ease;
  &:disabled {
    background: grey;
    color: ${Colors.WHITE};
  }
`;

export const BSecondary = styled.button<IBSecondary>`
  border: ${(props) => `1px solid  ${props.border}`};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: 700;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "40px"};
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  outline: none;
  margin: 10px 0px;
  cursor: pointer;
  background: ${Colors.WHITE};
  padding: 0px 10px;
  &:disabled {
    border: ${(props) => `1px solid  ${Colors.TEXT}`};
    color: ${Colors.TEXT};
    cursor: not-allowed;
  }
`;

export const BAuth = styled.a<IBSecondary>`
  border: ${(props) => `1px solid  ${props.border}`};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: 700;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "40px"};
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  outline: none;
  margin: 10px 0px;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
`;
