import { store } from "./configureStore";
import { ReceivedDoc } from "./types/receivedDoc";
import { EmittedDoc } from "./types/emittedDoc";
import { CompanyInfo } from "./types/companies";
import { Reporte } from "./types/report";
import axios from "axios";

export const fetch = async (toFetch: Array<string>) => {
  const { config } = store.getState();
  let workspace = config.workspace;

  if (toFetch.includes("companies") || toFetch.includes("all")) {
    let { data } = await axios.get<CompanyInfo[]>("/api/company/infoList");
    store.dispatch({
      type: "SET_COMPANY_INFO_LIST",
      payload: data as CompanyInfo[],
    });
    // Set selected workspace
    if (data.length > 0) {
      workspace = data.find((c) => c.status === "VAL")?.ruc.toString() || null;
      store.dispatch({
        type: "SET_CONFIG_PARAM",
        payload: { workspace },
      });
    }
  }

  if (workspace) {
    if (toFetch.includes("reception") || toFetch.includes("all")) {
      let { data } = await axios.get(
        `/api/reception/documents/${workspace}?dateOf=${
          config.receptiondateType
        }&startDate=${config.receptionStartDate}&endDate=${
          config.receptionEndDate
        }&startPage=${config.receptionPageCurrent || 0}&endPage=${
          config.receptionRowsPerPage
            ? (config.receptionPageCurrent + 1) * config.receptionRowsPerPage
            : 10
        }${
          config.receptionFilterSerie
            ? `&serie=${config.receptionFilterSerie}&seqInit=${config.receptionFilterInitSeq}&seqEnd=${config.receptionFilterEndSeq}`
            : ""
        }${
          config.receptionProveedorId
            ? `&proveedorId=${config.receptionProveedorId}`
            : ""
        }${
          config.receptiondocType ? `&docType=${config.receptiondocType}` : ""
        }${
          config.receptiondocState
            ? `&docStatus=${
                config.receptiondocState.split(",").includes("1-3")
                  ? config.receptiondocState.replace("1-3", "1,3")
                  : config.receptiondocState
              }`
            : ""
        }`
      );
      if (data.result) {
        //console.log("dataRecep",data);
        store.dispatch({
          type: "SET_RECEIVED_DOCS",
          payload: data.receivedDocs as ReceivedDoc[],
        });
      }
    }

    if (toFetch.includes("emission") || toFetch.includes("all")) {
      let { data } = await axios.get(
        `/api/emission/documents/${workspace}?dateOf=${
          config.dateType
        }&startDate=${config.emissionStartDate}&endDate=${
          config.emissionEndDate
        }&startPage=${config.emittedPageCurrent || 0}&endPage=${
          config.emittedRowsPerPage
            ? (config.emittedPageCurrent + 1) * config.emittedRowsPerPage
            : 10
        }${
          config.emittedClientId ? `&receiverId=${config.emittedClientId}` : ""
        }${
          config.emittedFilterSerie
            ? `&serie=${config.emittedFilterSerie}&seqInit=${config.emittedFilterInitSeq}&seqEnd=${config.emittedFilterEndSeq}`
            : ""
        }${config.docType ? `&docType=${config.docType}` : ""}${
          config.docState
            ? `&docStatus=${
                config.docState.split(",").includes("1-3")
                  ? config.docState.replace("1-3", "1,3")
                  : config.docState
              }`
            : ""
        }`
      );
      if (data.result) {
        //console.log(data);

        store.dispatch({
          type: "SET_CONFIG_PARAM",
          payload: { emittedPageTotal: data.totals },
        });
        store.dispatch({
          type: "SET_EMITTED_DOCS",
          payload: data.emittedDocs as EmittedDoc[],
        });
      }
    }

    if (toFetch.includes("emitted") || toFetch.includes("all")) {
      let { data } = await axios.get(
        `/api/emission/documents/${workspace}?dateOf=${
          config.dateType
        }&startDate=${config.emissionStartDate}&endDate=${
          config.emissionEndDate
        }&startPage=${config.emittedPageCurrent || 0}&endPage=${
          config.emittedRowsPerPage
            ? (config.emittedPageCurrent + 1) * config.emittedRowsPerPage
            : 10
        }${
          config.emittedClientId ? `&receiverId=${config.emittedClientId}` : ""
        }${
          config.emittedFilterSerie
            ? `&serie=${config.emittedFilterSerie}&seqInit=${config.emittedFilterInitSeq}&seqEnd=${config.emittedFilterEndSeq}`
            : ""
        }${config.docType ? `&docType=${config.docType}` : ""}${
          config.docState
            ? `&docStatus=${
                config.docState.split(",").includes("1-3")
                  ? config.docState.replace("1-3", "1,3")
                  : config.docState
              }`
            : ""
        }`
      );
      if (data.result) {
        //console.log(data);

        store.dispatch({
          type: "SET_CONFIG_PARAM",
          payload: { emittedPageTotal: data.totals },
        });
        store.dispatch({
          type: "SET_EMITTED_DOCS",
          payload: data.emittedDocs as EmittedDoc[],
        });
      }
    }
  }
  return true;
};
