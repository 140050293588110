import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import { WrapTextTwoTone as ChangeIcon } from "@material-ui/icons";
import { CompanyInfo } from "src/domain/models/Company";
import { setConfigParam } from "src/domain/store/actions/config";
import { fetch } from "src/domain/store/fetch";
import { ContainerNameCompany, ContainerTitle } from "./styled";
import TextStyle from "../textStyle";
import { Colors } from "src/infrastructure/static/colors";

interface Props {
  title: string;
}

const TitleFilter: React.FC<Props> = ({ title }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const companies = useSelector((state: any) => state.companiesInfo);
  const config = useSelector((state: any) => state.config);
  const dispatch = useDispatch();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectCompany = (
    event: React.MouseEvent<HTMLElement>,
    workspace: number
  ) => {
    dispatch(setConfigParam("workspace", workspace));
    handleClose();
    dispatch(setConfigParam("emittedPageCurrent", 0)); // Se resetea el paginado
    fetch(["reception", "emission", "reports"]);
  };

  return (
    <ContainerTitle>
      <TextStyle bold={700} color={Colors.WHITE} textAlign="center" type="h1">
        {title}
      </TextStyle>
      <ContainerNameCompany>
        <TextStyle bold={400} color={Colors.WHITE} textAlign="center" type="h3">
          {config.workspace !== null
            ? companies.find((c: CompanyInfo) => c.ruc === config.workspace)
                .legal_name
            : ""}
        </TextStyle>
        {companies.length > 1 && (
          <>
            <IconButton size="small" onClick={handleOpenMenu}>
              <ChangeIcon
                style={{
                  fontSize: 20,
                  color: Colors.WHITE,
                }}
              />
            </IconButton>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {companies.map((c: CompanyInfo) => (
                <MenuItem
                  key={c.ruc}
                  disabled={c.status === "NEW"}
                  selected={c.ruc === config.workspace}
                  onClick={(event) => handleSelectCompany(event, c.ruc)}
                >
                  {c.status === "NEW" ? (
                    <ListItemText
                      secondary={
                        <span style={{ fontStyle: "italic" }}>
                          Pendiente de validación
                        </span>
                      }
                      primary={c.legal_name}
                    />
                  ) : (
                    <ListItemText secondary={c.ruc} primary={c.legal_name} />
                  )}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </ContainerNameCompany>
    </ContainerTitle>
  );
};

export default TitleFilter;
