import { SET_CONFIG_PARAM, ConfigActionTypes } from "../types/config";

export function setConfigParam(
  parameter: string,
  value: string | number | null
): ConfigActionTypes {
  return {
    type: SET_CONFIG_PARAM,
    payload: { [parameter]: value },
  };
}
