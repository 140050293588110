import React from "react";
import { CompanyInformation } from "../components/companyInformation";
import DownloadEmitted from "../components/downloadEmitted";
import { EmittedDocList } from "../components/emittedDocList";
import Header from "../components/header";
import LayoutFilter from "../components/layoutFilter";
import LayoutSetting from "../components/layoutSetting";
import Loading from "../components/skeleton/loading";

const Setting = () => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
  };
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <Header />
      <LayoutSetting
        type="config"
        title="Configuración"
        componentLoading={Loading}
        onTabChange={handleTabChange}
      >
        
        <CompanyInformation activeIndex={activeTabIndex}/>
      </LayoutSetting>
    </div>
  );
};

export default Setting;
