import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { faCreditCard, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import Alert from "@material-ui/lab/Alert";
import {
  calculateDateReports,
  getDocumentStatusDescription,
} from "src/infrastructure/static/util";
import TextStyle from "../textStyle";
import { Colors } from "src/infrastructure/static/colors";
import { ButtonSecondary } from "../button";
import { ContainerCard, ContainerTitle } from "./styled";
import DownloadReports from "../downloadReports";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CloudDownload } from "@material-ui/icons";
import axios from "axios";
import FileDownload from "js-file-download";

const DashboardReports = () => {
  const resultReportDocs = useSelector((state: any) => state.reportDocs);
  const config = useSelector((state: any) => state.config);
  interface Column {
    id: "MB" | "Date" | "Type" | "BtnDownload";
    label: string;
    minWidth?: number;
    align?: "left";
    // format?: (value: number) => string;
  }

  const columns: Column[] = [
    { id: "MB", label: "", minWidth: 70 },
    { id: "Date", label: "Fecha Generada", minWidth: 80 },
    { id: "Type", label: "Tipo de Reporte", minWidth: 80 },
    { id: "BtnDownload", label: "", minWidth: 40 },
  ];

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageCli, setPageCli] = React.useState(0);
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPageCli(0);
  };

  const handleChangePageCli = (event: unknown, newPage: number) => {
    setPageCli(newPage);
  };

  const handleOpenMenuDescarga = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleCloseMenuDescarga = () => {
    setAnchorElement(null);
  };
  const bulkFileDownload = async (date: string, name: string) => {
    let { data } = await axios.get(
      `/api/emission/download/bulk/${config.workspace}/${date}/${name}`,
      {
        responseType: "blob",
      }
    );
    FileDownload(new Blob([data]), `Descarga-Masiva-${config.workspace}.zip`);
  };

  return (
    <div>
      <ContainerTitle>
        <div>
          <TextStyle bold={700} type="h1" margin="10px 0px 0px 0px">
            Resultado de Reportes
          </TextStyle>
        </div>
        <div>
          <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            onClick={handleOpenMenuDescarga}
            size="18px"
          >
            <GetAppIcon />
            Descargar Reporte
          </ButtonSecondary>
          <DownloadReports
            anchorEl={anchorElement}
            onCloseMenu={handleCloseMenuDescarga}
            totals={resultReportDocs.totalSend}
          />
        </div>
      </ContainerTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ContainerCard>
            <TextStyle
              bold={700}
              color={Colors.GREEN}
              size="40px"
              sizeMobile="24px"
            >
              {resultReportDocs ? resultReportDocs.totalSend : "0"}
            </TextStyle>
            <TextStyle bold={700} type="h2">
              Documentos enviados
            </TextStyle>
          </ContainerCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContainerCard>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextStyle bold={600} color={Colors.PURPLE} type="h1">
                  S/ {resultReportDocs ? resultReportDocs.totalPEN : "0"}
                </TextStyle>
                <TextStyle bold={700} type="h4" color={Colors.BLACK}>
                  PEN
                </TextStyle>
              </Grid>
              {resultReportDocs ? (
                resultReportDocs.totalDOLLAR !== 0 && (
                  <Grid item xs={6}>
                    <TextStyle bold={600} color={Colors.GREEN} type="h1">
                      $ {resultReportDocs.totalDOLLAR}
                    </TextStyle>
                    <TextStyle bold={700} type="h4" color={Colors.BLACK}>
                      USD
                    </TextStyle>
                  </Grid>
                )
              ) : (
                <Grid item xs={6}>
                  <TextStyle bold={600} color={Colors.GREEN} type="h1">
                    $ 0
                  </TextStyle>
                  <TextStyle bold={700} type="h4" color={Colors.BLACK}>
                    USD
                  </TextStyle>
                </Grid>
              )}
            </Grid>
            <TextStyle bold={700} type="h2">
              Total vendido
            </TextStyle>
          </ContainerCard>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={ContainerCard}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {resultReportDocs && resultReportDocs.stateDocuments ? (
                  resultReportDocs.stateDocuments.map(
                    (cod: any, index: number) => (
                      <TableRow>
                        <TableCell component="th" scope="row" key={index + 11}>
                          {cod.state === "0" && (
                            <Alert severity="warning">
                              {getDocumentStatusDescription(cod.state)}
                            </Alert>
                          )}
                          {cod.state === "1" && (
                            <Alert severity="success">
                              {getDocumentStatusDescription(cod.state)}
                            </Alert>
                          )}
                          {cod.state === "-1" && (
                            <Alert severity="error">
                              {getDocumentStatusDescription(cod.state)}
                            </Alert>
                          )}
                          {cod.state === "-2" && (
                            <Alert severity="error">
                              {getDocumentStatusDescription(cod.state)}
                            </Alert>
                          )}
                          {cod.state === "2" && (
                            <Alert severity="info">
                              {getDocumentStatusDescription(cod.state)}
                            </Alert>
                          )}
                          {cod.state === "4" && (
                            <Alert severity="warning">
                              {getDocumentStatusDescription(cod.state)}
                            </Alert>
                          )}
                          {cod.state === "5" && (
                            <Alert severity="warning">
                              {getDocumentStatusDescription(cod.state)}
                            </Alert>
                          )}
                        </TableCell>
                        <TableCell size="small" width={13}>
                          <TextStyle bold={600} color={Colors.BLACK} type="h2">
                            {cod.total_state}
                          </TextStyle>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <p>No existe registros</p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TableContainer component={ContainerCard}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      style={{ fontSize: "20px" }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <TextStyle bold={700} type="h3" color={Colors.BLACK}>
                      Contado
                    </TextStyle>
                  </TableCell>
                  <TableCell align="right">
                    {resultReportDocs ? (
                      <TextStyle bold={700} type="h3" color={Colors.BLACK}>
                        S/ {resultReportDocs.totalContadoPEN}
                      </TextStyle>
                    ) : (
                      "No existe registros"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {resultReportDocs ? (
                      <TextStyle bold={700} type="h3" color={Colors.BLACK}>
                        $ {resultReportDocs.totalContadoUSD}
                      </TextStyle>
                    ) : (
                      "No existe registros"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      style={{ fontSize: "20px" }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <TextStyle bold={700} type="h3" color={Colors.BLACK}>
                      Crédito
                    </TextStyle>
                  </TableCell>
                  <TableCell align="right">
                    {resultReportDocs ? (
                      <TextStyle bold={700} type="h3" color={Colors.BLACK}>
                        S/ {resultReportDocs.totalCreditoPEN}
                      </TextStyle>
                    ) : (
                      "No existe registros"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {resultReportDocs ? (
                      <TextStyle bold={700} type="h3" color={Colors.BLACK}>
                        $ {resultReportDocs.totalCreditoUSD}
                      </TextStyle>
                    ) : (
                      "No existe registros"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextStyle bold={700} type="h1">
            Reportes
          </TextStyle>
          <TextStyle bold={700} type="h2">
            Lista de reportes de los ultimos 30 dias
          </TextStyle>
          <ContainerCard>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id + 1}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {resultReportDocs && resultReportDocs.bulkDownloadFileList ? (
                  <TableBody>
                    {resultReportDocs.bulkDownloadFileList
                      .slice(
                        pageCli * rowsPerPage,
                        pageCli * rowsPerPage + rowsPerPage
                      )
                      .map((file: any, index: number) => {
                        return (
                          <TableRow>
                            <TableCell align="left" key={index + 3}>
                              {file.size} MB
                            </TableCell>
                            <TableCell align="left" key={index + 45}>
                              {file.date}{" "}
                              {calculateDateReports(file.name.split("_")[0])}
                            </TableCell>
                            <TableCell align="left" key={index + 45}>
                              {file.name.split("_")[1] === "emission.zip"
                                ? "R_DocumentosEmitidos"
                                : "R_DocumentosRecibidos"}
                            </TableCell>
                            <TableCell align="left" key={index + 13}>
                              <div
                                style={{
                                  height: 30,
                                  paddingBottom: "5px",
                                }}
                              >
                                <Tooltip title="Descargar" arrow>
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      bulkFileDownload(file.date, file.name);
                                    }}
                                  >
                                    <CloudDownload
                                      style={{
                                        fontSize: 22,
                                        color: Colors.iconOlive,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  "No existe registros"
                )}
              </Table>
            </TableContainer>
            {resultReportDocs && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count !== -1 ? count : `mas de ${to}`}`
                }
                component="div"
                count={
                  resultReportDocs.bulkDownloadFileList
                    ? resultReportDocs.bulkDownloadFileList.length
                    : 0
                }
                rowsPerPage={rowsPerPage}
                page={pageCli}
                onPageChange={handleChangePageCli}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </ContainerCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardReports;
