import axios from "axios";
import { flushStorage } from "../../domain/store/flush";
import { store } from "../../domain/store/configureStore";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://test.ratifika.com.pe"
    : "https://test.ratifika.com.pe";

const intializeAxios = async () => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  // Request interceptor
  axios.interceptors.request.use(
    (request) => {
      const state = store.getState();
      if (state.config.token) {
        request.headers.auth = state.config.token;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // Response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.error("API Error: ", error);
      if (error.response.status === 412 || error.response.status === 401) {
        flushStorage(error.response.status === 412);
      }
      return Promise.reject(error);
    }
  );
};

export default intializeAxios;
