import { Button, Table, TableBody, Chip, TableCell, Paper, TableContainer, TableHead, TableRow, FormControlLabel, Checkbox } from '@material-ui/core'
import { Filter, Search } from '@material-ui/icons'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
export const Loading = () => {
    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },

    ]


    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row-reverse",
                paddingBottom: 8
            }}
            >
                <Button
                    style={{
                        alignContent: "center",
                        justifyContent: "center",
                        fontSize: 14,
                        height: 30,
                        width: 80,
                        backgroundColor: "#e6e6e6",
                        marginRight: 10,
                    }}

                >
                    <Skeleton />
                </Button>
                <Button
                    style={{
                        alignContent: "center",
                        justifyContent: "center",
                        fontSize: 14,
                        height: 30,
                        width: 80,
                        backgroundColor: "#e6e6e6",
                        marginRight: 10,
                    }}

                >
                    <Skeleton />
                </Button>
                <Chip
                    size="small"
                    style={{ marginRight: 5, width: "80px" }}
                    label={<Skeleton />}
                />

                <Chip
                    size="small"
                    style={{ marginRight: 5, width: "80px" }}
                    label={<Skeleton />}
                />



            </div>

            <TableContainer  >
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell> <Skeleton /></TableCell>
                            <TableCell align="right"> <Skeleton /></TableCell>
                            <TableCell align="right"> <Skeleton /></TableCell>
                            <TableCell align="right"> <Skeleton /></TableCell>
                            <TableCell align="right"> <Skeleton /></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    <Skeleton />
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">  
                                <Skeleton
                                    circle
                                    width={30}
                                    height={15}
                                />
                                
                                </TableCell>
                                <TableCell align="center"><Skeleton /></TableCell>
                                <TableCell align="center"><Skeleton /></TableCell>
                                <TableCell align="center">
                                        <Skeleton
                                        circle
                                        width={20}
                                        height={20}
                                />                                
                                
                                 </TableCell>
                               
                            </TableRow>
                        ))}


                    </TableBody>
                </Table>
            </TableContainer>
            <Skeleton />
        </>
    )
}
