import {
  CircularProgress,
  Container,
  Drawer,
  Grid,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { comunService } from "../../../domain/services/Comun.service";
import {
  getCurrencySymbol,
  getDocumentStatusDescription,
  getDocumentTypeDesc,
} from "../../static/util";
import { Colors } from "../../static/colors";
import { ButtonPrimary } from "../button";
import { NoteBox, NoteType } from "../noteBox";
import TextStyle from "../textStyle";
import {
  ButtonClose,
  ContainerHead,
  ContainerResult,
  ContainerTitle,
} from "./styled";
import TableProducts from "./table";
import { IEmittedDoc } from "src/domain/models/Emitted";
import { ISearchDocumentParams } from "src/domain/models/Comun";
import DownloadFile from "./downloadFiles";
import { companyService } from "src/domain/services/Company.service";
import { RegisterForm } from "../registerForm";
import { LoginForm } from "../loginForm";
import CloseIcon from "@material-ui/icons/Close";

const TableConsultResult = () => {
  const {
    doctype,
    serie,
    correlative,
    total_value,
    ruc_emisor,
    emission_date,
    ruc_receptor,
  }: ISearchDocumentParams = useParams();

  const [infoFile, setInfoFile] = React.useState<IEmittedDoc>();
  const [logoCompany, setLogoCompany] = React.useState<string>("");
  const [infoCompany, setInfoCompany] = React.useState<any>();
  const [totalDocuments, setTotalDocuments] = React.useState<number>(0);
  const [openMenu, setOpenMenu] = React.useState<number>(0);

  const getLogoCompany = async (number: string) => {
    try {
      const response = await comunService.getLogo(number);
      setLogoCompany(response);
    } catch (err) {
      setLogoCompany("");
    }
  };

  const getCompanyInfo = async (number: string) => {
    try {
      const response = await companyService.getCompanyInfo(number);
      setInfoCompany(response.data);
    } catch (err) {
      setInfoCompany(null);
    }
  };

  const getSearchDocument = async () => {
    try {
      const response = await comunService.getSearchDocument({
        typeDoc: doctype,
        serie,
        correlative,
        totalAmount: total_value,
        rucEmitter: ruc_emisor,
        emissionDate: emission_date,
        rucReceptor: ruc_receptor,
      });
      const file = response.data.file;
      if (file) {
        setInfoFile(file);
        setTotalDocuments(response.data.totalDoc);
        getLogoCompany(file.emitter_company.number);
        getCompanyInfo(file.emitter_company.number);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  React.useEffect(() => {
    getSearchDocument();
  }, []);

  React.useEffect(() => {
    if (totalDocuments !== 0) {
      function animateValue(obj: any, start: any, end: any, duration: any) {
        let startTimestamp: any = null;
        const step = (timestamp: any) => {
          if (!startTimestamp) startTimestamp = timestamp;
          const progress = Math.min((timestamp - startTimestamp) / duration, 1);
          obj.innerHTML = Math.floor(progress * (start - end) + end);
          if (progress < 1) {
            window.requestAnimationFrame(step);
          }
        };
        window.requestAnimationFrame(step);
      }

      const obj = document.getElementById("value");
      if (totalDocuments > 600) animateValue(obj, totalDocuments, 0, 3000);
      if (totalDocuments < 200) animateValue(obj, totalDocuments, 0, 5000);
      if (totalDocuments < 50) animateValue(obj, totalDocuments, 0, 6000);
      if (totalDocuments < 5) animateValue(obj, totalDocuments, 0, 100);
    }
  }, [totalDocuments]);

  return (
    <div style={{ paddingBottom: 100 }}>
      <ContainerTitle>
        <TextStyle color={Colors.WHITE} type="h1" textAlign="center" bold={700}>
          ¡Hola! {infoFile?.receiver?.legal_name}
        </TextStyle>
        <TextStyle type="h1" textAlign="center" bold={700} color={Colors.WHITE}>
          Encontramos &nbsp;
          <span style={{ fontSize: 50 }}>
            {totalDocuments !== 0 ? (
              <div
                id="value"
                style={{
                  fontSize: "50px !important",
                  display: "inline",
                  textAlign: "center",
                }}
              ></div>
            ) : (
              <CircularProgress size="30px" style={{ color: Colors.WHITE }} />
            )}
          </span>
          &nbsp;comprobante(s) con tu información
        </TextStyle>
        <ButtonPrimary
          background={Colors.GREEN}
          color={Colors.WHITE}
          size="24px"
          width="300px"
          onClick={() => setOpenMenu(1)}
          height="60px"
        >
          Descúbrelos aquí
        </ButtonPrimary>
      </ContainerTitle>
      {infoFile && (
        <ContainerResult>
          <ContainerHead>
            <img src={logoCompany} alt="logoCompany" width="200px" />
            {["-1", "-2", "4"].includes(infoFile?.state) && (
              <NoteBox
                type={NoteType.Error}
                title={getDocumentStatusDescription(infoFile?.state)}
                body={infoFile?.error_desc}
              />
            )}
            {infoFile.state === "0" && (
              <NoteBox
                type={NoteType.Warning}
                title="Pendiente"
                body={getDocumentStatusDescription(infoFile?.state)}
              />
            )}
            {infoFile.state === "2" && (
              <NoteBox
                type={NoteType.Info}
                title="Anulado"
                body={getDocumentStatusDescription(infoFile?.state)}
                details={
                  infoFile?.metadata
                    ? [
                        `Comprobante de baja: ${infoFile?.metadata.void_number}`,
                        `Emitido el: ${moment(
                          infoFile.metadata.void_date
                        ).format("DD/MM/YYYY")}`,
                        `Ticket: ${infoFile.metadata.void_ticket}`,
                        `Motivo de la anulación: ${infoFile?.metadata.void_reason}`,
                      ]
                    : []
                }
              />
            )}
            {infoFile.state === "1" && (
              <NoteBox
                type={NoteType.Successful}
                title="Autorizado"
                body={getDocumentStatusDescription(infoFile?.state)}
                details={
                  infoFile.metadata
                    ? [`Forma de Pago: ${infoFile?.metadata.formaPago}`]
                    : []
                }
              />
            )}
          </ContainerHead>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextStyle type="h3" color={Colors.TEXT} bold={400}>
                <b>Tipo de documento: </b>
                {getDocumentTypeDesc(infoFile?.document_type)}{" "}
              </TextStyle>
              <TextStyle type="h3" color={Colors.TEXT} bold={400}>
                <b>RUC Emisor: </b>
                {infoFile?.emitter_company?.number}
              </TextStyle>
              <TextStyle type="h3" color={Colors.TEXT} bold={400}>
                <b>Fecha de emisión: </b>
                {emission_date}
              </TextStyle>
              <TextStyle type="h3" color={Colors.TEXT} bold={700}>
                Nº: {infoFile?.serie}-{infoFile.number}
              </TextStyle>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextStyle type="h3" color={Colors.TEXT} bold={400}>
                <b>RUC Receptor: </b>
                {infoFile?.receiver.number}
              </TextStyle>
              <TextStyle type="h3" color={Colors.TEXT} bold={400}>
                <b>Cliente: </b>
                {infoFile?.receiver.legal_name}
              </TextStyle>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextStyle
                type="h3"
                color={Colors.TEXT}
                bold={400}
                textAlign="end"
              >
                <b>Total gravado: </b>
                {getCurrencySymbol(infoFile?.currency)}
                {infoFile?.subtotal}
              </TextStyle>
              <TextStyle
                type="h3"
                color={Colors.TEXT}
                bold={400}
                textAlign="end"
              >
                <b>Total IGV: </b>
                {getCurrencySymbol(infoFile?.currency)}
                {infoFile?.total_igv}
              </TextStyle>
              <TextStyle
                type="h1"
                color={Colors.GREEN}
                bold={700}
                textAlign="end"
              >
                <b>TOTAL: </b>
                {getCurrencySymbol(infoFile?.currency)}
                {infoFile?.total_value}
              </TextStyle>
            </Grid>
          </Grid>
          {infoFile && infoCompany && (
            <>
              <TableProducts products={infoFile?.products || []} />
              <DownloadFile
                infoFile={infoFile}
                companyInfo={infoCompany}
                dataConsult={{
                  typeDoc: doctype,
                  serie,
                  correlative,
                  totalAmount: total_value,
                  rucEmitter: ruc_emisor,
                  emissionDate: emission_date,
                  rucReceptor: ruc_receptor,
                }}
                getData={getSearchDocument}
              />
            </>
          )}
        </ContainerResult>
      )}
      <Drawer
        anchor="right"
        open={openMenu !== 0}
        onClose={() => setOpenMenu(0)}
      >
        <Container maxWidth="xs">
          <ButtonClose>
            <IconButton onClick={() => setOpenMenu(0)}>
              <CloseIcon />
            </IconButton>
          </ButtonClose>
          {openMenu === 1 && (
            <RegisterForm openLoginForm={() => setOpenMenu(2)} />
          )}
          {openMenu === 2 && (
            <LoginForm openRegisterForm={() => setOpenMenu(1)} />
          )}
        </Container>
      </Drawer>
    </div>
  );
};

export default TableConsultResult;
