import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Drawer, Typography, CardContent } from "@material-ui/core";
import {
  TouchAppTwoTone,
  BlockTwoTone,
  Done,
  ErrorOutline,
  WarningTwoTone,
} from "@material-ui/icons";
import UseAnimations from "react-useanimations";
import radioButton from "react-useanimations/lib/radioButton";
import loading from "react-useanimations/lib/loading";
import { Colors } from "../../static/colors";
import * as xml2json from "xml-js";
import axios from "axios";
import JSZip from "jszip";
import { useSelector, useDispatch } from "react-redux";
import { fetch } from "../../store/fetch";
import { setUnadjudicatedDocs } from "../../store/actions/unadjudicatedDocs";
import { UnadjudicatedDoc } from "../../store/types/unadjudicatedDoc";
import "./upload.css";

interface UploadProps {
  title: string;
  subtitle?: string;
  isOpen: boolean;
  closeForm: () => void;
}

export const UploadReceivedDoc: React.FC<UploadProps> = ({
  title,
  subtitle,
  isOpen,
  closeForm,
}) => {
  const config = useSelector((state: any) => state.config);

  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "application/xml, text/xml",
    maxFiles: 50,
    maxSize: 2000000,
  });

  return (
    <Drawer anchor="right" open={isOpen} onClose={closeForm} style={{ maxWidth: 50 }}>
      <CardContent>
        <Typography
          variant="h6"
          component="h2"
          style={{
            fontFamily: "Corbel",
            color: Colors.textOlive,
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body2" component="p" style={{ color: "#a09d9d" }}>
            {subtitle}
          </Typography>
        )}
        <section className="container" style={{ width: "25em" }}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {isDragAccept && <TouchAppTwoTone style={{ fontSize: 70 }} />}
            {isDragReject && <BlockTwoTone style={{ fontSize: 70 }} />}
            {!isDragActive && (
              <p style={{ color: "#a1b3c3", fontSize: "1.5em" }}>
                Arrastra y suelta tus archivos Xml aquí, o haz clic para seleccionarlos
              </p>
            )}
            <em style={{ fontSize: 14 }}>
              (Solo archivos *.xml hasta un límite de 25 documentos a la vez)
            </em>
          </div>
          <aside>
            {acceptedFiles.length > 0 && (
              <ul style={{ paddingInlineStart: "0em" }}>
                {acceptedFiles.map((file: File) => (
                  <UploadItem
                    key={file.name}
                    file={file}
                    session_id={config.session_id}
                    workspace={config.workspace}
                  />
                ))}
              </ul>
            )}
          </aside>
        </section>
        <div />
      </CardContent>
    </Drawer>
  );
};

enum DocStatus {
  loading,
  error,
  ok,
  warning,
  alert,
}

interface GeneralStatus {
  status: DocStatus;
  msg: string | null;
}

enum ItemStatus {
  ok,
  warning,
  none,
}

interface ItemResult {
  result: string;
  docStatus: ItemStatus;
  docStatusDesc: string;
  rucStatus: ItemStatus;
  rucStatusDesc: string;
  addressStatus: ItemStatus;
  addressStatusDesc: string;
}

interface Props {
  file: File;
  session_id: string;
  workspace: string | null;
}

const UploadItem: React.FC<Props> = ({ file, session_id, workspace }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        let xmlData: any = reader.result;
        const json = JSON.parse(xml2json.xml2json(xmlData, { compact: true }));
        let res = await axios.post("/api/reception/upload", {
          document: json,
          origin: "WEB",
          session_id,
        });
        const data = res.data;
        if (data.result) {
          setItemResult({
            // Doc status
            docStatus:
              data.state_doc === "1" || data.state_doc === "3" ? ItemStatus.ok : ItemStatus.warning,
            docStatusDesc: data.state_doc_desc,
            result: data.result_desc,
            // RUC status
            rucStatus: !data.state_ruc
              ? ItemStatus.none
              : data.state_ruc === "00"
              ? ItemStatus.ok
              : ItemStatus.warning,
            rucStatusDesc: data.state_ruc_desc,
            // Address status
            addressStatus: !data.address_condition
              ? ItemStatus.none
              : data.address_condition === "00"
              ? ItemStatus.ok
              : ItemStatus.warning,
            addressStatusDesc: data.address_condition_desc,
          });
          setGeneralStatus({
            status:
              data.state_doc === "1" || data.state_doc === "3"
                ? DocStatus.ok
                : data.state_doc === "2"
                ? DocStatus.warning
                : DocStatus.alert,
            msg: null,
          });
          //---- Upload ZIP FILE for new or updated docs ---
          if (data.result_desc !== "exists") {
            let zip = new JSZip();           
            zip.file(`${data.file_name}.xml`, file);
            const zipFile = await zip.generateAsync({
              type: "blob",
              compression: "DEFLATE",
            });           
            const form = new FormData();
            form.append("document", zipFile, `${data.file_name}.zip`);
            axios.post("/api/file/upload", form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
          //---- Refrescar documentos recibidos, debe refrescarse de acuerdo al query de busqueda establecido
          if (data.is_unadjudicated) {
            const { data } = await axios.get("/api/reception/unadjudicated");
            if (data.result) {
              dispatch(setUnadjudicatedDocs(data.unadjudicatedDocs as UnadjudicatedDoc[]));
            }
          } else {
            // Si es un documento adjudicado se debe refrescar la lista de documentos recibidos
            await fetch(["reception"]);
          }
        } else {
          setGeneralStatus({ status: DocStatus.error, msg: data.msg });
        }
      } catch (e:any) {
        setGeneralStatus({ status: DocStatus.error, msg: e.message });
      }
    };

    reader.readAsText(file);
    setGeneralStatus({ status: DocStatus.loading, msg: null });
  }, [file]);

  const [generalStatus, setGeneralStatus] = useState<GeneralStatus>({
    status: DocStatus.loading,
    msg: null,
  });
  const [itemResult, setItemResult] = useState<ItemResult>();

  return (
    <div className="FileItem">
      <div className="iconContainer">
        {generalStatus.status === DocStatus.loading && (
          <UseAnimations
            animation={loading}
            autoplay
            size={32}
            strokeColor={"rgb(38 152 63)"}
            wrapperStyle={{ paddingRight: 5 }}
          />
        )}
        {generalStatus.status === DocStatus.ok && (
          <UseAnimations
            animation={radioButton}
            autoplay
            size={32}
            strokeColor={"rgb(38 152 63)"}
            wrapperStyle={{ paddingRight: 5 }}
          />
        )}
        {generalStatus.status === DocStatus.warning && (
          <UseAnimations
            animation={radioButton}
            autoplay
            size={32}
            strokeColor={"rgb(255 178 47)"}
            wrapperStyle={{ paddingRight: 5 }}
          />
        )}
        {generalStatus.status === DocStatus.alert && (
          <WarningTwoTone
            style={{
              fontSize: 25,
              color: "rgb(255 178 47)",
              paddingLeft: 2,
              paddingRight: 8,
            }}
          />
        )}
        {generalStatus.status === DocStatus.error && (
          <ErrorOutline
            style={{
              fontSize: 28,
              color: Colors.alert,
              paddingLeft: 2,
              paddingRight: 8,
            }}
          />
        )}
      </div>
      <div>
        <h4
          style={{
            marginBlockEnd: 3,
            fontWeight: 300,
            fontFamily: "sans-serif",
          }}
        >
          {file.name} - {Math.round(file.size / 1024)} Kb
        </h4>
        <ul className="result">
          {generalStatus.status === DocStatus.loading ? (
            <li className="ResultDetails">Subiendo comprobante...</li>
          ) : generalStatus.status === DocStatus.error ? (
            <li className="ResultDetails">No se pudo cargar el documento: {generalStatus.msg}</li>
          ) : (
            <>
              {itemResult?.result === "updated" && (
                <span className="existsLabel">El estado ha sido actualizado recientemente</span>
              )}
              <li className="ResultDetails">
                {itemResult?.docStatus === ItemStatus.ok && <Done style={{ fontSize: 12 }} />}{" "}
                {itemResult?.docStatusDesc}
              </li>
              {itemResult?.rucStatus !== ItemStatus.none && (
                <li className="ResultDetails">
                  {itemResult?.rucStatus === ItemStatus.ok && <Done style={{ fontSize: 12 }} />}{" "}
                  {itemResult?.rucStatusDesc}
                </li>
              )}
              {itemResult?.addressStatus !== ItemStatus.none && (
                <li className="ResultDetails">
                  {itemResult?.addressStatus === ItemStatus.ok && <Done style={{ fontSize: 12 }} />}{" "}
                  {itemResult?.addressStatusDesc}
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
