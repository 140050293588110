import { Sizes } from "src/infrastructure/static/colors";
import styled from "styled-components";

export const ContainerButtons = styled.div`
  display: flex;
  button {
    margin: 0px 10px;
  }
  @media ${Sizes.TABLET} {
    display: block;
    button {
      margin: 10px 0px;
    }
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  div:first-child {
    flex-grow: 1;
  }
  margin-bottom: 20px;
  @media ${Sizes.TABLET} {
    display: block;
  }
`;
