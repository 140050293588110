import { Sizes } from "../../static/colors";
import styled from "styled-components";

export const ContainerFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  max-width: 1920px;
  margin: 0 auto;
  flex-wrap: wrap;
  div {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }

  @media ${Sizes.TABLET} {
    padding: 0 35px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  height: 50px;
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  margin-top: 20px;

  @media ${Sizes.TABLET} {
    bottom: 40px;
  }
`;
