import styled from "styled-components";
import { Sizes } from "../../static/colors";

interface IHeaderProps {
  isView: boolean;
}

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  max-width: 1920px;
  margin: 0 auto;

  @media ${Sizes.TABLET} {
    svg {
      width: 50px;
    }
    padding: 0 35px;
  }
`;

export const Header = styled.header<IHeaderProps>`
  display: flex;
  width: 100%;
  height: 90px;
  background: transparent;
  position: ${(props) => (props.isView ? "absolute" : "relative")};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  margin-top: 20px;
`;
