import { Colors } from "src/infrastructure/static/colors";
import styled from "styled-components";

export const ContainerNameCompany = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerTitle = styled.div`
  background: ${Colors.PURPLE};
  padding: 20px;
`;
