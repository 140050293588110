import { Sizes } from "../../static/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 400px;

  @media ${Sizes.TABLET} {
    width: 100%;
  }
`;
