import axios from "axios";
import { PATH } from "../http/Path";

export const paymentRepository = {
  paymentVoucher: async (file_name: string) => {
    const response = await axios.get(`${PATH.PAYMENT_VOUCHER}/${file_name}`);
    return response;
  },
  paymentVoucherPDF: async (data: any) => {
    const response = await axios.post(`${PATH.PAYMENT_VOUCHER_PDF}`, data, {
      responseType: "blob",
    });
    return response;
  },
  paymentInvoice: async (file_name: string, data: any) => {
    const response = await axios.post(
      `${PATH.PAYMENT_INVOICE}/${file_name}`,
      data
    );
    return response;
  },
  paymentReceiptPDF: async (data: any) => {
    const response = await axios.post(`${PATH.PAYMENT_RECEIPT_PDF}`, data, {
      responseType: "blob",
    });
    return response;
  },
};
