import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  TextField,
  Collapse,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";
import { RCheckbox } from "../ratifikaElements";
import { useDispatch, useSelector } from "react-redux";
import { setConfigParam } from "../../store/actions/config";
import { fetch } from "../../store/fetch";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import "../filterDialog/theme.css";

interface Contributor {
  number_type: string;
  number: string;
  legal_name: string;
  id: number;
}

export const FilterDialog = ({ showAlert }: { showAlert: any }) => {
  const dispatch = useDispatch();
  const config = useSelector((state: any) => state.config);

  const [filterSerie, setFilterSerie] = React.useState<string>(
    config.emittedFilterSerie || ""
  );
  const [filterInitSeq, setFilterInitSeq] = React.useState<string>(
    config.emittedFilterInitSeq || ""
  );
  const [filterEndSeq, setFilterEndSeq] = React.useState<string>(
    config.emittedFilterEndSeq || ""
  );
  const [validSerie, setValidSerie] = React.useState<boolean | null>(null);
  const [validFilterInitSeq, setValidFilterInitSeq] = React.useState<
    boolean | null
  >(null);
  const [validFilterEndSeq, setValidFilterEndSeq] = React.useState<
    boolean | null
  >(null);

  const filteredDocTypes = config.docType ? config.docType.split(",") : [];
  const [docTypes, setDocTypes] = React.useState({
    checkedFacturas: filteredDocTypes.includes("01"),
    checkedBoletas: filteredDocTypes.includes("03"),
    checkedNotasCredito: filteredDocTypes.includes("07"),
    checkedNotasDebito: filteredDocTypes.includes("08"),
  });

  const handleCheckDocType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocTypes({ ...docTypes, [event.target.name]: event.target.checked });
  };

  const filteredDocStates = config.docState
    ? config.docState.replace("1-3", "1,3").split(",")
    : [];
  const [docStates, setDocStates] = React.useState({
    checkedAutorizado:
      filteredDocStates.includes("1") || filteredDocStates.includes("3"),
    checkedAnulado: filteredDocStates.includes("2"),
    checkedPendiente: filteredDocStates.includes("0"),
    checkedErroneo: filteredDocStates.includes("-1"),
    checkedRechazado: filteredDocStates.includes("-2"),
  });

  const handleCheckDocStates = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocStates({ ...docStates, [event.target.name]: event.target.checked });
  };
  /*Buyer */
  const [availableCustomers, setAvailableCustomers] = useState<
    Array<Contributor>
  >([]);
  const [name, setName] = useState<string | null>("");

  const [autocompleteCustomers, setAutocompleteCustomers] = useState<
    Array<Contributor>
  >([]);
  const [buyerKey, setBuyerKey] = useState<string>(
    config.emittedClientName || ""
  );
  const [filterClientId, setfilterClientId] = React.useState<number | null>(
    config.emittedClientId || null
  );
  //
  const [collapseNumeral, setCollapseNumeral] = React.useState(false);
  const [collapseState, setCollapseState] = React.useState(false);
  const [collapseDocType, setCollapseDocType] = React.useState(false);

  useEffect(() => {
    handleFilterClick();
  }, [
    docTypes,
    docStates,
    filterSerie,
    filterInitSeq,
    filterEndSeq,
    filterClientId,
    name,
  ]);

  useEffect(() => {
    if (config.workspace) {
      async function fetchCostumers() {
        const { data } = await axios.get<Contributor[]>(
          `api/documents/customer/${config.workspace}?dateOf=${
            config.dateType
          }&startDate=${config.emissionStartDate}&endDate=${
            config.emissionEndDate
          }${
            config.emittedFilterSerie
              ? `&serie=${config.emittedFilterSerie}&seqInit=${config.emittedFilterInitSeq}&seqEnd=${config.emittedFilterEndSeq}`
              : ""
          }${config.docType ? `&docType=${config.docType}` : ""}${
            config.docState
              ? `&docStatus=${
                  config.docState.split(",").includes("1-3")
                    ? config.docState.replace("1-3", "1,3")
                    : config.docState
                }`
              : ""
          }`
        );
        setAvailableCustomers(data);
      }
      fetchCostumers();
    }
  }, [
    config.workspace,
    config.dateType,
    config.emissionStartDate,
    config.emissionEndDate,
    config.emittedFilterSerie,
    config.emittedFilterInitSeq,
    config.emittedFilterEndSeq,
    config.docType,
    config.docState,
  ]);

  /*Inicio de Funciones */
  //show alert
  const handleShowAlertReport = (value: boolean) => {
    showAlert(value);
  };
  //end show alert

  const handleFilterClick = () => {
    let hasErrors = false;

    if (filterSerie && filterSerie.trim().length !== 4) {
      setValidSerie(false);
      hasErrors = true;
    }
    if (filterInitSeq && Number(filterInitSeq) === 0) {
      setValidFilterInitSeq(false);
      hasErrors = true;
    }
    if (filterEndSeq && Number(filterEndSeq) < Number(filterInitSeq)) {
      setValidFilterEndSeq(false);
      hasErrors = true;
    }

    if ((filterInitSeq || filterEndSeq) && !filterSerie) {
      setValidSerie(false);
      hasErrors = true;
    }

    // Init Seq should have a value if serie or end seq have some value
    if ((filterSerie || filterEndSeq) && !filterInitSeq) {
      setValidFilterInitSeq(false);
      hasErrors = true;
    }
    // End Seq should have a value if serie or init seq have some value
    if ((filterSerie || filterInitSeq) && !filterEndSeq) {
      setValidFilterEndSeq(false);
      hasErrors = true;
    }

    if (!hasErrors) {
      // Set filter series/sequentials
      dispatch(setConfigParam("emittedFilterSerie", filterSerie));
      dispatch(setConfigParam("emittedFilterInitSeq", filterInitSeq));
      dispatch(setConfigParam("emittedFilterEndSeq", filterEndSeq));
      //put loading snackbar
      // Set filter doc type
      let filteredDocTypes = "";
      if (docTypes.checkedFacturas) {
        filteredDocTypes = "01";
      }
      if (docTypes.checkedBoletas) {
        filteredDocTypes += filteredDocTypes ? ",03" : "03";
      }
      if (docTypes.checkedNotasCredito) {
        filteredDocTypes += filteredDocTypes ? ",07" : "07";
      }
      if (docTypes.checkedNotasDebito) {
        filteredDocTypes += filteredDocTypes ? ",08" : "08";
      }
      dispatch(setConfigParam("docType", filteredDocTypes));

      // Set filter doc state
      let filteredDocStates = "";
      if (docStates.checkedAutorizado) {
        filteredDocStates = "1-3";
      }
      if (docStates.checkedAnulado) {
        filteredDocStates += filteredDocStates ? ",2" : "2";
      }
      if (docStates.checkedPendiente) {
        filteredDocStates += filteredDocStates ? ",0" : "0";
      }
      if (docStates.checkedErroneo) {
        filteredDocStates += filteredDocStates ? ",-1" : "-1";
      }
      if (docStates.checkedRechazado) {
        filteredDocStates += filteredDocStates ? ",-2" : "-2";
      }
      dispatch(setConfigParam("docState", filteredDocStates));

      dispatch(setConfigParam("emittedClientId", filterClientId));
      dispatch(setConfigParam("emittedClientName", name));

      fetch(["emission"]);
      showAlert(true);
    }
  };

  const getCustomersSuggestions = async (
    value: string
  ): Promise<Contributor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results = [];

    if (Number.isInteger(Number(inputValue))) {
      // posiblemente sea un RUC
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter(
                (contrib) => contrib.number.slice(0, inputLength) === inputValue
              )
              .sort((a, b) => {
                return (
                  a.number.indexOf(inputValue) - b.number.indexOf(inputValue)
                );
              });
    } else {
      // Posiblemente sea un nombre legal
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter((contrib) =>
                contrib.legal_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
              .sort((a, b) => {
                return (
                  a.legal_name.indexOf(inputValue) -
                  b.legal_name.indexOf(inputValue)
                );
              });
    }

    return results;
  };

  const getSuggestionValue = (suggestion: Contributor) => {
    //setDocType(suggestion.number_type);
    setfilterClientId(suggestion.id);
    setName(suggestion.legal_name);

    return suggestion.legal_name || suggestion.number;
  };

  const renderSuggestion = (suggestion: Contributor, { query }: any) => {
    const handleNumber = Number.isInteger(Number(query));
    const suggestionText = handleNumber
      ? suggestion.number
      : suggestion.legal_name;

    const indexOfSuggestion = suggestionText
      .toLowerCase()
      .indexOf(query.toLowerCase());
    const substring = suggestionText.substring(
      indexOfSuggestion,
      indexOfSuggestion + query.length
    );
    const highlight = suggestionText.replace(
      substring,
      `<strong ${
        handleNumber ? `style="font-size: 11px"` : ""
      }>${substring}</strong>`
    );
    return (
      <div>
        {!handleNumber ? (
          <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
        ) : (
          <span style={{ fontSize: 11 }}>{suggestion.legal_name}</span>
        )}
        <br></br>
        {handleNumber ? (
          <span
            style={{ fontSize: 11 }}
            dangerouslySetInnerHTML={{ __html: highlight }}
          ></span>
        ) : (
          <strong style={{ fontSize: 11 }}>{suggestion.number}</strong>
        )}
      </div>
    );
  };

  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteCustomers(await getCustomersSuggestions(param.value));
    /*  if(autocompleteCustomers.length===0){
      setfilterClientId(null);  
     }  */
  };

  const onSuggestionsClearRequested = () => {
    setAutocompleteCustomers([]);
  };

  return (
    <React.Fragment>
      <ListItem>
        <ListItemText secondary="Filtrar por Cliente"></ListItemText>
      </ListItem>
      <ListItem style={{ padding: 0 }}>
        <Autosuggest
          suggestions={autocompleteCustomers}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder: "  Ruc, DNI o nombre",
            value: buyerKey,
            onChange: (
              event: React.FormEvent<any>,
              { newValue, method }: Autosuggest.ChangeEvent
            ) => {
              setBuyerKey(newValue);
              if (newValue === "") {
                setfilterClientId(null);
                setName(null);
              }
            },
            ref: React.createRef<HTMLInputElement>(),
          }}
        />
      </ListItem>

      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem
          button
          onClick={() => {
            setCollapseDocType(!collapseDocType);
          }}
        >
          <ListItemText secondary="Filtrar por tipo de documento"></ListItemText>
          {collapseDocType ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapseDocType} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Factura"
                control={
                  <RCheckbox
                    checked={docTypes.checkedFacturas}
                    onChange={handleCheckDocType}
                    name="checkedFacturas"
                  />
                }
              />
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Boleta"
                control={
                  <RCheckbox
                    checked={docTypes.checkedBoletas}
                    onChange={handleCheckDocType}
                    name="checkedBoletas"
                  />
                }
              />
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Nota de Crédito"
                control={
                  <RCheckbox
                    checked={docTypes.checkedNotasCredito}
                    onChange={handleCheckDocType}
                    name="checkedNotasCredito"
                  />
                }
              />
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Nota de Débito"
                control={
                  <RCheckbox
                    checked={docTypes.checkedNotasDebito}
                    onChange={handleCheckDocType}
                    name="checkedNotasDebito"
                  />
                }
              />
            </ListItem>
          </List>
        </Collapse>
      </List>

      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem
          button
          onClick={() => {
            setCollapseState(!collapseState);
          }}
        >
          <ListItemText secondary="Filtrar por estado de documento"></ListItemText>
          {collapseState ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapseState} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Autorizado"
                control={
                  <RCheckbox
                    checked={docStates.checkedAutorizado}
                    onChange={handleCheckDocStates}
                    name="checkedAutorizado"
                  />
                }
              />
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Anulado"
                control={
                  <RCheckbox
                    checked={docStates.checkedAnulado}
                    onChange={handleCheckDocStates}
                    name="checkedAnulado"
                  />
                }
              />
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Erróneo"
                control={
                  <RCheckbox
                    checked={docStates.checkedErroneo}
                    onChange={handleCheckDocStates}
                    name="checkedErroneo"
                  />
                }
              />
            </ListItem>

            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Rechazado"
                control={
                  <RCheckbox
                    checked={docStates.checkedRechazado}
                    onChange={handleCheckDocStates}
                    name="checkedRechazado"
                  />
                }
              />
            </ListItem>
            <ListItem style={{ padding: 0 }}>
              <FormControlLabel
                label="Pendiente"
                control={
                  <RCheckbox
                    checked={docStates.checkedPendiente}
                    onChange={handleCheckDocStates}
                    name="checkedPendiente"
                  />
                }
              />
            </ListItem>
          </List>
        </Collapse>
      </List>

      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem
          button
          onClick={() => {
            setCollapseNumeral(!collapseNumeral);
          }}
        >
          <ListItemText secondary="Filtrar por numeral"></ListItemText>
          {collapseNumeral ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={collapseNumeral} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem>
              <TextField
                fullWidth
                label="Serie"
                variant="outlined"
                size="small"
                autoComplete="off"
                style={{ width: 180, paddingRight: 10 }}
                error={validSerie === false}
                value={filterSerie}
                type="text"
                onChange={(e) => {
                  if (e.target.value.length === 5) return;
                  setFilterSerie(e.target.value.toUpperCase());
                  if (!validSerie /* y es una serie valida */) {
                    setValidSerie(true);
                  }
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                fullWidth
                label="Secuencial Inicio"
                variant="outlined"
                size="small"
                type="number"
                autoComplete="off"
                style={{ width: 180, paddingRight: 10 }}
                error={validFilterInitSeq === false}
                value={filterInitSeq}
                onChange={(e) => {
                  if (
                    (e.target.value && !/^\d+$/.test(e.target.value)) ||
                    e.target.value.length === 9
                  )
                    return;
                  setFilterInitSeq(e.target.value);
                  if (!validFilterInitSeq && Number(e.target.value) > 0) {
                    setValidFilterInitSeq(true);
                  }
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                fullWidth
                label="Secuencial Fin"
                variant="outlined"
                size="small"
                type="number"
                autoComplete="off"
                style={{ width: 180, paddingRight: 10 }}
                error={validFilterEndSeq === false}
                value={filterEndSeq}
                onChange={(e) => {
                  if (
                    (e.target.value && !/^\d+$/.test(e.target.value)) ||
                    e.target.value.length === 9
                  )
                    return;
                  setFilterEndSeq(e.target.value);
                  if (
                    !validFilterEndSeq &&
                    Number(e.target.value) >= Number(validFilterInitSeq)
                  ) {
                    setValidFilterEndSeq(true);
                  }
                }}
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </React.Fragment>
  );
};
