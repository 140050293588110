import { companyRepository } from "src/infrastructure/repositories/Company.repository";
import { emissionRepository } from "src/infrastructure/repositories/Emission.repository";
import { EmittedDoc } from "../models/Emission";
import { store } from "../store/configureStore";

const getEmissionDocuments = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.emittedDateType
  }&startDate=${config.emittedStartDate}&endDate=${
    config.emittedEndDate
  }&startPage=${config.emittedPageCurrent || 0}&endPage=${
    config.emittedRowsPerPage
      ? (config.emittedPageCurrent + 1) * config.emittedRowsPerPage
      : 10
  }${config.emittedClientId ? `&receiverId=${config.emittedClientId}` : ""}${
    config.emittedSerie
      ? `&serie=${config.emittedSerie}&seqInit=${config.emittedSequentialStart}&seqEnd=${config.emittedSequentialEnd}`
      : ""
  }${
    config.emittedDocumentType ? `&docType=${config.emittedDocumentType}` : ""
  }${
    config.emittedDocumentStatus
      ? `&docStatus=${
          config.emittedDocumentStatus.split(",").includes("1-3")
            ? config.emittedDocumentStatus.replace("1-3", "1,3")
            : config.emittedDocumentStatus
        }`
      : ""
  }`;
  const response = await emissionRepository.getEmissionDocuments(params);
  if (response.data.result) {
    store.dispatch({
      type: "SET_CONFIG_PARAM",
      payload: { emittedPageTotal: response.data.totals },
    });
    store.dispatch({
      type: "SET_EMITTED_DOCS",
      payload: response.data.emittedDocs as EmittedDoc[],
    });
  } else {
    store.dispatch({
      type: "SET_EMITTED_DOCS",
      payload: [],
    });
  }
  return response;
};

export const emissionService = {
  getEmissionDocuments,
};
