import axios from "axios";
import { PATH } from "../http/Path";

export const reportsRepository = {
  getReportsGeneral: async (params: string) => {
    const response = await axios.get(`${PATH.REPORTS_GENERAL}/${params}`);
    return response;
  },
  downloadReportEmission: async (params: string) => {
    const response = await axios.get(
      `${PATH.REPORTS_DOWNLOAD_EMISSION}/${params}`,
      {
        responseType: "blob",
      }
    );
    return response;
  },
  downloadReportFinancial: async (params: string) => {
    const response = await axios.get(
      `${PATH.REPORTS_DOWNLOAD_FINANCIAL}/${params}`,
      {
        responseType: "blob",
      }
    );
    return response;
  },
  downloadReportEmails: async (params: string) => {
    const response = await axios.get(
      `${PATH.REPORTS_DOWNLOAD_EMAILS}/${params}`,
      {
        responseType: "blob",
      }
    );
    return response;
  },
  downloadReportTxt: async (params: string) => {
    const response = await axios.get(`${PATH.REPORTS_DOWNLOAD_TXT}/${params}`, {
      responseType: "blob",
    });
    return response;
  },
};
