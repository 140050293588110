import React from "react";
import DashboardReception from "../components/dashboardReception";
import Header from "../components/header";
import LayoutFilter from "../components/layoutFilter";
import Loading from "../components/skeleton/loading";

const Reception = () => {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <Header />
      <LayoutFilter
        type="reception"
        title="Documentos Recibidos"
        componentLoading={Loading}
      >
        <DashboardReception />
      </LayoutFilter>
    </div>
  );
};

export default Reception;
