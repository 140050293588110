import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Popover,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CompanyInfo } from "../../../domain/store/types/companies";
import {
  RButton,
  RButtonSecondary,
  RSwitch,
  RTextField,
  RRadio,
  RFormControl,
} from "../ratifikaElements";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import { direcciones, ubigeos } from "../../static/ubigeo";
import {
  Add,
  AirportShuttle,
  Description,
  Flight,
  LocalShipping,
  LocalTaxi,
  LocationOn,
  Widgets,
} from "@material-ui/icons";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import CommentIcon from "@material-ui/icons/Comment";
import SearchIcon from "@material-ui/icons/Search";
import { DeleteTwoTone as DeleteIcon, Send } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { incrementSequential, Measure, measureList } from "../../static/util";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import moment from "moment";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Alert from "@material-ui/lab/Alert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonPrimary, ButtonSecondary } from "../button";
import { Colors } from "src/infrastructure/static/colors";
import { ContainerForm, ContainerTitle } from "./styled";
import TextStyle from "../textStyle";
import { useHistory, useLocation } from "react-router-dom";
import { EmittedDoc } from "src/store/types/emittedDoc";
import CustomSelect from "../select";
import { MotivoTraslado, portAirportList, relatedDocumentsList, taxConceptList } from "src/static/util";

interface Ubicacion {
  code: string;
  departamento: string;
  provincia: string;
  distrito: string;
}
interface PortAirport {
  title: string;
  code: string;
  name: string;
}
interface ErrorReport {
  title: string;
  message: string;
  details?: Array<string>;
}
interface TaxConcept{
  code: string;
  name: string;
  value: string;
}

interface Product {
  code: string;
  measure: string;
  description: string;
  taxConcept: Array<TaxConcept>;
  detalle?: string;
  is_new?: boolean;
  quantity: string;
  code_sunat: string;
  code_gtin: string;
}
interface ProductItem extends Product {
  count: number;
  line: number;
  measureSelected: Measure;
}

interface Indicator {
  indicador: string;
}

interface Contributor {
  addresses: Array<object>;
  number_type: string;
  number: string;
  legal_name: string;
  is_new?: boolean;
}

interface Transportista {
  addresses: string;
  number_type: string;
  number: string;
  legal_name: string;
  is_new?: boolean;
}

interface Conductor {
  name: string;
  number: string;
  number_type: string;
}
interface MultiSelectItem {
  label: string;
  value: string;
}

interface Anotacion {
  codigo?: string;
  valor: string;
}

interface ReceiverRelated {
  number: string;
  number_type: string;
}

interface RelatedDocument {
  serie: string;
  number: string;
  document_type: string;
  docTypeName: string;
  ruc: string;
  // issue_date: string;
  // total_value: number;
  // receiver: ReceiverRelated;
  // currency: string;
  // subtotal: number;
  // total_igv: number;
  // base_exemption: number;
  // base_unaffected: number;
  // base_igv: number;
  // total_isc: number;
  // other_charges: number;
}

interface TransportData {
  plate: string;
  TUCECerticate?: string;
  identification: string;
  legalName: string;
  docType: string;
  license: string;
}

function ColorlibStepIcon(props: StepIconProps) {
  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentIcon />,
    2: <Description />,
    3: <EmojiTransportationIcon />,
    4: <Flight />,
    5: <FormatListNumberedIcon />,
    6: <CheckCircleIcon />,
  };

  return <div>{icons[String(props.icon)]}</div>;
}

function getSteps() {
  return [
    "INFORMACION BASICA",
    "DATOS DE ENVIO",
    "DATOS DE TRANSPORTE",
    "DATOS DE TRASLADO",
    "PRODUCTOS",
    "CONFIRMAR",
  ];
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function a11yPropsTypeTransport(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FormNewGuieRemission = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const history = useHistory();
  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const emitter: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );
  const location = useLocation();

  const [responseError, setResponseError] = useState<ErrorReport | null>(null);
  const [codeUbigeoOrigen, setCodeUbigeoOrigen] = useState<string | undefined>(
    ""
  );
  const [selectUbigeoOrigen, setSelectUbigeoOrigen] =
    useState<Ubicacion | null>(null);
  const [addressOrigen, setAddressOrigen] = useState<string>("");
  const [hasErrorTransferDescription, setHasErrorTransferDescription] =
    useState(false);
  const [hasAdressOrigenError, setHasAdressOrigenError] = useState(false);
  const [hasUbigeoOrigenError, setHasUbigeoOrigenError] = useState(false);
  const [codeUbigeoDestino, setCodeUbigeoDestino] = useState<
    string | undefined
  >("");
  const [selectUbigeoDestino, setSelectUbigeoDestino] =
    useState<Ubicacion | null>(null);
  const [selectPortAirport, setSelectPortAirport] =
    useState<PortAirport | null>(null);
  const [addressDestino, setAddressDestino] = useState<string>("");
  const [hasAdressDestinoError, setHasAdressDestinoError] = useState(false);
  const [hasUbigeoDesError, setHasUbigeoDesError] = useState(false);
  const [tipoEnvio, setTipoEnvio] = useState<string>("");
  const [relatedDocumentType, setRelatedDocumentType] = useState<string>("");
  const [typeTaxConcept, setTypeTaxConcept] = useState<string>("");
  const [authorizationIssuingEntity, setAuthorizationIssuingEntity] =
    useState<string>("");
  const [freightPayer, setFreightPayer] = useState<string>("");
  const [listRelatedDocument, setListRelatedDocument] = useState<
    Array<MotivoTraslado>
  >([]);
  const [typeTransport, setTypeTransport] = useState<string>("02");
  const [buyerKey, setBuyerKey] = useState<string>("");
  const [driverKey, setDriverKey] = useState<string>("");
  const [issuerKey, setIssuerKey] = useState<string>("");
  const [hasErrorIssuerKey, setHasErrorIssuerKey] = useState(false);
  const [errorMsgIssuerKey, setErrorMsgIssuerKey] = React.useState<
    string | null
  >(null);
  const [hasErrorBuyerKey, setHasErrorBuyerKey] = useState(false);
  const [hasErrorSupplierDataKey, setHasErrorSupplierDataKey] = useState(false);
  const [hasErrorOutsourcedKey, setHasErrorOutsourcedKey] = useState(false);
  const [hasErrorBuyerDataKey, setHasErrorBuyerDataKey] = useState(false);
  const [hasErrorThirdFreightPayerKey, setHasErrorThirdFreightPayerKey] = useState(false);
  const [hasErrorFreightPayer, setHasErrorFreightPayer] = useState(false);
  const [errorMsgFreightPayer, setErrorMsgFreightPayer] =
    React.useState<string | null>(null);
  const [buyerDataKey, setBuyerDataKey] = useState<string>("");
  const [supplierDataKey, setSupplierDataKey] = useState<string>("");
  const [outsourcedKey, setOutsourcedKey] = useState<string>("");
  const [thirdFreightPayerKey, setThirdFreightPayerKey] = useState<string>("");
  const [availableCustomers, setAvailableCustomers] = useState<
    Array<Contributor>
  >([]);
  const [autocompleteCustomers, setAutocompleteCustomers] = useState<
    Array<Contributor>
  >([]);
  const [legalName, setLegalName] = React.useState<string>("");
  const [errorMsgLegalName, setErrorMsgLegalName] = React.useState<
    string | null
  >(null);
  const [identification, setIdentification] = React.useState<string>("");
  const [errorMsgIdentification, setErrorMsgIdentification] = React.useState<
    string | null
  >(null);
  const [docType, setDocType] = React.useState<string>("");
  const [legalNameDriver, setLegalNameDriver] = React.useState<string>("");
  const [errorMsgLegalNameDriver, setErrorMsgLegalNameDriver] = React.useState<
    string | null
  >(null);
  const [hasErrorIdentificationDriver, setHasErrorIdentificationDriver] =
    useState(false);
  const [identificationDriver, setIdentificationDriver] =
    React.useState<string>("");
  const [errorMsgIdentificationDriver, setErrorMsgIdentificationDriver] =
    React.useState<string | null>(null);
  const [docTypeDriver, setDocTypeDriver] = React.useState<string>("");
  const [legalNameBuyerData, setLegalNameBuyerData] =
    React.useState<string>("");
  const [errorMsgLegalNameBuyerData, setErrorMsgLegalNameBuyerData] =
    React.useState<string | null>(null);
  const [identificationBuyerData, setIdentificationBuyerData] =
    React.useState<string>("");
  const [errorMsgIdentificationBuyerData, setErrorMsgIdentificationBuyerData] =
    React.useState<string | null>(null);
  const [docTypeBuyerData, setDocTypeBuyerData] = React.useState<string>("");
  const [legalNameSupplierData, setLegalNameSupplierData] =
    React.useState<string>("");
  const [errorMsgLegalNameSupplierData, setErrorMsgLegalNameSupplierData] =
    React.useState<string | null>(null);
  const [identificationSupplierData, setIdentificationSupplierData] =
    React.useState<string>("");
  const [
    errorMsgIdentificationSupplierData,
    setErrorMsgIdentificationSupplierData,
  ] = React.useState<string | null>(null);
  const [errorMsgDocTypeSupplierData, setErrorMsgDocTypeSupplierData] =
    React.useState<string | null>(null);
  const [errorMsgDocTypeBuyerData, setErrorMsgDocTypeBuyerData] =
    React.useState<string | null>(null);
  const [docTypeSupplierData, setDocTypeSupplierData] =
    React.useState<string>("");
  const [legalNameIssuer, setLegalNameIssuer] = React.useState<string>("");
  const [errorMsgLegalNameIssuer, setErrorMsgLegalNameIssuer] = React.useState<
    string | null
  >(null);
  const [identificationIssuer, setIdentificationIssuer] =
    React.useState<string>("");
  const [errorMsgIdentificationIssuer, setErrorMsgIdentificationIssuer] =
    React.useState<string | null>(null);
  const [docTypeIssuer, setDocTypeIssuer] = React.useState<string>("");

  const [legalNameOutsourced, setLegalNameOutsourced] =
    React.useState<string>("");
  const [errorMsgLegalNameOutsourced, setErrorMsgLegalNameOutsourced] =
    React.useState<string | null>(null);
  const [identificationOutsourced, setIdentificationOutsourced] =
    React.useState<string>("");
  const [
    errorMsgIdentificationOutsourced,
    setErrorMsgIdentificationOutsourced,
  ] = React.useState<string | null>(null);
  const [docTypeOutsourced, setDocTypeOutsourced] = React.useState<string>("");

  const [legalNameThirdFreightPayer, setLegalNameThirdFreightPayer] =
    React.useState<string>("");
  const [
    errorMsgLegalNameThirdFreightPayer,
    setErrorMsgLegalNameThirdFreightPayer,
  ] = React.useState<string | null>(null);
  const [identificationThirdFreightPayer, setIdentificationThirdFreightPayer] =
    React.useState<string>("");
  const [
    errorMsgIdentificationThirdFreightPayer,
    setErrorMsgIdentificationThirdFreightPayer,
  ] = React.useState<string | null>(null);
  const [docTypeThirdFreightPayer, setDocTypeThirdFreightPayer] =
    React.useState<string>("");
  const [errorMsgTipoEnvio, setErrorMsgTipoEnvio] = React.useState<
    string | null
  >(null);
  const [selectedAddress, setSelectedAddress] =
    useState<MultiSelectItem | null>(null);
  const [productKey, setProductKey] = useState<string>("");
  const [availableProducts, setAvailableProducts] = useState<Array<Product>>(
    []
  );
  const [autocompleteProducts, setAutocompleteProducts] = useState<
    Array<Product>
  >([]);
  const [selectedItems, setSelectedItems] = useState<Array<ProductItem>>([]);
  const [indicators, setIndicators] = useState<Array<Indicator>>([]);
  const [addDescription, setAddDescription] = useState<boolean>(false);
  const [hasErrorTipoEnvio, setHasErrorTipoEnvio] = useState(false);
  const [hasErrorRelatedDocumentType, setHasErrorRelatedDocumentType] =
    useState(false);
  const [errorMsgRelatedDocumentType, setErrorMsgRelatedDocumentType] =
    useState<string | null>(null);
  const [hasErrorItem, setHasErrorItem] = useState(false);
  const [errorMsgItems, setErrorMsgItems] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [observaciones, setObservaciones] = useState<string>("");
  const [notes, setNotes] = useState<Array<string>>([]);

  const [dateSend, setDateSend] = React.useState<Date | null>(new Date());
  const [numBultos, setNumBultos] = useState<number>(0);
  const [hasNumBultosError, setHasNumBultosError] = useState(false);
  const [pesoTotal, setPesoTotal] = useState<number>(0.0);
  const [hasPesoError, setHasPesoError] = useState(false);
  const [dniConductor, setDniConductor] = useState<string>("");
  const [conductorSuggestions, setConductorSuggestions] = useState<
    Array<Conductor>
  >([]);
  const [numberDriver, setNumberDriver] = useState<string>("");
  const [numberTypeDriver, setNumberTypeDriver] = useState<string>("");
  const [hasNumTypeDriverError, setHasNumTypeDriverError] = useState(false);
  const [nameDriver, setNameDriver] = useState<string>("");
  const [vehiclePlate, setVehiclePlate] = useState<string>("");
  const [rucRelatedDoc, setRucRelatedDoc] = useState<string>("");
  const [serieRelatedDoc, setSerieRelatedDoc] = useState<string>("");
  const [correlativeRelatedDoc, setCorrelativeRelatedDoc] =
  useState<string>("");
  const [vehicleTUCECertificate, setVehicleTUCECertificate] =
  useState<string>("");
  const [driverLicense, setDriverLicense] = useState<string>("");
  const [authorizationNumberEntity, setAuthorizationNumberEntity] =
  useState<string>("");
  const [codeTaxConcept, setCodeTaxConcept] = useState<string>("");
  const [descriptionTaxConcept, setDescriptionTaxConcept] = useState<string>("");
  const [valueTaxConcept, setValueTaxConcept] = useState<string>("");
  const [hasLicenseError, setHasLicenseError] = useState(false);
  const [errorMsgLicense, setErrorMsgLicense] = useState<string | null>(null);
  const [hasErrorVehiclePlate, setHasErrorVehiclePlate] = useState(false);
  const [errorMsgVehiclePlate, setErrorMsgVehiclePlate] = useState<
    string | null
  >(null);
  const [hasErrorVehicleTUCECertificate, setHasErrorVehicleTUCECertificate] =
    useState(false);
  const [errorMsgVehicleTUCECertificate, setErrorMsgVehicleTUCECertificate] =
    useState<string | null>(null);
  const [hasErrorDriverLicense, setHasErrorDriverLicense] = useState(false);
  const [errorMsgDriverLicense, setErrorMsgDriverLicense] = useState<
    string | null
  >(null);
  const [hasErrorRucRelatedDoc, setHasErrorRucRelatedDoc] = useState(false);
  const [errorMsgRucRelatedDoc, setErrorMsgRucRelatedDoc] = useState<
    string | null
  >(null);
  const [hasErrorSerieRelatedDoc, setHasErrorSerieRelatedDoc] = useState(false);
  const [errorMsgSerieRelatedDoc, setErrorMsgSerieRelatedDoc] = useState<
    string | null
  >(null);
  const [hasErrorCorrelativeRelatedDoc, setHasErrorCorrelativeRelatedDoc] =
    useState(false);
  const [errorMsgCorrelativeRelatedDoc, setErrorMsgCorrelativeRelatedDoc] =
    useState<string | null>(null);
    const [hasErrorCodeTaxConcept, setHasErrorCodeTaxConcept] =
    useState(false);
  const [errorMsgCodeTaxConcept, setErrorMsgCodeTaxConcept] =
    useState<string | null>(null);
    const [hasErrorDescriptionTaxConcept, setHasErrorDescriptionTaxConcept] =
    useState(false);
  const [errorMsgDescriptionTaxConcept, setErrorMsgDescriptionTaxConcept] =
    useState<string | null>(null);
    const [hasErrorValueTaxConcept, setHasErrorValueTaxConcept] =
    useState(false);
  const [errorMsgValueTaxConcept, setErrorMsgValueTaxConcept] =
    useState<string | null>(null);
  const [errorMsgDni, setErrorMsgDni] = React.useState<string | null>(null);
  const [rucCarrier, setRucCarrier] = useState<string>("");
  const [carrierSuggestions, setCarrierSuggestions] = useState<
    Array<Transportista>
  >([]);
  const [numberCarrier, setNumberCarrier] = useState<string>("");
  const [numberTypeCarrier, setNumberTypeCarrier] = useState<string>("");
  const [hasNumTypeCarrierError, setHasNumTypeCarrierError] = useState(false);
  const [nameCarrier, setNameCarrier] = useState<string>("");
  const [errorMsgCarrier, setErrorMsgCarrier] = React.useState<string | null>(
    null
  );
  const [errorMsgTransferDescription, setErrorMsgTransferDescription] =
    React.useState<string | null>(null);
  const [referenceDoc, setReferenceDoc] = useState<boolean>(false);
  const [serieReference, setSerieReference] = useState<string>("");
  const [hasSerieError, setHasSerieError] = useState(false);
  const [numberReference, setNumberReference] = useState<string>("");
  const [hasCorrelativeError, setHasCorrelativeError] = useState(false);
  const [disableAll, setDisableAll] = useState<boolean>(false);
  const [errorMsgTryingSend, setErrorMsgTryingSend] = React.useState<
    string | null
  >("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );
  const [typeGuide, setTypeGuide] = React.useState(emitter.mtc_registration_number ? 1:0);
  const [issuerCPE, setIssuerCPE] = React.useState<boolean>(false);
  const [hasErrorIssuerCPE, setHasErrorIssuerCPE] = useState(false);
  const [errorMsgIssuerCPE, setErrorMsgIssuerCPE] = React.useState<
    string | null
  >(null);
  const [scheduledTransfer, setScheduledTransfer] =
    React.useState<boolean>(false);
  const [carryOutScheduledTransfer, setCarryOutScheduledTransfer] =
    React.useState<boolean>(false);
  const [transferM1LVehicles, setTransferM1LVehicles] =
    React.useState<boolean>(false);
  const [emptyVehicleReturn, setEmptyVehicleReturn] =
    React.useState<boolean>(false);
  const [returnEmptyVehicleContainer, setReturnEmptyVehicleContainer] =
    React.useState<boolean>(false);
  const [subcontractedTransport, setSubcontractedTransport] =
    React.useState<boolean>(false);
  const [sameSender, setSameSender] = React.useState<boolean>(false);
  const [foreignBuyer, setForeignBuyer] = React.useState<boolean>(false);
  const [loadingIssuerCPE, setLoadingIssuerCPE] = useState<boolean>(false);
  const [autocompleteRelatedDocuments, setAutocompleteRelatedDocuments] =
    useState<Array<RelatedDocument>>([]);
  const [availableRelatedDocuments, setAvailableRelatedDocuments] = useState<
    Array<RelatedDocument>
  >([]);
  const [selectedRelatedDocuments, setSelectedRelatedDocuments] = useState<
    Array<RelatedDocument>
  >([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [selectedTransportData, setSelectedTransportData] = useState<
    Array<TransportData>
  >([]);
  const [relatedDocumentKey, setRelatedDocumentKey] = useState<string>("");
  const [transferDescription, setTransferDescription] = useState<string>("");
  const [openDialogRelatedDoc, setOpenDialogRelatedDoc] =
    useState<boolean>(false);
  const [openDialogTaxConcept, setOpenDialogTaxConcept] =
    useState<boolean>(false);
  const [openDialogTransportData, setOpenDialogTransportData] =
    useState<boolean>(false);
  const [showSupplierData, setShowSupplierData] = useState<boolean>(false);
  const [focusComponentSupplier, setFocusComponentSupplier] =
    useState<string>("");
  const [freezeSupplierData, setFreezeSupplierData] = useState<boolean>(false);
  const [showBuyerData, setShowBuyerData] = useState<boolean>(false);
  const [focusComponentBuyer, setFocusComponentBuyer] = useState<string>("");
  const [freezeBuyerData, setFreezeBuyerData] = useState<boolean>(false);

  useEffect(() => {
    async function fetchCostumersRuc() {
      const { data } = await axios.get<Contributor[]>(
        "/api/contributor/customersWithTaxID"
      );
      setAvailableCustomers(data);
    }
    async function fetchCostumersDni() {
      const { data } = await axios.get<Contributor[]>(
        "/api/contributor/customersWithoutTaxID"
      );
      let customer = [...availableCustomers];
      customer = data;
      setAvailableCustomers(customer);
    }
    async function fetchProducts() {
      const { data } = await axios.get<Product[]>("/api/product/list");
      setAvailableProducts(data);
    }
    if (!referenceDoc) {
      setDisableAll(false);
      setSelectedItems([]);
    }

    fetchCostumersRuc();
    fetchCostumersDni();
    fetchProducts();
    if (location.state) {
      fillOutFieldsForGuia();
    }
  }, [referenceDoc]);

  useEffect(() => {
    async function fetchRelatedDocuments() {
      if (issuerCPE && docType && identification) {
        setLoadingIssuerCPE(true);
        const { data } = await axios.get(
          `/api/emission/documents/${emitter.ruc}/ALL/${docType}/${identification}/ALL`
        );
        if (data.result) {
          let relatedList = data.emittedDocs as RelatedDocument[];
          setAvailableRelatedDocuments(relatedList);
        }
        setLoadingIssuerCPE(false);
      }
    }
    fetchRelatedDocuments();
  }, [issuerCPE]);

  /*Funciones */
  const searchInvoice = async () => {
    if (serieReference === "" || serieReference.trim().length < 4) {
      setHasSerieError(true);
    }
    if (numberReference === "" || numberReference.length > 8) {
      setHasCorrelativeError(true);
    }
    if (!hasCorrelativeError && !hasSerieError) {
      const { data } = await axios.get(
        `/api/search/document/reference/${config.workspace}?serie=${serieReference}&correlativo=${numberReference}`
      );
      if (data.result) {
        setDisableAll(true);
        setIdentification(data.doc.receiver.number);
        setDocType(data.doc.receiver.number_type);
        setLegalName(data.doc.receiver.legal_name);
        setSelectedAddress({
          label: "",
          value: data.doc.receiver_address.address_line,
        });
        setSelectedItems(data.doc.products);
        setBuyerKey(data.doc.receiver.legal_name);
        setCodeUbigeoOrigen(emitter.ubigeo);
        setSelectUbigeoOrigen({
          code: emitter.ubigeo,
          departamento: emitter.department,
          provincia: emitter.province,
          distrito: emitter.district,
        });
        setAddressOrigen(emitter.address_line);
        setHasAdressOrigenError(false);
        setHasUbigeoOrigenError(false);
      } else {
        toast.warn("Documento no encontrado !");
      }
    }
  };

  const fillOutFieldsForGuia = () => {
    const emitedDocGuia = location.state as EmittedDoc;
    setBuyerKey(emitedDocGuia.receiver.legal_name as string);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const handleNext = async () => {
    if (activeStep === 0) {
      let hasGuideErrors = false;
      if (typeGuide === 0) {
        //Guia Remitente
        if (tipoEnvio === "") {
          setHasErrorTipoEnvio(true);
          setErrorMsgTipoEnvio("Seleccione un tipo de envio");
          hasGuideErrors = true;
        }
        if (tipoEnvio === "13" && transferDescription === "") {
          setHasErrorTransferDescription(true);
          setErrorMsgTransferDescription("Especifique el motivo de envio");
          hasGuideErrors = true;
        }
        if (
          [
            "04",
            "09",
            "48",
            "12",
            "49",
            "50",
            "52",
            "80",
            "81",
            "71",
            "72",
            "73",
            "74",
            "75",
            "76",
            "77",
            "78",
          ].includes(relatedDocumentType) &&
          issuerKey === ""
        ) {
          setHasErrorIssuerKey(true);
          setErrorMsgIssuerKey("Ingrese número de identificación del emisor");
          hasGuideErrors = true;
        }
      }
      if (identification.trim() === "") {
        setHasErrorBuyerKey(true);
        setErrorMsgIdentification(
          "Especifique una identificación para el comprador"
        );
        hasGuideErrors = true;
      }
      if (!hasGuideErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 1) {
      let hasDetailSendErrors = false;
      if (["02", "07", "13"].includes(tipoEnvio)) {
        if (legalNameSupplierData === "") {
          hasDetailSendErrors = true;
          setErrorMsgLegalNameSupplierData("Ingresar el nombre del proveedor");
        }
        if (identificationSupplierData === "") {
          hasDetailSendErrors = true;
          setErrorMsgIdentificationSupplierData(
            "Ingresar la identificación del proveedor"
          );
        }
        if (docTypeSupplierData === "") {
          hasDetailSendErrors = true;
          setErrorMsgDocTypeSupplierData(
            "Seleccione el tipo de identificación"
          );
        }
      }
      if (["03", "13"].includes(tipoEnvio)) {
        if (legalNameBuyerData === "") {
          hasDetailSendErrors = true;
          setErrorMsgLegalNameBuyerData("Ingresar el nombre del comprador");
        }
        if (identificationBuyerData === "") {
          hasDetailSendErrors = true;
          setErrorMsgIdentificationBuyerData(
            "Ingresar la identificación del comprador"
          );
        }
        if (docTypeBuyerData === "") {
          hasDetailSendErrors = true;
          setErrorMsgDocTypeBuyerData("Seleccione el tipo de identificación");
        }
      }
      if (!hasDetailSendErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 2) {
      let haveDetailTransport = false;
      if (selectedTransportData.length === 0) {
        haveDetailTransport = true;
        setOpenDialogTransportData(true);
      }
      if(typeGuide === 1){
        if(freightPayer === ""){
          haveDetailTransport = true;
          setHasErrorFreightPayer(true);
          setErrorMsgFreightPayer("Seleccionar el pagador de flete");
        }
        if(subcontractedTransport && identificationOutsourced === ""){
          haveDetailTransport = true;
          setHasErrorOutsourcedKey(true);
          setErrorMsgIdentificationOutsourced("Ingresar la identificación del subcontratado")
        }
        if(freightPayer === "SUNAT_Envio_IndicadorPagadorFlete_Tercero" && identificationThirdFreightPayer === ""){
          haveDetailTransport = true;
          setHasErrorThirdFreightPayerKey(true);
          setErrorMsgIdentificationThirdFreightPayer("Ingresar la identificación del pagador tercero");
        }
      }
      if (!haveDetailTransport) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 3) {
      let hasTransferDetailsError = false;
      if (codeUbigeoOrigen === "") {
        hasTransferDetailsError = true;
        setHasUbigeoOrigenError(true);
      }
      if (!scheduledTransfer && addressOrigen === "") {
        hasTransferDetailsError = true;
        setHasAdressOrigenError(true);
      }
      if (codeUbigeoDestino === "") {
        hasTransferDetailsError = true;
        setHasUbigeoDesError(true);
      }
      if (!scheduledTransfer && addressDestino === "") {
        hasTransferDetailsError = true;
        setHasAdressDestinoError(true);
      }
      if (!hasTransferDetailsError) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 4) {
      let hasItemErrors = false;
      if (selectedItems.length === 0) {
        hasItemErrors = true;
        setHasErrorItem(true);
        setErrorMsgItems("Debes incluir algún item en la guia");
      }
      if (
        dateSend === null ||
        numBultos === 0 ||
        pesoTotal === 0
      ) {
        hasItemErrors = true;
        // setHasTypeSendError(true);
        setHasNumBultosError(true);
        setHasPesoError(true);
      }

      if (!hasItemErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (activeStep === 5) {
      setLoading(true);
      sendGuia();
    }
  };

  const sendGuia = async () => {
    const responseSequential = await axios.get(
      `/api/company/lastSequential/${emitter.ruc}/${typeGuide === 1 ? "31":"09"}`
    );
    if (!responseSequential.data.result) {
      setResponseError({
        title: "Ha ocurrido un problema",
        message: responseSequential.data.msg,
      });
      return;
    }

    const json = generateJsonGuia(
      responseSequential.data.serie,
      responseSequential.data.sequential
    );

    let { data } = await axios.post("/api/emission/send", json);

    setLoading(false);
    if (data.procesado) {
      history.push("/ventas");
    } else {
      setErrorMsgTryingSend(`${data.mensaje}`);
    }
  };

  const onSwitchDocEmitido = () => {
    setReferenceDoc(!referenceDoc);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*  const handleReset = () => {
        setActiveStep(0);
    }; */
  const handleChangeTipoEnvio = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTipoEnvio(event.target.value as string);
    setHasErrorTipoEnvio(false);
  };

  /*Products */

  const getSuggestionProductValue = (suggestion: Product) => {
    const measureSelected =
      measureList.find((m) => m.code === suggestion.measure) || measureList[0];

    const newItem: ProductItem = {
      code: suggestion.code,
      measureSelected,
      measure: suggestion.measure, // original
      description: suggestion.description,
      quantity: suggestion.quantity,
      line: 1.0,
      count: 1.0,
      code_sunat: suggestion.code_sunat,
      code_gtin: suggestion.code_gtin,
      taxConcept: []
    };
    const updatedItems = [...selectedItems, newItem];
    setSelectedItems(updatedItems);
    setHasErrorItem(false);
    return "";
  };

  const renderProductSuggestion = (suggestion: Product, { query }: any) => {
    if (suggestion.is_new) {
      return (
        <span>
          Agregar nuevo producto "<strong>{suggestion.description}</strong>"
        </span>
      );
    } else {
      const indexOfSuggestion = suggestion.description
        .toLowerCase()
        .indexOf(query.toLowerCase());
      const substring = suggestion.description.substring(
        indexOfSuggestion,
        indexOfSuggestion + query.length
      );
      const highlight = suggestion.description.replace(
        substring,
        `<strong>${substring}</strong>`
      );
      return (
        <div>
          <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
        </div>
      );
    }
  };

  const onSuggestionsFetchProductsRequested = async (param: any) => {
    setAutocompleteProducts(await getProductsSuggestions(param.value));
  };

  const onSuggestionsClearProductRequested = () => {
    setAutocompleteProducts([]);
  };

  const getProductsSuggestions = async (value: string): Promise<Product[]> => {
    const inputValue = value.trim();
    let results = availableProducts.filter(
      (product) =>
        product.description.toLowerCase().includes(inputValue.toLowerCase()) ||
        product.code === inputValue
    );

    if (results.length === 0) {
      results = [
        {
          code: "",
          measure: measureList[0].code,
          description: inputValue,
          is_new: true,
          quantity: "",
          code_sunat: "",
          code_gtin: "",
          taxConcept: []
        },
      ];
    }

    const products = results.sort((a, b) => {
      return (
        a.description.indexOf(inputValue) - b.description.indexOf(inputValue)
      );
    });

    return products;
  };

  const updateItemCountChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) < 0) return;
      let newArr = [...selectedItems];
      newArr[index].line = Number(e.target.value);
      newArr[index].count = Number(e.target.value);
      setSelectedItems(newArr);
    };

  const updateItemCodeChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].code = e.target.value;
      setSelectedItems(newArr);
    };

  const updateItemCodeSunatChanged =
  (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let newArr = [...selectedItems];
    newArr[index].code_sunat = e.target.value;
    setSelectedItems(newArr);
  };

  const updateItemCodeGtinChanged =
  (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let newArr = [...selectedItems];
    newArr[index].code_gtin = e.target.value;
    setSelectedItems(newArr);
  };

  const updateItemDescriptionChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].description = e.target.value;
      setSelectedItems(newArr);
    };
  const updateItemDetalleChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].detalle = e.target.value;
      setSelectedItems(newArr);
    };

  const updateItemUnitChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...selectedItems];
    newArr[index].measureSelected =
      measureList.find((m) => m.code === e.target.value) || measureList[0];
    setSelectedItems(newArr);
  };

  const deleteItemClick = (index: any) => {
    let newArr = [...selectedItems];
    newArr.splice(index, 1);
    setSelectedItems(newArr);
  };
  /*End Products */

  /*Contributor */
  const getCustomersSuggestions = async (
    value: string
  ): Promise<Contributor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results = [];

    if (Number.isInteger(Number(inputValue))) {
      // posiblemente sea un RUC
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter(
                (contrib) => contrib.number.slice(0, inputLength) === inputValue
              )
              .sort((a, b) => {
                return (
                  a.number.indexOf(inputValue) - b.number.indexOf(inputValue)
                );
              });

      if (results.length === 0) {
        if (inputLength === 8) {
          await axios
            .get(`/api/info/by/dni/${Number(inputValue)}`)
            .then((e) => {
              const data = e.data;
              results = [
                {
                  legal_name: data.nombre,
                  number: inputValue,
                  number_type: data.tipoDocumento,
                  addresses: [
                    {
                      ubigeo: data.ubigeo || null,
                      address_line: data.direccion || null,
                      district: data.distrito || null,
                      province: data.provincia || null,
                      department: data.departamento || null,
                      default: true,
                    },
                  ],
                  is_new: true,
                },
              ];
            });
        }
        if (inputLength === 11) {
          await axios
            .get<any>(`/api/info/by/ruc/?ruc=${Number(inputValue)}`)
            .then((e) => {
              const data = e.data;
              results = [
                {
                  legal_name: data.nombre,
                  number: inputValue,
                  number_type: data.tipoDocumento,
                  addresses: [
                    {
                      ubigeo: data.ubigeo || null,
                      address_line: data.direccion || null,
                      district: data.distrito || null,
                      province: data.provincia || null,
                      department: data.departamento || null,
                      default: true,
                    },
                  ],
                  is_new: true,
                },
              ];
            });
        }
      }
    } else {
      // Posiblemente sea un nombre legal
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter((contrib) =>
                contrib.legal_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
              .sort((a, b) => {
                return (
                  a.legal_name.indexOf(inputValue) -
                  b.legal_name.indexOf(inputValue)
                );
              });
    }
    return results;
  };

  const getSuggestionValue = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      ////Direccion del Nuevo sugerido
      const defaultAddress =
        suggestion.addresses.length !== 0
          ? (suggestion.addresses.find(
              (item: any) => item.default === true
            ) as any)
          : null;

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === defaultAddress.ubigeo
        );
      }

      //
      setLegalName(suggestion.legal_name || "");
      setIdentification(suggestion.number || "");
      setDocType(suggestion.number_type);
      setSelectedAddress(
        defaultAddress
          ? {
              label: defaultAddress.address_line,
              value: defaultAddress.address_line,
            }
          : null
      );
    } else {
      // Ya existe data del cliente
      setLegalName(suggestion.legal_name);
      setIdentification(suggestion.number);
      setDocType(suggestion.number_type);

      const defaultAddress = suggestion.addresses
        ? (suggestion.addresses.find(
            (item: any) => item.default === true
          ) as any)
        : null;

      setSelectedAddress(
        defaultAddress
          ? {
              label: defaultAddress.address_line,
              value: defaultAddress.address_line,
            }
          : null
      );

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === defaultAddress.ubigeo
        );
      }
    }

    if (errorMsgLegalName) {
      setErrorMsgLegalName(null);
    }
    if (errorMsgIdentification) {
      setErrorMsgIdentification(null);
    }

    return suggestion.legal_name || suggestion.number;
  };
  
  const getSuggestionValueThirdFreightPayer = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      setLegalNameThirdFreightPayer(suggestion.legal_name || "");
      setIdentificationThirdFreightPayer(suggestion.number || "");
      setDocTypeThirdFreightPayer(suggestion.number_type);
    } else {
      // Ya existe data del cliente
      setLegalNameThirdFreightPayer(suggestion.legal_name);
      setIdentificationThirdFreightPayer(suggestion.number);
      setDocTypeThirdFreightPayer(suggestion.number_type);
    }

    if (errorMsgLegalNameThirdFreightPayer) {
      setErrorMsgLegalNameThirdFreightPayer(null);
    }
    if (errorMsgIdentificationThirdFreightPayer) {
      setErrorMsgIdentificationThirdFreightPayer(null);
    }

    return suggestion.legal_name || suggestion.number;
  };

  const getSuggestionValueOutsourced = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      setLegalNameOutsourced(suggestion.legal_name || "");
      setIdentificationOutsourced(suggestion.number || "");
      setDocTypeOutsourced(suggestion.number_type);
    } else {
      // Ya existe data del cliente
      setLegalNameOutsourced(suggestion.legal_name);
      setIdentificationOutsourced(suggestion.number);
      setDocTypeOutsourced(suggestion.number_type);
    }

    if (errorMsgLegalNameThirdFreightPayer) {
      setErrorMsgLegalNameOutsourced(null);
    }
    if (errorMsgIdentificationThirdFreightPayer) {
      setErrorMsgIdentificationOutsourced(null);
    }

    return suggestion.legal_name || suggestion.number;
  };

  const getSuggestionValueDriver = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      ////Direccion del Nuevo sugerido
      const defaultAddress =
        suggestion.addresses.length !== 0
          ? (suggestion.addresses.find(
              (item: any) => item.default === true
            ) as any)
          : null;

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === defaultAddress.ubigeo
        );
      }

      //
      setLegalNameDriver(suggestion.legal_name || "");
      setIdentificationDriver(suggestion.number || "");
      setDocTypeDriver(suggestion.number_type);
      // setSelectedAddress(
      //   defaultAddress
      //     ? {
      //         label: defaultAddress.address_line,
      //         value: defaultAddress.address_line,
      //       }
      //     : null
      // );
    } else {
      // Ya existe data del cliente
      setLegalNameDriver(suggestion.legal_name);
      setIdentificationDriver(suggestion.number);
      setDocTypeDriver(suggestion.number_type);
    }

    if (errorMsgLegalNameDriver) {
      setErrorMsgLegalNameDriver(null);
    }
    if (errorMsgIdentificationDriver) {
      setErrorMsgIdentificationDriver(null);
    }

    return suggestion.legal_name || suggestion.number;
  };

  const getSuggestionValueSupplierData = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      ////Direccion del Nuevo sugerido
      const defaultAddress =
        suggestion.addresses.length !== 0
          ? (suggestion.addresses.find(
              (item: any) => item.default === true
            ) as any)
          : null;

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        // const codeUbigeo = direcciones.find(
        //   (e) => e.code === defaultAddress.ubigeo
        // );
        // if (codeUbigeo) {
        //   setSelectUbigeo(codeUbigeo);
        //   setDireccionUbigeo(codeUbigeo.code);
        // }
      }

      //
      setLegalNameSupplierData(suggestion.legal_name || "");
      setIdentificationSupplierData(suggestion.number || "");
      setDocTypeSupplierData("6");
      setSelectedAddress(
        defaultAddress
          ? {
              label: defaultAddress.address_line,
              value: defaultAddress.address_line,
            }
          : null
      );

      ///
      // setAvailableEmails([]);
      setFreezeSupplierData(false);
      setShowSupplierData(true);
      setFocusComponentSupplier(
        suggestion.legal_name ? "legalNameSupplierData" : "numberSupplierData"
      );
    } else {
      // Ya existe data del cliente
      setLegalNameSupplierData(suggestion.legal_name);
      setIdentificationSupplierData(suggestion.number);
      setDocTypeSupplierData(suggestion.number_type);
      setFreezeSupplierData(true);
      setShowSupplierData(true);
    }

    if (errorMsgLegalNameSupplierData) {
      setErrorMsgLegalNameSupplierData(null);
    }
    if (errorMsgIdentificationSupplierData) {
      setErrorMsgIdentificationSupplierData(null);
    }

    return suggestion.legal_name || suggestion.number;
  };

  const getSuggestionValueBuyerData = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      ////Direccion del Nuevo sugerido
      const defaultAddress =
        suggestion.addresses.length !== 0
          ? (suggestion.addresses.find(
              (item: any) => item.default === true
            ) as any)
          : null;

      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        // const codeUbigeo = direcciones.find(
        //   (e) => e.code === defaultAddress.ubigeo
        // );
        // if (codeUbigeo) {
        //   setSelectUbigeo(codeUbigeo);
        //   setDireccionUbigeo(codeUbigeo.code);
        // }
      }

      //
      setLegalNameBuyerData(suggestion.legal_name || "");
      setIdentificationBuyerData(suggestion.number || "");
      setDocTypeBuyerData("6");
      // setSelectedAddress(
      //   defaultAddress
      //     ? {
      //         label: defaultAddress.address_line,
      //         value: defaultAddress.address_line,
      //       }
      //     : null
      // );

      ///
      // setAvailableEmails([]);
      setFreezeBuyerData(false);
      setShowBuyerData(true);
      setFocusComponentBuyer(
        suggestion.legal_name ? "legalNameBuyerData" : "numberBuyerData"
      );
    } else {
      // Ya existe data del cliente
      setLegalNameBuyerData(suggestion.legal_name);
      setIdentificationBuyerData(suggestion.number);
      setDocTypeBuyerData(suggestion.number_type);
      setFreezeBuyerData(true);
      setShowBuyerData(true);
    }

    if (errorMsgLegalNameBuyerData) {
      setErrorMsgLegalNameBuyerData(null);
    }
    if (errorMsgIdentificationBuyerData) {
      setErrorMsgIdentificationBuyerData(null);
    }

    return suggestion.legal_name || suggestion.number;
  };

  const renderSuggestion = (suggestion: Contributor, { query }: any) => {
    if (suggestion.is_new) {
      if (suggestion.legal_name) {
        return (
          <span>
            "<strong>{suggestion.legal_name}</strong>"
          </span>
        );
      } else {
        return (
          <span>
            "<strong>Ruc invalido</strong>"
          </span>
        );
      }
    } else {
      const handleNumber = Number.isInteger(Number(query));
      const suggestionText = handleNumber
        ? suggestion.number
        : suggestion.legal_name;

      const indexOfSuggestion = suggestionText
        .toLowerCase()
        .indexOf(query.toLowerCase());
      const substring = suggestionText.substring(
        indexOfSuggestion,
        indexOfSuggestion + query.length
      );
      const highlight = suggestionText.replace(
        substring,
        `<strong ${
          handleNumber ? `style="font-size: 12px"` : ""
        }>${substring}</strong>`
      );
      return (
        <div>
          {!handleNumber ? (
            <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
          ) : (
            <span>{suggestion.legal_name}</span>
          )}
          <br></br>
          {handleNumber ? (
            <span
              style={{ fontSize: 12 }}
              dangerouslySetInnerHTML={{ __html: highlight }}
            ></span>
          ) : (
            <strong style={{ fontSize: 12 }}>{suggestion.number}</strong>
          )}
        </div>
      );
    }
  };

  const renderSuggestionAddNew = (suggestion: Contributor, { query }: any) => {
    if (suggestion.is_new) {
      return (
        <span>
          Agregar nuevo comprador "
          <strong>{suggestion.legal_name || suggestion.number}</strong>"
        </span>
      );
    } else {
      const handleNumber = Number.isInteger(Number(query));
      const suggestionText = handleNumber
        ? suggestion.number
        : suggestion.legal_name;

      const indexOfSuggestion = suggestionText
        .toLowerCase()
        .indexOf(query.toLowerCase());
      const substring = suggestionText.substring(
        indexOfSuggestion,
        indexOfSuggestion + query.length
      );
      const highlight = suggestionText.replace(
        substring,
        `<strong ${
          handleNumber ? `style="font-size: 12px"` : ""
        }>${substring}</strong>`
      );
      return (
        <div>
          {!handleNumber ? (
            <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
          ) : (
            <span>{suggestion.legal_name}</span>
          )}
          <br></br>
          {handleNumber ? (
            <span
              style={{ fontSize: 12 }}
              dangerouslySetInnerHTML={{ __html: highlight }}
            ></span>
          ) : (
            <strong style={{ fontSize: 12 }}>{suggestion.number}</strong>
          )}
        </div>
      );
    }
  };

  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteCustomers(await getCustomersSuggestions(param.value));
  };

  const onSuggestionsClearRequested = () => {
    setAutocompleteCustomers([]);
  };
  /*Fin contributor */

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  /*Fecha de emision */
  const handleDateSendChange = (date: Date | null) => {
    setDateSend(date);
  };

  /*Transportista */
  const getRucSuggestions = async (value: string): Promise<Transportista[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results: any[] = [];
    if (Number.isInteger(Number(inputValue))) {
      if (inputLength === 11) {
        await axios
          .get<any>(`/api/info/by/ruc/?ruc=${Number(inputValue)}`)
          .then((e) => {
            const data = e.data;
            results = [
              {
                legal_name: data.nombre,
                number: inputValue,
                number_type: data.tipoDocumento,
              },
            ];
          });
      } else {
        results = [
          {
            number: inputValue,
            number_type: "",
            legal_name: "",
          },
        ];
      }
    }

    return results;
  };
  const onSuggestionsFetchCarrier = async (param: any) => {
    setCarrierSuggestions(await getRucSuggestions(param.value));
  };

  const getSuggestionValCarrier = (suggestion: Transportista) => {
    setNumberCarrier(suggestion.number);
    setNumberTypeCarrier(suggestion.number_type);
    setNameCarrier(suggestion.legal_name);
    setHasNumTypeCarrierError(false);
    setErrorMsgCarrier(null);
    return suggestion.legal_name || suggestion.number;
  };

  const renderCarrier = (suggestion: Transportista, { query }: any) => {
    if (suggestion.legal_name) {
      return (
        <span>
          <strong>{suggestion.legal_name || suggestion.number}</strong>
        </span>
      );
    }
  };
  const carrierClearRequested = () => {
    setCarrierSuggestions([]);
  };

  /*Conductor*/
  const getDniSuggestions = async (value: string): Promise<Conductor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results: any[] = [];
    if (Number.isInteger(Number(inputValue))) {
      if (inputLength === 8) {
        await axios.get(`/api/info/by/dni/${Number(inputValue)}`).then((e) => {
          const data = e.data;
          results = [
            {
              name: `${data.nombres} - ${data.apellidoPaterno} ${data.apellidoMaterno}`,
              number: inputValue,
              number_type: data.tipoDocumento,
            },
          ];
        });
      } else {
        results = [
          {
            number: inputValue,
            number_type: "",
          },
        ];
      }
    }

    return results;
  };
  const onSuggestionsFetchConductor = async (param: any) => {
    setConductorSuggestions(await getDniSuggestions(param.value));
  };

  const getSuggestionValConductor = (suggestion: Conductor) => {
    // setNumberDriver(suggestion.number);
    // setNumberTypeDriver(suggestion.number_type);
    // setNameDriver(suggestion.name);
    // setHasNumTypeDriverError(false);
    // setErrorMsgDni(null);

    setLegalNameDriver(suggestion.name);
    setIdentificationDriver(suggestion.number);
    setDocTypeDriver(suggestion.number_type);
    return suggestion.name || suggestion.number;
  };

  const renderConductor = (suggestion: Conductor, { query }: any) => {
    return (
      <span>
        <strong>{suggestion.name}</strong>
      </span>
    );
  };
  const conductorClearRequested = () => {
    setConductorSuggestions([]);
  };

  /*Procesamiento de la guia */
  const generateJsonGuia = (serie: string, sequential: string) => {
    if (freightPayer !== "") {
      indicators.push({ indicador: freightPayer });
    }
    let jsonGuia: any = {
      ubl: "2.1",
      tipoComprobante: typeGuide === 1 ? "31":"09",
      serie: `${typeGuide === 1 ? "V": "T"}${serie}`,
      numeroComprobante: incrementSequential(sequential),
      actualizarSecuencial: true,
      fechaEmision: moment(selectedDate).format("YYYY-MM-DD"),
      horaEmision: moment().format("HH:mm:ss"),
      emisor: {
        numeroDocumento: emitter.ruc,
        tipoDocumento: "6",
        nombre: emitter.legal_name,
        direccion: {
          codigo: "0000",
          ubigeo: emitter.ubigeo,
          provincia: emitter.province,
          departament: emitter.department,
          distrito: emitter.district,
          direccionCompleta: emitter.address_line,
        },
      },
      receptor: {
        numeroDocumento: identification,
        tipoDocumento: docType,
        nombre: legalName,
        direccion: {
          direccionCompleta:
            selectedAddress?.value != null ? selectedAddress?.value : "-",
          pais: "PE", // TODO: incluir pais
        },
      },
      Origen: {
        Ubigeo: codeUbigeoOrigen,
        Direccion: addressOrigen,
      },
      Destino: {
        Ubigeo: codeUbigeoDestino,
        Direccion: addressDestino,
      },
      infoTraslado: {
        fechaSalida: moment(dateSend).format("YYYY-MM-DD"),
        ...(typeGuide === 0 && {
          motivoTraslado: tipoEnvio,
        }),
        descripcionTraslado: transferDescription,
        modalidadTraslado: typeTransport,
      },
      infoBulto: {
        pesoBruto: pesoTotal.toFixed(2).toString(),
        numeroBultos: numBultos.toString(),
        unidadmedida: "Kg",
      },
      vehiculos: selectedTransportData.map((vehicle, index) => {
        return {
          tipo: index === 0 ? "Principal" : "Secundario",
          placaVehiculo: vehicle.plate,
          tarjetaVehicular: vehicle.TUCECerticate,

        };
      }),
      ...(!transferM1LVehicles && {
        conductor: selectedTransportData.map((driver, index) => {
          return {
            tipo: index === 0 ? "Principal" : "Secundario",
            tipoDocumento: driver.docType,
            nombres: driver.legalName.split("-")[0].trim(),
            apellidos: driver.legalName.split("-")[1].trim(),
            licencia: driver.license,
          };
        }),
      }),
      items: selectedItems.map((item, index) => {
        return {
          numeroItem: (index + 1).toString(),
          descripcion: item.description,
          codigo: item.code || "",
          unidadMedida: disableAll ? item.measure : item.measureSelected.code,
          cantidad: item.count
            ? item.count.toString()
            : item.quantity.toString(),
          detalle: item.detalle || "",
          ...(item.taxConcept.length > 0 && {
            propiedades: item.taxConcept.map((element)=>
              ({
                codigo: element.code,
                nombre: element.name,
                valor: element.value
              })
            )
          }),
        };
      }),
    };
    if(identificationSupplierData !== ""){
      jsonGuia = {
        ...jsonGuia,
        proveedor: {
          numeroDocumento: identificationSupplierData,
          tipoDocumento: docTypeSupplierData,
          nombre: legalNameSupplierData,
        },
      }
    }
    if(identificationBuyerData !== ""){
      jsonGuia = {
        ...jsonGuia,
        comprador: {
          numeroDocumento: identificationBuyerData,
          tipoDocumento: docTypeBuyerData,
          nombre: legalNameBuyerData,
        },
      }
    }
    if (indicators.length > 0) {
      jsonGuia = {
        ...jsonGuia,
        indicadores: indicators,
      };
    }
    if (tipoEnvio === "08" || tipoEnvio === "09") {
      jsonGuia = {
        ...jsonGuia,
        embarque: {
          codigo: selectPortAirport?.code,
          tipo: selectPortAirport?.title,
          nombre: selectPortAirport?.name,
        },
      };
    }
    if (typeGuide === 0 && selectedRelatedDocuments.length > 0) {
      jsonGuia = {
        ...jsonGuia,
        otrosDocumentos: selectedRelatedDocuments.map((relatedItem) => {
          return {
            tipoDocumento: relatedItem.document_type,
            nombreDocumento: relatedItem.docTypeName,
            numeroDocumento: `${relatedItem.serie}-${relatedItem.number}`,
            ruc: relatedItem.ruc,
          };
        }),
      };
    }
    if (typeGuide === 1) {
      // Guia transportista
      jsonGuia = {
        ...jsonGuia,
        transportista: {
          ...(freightPayer === "SUNAT_Envio_IndicadorPagadorFlete_Tercero" && {
            numeroDocumento: identificationThirdFreightPayer,
            tipoDocumento: docTypeThirdFreightPayer,
            nombre: legalNameThirdFreightPayer,
          }),
          ...(freightPayer === "SUNAT_Envio_IndicadorPagadorFlete_Remitente" && {
            numeroDocumento: emitter.ruc,
            tipoDocumento: "6",
            nombre: emitter.legal_name,
          }),
          ...(freightPayer === "SUNAT_Envio_IndicadorPagadorFlete_Subcontratador" && {
            numeroDocumento: identificationOutsourced,
            tipoDocumento: docTypeOutsourced,
            nombre: legalNameOutsourced,
          }),
          numeroRegistro: emitter.mtc_registration_number,
          entidad: {
            codigoEntidad: authorizationIssuingEntity,
            numeroAutorizacion: authorizationNumberEntity,
          },
        },
      };
    }
    let anotaciones: Array<Anotacion> = notes
      .filter((n) => n.trim() !== "")
      .map((n) => {
        return { valor: n };
      });
    if (observaciones != null) {
      jsonGuia = {
        ...jsonGuia,
        notas: anotaciones,
      };
    }
    return jsonGuia;
  };
  /*Notas */

  const handleChangeTypeGuide = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTypeGuide(newValue);
  };

  const handleChangeTransport = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    setTypeTransport(newValue);
  };

  const handleChangeRelatedDocumentType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRelatedDocumentType(event.target.value as string);
    setHasErrorRelatedDocumentType(false);
  };

  const handleChangeTypeTaxConcept = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTypeTaxConcept(event.target.value as string);
  };

  const handleChangeAuthorizationIssuingEntity = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setAuthorizationIssuingEntity(event.target.value as string);
  };

  const handleChangeFreightPayer = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFreightPayer(event.target.value as string);
    setHasErrorFreightPayer(false);
    setErrorMsgFreightPayer(null);
  };

  const onSwitchIssuerCPEChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasErrorIssuerCPE(false);
    if (identification === "") {
      setHasErrorIssuerCPE(true);
      setErrorMsgIssuerCPE("Debe ingresar primero los datos del destinatario");
      return;
    }
    setIssuerCPE(event.target.checked);
  };

  const onSwitchScheduledTransferChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setScheduledTransfer(event.target.checked);
  };

  const onSwitchCarryOutScheduledTransferChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCarryOutScheduledTransfer(event.target.checked);
    if (event.target.checked) {
      setIndicators((prevIndicators) => [
        ...prevIndicators,
        { indicador: "SUNAT_Envio_IndicadorTransbordoProgramado" },
      ]);
    } else {
      setIndicators((prevIndicators) =>
        prevIndicators.filter(
          (indicator) =>
            indicator.indicador !== "SUNAT_Envio_IndicadorTransbordoProgramado"
        )
      );
    }
  };

  const onSwitchTransferM1LVehiclesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTransferM1LVehicles(event.target.checked);
    if (event.target.checked) {
      setIndicators((prevIndicators) => [
        ...prevIndicators,
        { indicador: "SUNAT_Envio_IndicadorTrasladoVehiculoM1L" },
      ]);
    } else {
      setIndicators((prevIndicators) =>
        prevIndicators.filter(
          (indicator) =>
            indicator.indicador !== "SUNAT_Envio_IndicadorTrasladoVehiculoM1L"
        )
      );
    }
  };

  const onSwitchEmptyVehicleReturnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmptyVehicleReturn(event.target.checked);
    if (event.target.checked) {
      setIndicators((prevIndicators) => [
        ...prevIndicators,
        { indicador: "SUNAT_Envio_IndicadorRetornoVehiculoVacio" },
      ]);
    } else {
      setIndicators((prevIndicators) =>
        prevIndicators.filter(
          (indicator) =>
            indicator.indicador !== "SUNAT_Envio_IndicadorRetornoVehiculoVacio"
        )
      );
    }
  };

  const onSwitchReturnEmptyVehicleContainerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReturnEmptyVehicleContainer(event.target.checked);
    if (event.target.checked) {
      setIndicators((prevIndicators) => [
        ...prevIndicators,
        { indicador: "SUNAT_Envio_IndicadorRetornoVehiculoEnvaseVacio" },
      ]);
    } else {
      setIndicators((prevIndicators) =>
        prevIndicators.filter(
          (indicator) =>
            indicator.indicador !==
            "SUNAT_Envio_IndicadorRetornoVehiculoEnvaseVacio"
        )
      );
    }
  };

  const onSwitchSubcontractedTransportChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubcontractedTransport(event.target.checked);
    if (event.target.checked) {
      setIndicators((prevIndicators) => [
        ...prevIndicators,
        { indicador: "SUNAT_Envio_IndicadorTrasporteSubcontratado" },
      ]);
    } else {
      setIndicators((prevIndicators) =>
        prevIndicators.filter(
          (indicator) =>
            indicator.indicador !==
            "SUNAT_Envio_IndicadorTrasporteSubcontratado"
        )
      );
    }
  };

  const onSwitchSameSenderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSameSender(event.target.checked);
  };

  const onSwitchForeignBuyerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setForeignBuyer(event.target.checked);
  };

  const onSuggestionsFetchRelatedDocumentsRequested = async (param: any) => {
    setAutocompleteRelatedDocuments(
      await getRelatedDocumentsSuggestions(param.value)
    );
  };

  const onSuggestionsClearDocumentsAdvanceRequested = () => {
    setAutocompleteRelatedDocuments([]);
  };

  const getRelatedDocumentsSuggestions = async (
    value: string
  ): Promise<RelatedDocument[]> => {
    const inputValue = value.trim();
    let results = availableRelatedDocuments.filter((document) =>
      document.serie
        .toLowerCase()
        .concat(`-${document.number}`)
        .includes(inputValue.toLowerCase())
    );

    const relatedDocuments = results.sort((a, b) => {
      return a.serie.indexOf(inputValue) - b.serie.indexOf(inputValue);
    });

    return relatedDocuments;
  };

  const getSuggestionRelatedDocumentValue = (suggestion: RelatedDocument) => {
    const updatedItemsRelatedDocuments: RelatedDocument[] = [
      ...selectedRelatedDocuments,
      {
        ...suggestion,
        ruc: emitter.ruc.toString(),
        docTypeName:
          relatedDocumentsList.find(
            (item) => item.value === suggestion.document_type
          )?.label || "",
      },
    ];
    setSelectedRelatedDocuments(updatedItemsRelatedDocuments);
    return "";
  };

  const renderRelatedDocumentsSuggestion = (
    suggestion: RelatedDocument,
    { query }: any
  ) => {
    const indexOfSuggestion = suggestion.serie
      .concat(`-${suggestion.number}`)
      .toLowerCase()
      .indexOf(query.toLowerCase());
    const substring = suggestion.serie
      .concat(`-${suggestion.number}`)
      .substring(indexOfSuggestion, indexOfSuggestion + query.length);
    const highlight = suggestion.serie
      .concat(`-${suggestion.number}`)
      .replace(substring, `<strong>${substring}</strong>`);
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
      </div>
    );
  };

  /* Related Documents */
  const deleteItemRelatedClick = (index: any) => {
    let newArr = [...selectedRelatedDocuments];
    newArr.splice(index, 1);
    setSelectedRelatedDocuments(newArr);
  };

  const deleteItemTransportDataClick = (index: any) => {
    let newArr = [...selectedTransportData];
    newArr.splice(index, 1);
    setSelectedTransportData(newArr);
  };

  const handleClickOpenDialogRelatedDoc = () => {
    setOpenDialogRelatedDoc(true);
  };

  const handleClickOpenDialogTaxConcept = (index: any) => {
    setOpenDialogTaxConcept(true);
    setSelectedItemIndex(index);
    if(selectedItems[index].taxConcept.length > 0){
      setTypeTaxConcept(selectedItems[index].taxConcept[0].code);
      setDescriptionTaxConcept(selectedItems[index].taxConcept[0].name);
      setValueTaxConcept(selectedItems[index].taxConcept[0].value);
      setCodeTaxConcept(selectedItems[index].taxConcept[0].code);
    }
  }

  const handleClickOpenDialogTransportData = () => {
    setOpenDialogTransportData(true);
  };

  const handleCloseDialogRelatedDoc = () => {
    setOpenDialogRelatedDoc(false);
  };

  const handleCloseDialogTransportData = () => {
    setOpenDialogTransportData(false);
  };

  const handleCloseDialogTaxConcept = () => {
    setOpenDialogTaxConcept(false);
  };

  const addRelatedDocumet = () => {
    let haveErrorAddRelatedDoc = false;
    if (relatedDocumentType === "") {
      setHasErrorRelatedDocumentType(true);
      setErrorMsgRelatedDocumentType("Seleccione el tipo de documento");
      haveErrorAddRelatedDoc = true;
    }
    if (
      rucRelatedDoc === "" &&
      ["01", "03", "04", "09", "48", "12"].includes(relatedDocumentType)
    ) {
      setHasErrorRucRelatedDoc(true);
      setErrorMsgRucRelatedDoc("Ingrese el ruc del documento");
      haveErrorAddRelatedDoc = true;
    }
    if (
      serieRelatedDoc === "" &&
      ["01", "03", "04", "09", "48"].includes(relatedDocumentType)
    ) {
      setHasErrorSerieRelatedDoc(true);
      setErrorMsgSerieRelatedDoc("Ingrese la serie del documento");
      haveErrorAddRelatedDoc = true;
    }
    if (
      correlativeRelatedDoc === "" &&
      [
        "01",
        "03",
        "04",
        "09",
        "48",
        "12",
        "49",
        "50",
        "52",
        "80",
        "81",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
      ].includes(relatedDocumentType)
    ) {
      setHasErrorCorrelativeRelatedDoc(true);
      setErrorMsgCorrelativeRelatedDoc("Ingrese el número del documento");
      haveErrorAddRelatedDoc = true;
    }

    if (!haveErrorAddRelatedDoc) {
      const itemsRelatedDocuments = [
        ...selectedRelatedDocuments,
        {
          serie: serieRelatedDoc,
          number: correlativeRelatedDoc,
          document_type: relatedDocumentType,
          docTypeName:
            relatedDocumentsList.find(
              (item) => item.value === relatedDocumentType
            )?.label || "",
          ruc: rucRelatedDoc,
        },
      ];

      setSelectedRelatedDocuments(itemsRelatedDocuments);
      setSerieRelatedDoc("");
      setCorrelativeRelatedDoc("");
      setRucRelatedDoc("");
      setOpenDialogRelatedDoc(false);
    }
  };

  const addTransportData = () => {
    let haveErrorAddTransportData = false;
    if (vehiclePlate === "") {
      setHasErrorVehiclePlate(true);
      setErrorMsgVehiclePlate("Ingrese la placa del vehículo");
      haveErrorAddTransportData = true;
    }
    if(!transferM1LVehicles){
      if (vehicleTUCECertificate === "" && typeGuide === 1) {
        setHasErrorVehicleTUCECertificate(true);
        setErrorMsgVehicleTUCECertificate(
          "Ingrese el TUCE/Certificado del vehículo"
        );
        haveErrorAddTransportData = true;
      }
      if (identificationDriver === "") {
        setHasErrorIdentificationDriver(true);
        setErrorMsgIdentificationDriver(
          "Especifique una identificación para el conductor"
        );
        haveErrorAddTransportData = true;
      }
      if (driverLicense === "") {
        setHasErrorDriverLicense(true);
        setErrorMsgDriverLicense("Ingrese la licencia del conductor");
        haveErrorAddTransportData = true;
      }
    }

    if (!haveErrorAddTransportData) {
      const itemsTransportData = [
        ...selectedTransportData,
        {
          plate: vehiclePlate,
          ...(typeGuide === 1 && { TUCECerticate: vehicleTUCECertificate }),
          identification: identificationDriver,
          legalName: legalNameDriver,
          docType: docTypeDriver,
          license: driverLicense,
        },
      ];

      setSelectedTransportData(itemsTransportData);
      setVehiclePlate("");
      setVehicleTUCECertificate("");
      setDriverKey("");
      setIdentificationDriver("");
      setLegalNameDriver("");
      setDocTypeDriver("");
      setDriverLicense("");
      setOpenDialogTransportData(false);
    }
  };

  const addTaxConceptProductItem = () => {
    let hasErrorAddTaxConcept = false;
    if(typeTaxConcept === "Otros"){
      if(codeTaxConcept === ""){
        setHasErrorCodeTaxConcept(true);
        setErrorMsgCodeTaxConcept("Ingrese el código");
        hasErrorAddTaxConcept = true;
      }
      if(descriptionTaxConcept === ""){
        setHasErrorDescriptionTaxConcept(true);
        setErrorMsgDescriptionTaxConcept("Ingrese la descripción");
        hasErrorAddTaxConcept = true;
      }
    }
    if(typeTaxConcept !== "" && valueTaxConcept === ""){
      setHasErrorValueTaxConcept(true);
      setErrorMsgValueTaxConcept("Ingrese el valor");
      hasErrorAddTaxConcept = true;
    }
    if(!hasErrorAddTaxConcept){
      let newArr = [...selectedItems];
      if(selectedItemIndex!==null && typeTaxConcept !== ""){
        newArr[selectedItemIndex].taxConcept[0] = 
          {
            code: typeTaxConcept==="Otros"?codeTaxConcept:typeTaxConcept,
            name: typeTaxConcept==="Otros"? descriptionTaxConcept : taxConceptList.find(item => item.value === typeTaxConcept)?.label || "",
            value: valueTaxConcept
          }
        setSelectedItems(newArr);
      }
      setOpenDialogTaxConcept(false);
      setTypeTaxConcept("");
      setDescriptionTaxConcept("");
      setValueTaxConcept("");
      setCodeTaxConcept("");
    }
  }

  const handleDocTypeSupplierDataChange = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    setDocTypeSupplierData(event.target.value);
  };

  const handleDocTypeBuyerDataChange = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    setDocTypeBuyerData(event.target.value);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ContainerTitle>
        <TextStyle color={Colors.WHITE} type="h1" textAlign="center" bold={700}>
          {"Guía de Remisión"}
          <span style={{ fontSize: 15, marginLeft: 15 }}>
            / emitida por: {emitter.legal_name}
          </span>
        </TextStyle>
      </ContainerTitle>
      {/* <ContainerForm> */}
      <Tabs
        value={typeGuide}
        onChange={handleChangeTypeGuide}
        TabIndicatorProps={{
          style: {
            backgroundColor: Colors.GREEN,
          },
        }}
        centered
      >
        <Tab
          label={
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                Remitente
                <Widgets />
              </div>
            </>
          }
          {...a11yProps(0)}
        />
        {emitter.mtc_registration_number && (
          <Tab
            label={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  Transportista
                  <LocalShipping />
                </div>
              </>
            }
            {...a11yProps(1)}
          />
        )}
      </Tabs>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sm={12}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
                <StepContent>
                  {/* SECCION 1 */}
                  {index === 0 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          flexWrap: "wrap",
                          marginBottom: 2,
                        }}
                      >
                        {/* <FormControlLabel
                          value={referenceDoc}
                          control={
                            <RSwitch
                              checked={referenceDoc}
                              onChange={onSwitchDocEmitido}
                            />
                          }
                          label="Documento emitido"
                          labelPlacement="top"
                          style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        /> */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date_emission"
                            label="Fecha de Emisión "
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      {/* {referenceDoc ? (
                        <div style={{ marginBottom: 25 }}>
                          <div
                            className="clearfix"
                            style={{ marginBottom: 20 }}
                          ></div>

                          <Grid container>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: 5 }}
                            >
                              <Grid item>
                                <RTextField
                                  variant="outlined"
                                  autoFocus
                                  autoComplete="off"
                                  label="Serie *"
                                  style={{ width: 100 }}
                                  value={serieReference}
                                  onChange={(e) => {
                                    setSerieReference(e.target.value);
                                    if (e.target.value.trim().length === 4) {
                                      setHasSerieError(false);
                                    }
                                  }}
                                  error={hasSerieError}
                                />
                              </Grid>
                              <h6>-</h6>
                              <Grid item>
                                <RTextField
                                  variant="outlined"
                                  autoComplete="off"
                                  label="Correlativo"
                                  style={{ width: 120 }}
                                  type="number"
                                  value={numberReference}
                                  onChange={(e) => {
                                    setNumberReference(e.target.value);
                                    if (e.target.value.trim().length <= 5) {
                                      setHasCorrelativeError(false);
                                    }
                                  }}
                                  error={hasCorrelativeError}
                                  required
                                />
                                <RButton
                                  aria-label="search"
                                  style={{ marginLeft: "10px" }}
                                  onClick={searchInvoice}
                                >
                                  <SearchIcon />
                                </RButton>
                                <Popover
                                  open={openPopOver}
                                  anchorEl={anchorEl}
                                  onClose={handleClosePopOver}
                                  anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                  }}
                                >
                                  <Typography style={{ padding: "10px" }}>
                                    Lista de posibles documentos
                                  </Typography>
                                </Popover>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      ) : null} */}
                      <Grid container spacing={2} alignItems="center">
                        {typeGuide === 0 && (
                          <>
                            <Grid item xs={12} sm={6} md={4}>
                              <RFormControl
                                variant="outlined"
                                fullWidth
                                error={hasErrorTipoEnvio}
                              >
                                <InputLabel>Motivo de Traslado</InputLabel>
                                <Select
                                  value={tipoEnvio}
                                  onChange={handleChangeTipoEnvio}
                                  label="Tipo de envío"
                                  error={hasErrorTipoEnvio}
                                >
                                  <MenuItem value="02">Compra</MenuItem>
                                  <MenuItem value="01">Venta</MenuItem>
                                  <MenuItem value="03">
                                    Venta con entrega a terceros
                                  </MenuItem>
                                  <MenuItem value="04">
                                    Traslado entre establecimientos de la misma
                                    empres
                                  </MenuItem>
                                  <MenuItem value="05">Consignación</MenuItem>
                                  <MenuItem value="06">Devolución</MenuItem>
                                  <MenuItem value="07">
                                    Recojo de bienes transformados
                                  </MenuItem>
                                  <MenuItem value="08">Importación</MenuItem>
                                  <MenuItem value="09">Exportación</MenuItem>
                                  <MenuItem value="14">
                                    Venta sujeta a confirmación del comprador
                                  </MenuItem>
                                  <MenuItem value="13">Otros</MenuItem>
                                </Select>
                                {hasErrorTipoEnvio && (
                                  <FormHelperText>
                                    {errorMsgTipoEnvio}
                                  </FormHelperText>
                                )}
                              </RFormControl>
                            </Grid>
                            {tipoEnvio === "13" && (
                              <Grid item xs={12} sm={6} md={4}>
                                <RTextField
                                  id="descripción del motivo"
                                  label="Especifique el motivo de envio"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  error={hasErrorTransferDescription}
                                  value={transferDescription}
                                  helperText={
                                    hasErrorTransferDescription
                                      ? errorMsgTransferDescription
                                      : null
                                  }
                                  onChange={(e) => {
                                    setTransferDescription(e.target.value);
                                    setHasErrorTransferDescription(false);
                                  }}
                                />
                              </Grid>
                            )}
                            {tipoEnvio !== "" && (
                              <Grid item xs={12} sm={6} md={4}>
                                <ButtonPrimary
                                  onClick={handleClickOpenDialogRelatedDoc}
                                  background={Colors.GREEN}
                                  color={Colors.WHITE}
                                  size="16px"
                                  width="100%"
                                >
                                  <Add />
                                  Agregar Documento
                                </ButtonPrimary>
                              </Grid>
                            )}
                          </>
                        )}
                        <Grid item xs={12} md={6} sm={6}>
                          <Typography color="textSecondary" variant="caption">
                            DESTINATARIO
                          </Typography>
                          <br />
                          <div
                            className={
                              disableAll
                                ? "autocompleteDisable"
                                : "autocomplete"
                            }
                          >
                            <Autosuggest
                              suggestions={autocompleteCustomers}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={{
                                placeholder: "Identificación o nombre legal",
                                value: buyerKey,
                                onChange: (
                                  event: React.FormEvent<any>,
                                  { newValue, method }: Autosuggest.ChangeEvent
                                ) => {
                                  setBuyerKey(newValue);
                                  setHasErrorBuyerKey(false);
                                  if (newValue === "") {
                                    setLegalName("");
                                    setIdentification("");
                                    setDocType("");
                                  }
                                },
                                ref: React.createRef<HTMLInputElement>(),
                              }}
                            />
                            {hasErrorBuyerKey && (
                              <FormHelperText style={{ color: "red" }}>
                                {errorMsgIdentification}
                              </FormHelperText>
                            )}
                          </div>
                        </Grid>
                        {typeGuide === 0 && (
                          <>
                            {relatedDocumentType &&
                              [
                                "04",
                                "09",
                                "48",
                                "12",
                                "49",
                                "50",
                                "52",
                                "80",
                                "81",
                                "71",
                                "72",
                                "73",
                                "74",
                                "75",
                                "76",
                                "77",
                                "78",
                              ].includes(relatedDocumentType) && (
                                <Grid item xs={12} md={6} sm={6}>
                                  <Typography
                                    color="textSecondary"
                                    variant="caption"
                                  >
                                    EMISOR
                                  </Typography>
                                  <br />
                                  <div
                                    className={
                                      disableAll
                                        ? "autocompleteDisable"
                                        : "autocomplete"
                                    }
                                  >
                                    <Autosuggest
                                      suggestions={autocompleteCustomers}
                                      onSuggestionsFetchRequested={
                                        onSuggestionsFetchRequested
                                      }
                                      onSuggestionsClearRequested={
                                        onSuggestionsClearRequested
                                      }
                                      getSuggestionValue={getSuggestionValue}
                                      renderSuggestion={renderSuggestion}
                                      inputProps={{
                                        placeholder:
                                          "Identificación o nombre legal",
                                        value: issuerKey,
                                        onChange: (
                                          event: React.FormEvent<any>,
                                          {
                                            newValue,
                                            method,
                                          }: Autosuggest.ChangeEvent
                                        ) => {
                                          setIssuerKey(newValue);
                                          setHasErrorIssuerKey(false);
                                          if (newValue === "") {
                                            setLegalNameIssuer("");
                                            setIdentificationIssuer("");
                                            setDocTypeIssuer("");
                                          }
                                        },
                                        ref: React.createRef<HTMLInputElement>(),
                                      }}
                                    />
                                    {hasErrorIssuerKey && (
                                      <FormHelperText style={{ color: "red" }}>
                                        {errorMsgIssuerKey}
                                      </FormHelperText>
                                    )}
                                  </div>
                                </Grid>
                              )}
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <RSwitch
                                    checked={issuerCPE}
                                    onChange={onSwitchIssuerCPEChange}
                                    name="issuerCPE"
                                    disabled={loadingIssuerCPE}
                                  />
                                }
                                label={
                                  <Typography style={{ fontWeight: "bold" }}>
                                    Soy emisor del CPE
                                  </Typography>
                                }
                              />
                              {hasErrorIssuerCPE && (
                                <FormHelperText style={{ color: "red" }}>
                                  {errorMsgIssuerCPE}
                                </FormHelperText>
                              )}
                            </Grid>
                            {!loadingIssuerCPE && issuerCPE && (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Autosuggest
                                  suggestions={autocompleteRelatedDocuments}
                                  onSuggestionsFetchRequested={
                                    onSuggestionsFetchRelatedDocumentsRequested
                                  }
                                  onSuggestionsClearRequested={
                                    onSuggestionsClearDocumentsAdvanceRequested
                                  }
                                  getSuggestionValue={
                                    getSuggestionRelatedDocumentValue
                                  }
                                  renderSuggestion={
                                    renderRelatedDocumentsSuggestion
                                  }
                                  inputProps={{
                                    placeholder:
                                      "Serie y/o correlativo de un documento",
                                    value: relatedDocumentKey,
                                    onChange: (
                                      event: React.FormEvent<any>,
                                      {
                                        newValue,
                                        method,
                                      }: Autosuggest.ChangeEvent
                                    ) => setRelatedDocumentKey(newValue),
                                    ref: React.createRef<HTMLInputElement>(),
                                  }}
                                />
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <div
                                style={{
                                  overflowX: "auto",
                                  maxWidth: "50%",
                                }}
                              >
                                {!loadingIssuerCPE ? (
                                  selectedRelatedDocuments.length > 0 && (
                                    <Table
                                      size="small"
                                      aria-label="purchases"
                                      style={{
                                        marginBottom: 20,
                                        width: "100%",
                                      }}
                                      className="table-details"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                          ></TableCell>
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                            align="left"
                                          >
                                            Tipo
                                          </TableCell>
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                            align="left"
                                          >
                                            Serie
                                          </TableCell>
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                            align="left"
                                          >
                                            Correlativo
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {selectedRelatedDocuments.map(
                                          (related, index) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                align="left"
                                                width={40}
                                              >
                                                <Tooltip
                                                  title="Remover documento relacionado"
                                                  arrow
                                                >
                                                  <IconButton
                                                    style={{ width: 40 }}
                                                    size="small"
                                                    onClick={() =>
                                                      deleteItemRelatedClick(
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <DeleteIcon
                                                      fontSize="small"
                                                      color="error"
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              </TableCell>
                                              <TableCell>
                                                {related.docTypeName}
                                              </TableCell>
                                              <TableCell>
                                                {related.serie}
                                              </TableCell>
                                              <TableCell>
                                                {related.number}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  )
                                ) : (
                                  <LinearProgress
                                    style={{ color: Colors.WHITE }}
                                  />
                                )}
                              </div>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                  {/* SECCION 2 */}
                  {index === 1 && (
                    <Grid container spacing={2}>
                      {([
                        "01",
                        "03",
                        "05",
                        "06",
                        "08",
                        "09",
                        "14",
                        "13",
                        "17",
                      ].includes(tipoEnvio) || typeGuide === 1 )&& (
                        <Grid item xs={12} sm={4}>
                          <Card variant="outlined" className="cardDireccion">
                            <CardContent>
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                Datos del Destinatario
                              </Typography>
                              {/* {tipoEnvio === "08" && (
                                <FormControlLabel
                                  control={
                                    <RSwitch
                                      checked={sameSender}
                                      onChange={onSwitchSameSenderChange}
                                      name="same remitente"
                                      // disabled={loadingIssuerCPE}
                                    />
                                  }
                                  label={
                                    <Typography style={{ fontWeight: "bold" }}>
                                      Es el mismo que Remitente
                                    </Typography>
                                  }
                                  style={{ marginRight: 15 }}
                                />
                              )} */}
                              <RTextField
                                label="Identificación"
                                variant="outlined"
                                required
                                fullWidth
                                margin="normal"
                                value={identification}
                                disabled
                                // error={hasAdressOrigenError}
                                // value={addressOrigen}
                                // onChange={(e) => {
                                //   setAddressOrigen(e.target.value);
                                //   setHasAdressOrigenError(false);
                                // }}
                              />
                              <RTextField
                                label="Nombre Legal"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={legalName}
                                disabled
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      {["02", "07", "13"].includes(tipoEnvio) && (
                        <Grid item xs={12} sm={4}>
                          <div
                            style={{
                              padding: 16,
                              paddingBottom: 24,
                              border: 2,
                              borderRadius: 4,
                            }}
                            className="cardDireccion"
                          >
                            <Typography color="textSecondary" variant="caption">
                              Datos del Proveedor
                            </Typography>
                            <br />
                            <Autosuggest
                              suggestions={autocompleteCustomers}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={
                                getSuggestionValueSupplierData
                              }
                              renderSuggestion={renderSuggestionAddNew}
                              inputProps={{
                                placeholder: "Identificación o nombre legal",
                                value: supplierDataKey,
                                onChange: (
                                  event: React.FormEvent<any>,
                                  { newValue, method }: Autosuggest.ChangeEvent
                                ) => {
                                  setSupplierDataKey(newValue);
                                  if (newValue === "") {
                                    setLegalNameSupplierData("");
                                    setIdentificationSupplierData("");
                                    setDocTypeSupplierData("");
                                  }
                                },
                                ref: React.createRef<HTMLInputElement>(),
                              }}
                            />
                            {!showSupplierData && (
                              <FormHelperText style={{ color: "red" }}>
                                {errorMsgIdentificationSupplierData}
                              </FormHelperText>
                            )}
                            {showSupplierData && (
                              <>
                                <RTextField
                                  id="legal-name"
                                  label="Nombre legal"
                                  variant="outlined"
                                  size="small"
                                  required
                                  autoFocus={
                                    focusComponentSupplier ===
                                    "legalNameSupplierData"
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                  disabled={freezeSupplierData}
                                  fullWidth
                                  style={{ marginTop: 20, marginBottom: 20 }}
                                  helperText={errorMsgLegalNameSupplierData}
                                  error={errorMsgLegalNameSupplierData !== null}
                                  value={legalNameSupplierData}
                                  onChange={(e) => {
                                    setLegalNameSupplierData(e.target.value);
                                    if (
                                      errorMsgLegalNameSupplierData &&
                                      e.target.value.trim() !== ""
                                    ) {
                                      setErrorMsgLegalNameSupplierData(null);
                                    }
                                  }}
                                />
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginBottom: 5 }}
                                >
                                  <Grid item xs={12}>
                                    <RTextField
                                      id="id-number"
                                      label="Identificación"
                                      variant="outlined"
                                      size="small"
                                      required
                                      autoFocus={
                                        focusComponentSupplier ===
                                        "numberSupplierData"
                                          ? true
                                          : false
                                      }
                                      autoComplete="off"
                                      disabled={freezeSupplierData}
                                      fullWidth
                                      helperText={
                                        errorMsgIdentificationSupplierData
                                      }
                                      error={
                                        errorMsgIdentificationSupplierData !==
                                        null
                                      }
                                      value={identificationSupplierData}
                                      onChange={(e) => {
                                        setIdentificationSupplierData(
                                          e.target.value
                                        );
                                        if (
                                          errorMsgIdentificationSupplierData &&
                                          e.target.value.trim() !== ""
                                        ) {
                                          setErrorMsgIdentificationSupplierData(
                                            null
                                          );
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <RFormControl
                                      disabled={freezeSupplierData}
                                      variant="outlined"
                                      // style={{ marginRight: 10 }}
                                      fullWidth
                                    >
                                      <InputLabel
                                        id="province-select-label"
                                        // style={{ marginTop: -5 }}
                                      >
                                        Tipo de identificación
                                      </InputLabel>
                                      <Select
                                        labelId="province-select-label"
                                        value={docTypeSupplierData}
                                        onChange={
                                          handleDocTypeSupplierDataChange
                                        }
                                        label="Tipo de identificación"
                                        style={{ height: 40 }}
                                      >
                                        <MenuItem value="1">
                                          <Tooltip title="Documento Nacional de Identidad">
                                            <span>DNI</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="0">
                                          <Tooltip title="Documento Tributuario no Domiciliado sin RUC">
                                            <span>DOC.TRIB.NO.DOM.SIN.RUC</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="4">
                                          <Tooltip title="Carné de Extranjería">
                                            <span>Carné de Extranjería</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="7">
                                          <Tooltip title="Pasaporte">
                                            <span>Pasaporte</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="6">
                                          <Tooltip title="Registro Único de Contribuyente">
                                            <span>RUC</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="C">
                                          <Tooltip title="TIN – Documento Tributario PP.NN">
                                            <span>
                                              Tax Identification Number (TIN)
                                            </span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="D">
                                          <Tooltip title="IN – Documento Tributario PP.JJ">
                                            <span>
                                              Identification Number (IN)
                                            </span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="4">
                                          <Tooltip title="Carné de Extranjería">
                                            <span>Carné de Extranjería</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="7">
                                          <Tooltip title="Pasaporte">
                                            <span>Pasaporte</span>
                                          </Tooltip>
                                        </MenuItem>
                                      </Select>
                                    </RFormControl>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </div>
                        </Grid>
                      )}
                      {["03", "13"].includes(tipoEnvio) && (
                        <Grid item xs={12} sm={4}>
                          <div
                            style={{
                              padding: 16,
                              paddingBottom: 24,
                              border: 2,
                              borderRadius: 4,
                            }}
                            className="cardDireccion"
                          >
                            <Typography color="textSecondary" variant="caption">
                              Datos del Comprador
                            </Typography>
                            <br />
                            <Autosuggest
                              suggestions={autocompleteCustomers}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValueBuyerData}
                              renderSuggestion={renderSuggestionAddNew}
                              inputProps={{
                                placeholder: "Identificación o nombre legal",
                                value: buyerDataKey,
                                onChange: (
                                  event: React.FormEvent<any>,
                                  { newValue, method }: Autosuggest.ChangeEvent
                                ) => {
                                  setBuyerDataKey(newValue);
                                  if (newValue === "") {
                                    setLegalNameBuyerData("");
                                    setIdentificationBuyerData("");
                                    setDocTypeBuyerData("");
                                  }
                                },
                                ref: React.createRef<HTMLInputElement>(),
                              }}
                            />
                            {!showBuyerData && (
                              <FormHelperText style={{ color: "red" }}>
                                {errorMsgIdentificationBuyerData}
                              </FormHelperText>
                            )}
                            {showBuyerData && (
                              <>
                                <RTextField
                                  id="legal-name"
                                  label="Nombre legal"
                                  variant="outlined"
                                  size="small"
                                  required
                                  autoFocus={
                                    focusComponentBuyer === "legalNameBuyerData"
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                  disabled={freezeBuyerData}
                                  fullWidth
                                  style={{ marginTop: 20, marginBottom: 20 }}
                                  helperText={errorMsgLegalNameBuyerData}
                                  error={errorMsgLegalNameBuyerData !== null}
                                  value={legalNameBuyerData}
                                  onChange={(e) => {
                                    setLegalNameBuyerData(e.target.value);
                                    if (
                                      errorMsgLegalNameBuyerData &&
                                      e.target.value.trim() !== ""
                                    ) {
                                      setErrorMsgLegalNameBuyerData(null);
                                    }
                                  }}
                                />
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginBottom: 5 }}
                                >
                                  <Grid item xs={12}>
                                    <RTextField
                                      id="id-number"
                                      label="Identificación"
                                      variant="outlined"
                                      size="small"
                                      required
                                      autoFocus={
                                        focusComponentBuyer ===
                                        "numberBuyerData"
                                          ? true
                                          : false
                                      }
                                      autoComplete="off"
                                      disabled={freezeBuyerData}
                                      fullWidth
                                      helperText={
                                        errorMsgIdentificationBuyerData
                                      }
                                      error={
                                        errorMsgIdentificationBuyerData !== null
                                      }
                                      value={identificationBuyerData}
                                      onChange={(e) => {
                                        setIdentificationBuyerData(
                                          e.target.value
                                        );
                                        if (
                                          errorMsgIdentificationBuyerData &&
                                          e.target.value.trim() !== ""
                                        ) {
                                          setErrorMsgIdentificationBuyerData(
                                            null
                                          );
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <RFormControl
                                      disabled={freezeBuyerData}
                                      variant="outlined"
                                      // style={{ marginRight: 10 }}
                                      fullWidth
                                    >
                                      <InputLabel
                                        id="province-select-label"
                                        // style={{ marginTop: -5 }}
                                      >
                                        Tipo de identificación
                                      </InputLabel>
                                      <Select
                                        labelId="province-select-label"
                                        value={docTypeBuyerData}
                                        onChange={handleDocTypeBuyerDataChange}
                                        label="Tipo de identificación"
                                        style={{ height: 40 }}
                                      >
                                        <MenuItem value="1">
                                          <Tooltip title="Documento Nacional de Identidad">
                                            <span>DNI</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="0">
                                          <Tooltip title="Documento Tributuario no Domiciliado sin RUC">
                                            <span>DOC.TRIB.NO.DOM.SIN.RUC</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="4">
                                          <Tooltip title="Carné de Extranjería">
                                            <span>Carné de Extranjería</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="7">
                                          <Tooltip title="Pasaporte">
                                            <span>Pasaporte</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="6">
                                          <Tooltip title="Registro Único de Contribuyente">
                                            <span>RUC</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="C">
                                          <Tooltip title="TIN – Documento Tributario PP.NN">
                                            <span>
                                              Tax Identification Number (TIN)
                                            </span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="D">
                                          <Tooltip title="IN – Documento Tributario PP.JJ">
                                            <span>
                                              Identification Number (IN)
                                            </span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="4">
                                          <Tooltip title="Carné de Extranjería">
                                            <span>Carné de Extranjería</span>
                                          </Tooltip>
                                        </MenuItem>
                                        <MenuItem value="7">
                                          <Tooltip title="Pasaporte">
                                            <span>Pasaporte</span>
                                          </Tooltip>
                                        </MenuItem>
                                      </Select>
                                    </RFormControl>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {/* SECCION 3 */}
                  {index === 2 && (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={12} sm={12}>
                        {/* <Typography color="textSecondary" variant="caption">
                          Tipo de Transporte
                        </Typography>
                        <br /> */}
                        <Tabs
                          value={typeTransport}
                          onChange={handleChangeTransport}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: Colors.GREEN,
                            },
                          }}
                          centered
                        >
                          <Tab
                            value={"02"}
                            label={
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  Privado
                                  <LocalTaxi />
                                </div>
                              </>
                            }
                            {...a11yPropsTypeTransport("02")}
                          />
                          <Tab
                            value={"01"}
                            label={
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    gap: 2,
                                  }}
                                >
                                  Público
                                  <AirportShuttle />
                                </div>
                              </>
                            }
                            {...a11yPropsTypeTransport("01")}
                          />
                        </Tabs>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <RSwitch
                              checked={carryOutScheduledTransfer}
                              onChange={onSwitchCarryOutScheduledTransferChange}
                              name="carry out scheduled transfer"
                            />
                          }
                          label={
                            <Typography style={{ fontWeight: "bold" }}>
                              Realizar transbordo programado
                            </Typography>
                          }
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <RSwitch
                              checked={transferM1LVehicles}
                              onChange={onSwitchTransferM1LVehiclesChange}
                              name="transfer in M1 or L vehicles"
                            />
                          }
                          label={
                            <Typography style={{ fontWeight: "bold" }}>
                              Traslado en vehiculos M1 o L
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <RSwitch
                              checked={emptyVehicleReturn}
                              onChange={onSwitchEmptyVehicleReturnChange}
                              name="Empty vehicle return"
                            />
                          }
                          label={
                            <Typography style={{ fontWeight: "bold" }}>
                              Retorno de vehiculo vacío
                            </Typography>
                          }
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <RSwitch
                              checked={returnEmptyVehicleContainer}
                              onChange={
                                onSwitchReturnEmptyVehicleContainerChange
                              }
                              name="Return of vehicle with empty containers or packaging"
                            />
                          }
                          label={
                            <Typography style={{ fontWeight: "bold" }}>
                              Retorno de vehículo con envases o embalajes vaciós
                            </Typography>
                          }
                        />
                        <br />
                        {typeGuide === 1 && (
                          <FormControlLabel
                            control={
                              <RSwitch
                                checked={subcontractedTransport}
                                onChange={onSwitchSubcontractedTransportChange}
                                name="Subcontracted transport"
                              />
                            }
                            label={
                              <Typography style={{ fontWeight: "bold" }}>
                                Transporte subcontratado
                              </Typography>
                            }
                          />
                        )}
                      </Grid>
                      {!transferM1LVehicles && typeTransport === "02" && (
                        <>
                          <Grid item xs={12} sm={6} md={4}>
                            <CustomSelect
                              name="authorization issuing"
                              placeholder="Entidad emisora de la autorización"
                              value={authorizationIssuingEntity}
                              selectHandle={false}
                              options={[
                                {
                                  id: "01",
                                  text: "SUCAMEC - Superintendencia Nacional de Control de Servicios de Seguridad, Armas, Municiones y Explosivos de Uso Civil",
                                },
                                {
                                  id: "02",
                                  text: "DIGEMID - Dirección General de Medicamentos Insumos y Drogas",
                                },
                                {
                                  id: "03",
                                  text: "DIGESA - Dirección General de Salud Ambiental",
                                },
                                {
                                  id: "04",
                                  text: "SENASA - Servicio Nacional de Sanidad Agraria",
                                },
                                {
                                  id: "05",
                                  text: "SERFOR - Servicio Nacional Forestal y de Fauna Silvestre",
                                },
                                {
                                  id: "06",
                                  text: "MTC - Ministerio de Transportes y Comunicaciones",
                                },
                                {
                                  id: "07",
                                  text: "PRODUCE - Ministerio de la Producción",
                                },
                                {
                                  id: "08",
                                  text: "MIN. AMBIENTE - Ministerio del Ambiente",
                                },
                                {
                                  id: "09",
                                  text: "SANIPES - Organismo Nacional de Sanidad Pesquera",
                                },
                                {
                                  id: "10",
                                  text: "MML - Municipalidad Metropolitana de Lima",
                                },
                                {
                                  id: "11",
                                  text: "MINSA - Ministerio de Salud",
                                },
                                { id: "12", text: "GR - Gobierno Regional" },
                              ]}
                              onChange={handleChangeAuthorizationIssuingEntity}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <RTextField
                              id="numero de autorizacion"
                              label="Número de autorización especial emitido por la entidad"
                              variant="outlined"
                              fullWidth
                              required
                              inputProps={{ maxLength: 50 }}
                              // error={hasLicenseError}
                              value={authorizationNumberEntity}
                              // helperText={errorMsgLicense}
                              onChange={(e) => {
                                setAuthorizationNumberEntity(e.target.value);
                                // if (e.target.value.trim.length >= 4) {
                                //   setHasLicenseError(false);
                                //   setErrorMsgLicense(null);
                                // }
                              }}
                            />
                          </Grid>
                        </>
                      )}
                      {typeGuide === 1 && subcontractedTransport && (
                        <Grid item xs={12} sm={6} md={6}>
                        <Typography color="textSecondary" variant="caption">
                          DATOS DEL SUBCONTRATADO
                        </Typography>
                        <br />
                        <div
                          className={
                            disableAll
                              ? "autocompleteDisable"
                              : "autocomplete"
                          }
                        >
                          <Autosuggest
                            suggestions={autocompleteCustomers}
                            onSuggestionsFetchRequested={
                              onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValueOutsourced}
                            renderSuggestion={renderSuggestion}
                            inputProps={{
                              placeholder: "Identificación o nombre legal",
                              value: outsourcedKey,
                              onChange: (
                                event: React.FormEvent<any>,
                                {
                                  newValue,
                                  method,
                                }: Autosuggest.ChangeEvent
                              ) => {
                                setOutsourcedKey(newValue);
                                if (newValue === "") {
                                  setLegalNameOutsourced("");
                                  setIdentificationOutsourced("");
                                  setDocTypeOutsourced("");
                                }
                              },
                              ref: React.createRef<HTMLInputElement>(),
                            }}
                          />
                          {hasErrorOutsourcedKey && (
                            <FormHelperText style={{color:"red"}}>{errorMsgIdentificationOutsourced}</FormHelperText>
                          )}
                        </div>
                      </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={4}>
                        <ButtonPrimary
                          onClick={handleClickOpenDialogTransportData}
                          background={Colors.GREEN}
                          color={Colors.WHITE}
                          size="16px"
                          width="100%"
                        >
                          <Add />
                          Agregar Datos de Transporte
                        </ButtonPrimary>
                      </Grid>
                      {typeGuide === 1 && (
                        <>
                          <Grid item xs={12} sm={6} md={4}>
                            <CustomSelect
                              name="freight payer"
                              placeholder="Pagador de flete"
                              value={freightPayer}
                              selectHandle={false}
                              options={[
                                {
                                  id: "SUNAT_Envio_IndicadorPagadorFlete_Tercero",
                                  text: "Otro (tercero)",
                                },
                                {
                                  id: "SUNAT_Envio_IndicadorPagadorFlete_Remitente",
                                  text: "Remitente",
                                },
                                {
                                  id: "SUNAT_Envio_IndicadorPagadorFlete_Subcontratador",
                                  text: "Subcontratado",
                                },
                              ]}
                              onChange={handleChangeFreightPayer}
                              error = {hasErrorFreightPayer}
                              helperText={errorMsgFreightPayer || ""}
                            />
                          </Grid>
                          {freightPayer ===
                            "SUNAT_Envio_IndicadorPagadorFlete_Tercero" && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                DATOS DEL PAGADOR TERCERO
                              </Typography>
                              <br />
                              <div
                                className={
                                  disableAll
                                    ? "autocompleteDisable"
                                    : "autocomplete"
                                }
                              >
                                <Autosuggest
                                  suggestions={autocompleteCustomers}
                                  onSuggestionsFetchRequested={
                                    onSuggestionsFetchRequested
                                  }
                                  onSuggestionsClearRequested={
                                    onSuggestionsClearRequested
                                  }
                                  getSuggestionValue={getSuggestionValueThirdFreightPayer}
                                  renderSuggestion={renderSuggestion}
                                  inputProps={{
                                    placeholder:
                                      "Identificación o nombre legal",
                                    value: thirdFreightPayerKey,
                                    onChange: (
                                      event: React.FormEvent<any>,
                                      {
                                        newValue,
                                        method,
                                      }: Autosuggest.ChangeEvent
                                    ) => {
                                      setThirdFreightPayerKey(newValue);
                                      if (newValue === "") {
                                        setLegalNameThirdFreightPayer("");
                                        setIdentificationThirdFreightPayer("");
                                        setDocTypeThirdFreightPayer("");
                                      }
                                    },
                                    ref: React.createRef<HTMLInputElement>(),
                                  }}
                                />
                                {hasErrorThirdFreightPayerKey && (
                                  <FormHelperText style={{color:"red"}}>{errorMsgIdentificationThirdFreightPayer}</FormHelperText>
                                )}
                              </div>
                            </Grid>
                          )}
                        </>
                      )}
                      {selectedTransportData.length > 0 && (
                        <TableContainer>
                                    <Table
                                      size="small"
                                      aria-label="purchases"
                                      style={{
                                        marginBottom: 20,
                                        width: "100%",
                                      }}
                                      className="table-details"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                          ></TableCell>
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                          >Nro</TableCell>
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                            align="left"
                                          >
                                            Placa Vehículo
                                          </TableCell>
                                          {typeGuide === 1 && (
                                            <TableCell
                                              style={{ fontWeight: "bold" }}
                                              align="left"
                                            >
                                              TUCE/Certificado
                                            </TableCell>
                                          )}
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                            align="left"
                                          >
                                            Nombre del Conductor
                                          </TableCell>
                                          <TableCell
                                            style={{ fontWeight: "bold" }}
                                            align="left"
                                          >
                                            Licencia del Conductor
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {selectedTransportData.map(
                                          (item, index) => (
                                            <TableRow key={index}>
                                              <TableCell
                                                align="left"
                                                width={40}
                                              >
                                                <Tooltip
                                                  title="Remover datos de transporte"
                                                  arrow
                                                >
                                                  <IconButton
                                                    style={{ width: 40 }}
                                                    size="small"
                                                    onClick={() =>
                                                      deleteItemTransportDataClick(
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <DeleteIcon
                                                      fontSize="small"
                                                      color="error"
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              </TableCell>
                                              <TableCell>
                                                {index+1}
                                              </TableCell>
                                              <TableCell>
                                                {item.plate}
                                              </TableCell>
                                              {typeGuide === 1 && (
                                                <TableCell>
                                                  {item.TUCECerticate}
                                                </TableCell>
                                              )}
                                              <TableCell>
                                                {item.legalName}
                                              </TableCell>
                                              <TableCell>
                                                {item.license}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                    </TableContainer>
                                  )}
                    </Grid>
                  )}
                  {/* SECCION 4 */}
                  {index === 3 && (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControlLabel
                          control={
                            <RSwitch
                              checked={scheduledTransfer}
                              onChange={onSwitchScheduledTransferChange}
                              name="scheduled transfer"
                            />
                          }
                          label={
                            <Typography style={{ fontWeight: "bold" }}>
                              Transbordo programado
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Fecha de inicio de traslado"
                            id="date-picker-send"
                            value={dateSend}
                            onChange={handleDateSendChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <Card variant="outlined" className="cardDireccion">
                          <CardContent>
                            <Typography color="textSecondary" variant="caption">
                              ORIGEN
                            </Typography>
                            <FlightTakeoffIcon />
                            <br />
                            <Autocomplete
                              id="ubigeo-origen"
                              options={direcciones}
                              getOptionLabel={(option) =>
                                `${option.departamento}-${option.provincia}-${option.distrito}`
                              }
                              fullWidth={true}
                              popupIcon={<LocationOn />}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Distrito *"
                                  placeholder="Buscar..."
                                  variant="outlined"
                                  error={hasUbigeoOrigenError}
                                />
                              )}
                              renderOption={(option: any) => (
                                <React.Fragment>
                                  <Typography
                                    style={{ padding: "5px" }}
                                    variant="body2"
                                  >
                                    {option.departamento}-{option.provincia}-
                                    {option.distrito}
                                    <br />
                                    <Typography variant="body2">
                                      <small style={{ color: "#777" }}>
                                        {option.code}
                                      </small>
                                    </Typography>
                                  </Typography>
                                </React.Fragment>
                              )}
                              value={selectUbigeoOrigen}
                              onChange={(event, value) => {
                                setSelectUbigeoOrigen(value);
                                setCodeUbigeoOrigen(value?.code);
                                setHasUbigeoOrigenError(false);
                                if (value === null) {
                                  setSelectUbigeoOrigen(null);
                                  setCodeUbigeoOrigen("");
                                  setHasUbigeoOrigenError(true);
                                }
                              }}
                            />
                            {!scheduledTransfer && (
                              <RTextField
                                id="direccion-origen"
                                label="Dirección"
                                variant="outlined"
                                required
                                fullWidth={true}
                                margin="normal"
                                error={hasAdressOrigenError}
                                value={addressOrigen}
                                onChange={(e) => {
                                  setAddressOrigen(e.target.value);
                                  setHasAdressOrigenError(false);
                                }}
                              />
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <Card variant="outlined" className="cardDireccion">
                          <CardContent>
                            <Typography color="textSecondary" variant="caption">
                              DESTINO
                            </Typography>
                            <FlightLandIcon />
                            <br />
                            <Autocomplete
                              id="ubigeo-destino"
                              options={direcciones}
                              getOptionLabel={(option) =>
                                `${option.departamento}-${option.provincia}-${option.distrito}`
                              }
                              fullWidth={true}
                              popupIcon={<LocationOn />}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Distrito *"
                                  placeholder="Buscar..."
                                  variant="outlined"
                                  error={hasUbigeoDesError}
                                />
                              )}
                              renderOption={(option: any) => (
                                <React.Fragment>
                                  <Typography
                                    style={{ padding: "5px" }}
                                    variant="body2"
                                  >
                                    {option.departamento}-{option.provincia}-
                                    {option.distrito}
                                    <br />
                                    <Typography variant="body2">
                                      <small style={{ color: "#777" }}>
                                        {option.code}
                                      </small>
                                    </Typography>
                                  </Typography>
                                </React.Fragment>
                              )}
                              value={selectUbigeoDestino}
                              onChange={(event, value) => {
                                setSelectUbigeoDestino(value);
                                setCodeUbigeoDestino(value?.code);
                                setHasUbigeoDesError(false);
                                if (value === null) {
                                  setSelectUbigeoDestino(null);
                                  setCodeUbigeoDestino("");
                                  setHasUbigeoDesError(true);
                                }
                              }}
                            />
                            {!scheduledTransfer && (
                              <RTextField
                                id="direccion-destino"
                                label="Dirección"
                                variant="outlined"
                                required
                                fullWidth={true}
                                margin="normal"
                                error={hasAdressDestinoError}
                                value={addressDestino}
                                onChange={(e) => {
                                  setAddressDestino(e.target.value);
                                  setHasAdressDestinoError(false);
                                }}
                              />
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                      {(tipoEnvio === "08" || tipoEnvio === "09") && (
                        <Grid item xs={12} md={6} sm={6}>
                          <Autocomplete
                            options={portAirportList}
                            getOptionLabel={(option) =>
                              `${option.title}-${option.code}-${option.name}`
                            }
                            renderInput={(params) => (
                              <RTextField
                                {...params}
                                label="Descripción del Puerto o Aeropuerto"
                                variant="outlined"
                              />
                            )}
                            value={selectPortAirport}
                            onChange={(event, value) => {
                              setSelectPortAirport(value);
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {/* SECCION 5 */}
                  {index === 4 && (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        sm={6}
                        className={
                          disableAll ? "autocompleteDisable" : "autocomplete"
                        }
                      >
                        <Autosuggest
                          suggestions={autocompleteProducts}
                          onSuggestionsFetchRequested={
                            onSuggestionsFetchProductsRequested
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearProductRequested
                          }
                          getSuggestionValue={getSuggestionProductValue}
                          renderSuggestion={renderProductSuggestion}
                          inputProps={{
                            placeholder:
                              "Descripción del producto o código del prod.",
                            value: productKey,
                            onChange: (
                              event: React.FormEvent<any>,
                              { newValue, method }: Autosuggest.ChangeEvent
                            ) => setProductKey(newValue),
                            ref: React.createRef<HTMLInputElement>(),
                          }}
                        />
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {hasErrorItem ? (
                            <Typography variant="body2" color="error">
                              {errorMsgItems}
                            </Typography>
                          ) : (
                            ""
                          )}
                          {selectedItems.length > 0 && (
                            <Table
                              size="small"
                              aria-label="purchases"
                              style={{ marginTop: 30, marginBottom: 20 }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{ fontWeight: "bold" }}
                                    align="center"
                                  >
                                    Cantidad
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Código
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Cód. prod. Sunat
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Cód. GTIN
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Nombre
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Descripción
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedItems.map((product, index) => (
                                  <TableRow
                                    key={index}
                                    style={{
                                      borderColor: "transparent",
                                      borderStyle: "solid",
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ minWidth: 120, maxWidth: 80 }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <Tooltip title="Remover Item" arrow>
                                          <IconButton
                                            style={{
                                              marginRight: 10,
                                              width: 34,
                                            }}
                                            size="small"
                                            onClick={() =>
                                              deleteItemClick(index)
                                            }
                                          >
                                            <DeleteIcon
                                              fontSize="small"
                                              color="error"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Input
                                          disabled={disableAll}
                                          autoComplete="off"
                                          value={
                                            product.quantity || product.count
                                          }
                                          onChange={updateItemCountChanged(
                                            index
                                          )}
                                          type="number"
                                          style={{
                                            marginRight: 10,
                                            minWidth: 30,
                                          }}
                                        />
                                        {!disableAll ? (
                                          <Select
                                            value={product.measureSelected.code}
                                            disabled={disableAll}
                                            onChange={(e) =>
                                              updateItemUnitChanged(index, e)
                                            }
                                          >
                                            {measureList.map((m) => (
                                              <MenuItem
                                                key={m.code}
                                                value={m.code}
                                              >
                                                <Tooltip title={m.description}>
                                                  <span>{m.display}</span>
                                                </Tooltip>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        ) : (
                                          <Select
                                            value={product.measure}
                                            disabled={true}
                                          >
                                            {measureList.map((m) => (
                                              <MenuItem
                                                key={m.code}
                                                value={m.code}
                                              >
                                                <Tooltip title={m.description}>
                                                  <span>{m.display}</span>
                                                </Tooltip>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 50, maxWidth: 30 }}
                                    >
                                      <Input
                                        disabled={disableAll}
                                        style={{ width: "100%" }}
                                        autoComplete="off"
                                        value={product.code}
                                        onChange={updateItemCodeChanged(index)}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 50, maxWidth: 30 }}
                                    >
                                      <Input
                                        disabled={disableAll}
                                        style={{ width: "100%" }}
                                        autoComplete="off"
                                        value={product.code_sunat}
                                        onChange={updateItemCodeSunatChanged(index)}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 50, maxWidth: 30 }}
                                    >
                                      <Input
                                        disabled={disableAll}
                                        style={{ width: "100%" }}
                                        autoComplete="off"
                                        value={product.code_gtin}
                                        onChange={updateItemCodeGtinChanged(index)}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 130, maxWidth: 500 }}
                                    >
                                      <Input
                                        disabled={disableAll}
                                        style={{ width: "100%" }}
                                        autoComplete="off"
                                        error={product.description === ""}
                                        value={product.description}
                                        onChange={updateItemDescriptionChanged(
                                          index
                                        )}
                                      />
                                    </TableCell>
                                      <TableCell
                                        style={{
                                          minWidth: 130,
                                          maxWidth: 500,
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: 1,
                                        }}
                                      >
                                        <Tooltip
                                          title="Agregar concepto tributario"
                                          aria-label="add"
                                        >
                                          <Button
                                            variant="contained"
                                            color="default"
                                            disableElevation
                                            onClick={()=>handleClickOpenDialogTaxConcept(index)}
                                            style={{width:"20%"}}
                                          >
                                            <Add
                                              fontSize="small"
                                            />
                                          </Button>
                                        </Tooltip>
                                        {addDescription ? (
                                          <Input
                                            style={{ width: "80%" }}
                                            autoComplete="off"
                                            error={product.detalle === ""}
                                            value={product.detalle}
                                            onChange={updateItemDetalleChanged(
                                              index
                                            )}
                                          />
                                        ):(
                                          <Tooltip
                                          title="Agregar información adicional"
                                          aria-label="add"
                                        >
                                          <Button
                                            variant="contained"
                                            color="default"
                                            disableElevation
                                            onClick={() =>
                                              setAddDescription(true)
                                            }
                                          >
                                            <CommentIcon
                                              fontSize="small"
                                            />
                                          </Button>
                                        </Tooltip>
                                        )}
                                      </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <RTextField
                            id="num-bultos"
                            label="Cantidad bultos"
                            variant="outlined"
                            autoComplete="number"
                            required
                            type="number"
                            fullWidth
                            value={numBultos}
                            error={hasNumBultosError}
                            InputProps={{
                              endAdornment: (
                                <FontAwesomeIcon
                                  icon={faCubes}
                                  style={{
                                    fontSize: "18px",
                                    paddingRight: "3px",
                                  }}
                                />
                              ),
                            }}
                            onChange={(e) => {
                              let bultos = Number(e.target.value);
                              setNumBultos(bultos);
                              setHasNumBultosError(false);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <RTextField
                            id="peso-total"
                            label="Peso Total"
                            variant="outlined"
                            autoComplete="number"
                            required
                            type="number"
                            fullWidth
                            value={pesoTotal}
                            error={hasPesoError}
                            InputProps={{
                              endAdornment: (
                                <>
                                  <InputAdornment position="end">
                                    {" "}
                                    <b>Kg</b>
                                  </InputAdornment>
                                </>
                              ),
                            }}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              let peso = Number(event.target.value);
                              setPesoTotal(peso);
                              setHasPesoError(false);
                            }}
                          />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <RTextField
                            id="nota"
                            label="Observaciones para el traslado "
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth={true}
                            margin="normal"
                            onChange={(e) => {
                              let newArr = [];
                              newArr.push(e.target.value);
                              setNotes(newArr);
                              setObservaciones(e.target.value);
                            }}
                            value={observaciones}
                          />
                        </Grid> */}
                      </Grid>
                    </>
                  )}
                  {/* SECCION 6 */}
                  {index === 5 && (
                    <>
                      <div style={{ marginTop: 20 }}>
                        <Typography variant="h6">
                          ¿Ha completado su Guia?
                        </Typography>
                        <Typography variant="body2">
                          Se enviará el documento a la SUNAT
                        </Typography>
                      </div>
                      {errorMsgTryingSend !== "" && (
                        <Alert severity="error">{errorMsgTryingSend}</Alert>
                      )}
                    </>
                  )}
                  <div style={{ marginBottom: 5, marginTop: 25 }}>
                    <div>
                      {activeStep !== 0 && (
                        <ButtonSecondary
                          onClick={handleBack}
                          border={Colors.GREEN}
                          color={Colors.GREEN}
                          size="16px"
                          width="200px"
                        >
                          Atras
                        </ButtonSecondary>
                      )}
                      <ButtonPrimary
                        onClick={handleNext}
                        disabled={loading}
                        background={Colors.GREEN}
                        color={Colors.WHITE}
                        size="16px"
                        width="200px"
                      >
                        {activeStep === steps.length - 1 ? (
                          <>
                            {loading ? (
                              <>
                                <CircularProgress color="inherit" size={14} />
                              </>
                            ) : (
                              <>
                                <Send
                                  fontSize="small"
                                  style={{ marginRight: 5 }}
                                />
                                {"Enviar"}
                              </>
                            )}
                          </>
                        ) : (
                          "Continuar"
                        )}
                      </ButtonPrimary>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      {/* </ContainerForm> */}
      <Dialog
        open={openDialogRelatedDoc}
        onClose={handleCloseDialogRelatedDoc}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">AGREGAR DOCUMENTO</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <RFormControl
                variant="outlined"
                fullWidth
                error={hasErrorRelatedDocumentType}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo de documento relacionado
                </InputLabel>
                <Select
                  value={relatedDocumentType}
                  onChange={handleChangeRelatedDocumentType}
                  label="documento relacionado"
                  style={{ minWidth: 220 }}
                  error={hasErrorRelatedDocumentType}
                >
                  {relatedDocumentsList
                    .filter((item) => item.shippingTypeValue === tipoEnvio)
                    .map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
                {hasErrorRelatedDocumentType && (
                  <FormHelperText>{errorMsgRelatedDocumentType}</FormHelperText>
                )}
              </RFormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <RTextField
                id="numero de ruc"
                label="Número de RUC del emisor"
                variant="outlined"
                autoComplete="number"
                fullWidth
                value={rucRelatedDoc}
                error={hasErrorRucRelatedDoc}
                helperText={
                  hasErrorRucRelatedDoc ? errorMsgRucRelatedDoc : null
                }
                onChange={(e) => {
                  setRucRelatedDoc(e.target.value);
                  setHasErrorRucRelatedDoc(false);
                  setErrorMsgRucRelatedDoc(null);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <RTextField
                id="serie"
                label="Serie del documento"
                variant="outlined"
                autoComplete="number"
                fullWidth
                value={serieRelatedDoc}
                error={hasErrorSerieRelatedDoc}
                helperText={
                  hasErrorSerieRelatedDoc ? errorMsgSerieRelatedDoc : null
                }
                onChange={(e) => {
                  setSerieRelatedDoc(e.target.value);
                  setHasErrorSerieRelatedDoc(false);
                  setErrorMsgSerieRelatedDoc(null);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <RTextField
                id="Correlativo"
                label="Correlativo"
                variant="outlined"
                autoComplete="number"
                fullWidth
                value={correlativeRelatedDoc}
                error={hasErrorCorrelativeRelatedDoc}
                helperText={
                  hasErrorCorrelativeRelatedDoc
                    ? errorMsgCorrelativeRelatedDoc
                    : null
                }
                onChange={(e) => {
                  setCorrelativeRelatedDoc(e.target.value);
                  setHasErrorCorrelativeRelatedDoc(false);
                  setErrorMsgCorrelativeRelatedDoc(null);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary
            onClick={handleCloseDialogRelatedDoc}
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            width="200px"
          >
            CANCELAR
          </ButtonSecondary>
          <ButtonPrimary
            onClick={addRelatedDocumet}
            background={Colors.GREEN}
            color={Colors.WHITE}
            size="14px"
            width="200px"
          >
            AGREGAR
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogTransportData}
        onClose={handleCloseDialogTransportData}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{overflow:"visible"}}
      >
        <DialogTitle id="alert-dialog-title">
          AGREGAR DATOS DE TRANSPORTE
        </DialogTitle>
        <DialogContent style={{overflowY:"unset"}}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={6}>
              <RTextField
                id="placa"
                label="Placa vehículo"
                variant="outlined"
                autoComplete="number"
                required
                fullWidth
                inputProps={{ maxLength: 8 }}
                error={hasErrorVehiclePlate}
                value={vehiclePlate}
                helperText={errorMsgVehiclePlate}
                onChange={(e) => {
                  setVehiclePlate(e.target.value);
                  setHasErrorVehiclePlate(false);
                  setErrorMsgVehiclePlate(null);
                }}
              />
            </Grid>
            {!transferM1LVehicles && (
              <>
              {typeGuide === 1 && (
              <Grid item xs={12} sm={6}>
                <RTextField
                  id="placa"
                  label="TUCE/Certificado principal"
                  variant="outlined"
                  autoComplete="number"
                  required
                  fullWidth
                  error={hasErrorVehicleTUCECertificate}
                  value={vehicleTUCECertificate}
                  helperText={errorMsgVehicleTUCECertificate}
                  onChange={(e) => {
                    setVehicleTUCECertificate(e.target.value);
                    setHasErrorVehicleTUCECertificate(false);
                    setErrorMsgVehicleTUCECertificate(null);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={8}>
              <Typography color="textSecondary" variant="caption">
                Conductor
              </Typography>
              <br />
              <div
                className={disableAll ? "autocompleteDisable" : "autocomplete"}
              >
                {/* <Autosuggest
                  suggestions={conductorSuggestions}
                  onSuggestionsFetchRequested={
                    onSuggestionsFetchConductor
                  }
                  onSuggestionsClearRequested={
                    conductorClearRequested
                  }
                  getSuggestionValue={
                    getSuggestionValConductor
                  }
                  renderSuggestion={renderConductor}
                  inputProps={{
                    placeholder: "DNI conductor",
                    value: dniConductor,
                    onChange: (
                      event: React.FormEvent<any>,
                      {
                        newValue,
                        method,
                      }: Autosuggest.ChangeEvent
                    ) => {
                      setDniConductor(newValue);

                      if (newValue === "") {
                        setNumberDriver("");
                        setNumberTypeDriver("");
                        setHasNumTypeDriverError(true);
                      }
                    },
                    ref: React.createRef<HTMLInputElement>(),
                  }}
                /> */}
                <Autosuggest
                  suggestions={conductorSuggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchConductor}
                  onSuggestionsClearRequested={conductorClearRequested}
                  getSuggestionValue={getSuggestionValConductor}
                  renderSuggestion={renderConductor}
                  inputProps={{
                    placeholder: "DNI conductor",
                    value: driverKey,
                    onChange: (
                      event: React.FormEvent<any>,
                      { newValue, method }: Autosuggest.ChangeEvent
                    ) => {
                      setDriverKey(newValue);
                      if (newValue === "") {
                        setLegalNameDriver("");
                        setIdentificationDriver("");
                        setDocTypeDriver("");
                      }
                    },
                    ref: React.createRef<HTMLInputElement>(),
                  }}
                />
                {hasErrorIdentificationDriver && (
                  <FormHelperText style={{ color: "red" }}>
                    {errorMsgIdentificationDriver}
                  </FormHelperText>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <RTextField
                id="primary"
                label="Licencia"
                variant="outlined"
                autoComplete="number"
                required
                fullWidth
                error={hasErrorDriverLicense}
                value={driverLicense}
                helperText={errorMsgDriverLicense}
                onChange={(e) => {
                  setDriverLicense(e.target.value);
                  setHasErrorDriverLicense(false);
                  setErrorMsgDriverLicense(null);
                }}
              />
            </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary
            onClick={handleCloseDialogTransportData}
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            width="200px"
          >
            CANCELAR
          </ButtonSecondary>
          <ButtonPrimary
            onClick={addTransportData}
            background={Colors.GREEN}
            color={Colors.WHITE}
            size="14px"
            width="200px"
          >
            AGREGAR
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogTaxConcept}
        onClose={handleCloseDialogTaxConcept}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">CONCEPTO TRIBUTARIO</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <RFormControl
                variant="outlined"
                fullWidth
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo de concepto
                </InputLabel>
                <Select
                  value={typeTaxConcept}
                  onChange={handleChangeTypeTaxConcept}
                  label="concepto tributario"
                  style={{ minWidth: 220 }}
                >
                  {taxConceptList.map((item)=>
                    <MenuItem value={item.value} key={item.value}>
                    {item.label}
                    </MenuItem>
                  )}
                </Select>
              </RFormControl>
            </Grid>
            {typeTaxConcept === "Otros" && (
<>
<Grid item xs={12} sm={6} md={6}>
              <RTextField
                id="codigo de concepto tributario"
                label="Código"
                variant="outlined"
                autoComplete="number"
                fullWidth
                value={codeTaxConcept}
                error={hasErrorCodeTaxConcept}
                helperText={
                  hasErrorCodeTaxConcept
                    ? errorMsgCodeTaxConcept
                    : null
                }
                onChange={(e) => {
                  setCodeTaxConcept(e.target.value);
                  setHasErrorCodeTaxConcept(false);
                  setErrorMsgCodeTaxConcept(null);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <RTextField
                id="description tax Concept"
                label="Descripción"
                variant="outlined"
                autoComplete="number"
                fullWidth
                value={descriptionTaxConcept}
                error={hasErrorDescriptionTaxConcept}
                helperText={
                  hasErrorDescriptionTaxConcept
                    ? errorMsgDescriptionTaxConcept
                    : null
                }
                onChange={(e) => {
                  setDescriptionTaxConcept(e.target.value);
                  setHasErrorDescriptionTaxConcept(false);
                  setErrorMsgDescriptionTaxConcept(null);
                }}
              />
            </Grid>
</>
            )}
            <Grid item xs={12} sm={6} md={6}>
              <RTextField
                id="value Tax Concept"
                label="Valor"
                variant="outlined"
                autoComplete="number"
                fullWidth
                value={valueTaxConcept}
                error={hasErrorValueTaxConcept}
                helperText={
                  hasErrorValueTaxConcept
                    ? errorMsgValueTaxConcept
                    : null
                }
                onChange={(e) => {
                  setValueTaxConcept(e.target.value);
                  setHasErrorValueTaxConcept(false);
                  setErrorMsgValueTaxConcept(null);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSecondary
            onClick={handleCloseDialogTaxConcept}
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            width="200px"
          >
            CANCELAR
          </ButtonSecondary>
          <ButtonPrimary
            onClick={addTaxConceptProductItem}
            background={Colors.GREEN}
            color={Colors.WHITE}
            size="14px"
            width="200px"
          >
            AGREGAR
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormNewGuieRemission;
