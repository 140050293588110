import { Configuration } from "../types/config";
import { ReceivedDoc } from "../types/receivedDoc";
import { UnadjudicatedDoc } from "../types/unadjudicatedDoc";
import { EmittedDoc } from "../types/emittedDoc";
import { CompanyInfo } from "../types/companies";
import { combineReducers } from "redux";
import configReducer from "./config";
import unadjudicatedDocsReducer from "./unadjudicatedDocs";
import emittedDocsReducer from "./emittedDocs";
import receivedDocsReducer from "./receivedDocs";
import companiesInfoReducer from "./companies";
import { ReportDoc } from "../types/reportDocs";
import reportsDocsReducer from "./reportDocs";

export interface AppState {
  config: Configuration;
  unadjudicatedDocs: UnadjudicatedDoc[];
  emittedDocs: EmittedDoc[];
  receivedDocs: ReceivedDoc[];
  companiesInfo: CompanyInfo[];
  reportDocs: ReportDoc;
}

const reducers = combineReducers<AppState>({
  config: configReducer,
  unadjudicatedDocs: unadjudicatedDocsReducer,
  emittedDocs: emittedDocsReducer,
  receivedDocs: receivedDocsReducer,
  companiesInfo: companiesInfoReducer,
  reportDocs: reportsDocsReducer,
});

export default reducers;
