import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getCurrencySymbol } from "src/infrastructure/static/util";
import { Colors } from "src/infrastructure/static/colors";

interface ITableProductsProps {
  products: any[];
}

const TableProducts: React.FC<ITableProductsProps> = ({ products }) => {
  const tableHead = [
    "CANTIDAD",
    "CÓDIGO",
    "DESCRIPCIÓN",
    "PRECIO UNIT.",
    "DESCUENTO",
    "IMPORTE",
  ];

  return (
    <TableContainer style={{ margin: "20px 0px" }}>
      <Table aria-label="simple table">
        <TableHead style={{ background: Colors.GREEN, color: Colors.WHITE }}>
          <TableRow>
            {tableHead.map((item, index) => {
              return (
                <TableCell
                  align="right"
                  style={{ color: Colors.WHITE, fontWeight: 700 }}
                  key={index}
                >
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((item) => (
            <TableRow key={item.code}>
              <TableCell align="right">{item.quantity}</TableCell>
              <TableCell align="right">{item.code}</TableCell>
              <TableCell align="right">{item.description}</TableCell>
              <TableCell align="right">
                {getCurrencySymbol(item.currency)}
                {parseFloat(`${item.unit_price}`).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {getCurrencySymbol(item.currency)}
                {item.discount}
              </TableCell>
              <TableCell align="right">
                {getCurrencySymbol(item.currency)}
                {item.price}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableProducts;
