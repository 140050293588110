import React from "react";
import DashboardReports from "../components/dashboardReports";
import Header from "../components/header";
import LayoutFilter from "../components/layoutFilter";
import LoadingReport from "../components/skeleton/loadingReport";

const Reports = () => {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <Header />
      <LayoutFilter
        type="reports"
        title="Reporte de ventas"
        componentLoading={LoadingReport}
      >
        <DashboardReports />
      </LayoutFilter>
    </div>
  );
};

export default Reports;
