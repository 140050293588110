import { Configuration, ConfigActionTypes } from "../types/config";

const reportState = {
  typeDate: "emitted",
  reportStartDate: null,
  reportEndDate: null,
  typeDoc: null,
  stateDoc: null,
  reportFilterSerie: null,
  reportFilterInitSeq: null,
  reportFilterEndSeq: null,
  emittedClientReport: null,
  emittedClientReportName: null,
  reportTotal: 0,
  /////////////////////////////////
  reportsDateType: "emitted",
  reportsStartDate: null,
  reportsEndDate: null,
  reportsDocumentType: null,
  reportsDocumentStatus: null,
  reportsSerie: null,
  reportsSequentialStart: null,
  reportsSequentialEnd: null,
  reportsTotal: null,
  reportsClientId: 0,
  reportsClientName: null,
};

const receptionState = {
  receptiondateType: "uploaded",
  // receptionStartDate: null,
  // receptionEndDate: null,
  receptiondocType: null,
  receptiondocState: null,
  // receptionRowsPerPage: 10,
  // receptionPageTotal: 0,
  // receptionPageCurrent: 0,
  receptionFilterSerie: null,
  receptionFilterInitSeq: null,
  receptionFilterEndSeq: null,
  receptionProveedorId: null,
  receptionProveedorName: null,
  ///////////////////////////
  receptionDateType: "uploaded",
  receptionStartDate: null,
  receptionEndDate: null,
  receptionDocumentType: null,
  receptionDocumentStatus: null,
  receptionRowsPerPage: 10,
  receptionPageTotal: 0,
  receptionPageCurrent: 0,
  receptionSerie: null,
  receptionSequentialStart: null,
  receptionSequentialEnd: null,
  receptionClientId: null,
  receptionClientName: null,
};

const initialState: Configuration = {
  token: null,
  loading: null,
  session_id: "",
  workspace: null,
  dateType: "uploaded",
  emissionStartDate: null,
  emissionEndDate: null,
  docType: null,
  docState: null,
  // emittedPageTotal: 0,
  // emittedRowsPerPage: 10,
  // emittedPageCurrent: 0,
  emittedFilterSerie: null,
  emittedFilterInitSeq: null,
  emittedFilterEndSeq: null,
  // emittedClientId: null,
  // emittedClientName: null,
  ///////////////////////////
  emittedDateType: "uploaded",
  emittedStartDate: null,
  emittedEndDate: null,
  emittedDocumentType: null,
  emittedDocumentStatus: null,
  emittedRowsPerPage: 10,
  emittedPageTotal: 0,
  emittedPageCurrent: 0,
  emittedSerie: null,
  emittedSequentialStart: null,
  emittedSequentialEnd: null,
  emittedClientId: null,
  emittedClientName: null,
  ...reportState,
  ...receptionState,
};

const configReducer = (state = initialState, action: ConfigActionTypes) => {
  switch (action.type) {
    case "SET_CONFIG_PARAM":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default configReducer;
