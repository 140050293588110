export interface CompanyInfo {
  ruc: number;
  legal_name: string;
  trade_name: string;
  ubigeo: string;
  province: string;
  department: string;
  district: string;
  address_line: string;
  detraction_number: string;
  status: string;
  ose: boolean;
  user_sol: string;
  phone_number: string;
  customize:any;
  credentials_openpay: any;
  credentials_infobip: any;
  client_id_sunat: string;
  client_secret_sunat: string;
  mtc_registration_number: string;
  senders: any;
  company_setting: any;
}

export const SET_COMPANY_INFO_LIST = "SET_COMPANY_INFO_LIST";
export const RESET_COMPANIES = "RESET_COMPANIES";

interface setCompanyInfoListAction {
  type: typeof SET_COMPANY_INFO_LIST;
  payload: CompanyInfo[];
}

interface setResetCompanies {
  type: typeof RESET_COMPANIES;
  payload: {};
}

export type CompanyInfoActionTypes = setCompanyInfoListAction | setResetCompanies;
