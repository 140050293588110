import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Input,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  FormControlLabel,
  TextField,
  Paper,
  Card,
  CardContent,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  DeleteTwoTone as DeleteIcon,
  Send,
  ExpandMore,
  ExpandLess,
  CommentOutlined as NoteIcon,
  Done as DoneIcon,
  LocationOn,
} from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  RButton,
  RButtonSecondary,
  RTextField,
  RFormControl,
  RInput,
  RRadio,
  RSwitch,
} from "../ratifikaElements";
import MultiSelect from "react-select/creatable";
import { NoteBox, NoteType } from "../noteBox";
import { ConfirmDialog } from "../confirmDialog";
import axios from "axios";
import { useSelector } from "react-redux";
import { CompanyInfo } from "../../store/types/companies";
import { fetch } from "../../store/fetch";
import {
  getCurrencySymbol,
  numberWithCommas,
  NumeroALetras,
  parseDecimalNumber,
  isValidDNI,
  incrementSequential,
  measureList,
  Measure,
  validateEmail,
  FormasPago,
  getCurrentDate,
  getAffectation,
} from "../../static/util";
import { Colors } from "../../static/colors";
import Autosuggest from "react-autosuggest";
import moment from "moment";
import { PaymentDetails } from "../common/paymentDetails";
//import DatePicker from "react-date-picker";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { direcciones, ubigeos } from "../../static/ubigeo";

interface Props {
  isOpen: boolean;
  close: () => void;
}

interface Contributor {
  addresses: Array<object>;
  number: string;
  legal_name: string;
  number_type: string;
  email_address: string;
  is_new?: boolean;
}
/* interface Addresses {
  address_line: string | null;
  department: string | null;
  district: string | null;
  province: string | null;
  ubigeo: string | null;
} */
interface Product {
  code: string;
  measure: string;
  description: string;
  unit_price: number;
  unit_value: number;
  other_taxes?: any;
  is_new?: boolean;
}

const igvPercent = 0.18;
const baseSinIgv: number = 1.18;
interface ProductItem extends Product {
  count: number;
  price: number;
  value: number;
  measureSelected: Measure;
  taxType: string;
}

interface ErrorReport {
  title: string;
  message: string;
  details?: Array<string>;
}

interface MultiSelectItem {
  label: string;
  value: string;
}
interface Ubicacion {
  code: string;
  departamento: string;
  provincia: string;
  distrito: string;
}

export const NewSaleReceiptModal: React.FC<Props> = ({ isOpen, close }) => {
  // Buyer
  const [buyerKey, setBuyerKey] = useState<string>("");
  const [availableCustomers, setAvailableCustomers] = useState<
    Array<Contributor>
  >([]);
  const [autocompleteCustomers, setAutocompleteCustomers] = useState<
    Array<Contributor>
  >([]);

  const [freezeBuyerInfo, setFreezeBuyerInfo] = useState<boolean>(false); // usado para bloquear la info del comprador en caso que seleccione del autocompletar
  const [showBuyerInfo, setShowBuyerInfo] = useState<boolean>(false);

  const [focusComponent, setFocusComponent] = useState<string>("");

  const [clientName, setClientName] = React.useState<string>("");
  const [errorMsgClientName, setErrorMsgClientName] = React.useState<
    string | null
  >(null);

  const [identification, setIdentification] = React.useState<string>("");
  const [errorMsgIdentification, setErrorMsgIdentification] = React.useState<
    string | null
  >(null);
  const [docType, setDocType] = React.useState<string>("1");

  const [availableAddresses, setAvailableAddresses] = useState<
    Array<MultiSelectItem>
  >([]);
  const [selectedAddress, setSelectedAddress] =
    useState<MultiSelectItem | null>(null);
  const [errorMsgAddressLine, setErrorMsgAddressLine] = React.useState<
    string | null
  >(null);

  const [availableEmails, setAvailableEmails] = useState<
    Array<MultiSelectItem>
  >([]);
  const [selectedEmails, setSelectedEmails] = useState<Array<MultiSelectItem>>(
    []
  );

  // Order details
  const [orderNumber, setOrderNumber] = React.useState<string>("");
  const [errorMsgOrderNumber, setErrorMsgOrderNumber] = React.useState<
    string | null
  >(null);

  // Products
  const [productKey, setProductKey] = useState<string>("");
  const [availableProducts, setAvailableProducts] = useState<Array<Product>>(
    []
  );
  const [autocompleteProducts, setAutocompleteProducts] = useState<
    Array<Product>
  >([]);
  const [selectedItems, setSelectedItems] = useState<Array<ProductItem>>([]);
  const [notes, setNotes] = useState<Array<string>>([]);

  // Totals
  const [currency, setCurrency] = useState<string>("PEN");
  const [subtotal, setSubtotal] = useState<number>(0);
  const [operationsIGV, setOperationsIGV] = useState<number>(0);
  const [operationsINA, setOperationsINA] = useState<number>(0);
  const [operationsEXO, setOperationsEXO] = useState<number>(0);
  const [operationGRA, setOperationGRA] = useState<number>(0);
  const [totalIGV, setTotalIGV] = useState<number>(0);
  const [totalToPay, setTotalToPay] = useState<number>(0);

  // Response
  const [responseError, setResponseError] = useState<ErrorReport | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [openAditional, setOpenAditional] = useState<boolean>(false);
  const [openOrderInfo, setOpenOrderInfo] = useState<boolean>(false);

  const [paymentType, setPaymentType] = useState<any>({});
  const [minDate, setMinDate] = useState<any>();
  const [maxDate, setMaxDate] = useState<any>();
  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const emitter: CompanyInfo = companies.find(
    (c: CompanyInfo) => c.ruc === config.workspace
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  /*Adress */
  const [direccionUbigeo, setDireccionUbigeo] = useState<string | undefined>(
    ""
  );
  const [selectUbigeo, setSelectUbigeo] = useState<Ubicacion | null>(null);

  /*Consulta de datos Publicos */
  const [tipoCambio, setTipoCambio] = useState<number>(0);

  const [masIgv, setMasIgv] = React.useState(true);

  const [redondeoTotal, setRedondeoTotal] = useState<number>(0);

  useEffect(() => {
    async function fetchCostumers() {
      const { data } = await axios.get<Contributor[]>(
        "/api/contributor/customersWithoutTaxID"
      );
      setAvailableCustomers(data);
    }
    async function fetchProducts() {
      // TODO: guardar los productos en Redux
      const { data } = await axios.get<Product[]>("/api/product/list");
      setAvailableProducts(data);
    }

    async function fetchTipoCambio() {
      const { data } = await axios.get(
        `/api/tipoCambio/day?fecha=${getCurrentDate()}`
      );
      setTipoCambio(data.venta);
    }

    fetchCostumers();
    fetchProducts();
    startDate();
    finalyDate();
    fetchTipoCambio();
  }, []);

  /* Buyer info */

  const getCustomersSuggestions = async (
    value: string
  ): Promise<Contributor[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results = [];

    if (Number.isInteger(Number(inputValue))) {
      // posiblemente sea un Numero de Documento
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter(
                (contrib) => contrib.number.slice(0, inputLength) === inputValue
              )
              .sort((a, b) => {
                return (
                  a.number.indexOf(inputValue) - b.number.indexOf(inputValue)
                );
              });
      if (results.length === 0) {
        if (inputValue.length === 8) {
          await axios
            .get(`/api/info/by/dni/${Number(inputValue)}`)
            .then((e) => {
              const data = e.data;
              results = [
                {
                  legal_name: data.nombre,
                  number: inputValue,
                  number_type: data.tipoDocumento,
                  addresses: [
                    {
                      ubigeo: data.ubigeo || null,
                      address_line: data.direccion || null,
                      district: data.distrito || null,
                      province: data.provincia || null,
                      department: data.departamento || null,
                      default: true,
                    },
                  ],
                  email_address: "",
                  is_new: true,
                },
              ];
            });
        } else {
          results = [
            {
              legal_name: "",
              number: inputValue,
              number_type: "",
              addresses: [],
              email_address: "",
              is_new: true,
            },
          ];
        }
      }
    } else {
      // Posiblemente sea un nombre
      results =
        inputLength === 0
          ? []
          : availableCustomers
              .filter((contrib) =>
                contrib.legal_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
              .sort((a, b) => {
                return (
                  a.legal_name.indexOf(inputValue) -
                  b.legal_name.indexOf(inputValue)
                );
              });

      if (results.length === 0) {
        results = [
          {
            legal_name: inputValue,
            number: "",
            addresses: [],
            email_address: "",
            number_type: "",
            is_new: true,
          },
        ];
      }
    }

    return results;
  };

  const getSuggestionValue = (suggestion: Contributor) => {
    if (suggestion.is_new) {
      setClientName(suggestion.legal_name || "");
      setIdentification(suggestion.number || "");
      setDocType("1");
      setSelectedAddress(null);
      setAvailableEmails([]);
      setFreezeBuyerInfo(false);
      setShowBuyerInfo(true);
      setFocusComponent(suggestion.legal_name ? "legal_name" : "number");
    } else {
      // Ya existe data del cliente
      setClientName(suggestion.legal_name);
      setIdentification(suggestion.number);
      setDocType(suggestion.number_type);
      setAvailableAddresses(
        suggestion.addresses
          ? suggestion.addresses.map((item: any) => {
              return {
                label: item["address_line"],
                value: item["address_line"],
              };
            })
          : []
      );
      const defaultAddress = suggestion.addresses
        ? (suggestion.addresses.find(
            (item: any) => item.default === true
          ) as any)
        : null;
      setSelectedAddress(
        defaultAddress
          ? {
              label: defaultAddress.address_line,
              value: defaultAddress.address_line,
            }
          : null
      );
      if (
        defaultAddress !== null &&
        defaultAddress.ubigeo &&
        ubigeos.find((u) => u.number === defaultAddress.ubigeo)
      ) {
        const codeUbigeo = direcciones.find(
          (e) => e.code === defaultAddress.ubigeo
        );
        if (codeUbigeo) {
          setSelectUbigeo(codeUbigeo);
          setDireccionUbigeo(codeUbigeo.code);
        }
      }

      setAvailableEmails(
        suggestion.email_address
          ? suggestion.email_address.split(";").map((email) => {
              return { label: email, value: email };
            })
          : []
      );
      setFreezeBuyerInfo(true);
      setShowBuyerInfo(true);
      if (suggestion.addresses === null || suggestion.addresses.length === 0) {
        setFocusComponent("address_line");
      } else {
        setFocusComponent("email_list");
      }
    }

    if (errorMsgClientName) {
      setErrorMsgClientName(null);
    }
    if (errorMsgIdentification) {
      setErrorMsgIdentification(null);
    }
    if (errorMsgAddressLine) {
      setErrorMsgAddressLine(null);
    }

    return suggestion.legal_name || suggestion.number;
  };

  const renderSuggestion = (suggestion: Contributor, { query }: any) => {
    if (suggestion.is_new) {
      return (
        <span>
          Agregar nuevo comprador "
          <strong>{suggestion.legal_name || suggestion.number}</strong>"
        </span>
      );
    } else {
      const handleNumber = Number.isInteger(Number(query));
      const suggestionText = handleNumber
        ? suggestion.number
        : suggestion.legal_name;

      const indexOfSuggestion = suggestionText
        .toLowerCase()
        .indexOf(query.toLowerCase());
      const substring = suggestionText.substring(
        indexOfSuggestion,
        indexOfSuggestion + query.length
      );
      const highlight = suggestionText.replace(
        substring,
        `<strong ${
          handleNumber ? `style="font-size: 12px"` : ""
        }>${substring}</strong>`
      );
      return (
        <div>
          {!handleNumber ? (
            <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
          ) : (
            <span>{suggestion.legal_name}</span>
          )}
          <br></br>
          {handleNumber ? (
            <span
              style={{ fontSize: 12 }}
              dangerouslySetInnerHTML={{ __html: highlight }}
            ></span>
          ) : (
            <strong style={{ fontSize: 12 }}>{suggestion.number}</strong>
          )}
        </div>
      );
    }
  };

  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteCustomers(await getCustomersSuggestions(param.value));
  };

  const onSuggestionsClearRequested = () => {
    setAutocompleteCustomers([]);
  };

  const handleMultiSelectChange = (emailList: any, action: any) => {
    if (action.action === "create-option") {
      if (!validateEmail(action.option.value)) {
        return;
      }
    }
    setSelectedEmails(emailList);
  };

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMasIgv(event.target.checked);
    const includeIGV = event.target.checked;
    updateTotals([...selectedItems], includeIGV);
  };
  /* Products */

  const updateTotals = (items: Array<ProductItem>, includeIGV: boolean) => {
    if (!includeIGV) {
      const calSubtotal = Number(
        items
          .filter((i) => i.taxType !== "Gratuito")
          .reduce((n, { price }) => n + price, 0)
          .toFixed(2)
      );
      setSubtotal(calSubtotal);
      const totalOpeIGV = Number(
        items
          .filter((i) => i.taxType === "IGV")
          .reduce((n, { price }) => n + price, 0)
          .toFixed(2)
      );
      const totalOpeINA = Number(
        items
          .filter((i) => i.taxType === "INA")
          .reduce((n, { price }) => n + price, 0)
          .toFixed(2)
      );
      const totalOpeEXO = Number(
        items
          .filter((i) => i.taxType === "EXO")
          .reduce((n, { price }) => n + price, 0)
          .toFixed(2)
      );

      const totalOpeGRA = Number(
        items
          .filter((i) => i.taxType === "Gratuito")
          .reduce((n, { price }) => n + price, 0)
          .toFixed(2)
      );

      const calTotalIGV = Number((totalOpeIGV * igvPercent).toFixed(2));
      setOperationsIGV(totalOpeIGV);
      setOperationsINA(totalOpeINA);
      setOperationsEXO(totalOpeEXO);
      setOperationGRA(totalOpeGRA);
      setTotalIGV(calTotalIGV);
      setTotalToPay(Number(Number(calSubtotal + calTotalIGV).toFixed(2)));
      setRedondeoTotal(0.0);
    } else if (includeIGV) {
      /*Switch Activo Incluido IGV */
      const calTotal = Number(
        items
          .filter((i) => i.taxType !== "Gratuito")
          .reduce((n, { value }) => n + value, 0)
          .toFixed(2)
      );

      setTotalToPay(Number(calTotal.toFixed(1)));
      const ajuste = Number(Number(calTotal.toFixed(1)) - calTotal).toFixed(2);

      setRedondeoTotal(Number(ajuste));

      setSubtotal(Number(Number(calTotal / baseSinIgv).toFixed(2)));

      const totalOpeIGV =
        Number(
          items
            .filter((i) => i.taxType === "IGV")
            .reduce((n, { value }) => n + value, 0)
            .toFixed(2)
        ) / baseSinIgv;
      const totalOpeINA = Number(
        items
          .filter((i) => i.taxType === "INA")
          .reduce((n, { value }) => n + value, 0)
          .toFixed(2)
      );
      const totalOpeEXO = Number(
        items
          .filter((i) => i.taxType === "EXO")
          .reduce((n, { value }) => n + value, 0)
          .toFixed(2)
      );
      const totalOpeGRA = Number(
        items
          .filter((i) => i.taxType === "Gratuito")
          .reduce((n, { value }) => n + value, 0)
          .toFixed(2)
      );

      const calTotalIGV = Number((totalOpeIGV * igvPercent).toFixed(2));
      setOperationsIGV(totalOpeIGV);
      setOperationsINA(totalOpeINA);
      setOperationsEXO(totalOpeEXO);
      setOperationGRA(totalOpeGRA);

      setTotalIGV(calTotalIGV);
    }
  };

  const getSuggestionProductValue = (suggestion: Product) => {
    const measureSelected =
      measureList.find((m) => m.code === suggestion.measure) || measureList[0];
    /*  const taxO=JSON.parse(suggestion.other_taxes)
       console.log(taxO[0].tax ); */

    let newItem: ProductItem = {
      code: suggestion.code,
      measureSelected,
      measure: suggestion.measure, // original
      description: suggestion.description,
      count: 1.0,
      taxType: "IGV",
      unit_price:
        suggestion.unit_price !== null
          ? Number(suggestion.unit_price.toString())
          : 0.0,
      price:
        suggestion.unit_price !== null
          ? Number(Number(suggestion.unit_price).toFixed(2))
          : 0.0,
      unit_value:
        suggestion.unit_value !== null
          ? Number(suggestion.unit_value.toString())
          : 0.0,
      value:
        suggestion.unit_value !== null
          ? Number(Number(suggestion.unit_value).toFixed(2))
          : 0.0,
    };

    const updatedItems = [...selectedItems, newItem];

    setSelectedItems(updatedItems);
    updateTotals(updatedItems, masIgv);
    return "";
  };

  const renderProductSuggestion = (suggestion: Product, { query }: any) => {
    if (suggestion.is_new) {
      return (
        <span>
          Agregar nuevo producto "<strong>{suggestion.description}</strong>"
        </span>
      );
    } else {
      const indexOfSuggestion = suggestion.description
        .toLowerCase()
        .indexOf(query.toLowerCase());
      const substring = suggestion.description.substring(
        indexOfSuggestion,
        indexOfSuggestion + query.length
      );
      const highlight = suggestion.description.replace(
        substring,
        `<strong>${substring}</strong>`
      );
      return (
        <div>
          <span dangerouslySetInnerHTML={{ __html: highlight }}></span>
        </div>
      );
    }
  };

  const onSuggestionsFetchProductsRequested = async (param: any) => {
    setAutocompleteProducts(await getProductsSuggestions(param.value));
  };

  const onSuggestionsClearProductRequested = () => {
    setAutocompleteProducts([]);
  };

  const getProductsSuggestions = async (value: string): Promise<Product[]> => {
    const inputValue = value.trim();
    let results = availableProducts.filter(
      (product) =>
        product.description.toLowerCase().includes(inputValue.toLowerCase()) ||
        product.code === inputValue
    );
    if (results.length === 0) {
      results = [
        {
          code: "",
          measure: measureList[0].code,
          description: inputValue,
          unit_price: 0,
          unit_value: 0,
          is_new: true,
        },
      ];
    }
    return results.sort((a, b) => {
      return (
        a.description.indexOf(inputValue) - b.description.indexOf(inputValue)
      );
    });
  };

  const updateItemCountChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) < 0) return;
      let newArr = [...selectedItems];
      newArr[index].count = Number(e.target.value);
      newArr[index].price = Number(
        (newArr[index].unit_price * Number(e.target.value)).toFixed(2)
      );
      newArr[index].value = Number(
        (newArr[index].unit_value * Number(e.target.value)).toFixed(2)
      );
      setSelectedItems(newArr);
      updateTotals(newArr, masIgv);
    };

  const updateItemUnitPriceChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) < 0) return;
      if (masIgv) {
        let newArr = [...selectedItems];
        newArr[index].unit_value = Number(e.target.value);
        newArr[index].value = Number(
          (newArr[index].count * Number(e.target.value)).toFixed(2)
        );
        setSelectedItems(newArr);
        updateTotals(newArr, masIgv);
      } else {
        let newArr = [...selectedItems];
        newArr[index].unit_price = Number(e.target.value);
        newArr[index].price = Number(
          (newArr[index].count * Number(e.target.value)).toFixed(2)
        );
        setSelectedItems(newArr);
        updateTotals(newArr, masIgv);
      }
    };

  const updateItemCodeChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].code = e.target.value;
      setSelectedItems(newArr);
    };

  const updateItemDescriptionChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...selectedItems];
      newArr[index].description = e.target.value;
      setSelectedItems(newArr);
    };

  const updateItemTaxChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...selectedItems];
    newArr[index].taxType = e.target.value as string;
    setSelectedItems(newArr);
    updateTotals(newArr, masIgv);
  };

  const updateItemUnitChanged = (
    index: any,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    let newArr = [...selectedItems];
    newArr[index].measureSelected =
      measureList.find((m) => m.code === e.target.value) || measureList[0];
    setSelectedItems(newArr);
  };

  const deleteItemClick = (index: any) => {
    let newArr = [...selectedItems];
    newArr.splice(index, 1);
    setSelectedItems(newArr);
    updateTotals(newArr, masIgv);
  };

  /* End products */

  /* Notes */

  const deleteItemNoteClick = (index: any) => {
    let newArr = [...notes];
    newArr.splice(index, 1);
    setNotes(newArr);
  };

  const updateItemNoteChanged =
    (index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newArr = [...notes];
      newArr[index] = e.target.value;
      setNotes(newArr);
    };

  /* Procesamiento de la boleta */

  const generateJsonInvoice = (serie: string, sequential: string) => {
    let taxesList: any = [];
    if (operationsIGV > 0) {
      taxesList = [
        ...taxesList,
        {
          codigoTributo: "1000",
          monto: totalIGV.toFixed(2),
          base: operationsIGV.toFixed(2),
        },
      ];
    }
    if (operationsINA > 0) {
      taxesList = [
        ...taxesList,
        {
          codigoTributo: "9998",
          monto: "0.00",
          base: operationsINA.toFixed(2),
        },
      ];
    }
    if (operationsEXO > 0) {
      taxesList = [
        ...taxesList,
        {
          codigoTributo: "9997",
          monto: "0.00",
          base: operationsEXO.toFixed(2),
        },
      ];
    }

    if (operationGRA > 0) {
      taxesList = [
        ...taxesList,
        {
          codigoTributo: "9996",
          monto: "0.00",
          base: operationGRA.toFixed(2),
        },
      ];
    }

    let tipo_cambio = null;
    if (currency === "USD") {
      tipo_cambio = tipoCambio.toFixed(2);
    }

    return {
      ubl: "2.1",
      tipoComprobante: "03",
      serie: `B${serie}`,
      numeroComprobante: incrementSequential(sequential),
      actualizarSecuencial: true,
      fechaEmision: moment(selectedDate).format("YYYY-MM-DD"),
      horaEmision: moment().format("HH:mm:ss"),
      montoLetras: NumeroALetras(totalToPay),
      notas: notes
        .filter((n) => n.trim() !== "")
        .map((n) => {
          return { valor: n };
        }),
      moneda: currency,
      tipoCambio: tipo_cambio,
      emisor: {
        numeroDocumento: emitter.ruc,
        tipoDocumento: "6",
        nombre: emitter.legal_name,
        direccion: {
          codigo: "0000",
          ubigeo: emitter.ubigeo,
          provincia: emitter.province,
          departament: emitter.department,
          distrito: emitter.district,
          direccionCompleta: emitter.address_line,
        },
      },
      receptor: {
        numeroDocumento: showBuyerInfo ? identification : "11111111",
        tipoDocumento: showBuyerInfo ? docType : "1",
        nombre: showBuyerInfo ? clientName : "No especificado",
        correo: selectedEmails.map((email) => email.value),
        direccion: {
          ubigeo: direccionUbigeo,
          direccionCompleta: showBuyerInfo
            ? selectedAddress?.value
            : "No especificado",
          pais: "PE",
        },
      },
      totales: {
        totalVenta: totalToPay.toFixed(2),
        total: totalToPay.toFixed(2),
        subTotal: subtotal.toFixed(2),
        totalImpuestos: totalIGV.toFixed(2),
        redondeo: redondeoTotal.toFixed(2),
      },
      impuestos: taxesList,
      // numero de orden
      ordenCompra: orderNumber,
      // tipo de pago
      formaPago: paymentType,
      items: selectedItems.map((item, index) => {
        let itemTaxValue =
          item.taxType === "IGV"
            ? masIgv
              ? ((item.value / baseSinIgv) * igvPercent).toFixed(2)
              : (item.price * igvPercent).toFixed(2)
            : "0.00";

        let itemUnitTaxValue =
          item.taxType === "IGV"
            ? masIgv
              ? (item.unit_value * igvPercent).toFixed(2)
              : (item.unit_price * igvPercent).toFixed(2)
            : "0.00";

        let price =
          item.taxType === "Gratuito"
            ? item.value.toFixed(2)
            : masIgv
            ? Number(item.value / baseSinIgv).toFixed(2)
            : item.price.toFixed(2);
        let unitPrice =
          item.taxType === "Gratuito"
            ? "0.00"
            : masIgv
            ? Number(item.unit_value / baseSinIgv).toFixed(2)
            : parseDecimalNumber(item.unit_price.toString());

        /*    let valor = item.taxType === "Gratuito" ? item.price.toFixed(2) : masIgv ? item.price.toFixed(2) : Number(item.price / baseSinIgv).toFixed(2);*/

        let unitValue =
          item.taxType === "IGV" && item.unit_value === 0.0
            ? Number(item.unit_price + item.unit_price * igvPercent).toFixed(2)
            : parseDecimalNumber(item.unit_value.toString());

        let value =
          item.taxType === "IGV" && item.unit_value === 0.0
            ? Number(unitValue) * item.count
            : item.value.toFixed(2);

        return {
          numeroItem: (index + 1).toString(),
          descripcion: item.description,
          codigo: item.code || "",
          unidadMedida: item.measureSelected.code,
          cantidad: item.count.toString(),
          precio: price,
          valor: value,
          precioReferencia: masIgv
            ? item.unit_value.toFixed(2)
            : (Number(itemUnitTaxValue) + item.unit_price).toFixed(2),
          precioUnitario: unitPrice,
          valorUnitario: unitValue,
          totalImpuestos: itemTaxValue,
          impuestos: [
            {
              monto: itemTaxValue,
              base: price,
              porcentaje:
                item.taxType === "IGV" ? (igvPercent * 100).toFixed(2) : "0.00",
              tipoAfectacion: getAffectation(item.taxType),
            },
          ],
        };
      }),
    };
  };

  const isValidateInvoice = () => {
    let generalErros: Array<string> = [];
    let isReadyToSend = true;
    if (showBuyerInfo) {
      if (clientName.trim() === "") {
        setErrorMsgClientName("Especifique un nombre para el comprador");
        isReadyToSend = false;
      }
      if (identification.trim() === "") {
        setErrorMsgIdentification(
          "Especifique una identificación para el comprador"
        );
        isReadyToSend = false;
      } else if (docType === "1" && !isValidDNI(identification)) {
        setErrorMsgIdentification("Número DNI incorrecto");
        isReadyToSend = false;
      }
      if (!selectedAddress) {
        setErrorMsgAddressLine("Especifique dirección para el comprador");
        isReadyToSend = false;
      }
    }
    if (selectedItems.length === 0) {
      generalErros = [...generalErros, "Debes incluir algún item en la boleta"];
    } else {
      /* if (totalToPay === 0) {
        generalErros = [...generalErros, "La boleta de venta debe tener un valor mayor que cero"];
      } else */ if (
        selectedItems.filter((item) => item.price === 0).length > 0 &&
        selectedItems.filter((item) => item.value === 0).length > 0
      ) {
        generalErros = [
          ...generalErros,
          "La boleta de venta contiene items con valor en cero",
        ];
      }
      if (totalToPay * (currency === "PEN" ? 1 : 3.8) >= 700) {
        // Para boletas mayore que 700 SOLES o equibalente
        if (!showBuyerInfo) {
          generalErros = [
            ...generalErros,
            "Debe especificar un comprador para boletas mayores de 700 SOLES o el equivalente en otra moneda",
          ];
        } else if (docType === "0") {
          generalErros = [
            ...generalErros,
            "Debe usar otro tipo de identificación del comprador para boletas mayores de 700 SOLES o el equivalente en otra moneda",
          ];
        }
      }
    }

    if (!checkOrderNumber(orderNumber)) {
      generalErros = [
        ...generalErros,
        "El número de orden debe tener menos de 20 caracteres",
      ];
    }

    if (!checkPaymentType()) {
      generalErros = [
        ...generalErros,
        "Debe crear las cuotas necesarias para cubrir el monto pendiente",
      ];
    }

    if (generalErros.length > 0) {
      setResponseError({
        title: "Parece que faltan algunos detalles",
        message: "La boleta no se ha enviado aún, revisa qué está faltando:",
        details: generalErros,
      });
      isReadyToSend = false;
    } else if (responseError) {
      setResponseError(null);
    }

    return isReadyToSend;
  };

  const sendInvoiceClick = async () => {
    if (isValidateInvoice()) {
      setOpenConfirmDialog(true);
    }
  };

  const sendInvoice = async () => {
    setOpenConfirmDialog(false);
    setLoading(true);

    const responseSequential = await axios.get(
      `/api/company/lastSequential/${emitter.ruc}/03`
    );
    if (!responseSequential.data.result) {
      setResponseError({
        title: "Ha ocurrido un problema",
        message: responseSequential.data.msg,
      });
      return;
    }

    const json = generateJsonInvoice(
      responseSequential.data.serie,
      responseSequential.data.sequential
    );
    let { data } = await axios.post("/api/emission/send", json);
    setLoading(false);
    if (data.procesado) {
      close();
      setTimeout(() => fetch(["emission"]), 1000);
      // Otro dialog
    } else {
      setResponseError({
        title: "Ha ocurrido un problema con su boleta",
        message: data.mensaje,
      });
    }
  };

  const checkOrderNumber = (value: string) => {
    if (value.length > 20) {
      setErrorMsgOrderNumber(
        "El número de orden debe tener menos de 20 caracteres"
      );
      return false;
    } else {
      setErrorMsgOrderNumber(null);
      return true;
    }
  };

  const checkPaymentType = () => {
    if (paymentType.formaPago === FormasPago.contado.value) {
      return true;
    } else {
      let aux = JSON.parse(JSON.stringify(paymentType.cuotas));
      let r = paymentType.pendientePago;
      aux.forEach((cuote: any) => {
        r -= cuote.montoCuota;
      });
      return r === 0;
    }
  };

  ///Control fecha de emision
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const startDate = () => {
    setMinDate(moment(new Date()).subtract(1, "d"));
  };

  const finalyDate = () => {
    setMaxDate(moment(new Date()).add(2, "d"));
  };

  return (
    <>
      <Dialog
        open={isOpen}
        scroll={"body"}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>
          {"Nueva Boleta de Venta"}
          <span style={{ fontSize: 15, marginLeft: 15 }}>
            / emitida por: {emitter.legal_name}
          </span>
          {responseError && (
            <Grid item xs={12} md={8} sm={10}>
              <NoteBox
                type={NoteType.Error}
                title={responseError.title}
                body={responseError.message}
                details={responseError.details}
              />
            </Grid>
          )}
        </DialogTitle>
        <DialogContent
          dividers={false}
          style={{ paddingRight: 10, paddingLeft: 10, minHeight: 600 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={7}>
              <div className="invoice-info-container">
                <Typography
                  style={{ marginBottom: 20 }}
                  color="textSecondary"
                  variant="caption"
                >
                  Datos del comprador (Opcional)
                </Typography>
                <Autosuggest
                  suggestions={autocompleteCustomers}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={{
                    placeholder: "Identificación o nombre",
                    value: buyerKey,
                    onChange: (
                      event: React.FormEvent<any>,
                      { newValue, method }: Autosuggest.ChangeEvent
                    ) => {
                      setBuyerKey(newValue);
                      if (newValue === "") {
                        setClientName("");
                        setIdentification("");
                        setDocType("1");
                        setSelectedAddress(null);
                        setFreezeBuyerInfo(false);
                        setShowBuyerInfo(false);
                      }
                    },
                    ref: React.createRef<HTMLInputElement>(),
                  }}
                />
                {showBuyerInfo && (
                  <>
                    <RTextField
                      id="legal-name"
                      label="Nombre"
                      variant="outlined"
                      size="small"
                      required
                      autoFocus={focusComponent === "legal_name" ? true : false}
                      autoComplete="off"
                      disabled={freezeBuyerInfo}
                      style={{ marginTop: 20, marginBottom: 20 }}
                      helperText={errorMsgClientName}
                      error={errorMsgClientName !== null}
                      value={clientName}
                      onChange={(e) => {
                        setClientName(e.target.value);
                        if (
                          errorMsgClientName &&
                          e.target.value.trim() !== ""
                        ) {
                          setErrorMsgClientName(null);
                        }
                      }}
                    />
                    <Grid container spacing={2} style={{ marginBottom: 5 }}>
                      <Grid item>
                        <RTextField
                          id="id-number"
                          label="Identificación"
                          variant="outlined"
                          size="small"
                          required
                          autoFocus={focusComponent === "number" ? true : false}
                          autoComplete="off"
                          disabled={freezeBuyerInfo}
                          helperText={errorMsgIdentification}
                          error={errorMsgIdentification !== null}
                          value={identification}
                          onChange={(e) => {
                            setIdentification(e.target.value);
                            if (
                              errorMsgIdentification &&
                              e.target.value.trim() !== ""
                            ) {
                              setErrorMsgIdentification(null);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <RFormControl
                          disabled={freezeBuyerInfo}
                          variant="outlined"
                          style={{ marginRight: 10 }}
                        >
                          <InputLabel
                            id="province-select-label"
                            style={{ marginTop: -5 }}
                          >
                            Tipo de identificación
                          </InputLabel>
                          <Select
                            labelId="province-select-label"
                            value={docType}
                            onChange={(
                              event: React.ChangeEvent<{ value: any }>
                            ) => {
                              setDocType(event.target.value);
                              if (
                                event.target.value !== "1" &&
                                errorMsgIdentification !== ""
                              ) {
                                setErrorMsgIdentification(null);
                              }
                            }}
                            label="Tipo de identificación"
                            style={{ height: 40, minWidth: 200 }}
                          >
                            <MenuItem value="1">
                              <Tooltip title="Documento Nacional de Identidad">
                                <span>DNI</span>
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value="0">
                              <Tooltip title="Documento Tributuario no Domiciliado sin RUC">
                                <span>DOC.TRIB.NO.DOM.SIN.RUC</span>
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value="4">
                              <Tooltip title="Carné de Extranjería">
                                <span>Carné de Extranjería</span>
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value="7">
                              <Tooltip title="Pasaporte">
                                <span>Pasaporte</span>
                              </Tooltip>
                            </MenuItem>
                          </Select>
                        </RFormControl>
                      </Grid>
                    </Grid>
                    <Autocomplete
                      id="autocomplete-addresses"
                      freeSolo
                      size="small"
                      style={{ marginTop: 10 }}
                      value={selectedAddress}
                      onChange={(e, newAddress) => {
                        setSelectedAddress(
                          newAddress as MultiSelectItem | null
                        );
                        if (errorMsgAddressLine && newAddress) {
                          setErrorMsgAddressLine(null);
                        }
                      }}
                      options={availableAddresses}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <RTextField
                          {...params}
                          label="Dirección"
                          required
                          variant="outlined"
                          autoFocus={
                            focusComponent === "address_line" ? true : false
                          }
                          helperText={errorMsgAddressLine}
                          error={errorMsgAddressLine !== null}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          onChange={(e) => {
                            let newAddress: MultiSelectItem | null =
                              e.target.value.trim() !== ""
                                ? {
                                    label: e.target.value,
                                    value: e.target.value,
                                  }
                                : null;
                            setSelectedAddress(newAddress);
                            if (errorMsgAddressLine && newAddress) {
                              setErrorMsgAddressLine(null);
                            }
                          }}
                        />
                      )}
                    />

                    {/*  Direccion Buyer */}
                    <br />
                    <Autocomplete
                      id="combo-box-demo"
                      options={direcciones}
                      getOptionLabel={(option) =>
                        `${option.departamento.toUpperCase()}-${option.provincia.toUpperCase()}-${option.distrito.toUpperCase()}`
                      }
                      fullWidth={true}
                      popupIcon={<LocationOn />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ubigeo"
                          placeholder="Buscar..."
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          <Paper style={{ width: "100%" }}>
                            <div style={{ padding: "5px" }}>
                              {option.departamento.toUpperCase()}-
                              {option.provincia.toUpperCase()}-
                              {option.distrito.toUpperCase()}
                              <div>
                                <small style={{ color: "#777" }}>
                                  {option.code}
                                </small>
                              </div>
                            </div>
                          </Paper>
                        </React.Fragment>
                      )}
                      PaperComponent={({ children }) => (
                        <Card style={{ background: "#f3f3f3" }}>
                          <CardContent>{children}</CardContent>
                        </Card>
                      )}
                      size="small"
                      value={selectUbigeo}
                      onChange={(event, value) => {
                        setSelectUbigeo(value);
                        setDireccionUbigeo(value?.code);
                      }}
                    />

                    <Typography
                      style={{ marginTop: 10, marginBottom: 3 }}
                      color="textSecondary"
                      variant="caption"
                    >
                      Destinatarios
                    </Typography>
                    <MultiSelect
                      styles={{
                        menu: (provided: any, state: any) => ({
                          ...provided,
                          width: state.selectProps.width,
                          marginTop: 1,
                        }),
                      }}
                      placeholder="Agrega multiples destinatarios de email"
                      autoFocus={focusComponent === "email_list" ? true : false}
                      isMulti
                      options={availableEmails}
                      defaultValue={selectedEmails}
                      value={selectedEmails}
                      formatCreateLabel={(option) => (
                        <div
                          style={{
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          Agregar nuevo email: <strong>{option}</strong>{" "}
                          {validateEmail(option) && (
                            <DoneIcon
                              style={{ color: Colors.successful, fontSize: 14 }}
                            />
                          )}
                        </div>
                      )}
                      noOptionsMessage={(option) =>
                        "Agrega multiples destinatarios de email"
                      }
                      classNamePrefix="select"
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnSelect={true}
                      onChange={handleMultiSelectChange}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "white",
                          primary: Colors.secundary,
                        },
                      })}
                    />
                    {availableEmails.length > 0 && (
                      <Typography
                        style={{
                          marginTop: 5,
                          fontStyle: "italic",
                          fontSize: 10,
                        }}
                        color="textSecondary"
                        variant="caption"
                      >
                        Para <strong>{clientName}</strong>, se han enviado
                        copias previas a:{" "}
                        {availableEmails.map((e) => e.value).join(", ")}
                      </Typography>
                    )}
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={1} md={2} sm={1} />
            <Grid item xs={12} md={4} sm={4} style={{ paddingRight: 30 }}>
              <Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-emss"
                    label="Fecha de Emisión "
                    value={selectedDate}
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Typography color="textSecondary" variant="caption">
                Moneda
              </Typography>
              <Grid container>
                <Grid item xs={8}>
                  <RadioGroup
                    row
                    value={currency}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCurrency((event.target as HTMLInputElement).value);
                    }}
                    style={{ padding: 10 }}
                  >
                    <FormControlLabel
                      value="PEN"
                      control={<RRadio color="primary" />}
                      label="SOLES"
                    />
                    <FormControlLabel
                      value="USD"
                      control={<RRadio color="primary" />}
                      label="DÓLARES"
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={4}>
                  {currency === "USD" ? (
                    <TextField
                      style={{ width: 120 }}
                      disabled
                      id="filled-disabled"
                      label="Tipo de cambio"
                      value={tipoCambio}
                      variant="outlined"
                      size="small"
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

              <Typography color="textSecondary" variant="caption">
                Totales
              </Typography>
              <table style={{ marginTop: 20 }} className="table table-clear">
                <tbody>
                  {(operationsINA > 0 || operationsEXO > 0) && (
                    <tr>
                      <td className="left">
                        <strong>Total Gravado</strong>
                      </td>
                      <td className="right">{`${getCurrencySymbol(
                        currency
                      )} ${numberWithCommas(operationsIGV)}`}</td>
                    </tr>
                  )}
                  {operationsINA > 0 && (
                    <tr>
                      <td className="left">
                        <strong>Total Inafecto</strong>
                      </td>
                      <td className="right">{`${getCurrencySymbol(
                        currency
                      )} ${numberWithCommas(operationsINA)}`}</td>
                    </tr>
                  )}
                  {operationsEXO > 0 && (
                    <tr>
                      <td className="left">
                        <strong>Total Exonerado</strong>
                      </td>
                      <td className="right">{`${getCurrencySymbol(
                        currency
                      )} ${numberWithCommas(operationsEXO)}`}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="left">
                      <strong>Subtotal</strong>
                    </td>
                    <td className="right">{`${getCurrencySymbol(
                      currency
                    )} ${numberWithCommas(subtotal)}`}</td>
                  </tr>
                  <tr>
                    <td className="left">
                      <strong>IGV</strong>
                    </td>
                    <td className="right">{`${getCurrencySymbol(
                      currency
                    )} ${numberWithCommas(totalIGV)}`}</td>
                  </tr>
                  {/* Redondeo */}
                  {masIgv ? (
                    <tr>
                      <td className="left">
                        <strong>Redondeo</strong>
                      </td>
                      <td className="right">{`${getCurrencySymbol(
                        currency
                      )} ${numberWithCommas(redondeoTotal)}`}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {/* <tr>
                  <td className="left">
                    <strong>ISC</strong>
                  </td>
                  <td className="right">{`${getCurrencySymbol(
                    currency
                  )} ${numberWithCommas(0)}`}</td>
                </tr>
                <tr>
                  <td className="left">
                    <strong>Otros cargos</strong>
                  </td>
                  <td className="right">{`${getCurrencySymbol(
                    currency
                  )} ${numberWithCommas(0)}`}</td>
                </tr>

                <tr>
                  <td className="left">
                    <strong>Descuentos</strong>
                  </td>
                  <td className="right">{`- ${getCurrencySymbol(
                    currency
                  )} ${numberWithCommas(0)}`}</td>
                </tr>
                <tr>
                  <td className="left">
                    <strong>Anticipo</strong>
                  </td>
                  <td className="right">{`${getCurrencySymbol(
                    currency
                  )} ${numberWithCommas(0)}`}</td>
                </tr>*/}
                  <tr>
                    <td className="left">
                      <strong>Total</strong>
                    </td>
                    <td className="right">
                      <strong>{`${getCurrencySymbol(
                        currency
                      )} ${numberWithCommas(totalToPay)}`}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Datos adicionales */}
              <div className="order-info-container">
                <Accordion
                  className="aditional-info-container"
                  expanded={openOrderInfo}
                  onChange={() => setOpenOrderInfo(!openOrderInfo)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    {!openOrderInfo ? (
                      <ExpandMore fontSize="small" color="action" />
                    ) : (
                      <ExpandLess fontSize="small" color="action" />
                    )}
                    <Typography color="textSecondary" variant="caption">
                      Detalles del comprobante
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ width: "100%" }}>
                      <RTextField
                        id="orden-compra-re"
                        label="Orden de Compra"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        style={{ marginBottom: 10, width: "100%" }}
                        helperText={errorMsgOrderNumber}
                        error={errorMsgOrderNumber !== null}
                        value={orderNumber}
                        onChange={(e) => {
                          setOrderNumber(e.target.value);
                          checkOrderNumber(e.target.value);
                        }}
                      />
                      <PaymentDetails
                        currency={getCurrencySymbol(currency)}
                        total={totalToPay}
                        cuotesChange={(paymentType: any) => {
                          setPaymentType(paymentType);
                        }}
                      ></PaymentDetails>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            {/* Items */}
            <Grid item xs={12} md={12} sm={12}>
              <div className="invoice-info-container">
                <Typography
                  style={{ marginBottom: 20 }}
                  color="textSecondary"
                  variant="caption"
                >
                  Items
                </Typography>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sm={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Autosuggest
                    suggestions={autocompleteProducts}
                    onSuggestionsFetchRequested={
                      onSuggestionsFetchProductsRequested
                    }
                    onSuggestionsClearRequested={
                      onSuggestionsClearProductRequested
                    }
                    getSuggestionValue={getSuggestionProductValue}
                    renderSuggestion={renderProductSuggestion}
                    inputProps={{
                      placeholder:
                        "Descripción del producto o código del prod.",
                      value: productKey,
                      onChange: (
                        event: React.FormEvent<any>,
                        { newValue, method }: Autosuggest.ChangeEvent
                      ) => setProductKey(newValue),
                      ref: React.createRef<HTMLInputElement>(),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={5}
                  sm={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <FormControlLabel
                    control={
                      <RSwitch
                        checked={masIgv}
                        onChange={onSwitchChange}
                        name="sinIGV"
                      />
                    }
                    label={
                      <Typography style={{ fontWeight: "bold" }}>
                        Mis precios incluyen IGV
                      </Typography>
                    }
                  />
                </Grid>
                {selectedItems.length > 0 && (
                  <Table
                    size="small"
                    aria-label="purchases"
                    style={{ marginTop: 30, marginBottom: 20 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Cantidad
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Código
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Descripción
                        </TableCell>
                        {masIgv ? (
                          <TableCell style={{ fontWeight: "bold" }}>
                            Valor unitario
                          </TableCell>
                        ) : (
                          <TableCell style={{ fontWeight: "bold" }}>
                            Precio unitario
                          </TableCell>
                        )}

                        <TableCell style={{ fontWeight: "bold" }}>
                          Afectación
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="right">
                          {masIgv ? "Valor" : "Precio"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedItems.map((product, index) => (
                        <TableRow
                          key={index}
                          style={{
                            borderColor: "transparent",
                            borderStyle: "solid",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ minWidth: 120, maxWidth: 80 }}
                          >
                            <div style={{ display: "flex" }}>
                              <Tooltip title="Remover Item" arrow>
                                <IconButton
                                  style={{ marginRight: 10, width: 34 }}
                                  size="small"
                                  onClick={() => deleteItemClick(index)}
                                >
                                  <DeleteIcon fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                              <Input
                                autoComplete="off"
                                value={product.count}
                                onChange={updateItemCountChanged(index)}
                                type="number"
                                style={{ marginRight: 10, minWidth: 30 }}
                              />
                              <Select
                                value={product.measureSelected.code}
                                onChange={(e) =>
                                  updateItemUnitChanged(index, e)
                                }
                              >
                                {measureList.map((m) => (
                                  <MenuItem key={m.code} value={m.code}>
                                    <Tooltip title={m.description}>
                                      <span>{m.display}</span>
                                    </Tooltip>
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </TableCell>
                          <TableCell style={{ minWidth: 50, maxWidth: 30 }}>
                            <Input
                              style={{ width: "100%" }}
                              autoComplete="off"
                              value={product.code}
                              onChange={updateItemCodeChanged(index)}
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: 130, maxWidth: 500 }}>
                            <RInput
                              style={{ width: "100%" }}
                              autoComplete="off"
                              error={product.description === ""}
                              value={product.description}
                              onChange={updateItemDescriptionChanged(index)}
                            />
                          </TableCell>
                          {masIgv ? (
                            <TableCell
                              align="right"
                              style={{ minWidth: 40, maxWidth: 20 }}
                            >
                              <Input
                                style={{ width: "100%" }}
                                autoComplete="off"
                                onChange={updateItemUnitPriceChanged(index)}
                                value={product.unit_value}
                                type="number"
                              />
                            </TableCell>
                          ) : (
                            <TableCell>
                              <Input
                                style={{ width: "100%" }}
                                autoComplete="off"
                                onChange={updateItemUnitPriceChanged(index)}
                                value={product.unit_price}
                                type="number"
                              />
                            </TableCell>
                          )}

                          <TableCell style={{ minWidth: 40, maxWidth: 40 }}>
                            <Select
                              value={product.taxType}
                              onChange={(e) => updateItemTaxChanged(index, e)}
                              style={{ width: "100%" }}
                            >
                              <MenuItem value="IGV">Gravado</MenuItem>
                              <MenuItem value="INA">Inafecto</MenuItem>
                              <MenuItem value="EXO">Exonerado</MenuItem>
                              <MenuItem value="Gratuito">Gratuito</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ minWidth: 40, maxWidth: 40, fontSize: 16 }}
                          >
                            {masIgv
                              ? numberWithCommas(product.value)
                              : numberWithCommas(product.price)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            </Grid>
            {/* Informacion adicional */}
            <Grid item xs={12} md={6} sm={6}>
              <Accordion
                className="aditional-info-container"
                expanded={openAditional}
                onChange={() => setOpenAditional(!openAditional)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  {!openAditional ? (
                    <ExpandMore fontSize="small" color="action" />
                  ) : (
                    <ExpandLess fontSize="small" color="action" />
                  )}

                  <Typography color="textSecondary" variant="caption">
                    Información adicional
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ width: "100%" }}>
                    <Button
                      variant="contained"
                      color="default"
                      disableElevation
                      onClick={() => setNotes([...notes, ""])}
                    >
                      <NoteIcon fontSize="small" style={{ marginRight: 5 }} />{" "}
                      Agregar nota
                    </Button>
                    {notes.map((note, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", marginTop: 20 }}
                      >
                        <Tooltip title="Remover Nota" arrow>
                          <IconButton
                            style={{ marginRight: 10, width: 40 }}
                            size="small"
                            onClick={() => deleteItemNoteClick(index)}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                        <RTextField
                          variant="outlined"
                          autoFocus
                          autoComplete="off"
                          size="small"
                          style={{ width: "100%" }}
                          onChange={updateItemNoteChanged(index)}
                          value={note}
                        />
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <RButtonSecondary
            onClick={close}
            color="primary"
            disabled={loading}
            style={{ marginRight: 5 }}
          >
            Cancelar
          </RButtonSecondary>
          <RButton
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={sendInvoiceClick}
          >
            {loading ? (
              <>
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
                Enviando...
              </>
            ) : (
              <>
                <Send fontSize="small" style={{ marginRight: 5 }} />
                Enviar
              </>
            )}
          </RButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title="¿Ha completado su Boleta de Venta?"
        body={
          showBuyerInfo
            ? "Se enviará el documento a la SUNAT"
            : "Se enviará el documento a la SUNAT sin información del comprador"
        }
        acceptBtnText="Confirmar"
        open={openConfirmDialog}
        handleClose={() => setOpenConfirmDialog(false)}
        handleAccept={sendInvoice}
      />
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
