import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import reducers, { AppState } from "./reducers";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer<AppState>(persistConfig, reducers);

let store =
  process.env.NODE_ENV === "development"
    ? createStore(persistedReducer, composeWithDevTools())
    : createStore(persistedReducer);

let persistor = persistStore(store);

export { store, persistor };
