import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  TouchAppTwoTone,
  BlockTwoTone,
  Done,
  ErrorOutline,
  WarningTwoTone,
} from "@material-ui/icons";
import UseAnimations from "react-useanimations";
import radioButton from "react-useanimations/lib/radioButton";
import loading from "react-useanimations/lib/loading";
import { Colors } from "../../static/colors";
import * as xml2json from "xml-js";
import axios from "axios";
import JSZip from "jszip";
import { useSelector, useDispatch } from "react-redux";
import { setConfigParam } from "../../../domain/store/actions/config";
import {
  Container,
  ContainerCard,
  Dropzone,
  ExistLabel,
  FileItem,
  ListFiles,
  ResultDetails,
} from "./styled";
import TextStyle from "../textStyle";

interface UploadProps {
  title: string;
  subtitle?: string;
}

const ValidationTool: React.FC<UploadProps> = ({ title, subtitle }) => {
  const config = useSelector((state: any) => state.config);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!config.session_id) {
      dispatch(
        setConfigParam(
          "session_id",
          Math.random().toString(36).replace("0.", "")
        )
      );
    }
  }, []);

  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "application/xml, text/xml",
    maxFiles: 50,
    maxSize: 2000000,
  });

  return (
    <ContainerCard>
      <TextStyle bold={700} type="h1" lineHeight={24} margin="0px 0px 20px 0px">
        {title}
      </TextStyle>
      {subtitle && (
        <TextStyle bold={300} type="h2" lineHeight={21}>
          {subtitle}
        </TextStyle>
      )}
      <Container>
        <Dropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragAccept && <TouchAppTwoTone style={{ fontSize: 70 }} />}
          {isDragReject && <BlockTwoTone style={{ fontSize: 70 }} />}
          {!isDragActive && (
            <TextStyle
              bold={700}
              lineHeight={21}
              margin="0px 0px 30px 0px"
              type="h3"
            >
              Arrastra y suelta tus archivos aquí, o haz clic para
              seleccionarlos
            </TextStyle>
          )}
          <TextStyle bold={300} type="h4">
            (Solo archivos *.xml hasta un límite de 25 documentos a la vez)
          </TextStyle>
        </Dropzone>
        <aside>
          {acceptedFiles.length > 0 && (
            <ListFiles>
              {acceptedFiles.map((file: File) => (
                <UploadItem
                  key={file.name}
                  file={file}
                  session_id={config.session_id}
                />
              ))}
            </ListFiles>
          )}
        </aside>
      </Container>
    </ContainerCard>
  );
};

enum DocStatus {
  loading,
  error,
  ok,
  warning,
  alert,
}

interface GeneralStatus {
  status: DocStatus;
  msg: string | null;
}

enum ItemStatus {
  ok,
  warning,
  none,
}

interface ItemResult {
  result: string;
  docStatus: ItemStatus;
  docStatusDesc: string;
  rucStatus: ItemStatus;
  rucStatusDesc: string;
  addressStatus: ItemStatus;
  addressStatusDesc: string;
}

interface Props {
  file: File;
  session_id: string;
}

const UploadItem: React.FC<Props> = ({ file, session_id }) => {
  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      try {
        let xmlData: any = reader.result;
        const json = JSON.parse(xml2json.xml2json(xmlData, { compact: true }));
        let res = await axios.post("/api/reception/upload/anonymous", {
          document: json,
          origin: "WEB",
          session_id,
        });
        const data = res.data;
        if (data.result) {
          setItemResult({
            // Doc status
            docStatus:
              data.state_doc === "1" || data.state_doc === "3"
                ? ItemStatus.ok
                : ItemStatus.warning,
            docStatusDesc: data.state_doc_desc,
            result: data.result_desc,
            // RUC status
            rucStatus: !data.state_ruc
              ? ItemStatus.none
              : data.state_ruc === "00"
              ? ItemStatus.ok
              : ItemStatus.warning,
            rucStatusDesc: data.state_ruc_desc,
            // Address status
            addressStatus: !data.address_condition
              ? ItemStatus.none
              : data.address_condition === "00"
              ? ItemStatus.ok
              : ItemStatus.warning,
            addressStatusDesc: data.address_condition_desc,
          });
          setGeneralStatus({
            status:
              data.state_doc === "1" || data.state_doc === "3"
                ? DocStatus.ok
                : data.state_doc === "2"
                ? DocStatus.warning
                : DocStatus.alert,
            msg: null,
          });
          //---- Upload ZIP FILE for new or updated docs ---
          if (data.result_desc !== "exists") {
            let zip = new JSZip();
            zip.file(`${data.file_name}.xml`, file);
            const zipFile = await zip.generateAsync({
              type: "blob",
              compression: "DEFLATE",
            });
            const form = new FormData();
            form.append("document", zipFile, `${data.file_name}.zip`);
            axios.post("/api/file/upload", form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        } else {
          setGeneralStatus({ status: DocStatus.error, msg: data.msg });
        }
      } catch (e: any) {
        setGeneralStatus({ status: DocStatus.error, msg: e.message });
      }
    };

    reader.readAsText(file);
    setGeneralStatus({ status: DocStatus.loading, msg: null });
  }, [file]);

  const [generalStatus, setGeneralStatus] = useState<GeneralStatus>({
    status: DocStatus.loading,
    msg: null,
  });
  const [itemResult, setItemResult] = useState<ItemResult>();

  return (
    <FileItem>
      <div>
        {generalStatus.status === DocStatus.loading && (
          <UseAnimations
            animation={loading}
            autoplay
            size={32}
            strokeColor={"rgb(38 152 63)"}
            wrapperStyle={{ paddingRight: 5 }}
          />
        )}
        {generalStatus.status === DocStatus.ok && (
          <UseAnimations
            animation={radioButton}
            autoplay
            size={32}
            strokeColor={"rgb(38 152 63)"}
            wrapperStyle={{ paddingRight: 5 }}
          />
        )}
        {generalStatus.status === DocStatus.warning && (
          <UseAnimations
            animation={radioButton}
            autoplay
            size={32}
            strokeColor={"rgb(255 178 47)"}
            wrapperStyle={{ paddingRight: 5 }}
          />
        )}
        {generalStatus.status === DocStatus.alert && (
          <WarningTwoTone
            style={{
              fontSize: 25,
              color: "rgb(255 178 47)",
              paddingLeft: 2,
              paddingRight: 8,
            }}
          />
        )}
        {generalStatus.status === DocStatus.error && (
          <ErrorOutline
            style={{
              fontSize: 28,
              color: Colors.alert,
              paddingLeft: 2,
              paddingRight: 8,
            }}
          />
        )}
      </div>
      <div>
        <h4>
          {file.name} - {Math.round(file.size / 1024)} Kb
        </h4>
        <ul>
          {generalStatus.status === DocStatus.loading ? (
            <ResultDetails>Subiendo comprobante...</ResultDetails>
          ) : generalStatus.status === DocStatus.error ? (
            <ResultDetails>
              No se pudo cargar el documento: {generalStatus.msg}
            </ResultDetails>
          ) : (
            <>
              {itemResult?.result === "updated" && (
                <ExistLabel>
                  El estado ha sido actualizado recientemente
                </ExistLabel>
              )}
              <ResultDetails>
                {itemResult?.docStatus === ItemStatus.ok && (
                  <Done style={{ fontSize: 12 }} />
                )}{" "}
                {itemResult?.docStatusDesc}
              </ResultDetails>
              {itemResult?.rucStatus !== ItemStatus.none && (
                <ResultDetails>
                  {itemResult?.rucStatus === ItemStatus.ok && (
                    <Done style={{ fontSize: 12 }} />
                  )}{" "}
                  {itemResult?.rucStatusDesc}
                </ResultDetails>
              )}
              {itemResult?.addressStatus !== ItemStatus.none && (
                <ResultDetails>
                  {itemResult?.addressStatus === ItemStatus.ok && (
                    <Done style={{ fontSize: 12 }} />
                  )}{" "}
                  {itemResult?.addressStatusDesc}
                </ResultDetails>
              )}
            </>
          )}
        </ul>
      </div>
    </FileItem>
  );
};

export default ValidationTool;
