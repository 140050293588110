import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Colors } from "src/infrastructure/static/colors";
import { BAuth, BPrimary, BSecondary } from "./styled";

interface IButtonPrimary {
  background: string;
  color: string;
  onClick: (e?: any) => void;
  children: React.ReactNode;
  size: string;
  height?: string;
  width?: string;
  disabled?: boolean;
  loading?: boolean;
}

interface IButtonSecondary {
  border: string;
  color: string;
  onClick: (e?: any) => void;
  children: React.ReactNode;
  size: string;
  height?: string;
  width?: string;
  disabled?: boolean;
  loading?: boolean;
}

interface IButtonAuth {
  border: string;
  color: string;
  children: React.ReactNode;
  size: string;
  height?: string;
  width?: string;
  href: string;
}

export const ButtonPrimary: React.FC<IButtonPrimary> = ({
  background,
  color,
  onClick,
  children,
  size,
  width,
  height,
  disabled,
  loading,
}) => {
  return (
    <BPrimary
      background={background}
      color={color}
      size={size}
      width={width}
      height={height}
      onClick={onClick}
      disabled={disabled}
    >
      {!loading ? (
        children
      ) : (
        <CircularProgress size="24px" style={{ color: Colors.WHITE }} />
      )}
    </BPrimary>
  );
};

export const ButtonSecondary: React.FC<IButtonSecondary> = ({
  border,
  color,
  onClick,
  children,
  size,
  width,
  height,
  disabled,
  loading,
}) => {
  return (
    <BSecondary
      border={border}
      color={color}
      size={size}
      width={width}
      height={height}
      onClick={onClick}
      disabled={disabled}
    >
      {!loading ? (
        children
      ) : (
        <CircularProgress size="24px" style={{ color: Colors.TEXT }} />
      )}
    </BSecondary>
  );
};

export const ButtonAuth: React.FC<IButtonAuth> = ({
  border,
  color,
  children,
  size,
  width,
  height,
  href,
}) => {
  return (
    <BAuth
      border={border}
      color={color}
      size={size}
      width={width}
      height={height}
      href={href}
    >
      {children}
    </BAuth>
  );
};
