import React from "react";

export const useForm = (initialState: any) => {
  const [state, setstate] = React.useState(initialState);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onReset = () => {
    setstate(initialState);
  };

  const onCancelEdit = (state: any) => {
    setstate(state);
  };

  const onChangeChecked = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setstate({
      ...state,
      [name]: e.target.checked,
    });
  };

  const onChangeSelect = (name: any, e: any) => {
    setstate({
      ...state,
      [name]: e.target.value,
    });
  };

  const onChangeValue = (name: any, value: any) => {
    setstate({
      ...state,
      [name]: value,
    });
  };

  const onChangeError = (stateError: any) => {
    setstate(stateError);
  };

  const onCompleteData = (data: any) => {
    setstate(data);
  };

  return {
    ...state,
    form: state,
    onChange,
    onReset,
    onCancelEdit,
    onChangeChecked,
    onChangeError,
    onCompleteData,
    onChangeSelect,
    onChangeValue,
  };
};
