import React from "react";
import { WhatsApp } from "@material-ui/icons";
import { Typography, Slide, Container, Link } from "@material-ui/core";
import illustration from "../../assets/imgs/sunatIllustration.svg";
import registroGif from "../../assets/imgs/Registro.gif";

export const AboutUs: React.FC<{}> = () => {
  return (
    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
      <div
        style={{
          //marginTop: "80px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container
          component="main"
          style={{ marginTop: 8, marginBottom: 2 }}
          maxWidth="sm"
        >
          <img
            src={registroGif}
            alt="registor_gif"
            width="100%"
            style={{ marginBottom: 10 }}
          />
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            Automatizamos la generación y verificación de autenticidad de sus
            comprobantes
          </Typography>
          <Typography variant="body1">
            Somos la única empresa peruana que valida y ratifica al instante la
            existencia de comprobantes de pago electrónico en SUNAT. Ofrecemos
            seguridad, soporte, custodia y múltiples canales de integración para
            la emisión de comprobantes electrónicos.
          </Typography>
          <div style={{ textAlign: "center", padding: 20 }}>
            <img width="220px" src={illustration} alt="Ratifika" />
          </div>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            ¿Conoce la importancia de verificar los comprobantes de pago
            electrónicos? Mira este caso
          </Typography>

          <Typography variant="body1">
            Supón que ya declaró e hizo uso del crédito fiscal, sin embargo los
            emisores pueden anular documentos posteriormente sin informarlo y
            llevarlo a cometer una mala declaración de compras ante SUNAT, por
            lo que podría estar expuesto a multas.
          </Typography>

          <Typography variant="body1" style={{ marginTop: 10 }}>
            Con Ratifika, además de emitir y recibir comprobantes electrónicos,
            podrá verificar todo el tiempo el estado real de sus comprobantes en
            SUNAT.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: "bold",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Prueba nuestra Herramienta de validación
          </Typography>

          <Typography variant="body1">
            Puede ratificar cualquier comprobante electrónico desde nuestra
            Herramienta de validación. Es gratis y no es necesario crear una
            cuenta para acceder a ella.
          </Typography>

          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: "bold",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            ¡Ponte en contacto!
          </Typography>

          <Typography variant="body1" style={{ marginBottom: 150 }}>
            Para cualquier consulta o sugerencia, llámanos o escríbenos a través
            de nuestro:
            <br />
            Whatsapp: (+51) 960 487 374{" "}
            <Link href="https://api.whatsapp.com/send/?phone=51960487374&text&type=phone_number&app_absent=0">
              {" "}
              <WhatsApp style={{ fontSize: 16, color: "#0e9a2b" }} />{" "}
            </Link>
            <br />
            Teléfono: (+51) 01 480 1790
            <br />
            Correo: apoyo@eximio.com.pe
            <br />
            Dirección: Calle 26 N°42 Urb. El Pinar, Comas Lima-Perú
          </Typography>
        </Container>
      </div>
    </Slide>
  );
};
