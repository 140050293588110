import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Menu,
  Paper,
  MenuItem,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import FileDownload from "js-file-download";
import {
  faFileExcel as excelIcon,
  faEnvelope,
  faFolder,
  faChartBar,
} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { toast } from "react-toastify";
import ConfirmDialog from "../confirmDialog";
import TextStyle from "../textStyle";
import { Colors } from "src/infrastructure/static/colors";
import { reportsService } from "src/domain/services/Reports.service";

interface Props {
  anchorEl: null | HTMLElement;
  onCloseMenu: () => void;
  totals: any;
}
const DownloadReports: React.FC<Props> = ({
  anchorEl,
  onCloseMenu,
  totals,
}) => {
  const config = useSelector((state: any) => state.config);
  const [blockDownloadExcel, setBlockDownloadExcel] =
    React.useState<boolean>(false);
  const [blockDownloadEmailExcel, setBlockDownloadEmailExcel] =
    React.useState<boolean>(false);
  const [downloadingExcel, setDownloadingExcel] =
    React.useState<boolean>(false);
  const [showDialogBackendExcel, setShowDialogBackendExcel] =
    React.useState<boolean>(false);
  const [downloadingEmailExcel, setDownloadingEmailExcel] =
    React.useState<boolean>(false);

  const handleDownloadExcel = async () => {
    if (totals <= 20000) {
      if (totals >= 10000) {
        toast.info("El proceso pudiera tardar unos minutos!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      const { data } = await reportsService.downloadReportEmission();
      setDownloadingExcel(true);
      setBlockDownloadExcel(true);
      onCloseMenu();
      FileDownload(
        new Blob([data]),
        `docs_emitidos_${config.workspace}_${config.reportsStartDate}_${config.reportsEndDate}.xlsx`
      );
      setDownloadingExcel(false);
      setTimeout(() => setBlockDownloadExcel(false), 3000);
    } else {
      setShowDialogBackendExcel(true);
    }
  };

  const handleDownloadFinancial = async () => {
    if (totals <= 20000) {
      if (totals >= 10000) {
        toast.info("El proceso pudiera tardar unos minutos!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      const { data } = await reportsService.downloadReportFinancial();
      setDownloadingExcel(true);
      setBlockDownloadExcel(true);
      onCloseMenu();
      FileDownload(
        new Blob([data]),
        `reporte_financiero_${config.workspace}_${config.reportsStartDate}_${config.reportsEndDate}.xlsx`
      );
      setDownloadingExcel(false);
      setTimeout(() => setBlockDownloadExcel(false), 3000);
    } else {
      setShowDialogBackendExcel(true);
    }
  };

  const handleDownloadEmailReportExcel = async () => {
    if (config.reportTotal <= 150000) {
      const { data } = await reportsService.downloadReportEmails();
      setDownloadingExcel(true);
      setBlockDownloadExcel(true);
      onCloseMenu();
      FileDownload(
        new Blob([data]),
        `reporte_correos_${config.workspace}_${config.reportsStartDate}_${config.reportsEndDate}.xlsx`
      );
      setDownloadingEmailExcel(false);
      setTimeout(() => setBlockDownloadEmailExcel(false), 3000);
    } else {
      // TODO: mostrar mensaje de que el reporte se le enviara mas adelante por email
      setShowDialogBackendExcel(true);
    }
  };

  const handleDownloadVentasReport = async () => {
    if (config.reportTotal <= 15000) {
      const { data } = await reportsService.downloadReportTxt();
      const anio = moment(config.reportsEndDate).format("YYYY");
      const mes = moment(config.reportsEndDate).format("MM");
      FileDownload(
        new Blob([data]),
        //LE	20538751228	2022	01	00	140100	00	1	1	1	1
        `LE${config.workspace}${anio}${mes}00140100001111.txt`
      );
    }
  };

  return (
    <>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div>
          <MenuItem onClick={handleDownloadExcel}>
            {downloadingExcel ? (
              <div>
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <FontAwesomeIcon
                  icon={excelIcon}
                  style={{ fontSize: "20px", marginRight: "5px" }}
                />
                <TextStyle bold={400} color={Colors.BLACK} type="h4">
                  Descargar reporte Doc. emitidos
                </TextStyle>
              </div>
            )}
          </MenuItem>
          <MenuItem onClick={handleDownloadFinancial}>
            {downloadingEmailExcel ? (
              <CircularProgress
                variant="indeterminate"
                size={14}
                style={{ marginRight: 5 }}
              />
            ) : (
              <div style={{ display: "flex" }}>
                <FontAwesomeIcon
                  icon={faFolder}
                  style={{ fontSize: "20px", marginRight: "5px" }}
                />
                <TextStyle bold={400} color={Colors.BLACK} type="h4">
                  Descargar reporte Financiero
                </TextStyle>
              </div>
            )}
          </MenuItem>
          <MenuItem onClick={handleDownloadEmailReportExcel}>
            {downloadingEmailExcel ? (
              <CircularProgress
                variant="indeterminate"
                size={14}
                style={{ marginRight: 5 }}
              />
            ) : (
              <div style={{ display: "flex" }}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ fontSize: "20px", marginRight: "5px" }}
                />
                <TextStyle bold={400} color={Colors.BLACK} type="h4">
                  Descargar reporte de Correos
                </TextStyle>
              </div>
            )}
          </MenuItem>
          <MenuItem onClick={handleDownloadVentasReport}>
            {downloadingEmailExcel ? (
              <CircularProgress
                variant="indeterminate"
                size={14}
                style={{ marginRight: 5 }}
              />
            ) : (
              <div style={{ display: "flex" }}>
                <FontAwesomeIcon
                  icon={faChartBar}
                  style={{ fontSize: "20px", marginRight: "5px" }}
                />
                <TextStyle bold={400} color={Colors.BLACK} type="h4">
                  Descargar txt PLE (Beta)
                </TextStyle>
              </div>
            )}
          </MenuItem>
        </div>
      </Menu>
      <ConfirmDialog
        title="Cantidad no soportada aun"
        body={`Para generar un reporte de ${totals} documentos, debe contactar a soporte momentaneamente`}
        acceptBtnText="Listo"
        open={showDialogBackendExcel}
        handleClose={() => setShowDialogBackendExcel(false)}
        handleAccept={() => setShowDialogBackendExcel(false)}
      />
    </>
  );
};

export default DownloadReports;
