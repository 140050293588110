import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Snackbar,
  TablePagination,
  Hidden,
  Drawer,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { DateRange } from "react-date-range";
import { HeaderTitle } from "../../components/headerTitle";
import { es } from "date-fns/locale";
import moment from "moment";
import { setConfigParam } from "../../store/actions/config";

import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { RButton, RRadio } from "../../components/ratifikaElements";
import {
  ArrowRightAlt as ArrowDateIcon,
  Today as DateRangeIcon,
  CloudDownload,
} from "@material-ui/icons";

import { getDocumentStatusDescription } from "../../static/util";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { fetch } from "../../store/fetch";
import { FilterReport } from "../../components/filterReport";
import { Product } from "../../store/types/emittedDoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faCoins,
  faCreditCard,
  faFilter,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "@material-ui/lab/Alert";
import { MenuExcelFile } from "../../components/fileExcel";
import { subDays } from "date-fns";
import { LoadingReport } from "../../components/skeleton/loadingReport";
import axios from "axios";
import FileDownload from "js-file-download";

export const Reports = () => {
  const config = useSelector((state: any) => state.config);
  const [open, setOpen] = React.useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = React.useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageProd, setPageProd] = React.useState(0);
  const [pageCli, setPageCli] = React.useState(0);
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );
  const [totalReport, setTotalReport] = React.useState<number | null>(0);
  const [showFilters, setShowFilters] = React.useState<boolean>(false);

  const [dateRange, setDateRange] = React.useState([
    {
      startDate: subDays(new Date(), 1),
      endDate: subDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const dispatch = useDispatch();

  //const resultReportDocs = useSelector((state: any) => state.reporteDocs);
  const [resultReportDocs, setResultReportDocs] = React.useState<any>([]);

  React.useEffect(() => {
    if (config.workspace) {
      async function fetchGeneralReport() {
        await axios
          .get(
            `api/general/report/${config.workspace}/?startDate=${
              config.reportStartDate
            }&endDate=${config.reportEndDate}&dateOf=${config.typeDate}${
              config.reportFilterSerie
                ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportFilterInitSeq}&seqEnd=${config.reportFilterEndSeq}`
                : ""
            }${config.typeDoc ? `&docType=${config.typeDoc}` : ""}${
              config.emittedClientReport
                ? `&receiverId=${config.emittedClientReport}`
                : ""
            }${
              config.stateDoc
                ? `&docStatus=${
                    config.stateDoc.split(",").includes("1-3")
                      ? config.stateDoc.replace("1-3", "1,3")
                      : config.stateDoc
                  }`
                : ""
            }`
          )
          .then((data) => {
            //Actualiza el estado del loading

            setShowUpdateAlert(false);

            if (data.data.result) {
              setResultReportDocs(data.data);
              setTotalReport(data.data.totalSend);
            }
          });
      }
      fetchGeneralReport();
    }
  }, [
    config.workspace,
    config.reportStartDate,
    config.reportEndDate,
    config.typeDate,
    config.reportFilterSerie,
    config.reportFilterInitSeq,
    config.reportFilterEndSeq,
    config.typeDoc,
    config.emittedClientReport,
    config.stateDoc,
  ]);

  /*     Select Fecha*/
  let selectedDates: any = {
    startDate: dateRange[0].startDate,
    endDate: dateRange[0].endDate,
  };

  const handleCalendarChange = (item: any) => {
    setDateRange([item.selection]);
    selectedDates = item.selection;
  };

  const handleCalendarSelected = (e: any) => {
    const [start, end] = e;
    if (start === 0 && end === 0) {
      setOpen(false);
      dispatch(
        setConfigParam(
          "reportStartDate",
          moment(selectedDates.startDate).format("YYYY-MM-DD")
        )
      );

      dispatch(
        setConfigParam(
          "reportEndDate",
          moment(selectedDates.endDate).format("YYYY-MM-DD")
        )
      );
      fetch(["reports"]);
      setShowUpdateAlert(true);
    }
  };
  /* Fin select Fecha */

  const handleDateTypeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setConfigParam("typeDate", (event.target as HTMLInputElement).value)
    );
    fetch(["reports"]);
    setShowUpdateAlert(true);
    setOpen(false);
  };

  //Modal
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  interface Column {
    id: "MB" | "Date" | "Type" | "BtnDownload";
    label: string;
    minWidth?: number;
    align?: "left";
    // format?: (value: number) => string;
  }

  const columns: Column[] = [
    { id: "MB", label: "", minWidth: 70 },
    { id: "Date", label: "Fecha Generada", minWidth: 80 },
    { id: "Type", label: "Tipo de Reporte", minWidth: 80 },
    { id: "BtnDownload", label: "", minWidth: 40 },
  ];

  //Products
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPageProd(0);
    setPageCli(0);
  };
  const handleChangePageProd = (event: unknown, newPage: number) => {
    setPageProd(newPage);
  };
  const handleChangePageCli = (event: unknown, newPage: number) => {
    setPageCli(newPage);
  };

  // abrir filter click event
  const handleOpenFilterClick = () => {
    setShowFilters(true);
  };
  const handleCloseFilters = () => {
    setShowFilters(false);
  };
  //Menu de archivos Excel
  const handleOpenMenuDescarga = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleCloseMenuDescarga = () => {
    setAnchorElement(null);
  };

  //handle show alert in Reporte
  const handleShowAlert = (value: boolean) => {
    setShowUpdateAlert(value);
  };

  const calculateDate = (value: string) => {
    let time = Number(value.split(".")[0]);
    let date = new Date(time);
    return date.toLocaleTimeString("en-US");
  };

  const bulkFileDownload = async (date: string, name: string) => {
    let { data } = await axios.get(
      `/api/emission/download/bulk/${config.workspace}/${date}/${name}`,
      {
        responseType: "blob",
      }
    );
    FileDownload(new Blob([data]), `Descarga-Masiva-${config.workspace}.zip`);
  };

  return (
    <React.Fragment>
      {/* <Snackbar
        open={showUpdateAlert}
        autoHideDuration={500}
        // onClose={() => setShowUpdateAlert(false)}
        onClose={() => showUpdateAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={`Cargando..`}
      /> */}

      <Grid container>
        <Hidden only={["xs", "sm", "md"]}>
          <Grid item xs={2} sm={2} className="linea">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <HeaderTitle title="Reporte ventas" />
            </div>

            <FilterReport showAlert={handleShowAlert} />
          </Grid>
        </Hidden>

        <Grid item xs={12} sm={11} md={11} lg={10} style={{ paddingLeft: 25 }}>
          {!config.workspace ? (
            <div className="noResultContainer">
              <Typography variant="h5">Aún no ha emitido documentos</Typography>
              <Typography className="noResultLabel">
                Una vez empieces a emitir documentos podras visualizar un
                reporte
              </Typography>
            </div>
          ) : (
            <>
              <Hidden lgUp>
                <Drawer open={showFilters} onClose={handleCloseFilters}>
                  <div style={{ marginLeft: "1rem", marginTop: "4rem" }}>
                    <FilterReport showAlert={handleShowAlert} />
                  </div>
                </Drawer>
              </Hidden>
              {showUpdateAlert ? (
                <LoadingReport />
              ) : (
                <>
                  <Hidden lgUp>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <HeaderTitle title="Reporte ventas" />
                    </div>
                  </Hidden>
                  <Grid container>
                    <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                      <Hidden only={["lg"]}>
                        <Tooltip title="Filtrar Reporte" arrow>
                          <Button
                            onClick={handleOpenFilterClick}
                            style={{ all: "unset", width: "0", height: "0" }}
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                          >
                            <div className="card-icon-setting">
                              <FontAwesomeIcon
                                icon={faFilter}
                                style={{ fontSize: "20px" }}
                              />
                            </div>
                          </Button>
                        </Tooltip>
                      </Hidden>

                      <Grid container>
                        <Grid item xs={12} sm={8}>
                          <div>
                            <div style={{ textAlign: "center" }}>
                              <Typography
                                color="textSecondary"
                                gutterBottom
                                style={{ fontSize: 13 }}
                              >
                                Selecciona una Fecha para ver un resumen de tus
                                actividades en Ratifika
                              </Typography>
                            </div>

                            <RButton
                              className="btnFecha"
                              disabled={!config.workspace}
                              onClick={handleClickOpen}
                            >
                              <DateRangeIcon
                                style={{
                                  fontSize: 18,
                                  paddingRight: 5,
                                  color: "white",
                                }}
                              />
                              <span style={{ color: "white" }}>
                                {moment(dateRange[0].startDate)
                                  .locale("es")
                                  .format("MMM DD")}
                              </span>
                              <ArrowDateIcon
                                style={{
                                  fontSize: 22,
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  color: "white",
                                }}
                              />
                              <span style={{ color: "white" }}>
                                {moment(dateRange[0].endDate)
                                  .locale("es")
                                  .format("MMM DD")}
                              </span>
                            </RButton>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={4} style={{ marginTop: "23px" }}>
                          <Button
                            variant="contained"
                            color="default"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={handleOpenMenuDescarga}
                            startIcon={
                              <FontAwesomeIcon
                                icon={faArrowAltCircleDown}
                                style={{ fontSize: "22px" }}
                              />
                            }
                          >
                            <Typography style={{ fontSize: "14px" }}>
                              DESCARGAR REPORTE
                            </Typography>
                          </Button>

                          <MenuExcelFile
                            anchorEl={anchorElement}
                            onCloseMenu={handleCloseMenuDescarga}
                            totals={totalReport}
                          />
                        </Grid>
                      </Grid>

                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogContent>
                          <div className="sizeCalendar">
                            <div>
                              <DateRange
                                onChange={handleCalendarChange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                months={2}
                                direction="horizontal"
                                locale={es}
                                rangeColors={["rgb(28, 108, 54)"]}
                                showMonthAndYearPickers={true}
                                maxDate={new Date()}
                                monthDisplayFormat="MMMM yyyy"
                                onRangeFocusChange={handleCalendarSelected}
                                className="sizeCalendar"
                              />
                            </div>
                            <div>
                              <Grid style={{ marginLeft: 20 }}>
                                <RadioGroup
                                  row
                                  value={config.typeDate}
                                  onChange={handleDateTypeSelect}
                                >
                                  <FormControlLabel
                                    value="uploaded"
                                    control={<RRadio color="primary" />}
                                    label={
                                      <Typography style={{ fontSize: 14 }}>
                                        Fecha de subida
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value="emitted"
                                    control={<RRadio color="primary" />}
                                    label={
                                      <Typography style={{ fontSize: 14 }}>
                                        Fecha de emisión
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </Grid>
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </Grid>
                  </Grid>

                  {/* //Fecha */}

                  {/* CARDS */}

                  <Grid container spacing={5} className="padd">
                    <Grid item xs={12} sm={6}>
                      <Paper elevation={6} className="tarjetas">
                        <Typography variant="h4" color="primary">
                          {resultReportDocs ? resultReportDocs.totalSend : "0"}
                        </Typography>
                        <Typography variant="h6">
                          Documentos enviados
                        </Typography>
                        <div style={{ textAlign: "right" }}>
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Paper elevation={6} className="tarjetas">
                        <Grid container spacing={2} className="cardSmall">
                          <Grid item xs={6}>
                            <Typography
                              variant="h5"
                              style={{ color: "#01579b" }}
                            >
                              {resultReportDocs
                                ? resultReportDocs.totalPEN
                                : "0"}{" "}
                              S/
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              PEN
                            </Typography>
                          </Grid>
                          {resultReportDocs ? (
                            resultReportDocs.totalDOLLAR !== 0 && (
                              <Grid item xs={6}>
                                <Typography
                                  variant="h5"
                                  style={{ color: "#4caf50" }}
                                >
                                  {resultReportDocs.totalDOLLAR} $
                                </Typography>

                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  USD
                                </Typography>
                              </Grid>
                            )
                          ) : (
                            <Grid item xs={6}>
                              <Typography
                                variant="h5"
                                style={{ color: "#4caf50" }}
                              >
                                0 $
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: "bold" }}
                              >
                                USD
                              </Typography>
                            </Grid>
                          )}
                        </Grid>

                        <Grid container style={{ padding: "5px" }}>
                          <Grid item sm={6}>
                            <Typography variant="h6">Total vendido</Typography>
                          </Grid>
                          <Grid item sm={6} style={{ textAlign: "right" }}>
                            <FontAwesomeIcon
                              icon={faCoins}
                              style={{ fontSize: "20px" }}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>

                  <br />
                  <br />
                  <Grid container spacing={6} className="padd">
                    <Grid item xs={12} sm={6}>
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableHead></TableHead>
                          <TableBody>
                            {resultReportDocs &&
                            resultReportDocs.stateDocuments ? (
                              resultReportDocs.stateDocuments.map(
                                (cod: any, index: number) => (
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      key={index + 11}
                                    >
                                      <Typography variant="h6">
                                        {cod.state === "0" && (
                                          <Alert severity="warning">
                                            {" "}
                                            {getDocumentStatusDescription(
                                              cod.state
                                            )}
                                          </Alert>
                                        )}
                                        {cod.state === "1" && (
                                          <Alert severity="success">
                                            {" "}
                                            {getDocumentStatusDescription(
                                              cod.state
                                            )}
                                          </Alert>
                                        )}
                                        {cod.state === "-1" && (
                                          <Alert severity="error">
                                            {" "}
                                            {getDocumentStatusDescription(
                                              cod.state
                                            )}
                                          </Alert>
                                        )}
                                        {cod.state === "-2" && (
                                          <Alert severity="error">
                                            {" "}
                                            {getDocumentStatusDescription(
                                              cod.state
                                            )}
                                          </Alert>
                                        )}
                                        {cod.state === "2" && (
                                          <Alert severity="info">
                                            {" "}
                                            {getDocumentStatusDescription(
                                              cod.state
                                            )}
                                          </Alert>
                                        )}
                                        {cod.state === "4" && (
                                          <Alert severity="warning">
                                            {" "}
                                            {getDocumentStatusDescription(
                                              cod.state
                                            )}
                                          </Alert>
                                        )}
                                        {cod.state === "5" && (
                                          <Alert severity="warning">
                                            {" "}
                                            {getDocumentStatusDescription(
                                              cod.state
                                            )}
                                          </Alert>
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell size="small" width={13}>
                                      <Typography
                                        variant="h6"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {cod.total_state}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                              )
                            ) : (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  <p>No existe registros</p>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <FontAwesomeIcon
                                  icon={faMoneyBill}
                                  style={{ fontSize: "20px" }}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Typography variant="subtitle1">
                                  Contado
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                {resultReportDocs ? (
                                  <Typography variant="h6">
                                    S/ {resultReportDocs.totalContadoPEN}
                                  </Typography>
                                ) : (
                                  "No existe registros"
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {resultReportDocs ? (
                                  <Typography variant="h6">
                                    $ {resultReportDocs.totalContadoUSD}
                                  </Typography>
                                ) : (
                                  "No existe registros"
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <FontAwesomeIcon
                                  icon={faCreditCard}
                                  style={{ fontSize: "20px" }}
                                />
                              </TableCell>

                              <TableCell component="th" scope="row">
                                <Typography variant="subtitle1">
                                  Crédito
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                {resultReportDocs ? (
                                  <Typography variant="h6">
                                    S/ {resultReportDocs.totalCreditoPEN}
                                  </Typography>
                                ) : (
                                  "No existe registros"
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {resultReportDocs ? (
                                  <Typography variant="h6">
                                    $ {resultReportDocs.totalCreditoUSD}
                                  </Typography>
                                ) : (
                                  "No existe registros"
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} className="padd">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">Reportes</Typography>
                      <Typography variant="subtitle1">
                        Lista de reportes de los ultimos 30 dias
                      </Typography>
                      <Paper>
                        <TableContainer>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id + 1}
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            {resultReportDocs &&
                            resultReportDocs.bulkDownloadFileList ? (
                              <TableBody>
                                {resultReportDocs.bulkDownloadFileList
                                  .slice(
                                    pageCli * rowsPerPage,
                                    pageCli * rowsPerPage + rowsPerPage
                                  )
                                  .map((file: any, index: number) => {
                                    return (
                                      <TableRow>
                                        <TableCell align="left" key={index + 3}>
                                          {file.size} MB
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          key={index + 45}
                                        >
                                          {file.date}{" "}
                                          {calculateDate(
                                            file.name.split("_")[0]
                                          )}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          key={index + 45}
                                        >
                                          {file.name.split("_")[1] ===
                                          "emission.zip"
                                            ? "R_DocumentosEmitidos"
                                            : "R_DocumentosRecibidos"}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          key={index + 13}
                                        >
                                          <div
                                            style={{
                                              height: 30,
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            <Tooltip title="Descargar" arrow>
                                              <Button
                                                onClick={() => {
                                                  bulkFileDownload(
                                                    file.date,
                                                    file.name
                                                  );
                                                }}
                                              >
                                                <CloudDownload
                                                  style={{
                                                    fontSize: 22,
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    color:
                                                      "rgba(0, 0, 0, 0.54)",
                                                  }}
                                                />
                                              </Button>
                                            </Tooltip>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            ) : (
                              "No existe registros"
                            )}
                          </Table>
                        </TableContainer>
                        {resultReportDocs && (
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            labelRowsPerPage="Filas por página"
                            labelDisplayedRows={({ from, to, count }) =>
                              `${from}-${to} de ${
                                count !== -1 ? count : `mas de ${to}`
                              }`
                            }
                            component="div"
                            count={
                              resultReportDocs.bulkDownloadFileList
                                ? resultReportDocs.bulkDownloadFileList.length
                                : 0
                            }
                            rowsPerPage={rowsPerPage}
                            page={pageCli}
                            onPageChange={handleChangePageCli}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        )}
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6">Listado de Productos</Typography>
                      <Paper>
                        <TableContainer>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  Código
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  Descripción
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {resultReportDocs && resultReportDocs.products ? (
                              <TableBody>
                                {resultReportDocs.products
                                  .slice(
                                    pageProd * rowsPerPage,
                                    pageProd * rowsPerPage + rowsPerPage
                                  )
                                  .map((prod: Product, index: number) => {
                                    return (
                                      <TableRow>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          key={index + 55}
                                        >
                                          <Typography
                                            align="center"
                                            variant="caption"
                                            color="textSecondary"
                                          >
                                            {prod.code}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="left" key={index + 8}>
                                          {" "}
                                          {prod.description}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            ) : (
                              "No ha registrado productos"
                            )}
                          </Table>
                        </TableContainer>
                        {resultReportDocs && (
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            labelRowsPerPage="Filas por página"
                            labelDisplayedRows={({ from, to, count }) =>
                              `${from}-${to} de ${
                                count !== -1 ? count : `mas de ${to}`
                              }`
                            }
                            component="div"
                            count={
                              resultReportDocs.products
                                ? resultReportDocs.products.length
                                : 0
                            }
                            rowsPerPage={rowsPerPage}
                            page={pageProd}
                            onPageChange={handleChangePageProd}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
