import { ReceivedDoc, RecivedDocsActionTypes } from "../types/receivedDoc";

const initialState: ReceivedDoc[] = [];

const receivedDocsReducer = (state = initialState, action: RecivedDocsActionTypes) => {
  switch (action.type) {
    case "SET_RECEIVED_DOCS":
      return action.payload;
    case "RESET_RECEIVED_DOCS":
      return initialState;
    default:
      return state;
  }
};

export default receivedDocsReducer;
