import { Sizes } from "../../static/colors";
import styled from "styled-components";

interface IContainerPublicityProps {
  background: string;
}

export const Image = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: contain;
`;

export const Information = styled.div`
  margin-left: 30%;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;

  @media ${Sizes.DEKSTOP_LARGE} {
    margin-left: 45%;
  }

  @media ${Sizes.TABLET_LARGE} {
    margin-left: 0%;
    margin-top: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContainerPublicity = styled.div<IContainerPublicityProps>`
  background: ${(props) => props.background};
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
