import { reportsRepository } from "src/infrastructure/repositories/Reports.repository";
import { ReportDoc } from "src/store/types/reportDocs";
import { store } from "../store/configureStore";

const getReportsGeneral = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}/?startDate=${
    config.reportsStartDate
  }&endDate=${config.reportsEndDate}&dateOf=${config.reportsDateType}${
    config.reportsSerie
      ? `&serie=${config.reportsSerie}&seqInit=${config.reportsSequentialStart}&seqEnd=${config.reportsSequentialEnd}`
      : ""
  }${
    config.reportsDocumentType ? `&docType=${config.reportsDocumentType}` : ""
  }${config.reportsClientId ? `&receiverId=${config.reportsClientId}` : ""}${
    config.reportsDocumentStatus
      ? `&docStatus=${
          config.reportsDocumentStatus.split(",").includes("1-3")
            ? config.reportsDocumentStatus.replace("1-3", "1,3")
            : config.reportsDocumentStatus
        }`
      : ""
  }`;
  const response = await reportsRepository.getReportsGeneral(params);
  if (response.data.result) {
    store.dispatch({
      type: "SET_REPORT_DOCS",
      payload: response.data as ReportDoc,
    });
  }
  return response;
};

const downloadReportEmission = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.reportsDateType
  }&startDate=${config.reportsStartDate}&endDate=${config.reportsEndDate}${
    config.reportFilterSerie
      ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportsSequentialStart}&seqEnd=${config.reportsSequentialEnd}`
      : ""
  }${
    config.reportsDocumentType ? `&docType=${config.reportsDocumentType}` : ""
  }${
    config.reportsDocumentStatus
      ? `&docStatus=${
          config.reportsDocumentStatus.split(",").includes("1-3")
            ? config.reportsDocumentStatus.replace("1-3", "1,3")
            : config.reportsDocumentStatus
        }`
      : ""
  }`;
  const response = await reportsRepository.downloadReportEmission(params);
  return response;
};

const downloadReportFinancial = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.reportsDateType
  }&startDate=${config.reportsStartDate}&endDate=${config.reportsEndDate}${
    config.reportFilterSerie
      ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportsSequentialStart}&seqEnd=${config.reportsSequentialEnd}`
      : ""
  }${
    config.reportsDocumentType ? `&docType=${config.reportsDocumentType}` : ""
  }${
    config.reportsDocumentStatus
      ? `&docStatus=${
          config.reportsDocumentStatus.split(",").includes("1-3")
            ? config.reportsDocumentStatus.replace("1-3", "1,3")
            : config.reportsDocumentStatus
        }`
      : ""
  }`;
  const response = await reportsRepository.downloadReportFinancial(params);
  return response;
};

const downloadReportEmails = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.reportsDateType
  }&startDate=${config.reportsStartDate}&endDate=${config.reportsEndDate}${
    config.reportFilterSerie
      ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportsSequentialStart}&seqEnd=${config.reportsSequentialEnd}`
      : ""
  }${
    config.reportsDocumentType ? `&docType=${config.reportsDocumentType}` : ""
  }${
    config.reportsDocumentStatus
      ? `&docStatus=${
          config.reportsDocumentStatus.split(",").includes("1-3")
            ? config.reportsDocumentStatus.replace("1-3", "1,3")
            : config.reportsDocumentStatus
        }`
      : ""
  }`;
  const response = await reportsRepository.downloadReportEmails(params);
  return response;
};

const downloadReportTxt = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.reportsDateType
  }&startDate=${config.reportsStartDate}&endDate=${config.reportsEndDate}${
    config.reportFilterSerie
      ? `&serie=${config.reportFilterSerie}&seqInit=${config.reportsSequentialStart}&seqEnd=${config.reportsSequentialEnd}`
      : ""
  }${
    config.reportsDocumentType ? `&docType=${config.reportsDocumentType}` : ""
  }${
    config.reportsDocumentStatus
      ? `&docStatus=${
          config.reportsDocumentStatus.split(",").includes("1-3")
            ? config.reportsDocumentStatus.replace("1-3", "1,3")
            : config.reportsDocumentStatus
        }`
      : ""
  }`;
  const response = await reportsRepository.downloadReportTxt(params);
  return response;
};

export const reportsService = {
  getReportsGeneral,
  downloadReportEmission,
  downloadReportFinancial,
  downloadReportEmails,
  downloadReportTxt,
};
