export const Colors = {
  primary: "#21a366",
  secundary: "#107c41",
  primaryLigth: "#d4f7e7",
  textOlive: "#2e4646",
  iconOlive: "#37503d",
  voided: "#6d6d6d",
  pending: "#ffa14e",
  successful: "#1c6c36",
  alert: "#ff1c37",
  border: "#e4e1e1",
  blueAlternative: "#149a8e",
  blueAlternativeSecundary: "#14759a",
  GREY_1: "#F1F4F7",
  GREEN: "#679A60",
  GREEN_2: "#2E4F29",
  WHITE: "#FFFFFF",
  TEXT: "#736D6D",
  RED: "#f44336",
  PURPLE: "#985f7b",
  BLACK: "#000000",
};

export const Sizes = {
  DEKSTOP_LARGE: "(max-width: 1440px)",
  DESKTOP: "(max-width: 1024px)",
  TABLET_LARGE: "(max-width: 920px)",
  TABLET: "(max-width: 768px)",
  MOBILE: "(max-width: 480px)",
  MOBILE_SMALL: "(max-width: 360px)",
};
