import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogTitle,
} from "@material-ui/core";
import {
  Done,
  DoneAll,
} from "@material-ui/icons";
import { Messages } from "../../../domain/store/types/emittedDoc";
import moment from "moment";
import { Colors } from "../../static/colors";
import { ButtonPrimary } from "../button";

interface Props {
  open: boolean;
  wabaList: Messages[];
  handleClose: () => void;
}

export const WabaHistory: React.FC<Props> = ({
  open,
  wabaList,
  handleClose,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{`Historial de enviados`}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Estado</strong>
                </TableCell>
                <TableCell>
                  <strong>Número</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Fecha</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wabaList
                .sort((a, b) => {
                  return (
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                  );
                })
                .map((message) => (
                  <TableRow key={`${message.to_number}${message.created_at}`}>
                    <TableCell component="th" scope="row">
                      <div
                        style={{
                          justifyContent: "flex-start",
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        { message.state && JSON.parse(message.state)['id'] === 7 && (    
                            <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                                <Done
                                    style={{
                                        fontSize: 16,
                                        color: Colors.pending,
                                        marginRight: 5,
                                    }}
                                />
                                <div>ENVIADO</div>
                            </div>
                        )}
                        {message.state && JSON.parse(message.state)['id'] === 5 && !message.seen_at && (
                          <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                            <Done
                                style={{
                                fontSize: 16,
                                color: Colors.successful,
                                marginRight: 5,
                                }}
                            />
                            <div>RECIBIDO</div>
                          </div>
                        )}
                        {message.sent_at && message.done_at && message.seen_at && (
                          <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                            <DoneAll
                                style={{
                                fontSize: 16,
                                color: Colors.successful,
                                marginRight: 5,
                                }}
                            />
                            <div>LEÍDO</div>
                          </div>
                        )}
                        {message.error && JSON.parse(message.error)['id'] !== 0 && !message.seen_at && (
                          <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                            <Done
                                style={{
                                fontSize: 16,
                                color: Colors.alert,
                                marginRight: 5,
                                }}
                            />
                            <div>RECHAZADO</div>
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{message.to_number}</TableCell>
                    <TableCell align="right">
                      {message.state && JSON.parse(message.state)['id'] === 5 && !message.seen_at? moment(message.done_at).format("DD/MM/YYYY hh:mm A") : 
                      message.sent_at && message.done_at && message.seen_at ? moment(message.seen_at).format("DD/MM/YYYY hh:mm A") : 
                      moment(message.sent_at?message.sent_at:message.created_at).format("DD/MM/YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <ButtonPrimary
          background={Colors.GREEN}
          color={Colors.WHITE}
          onClick={handleClose}
          size="14px"
          width="100px"
        >
          Cerrar
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};
