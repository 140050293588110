import { Dialog, FormControlLabel, Grid, RadioGroup } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { DateRange } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { setConfigParam } from "src/domain/store/actions/config";
import { es } from "date-fns/locale";
import TextStyle from "../textStyle";
import { RRadio } from "../ratifikaElements";

interface IModalDateRangeProps {
  open: boolean;
  handleClose: any;
  setDateRange: any;
  dateRange: any;
  type: string;
}

const ModalDateRange: React.FC<IModalDateRangeProps> = ({
  open,
  handleClose,
  setDateRange,
  dateRange,
  type,
}) => {
  const dispatch = useDispatch();
  const config = useSelector((state: any) => state.config);
  const handleCalendarChange = (item: any) => {
    setDateRange([item.selection]);

    dispatch(
      setConfigParam(
        `${type}StartDate`,
        moment(item.selection.startDate).format("YYYY-MM-DD")
      )
    );

    dispatch(
      setConfigParam(
        `${type}EndDate`,
        moment(item.selection.endDate).format("YYYY-MM-DD")
      )
    );
  };

  const handleDateTypeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setConfigParam(
        `${type}DateType`,
        (event.target as HTMLInputElement).value
      )
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DateRange
        onChange={handleCalendarChange}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        months={2}
        direction="horizontal"
        locale={es}
        rangeColors={["rgb(28, 108, 54)"]}
        showMonthAndYearPickers={true}
        maxDate={new Date()}
        monthDisplayFormat="MMMM yyyy"
        // onRangeFocusChange={handleCalendarSelected}
        className="sizeCalendar"
      />
      <div>
        <Grid style={{ marginLeft: 20 }}>
          <RadioGroup
            row
            value={config[`${type}DateType`]}
            onChange={handleDateTypeSelect}
          >
            <FormControlLabel
              value="uploaded"
              control={<RRadio color="primary" />}
              label={
                <TextStyle bold={400} type="h3">
                  Fecha de subida
                </TextStyle>
              }
            />
            <FormControlLabel
              value="emitted"
              control={<RRadio color="primary" />}
              label={
                <TextStyle bold={400} type="h3">
                  Fecha de emisión
                </TextStyle>
              }
            />
          </RadioGroup>
        </Grid>
      </div>
    </Dialog>
  );
};

export default ModalDateRange;
