import { companyRepository } from "src/infrastructure/repositories/Company.repository";

const getCompanyInfo = async (company: string) => {
  const response = await companyRepository.getCompanyInfo(company);
  return response;
};

const getCompanyInfoList = async () => {
  const response = await companyRepository.getCompanyInfoList();
  return response;
};

export const companyService = {
  getCompanyInfo,
  getCompanyInfoList,
};
