import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import { RButton, RButtonSecondary, RTextField } from "../ratifikaElements";
import { Colors } from "../../static/colors";
import moment from "moment";
import axios from "axios";
import documentSent from "../../imgs/document.sent.png";
import { fetch } from "../../store/fetch";
import { CompanyInfo } from "../../store/types/companies";

interface ToVoidDocument {
  doc_type: string;
  serie: string;
  number: string;
  issue_date: string;
}

interface Props {
  open: boolean;
  toVoid: ToVoidDocument;
  emitter: CompanyInfo;
  handleClose: () => void;
}

interface ErrorReport {
  title: string;
  message: string;
}

export const VoidDocument: React.FC<Props> = ({ open, toVoid, emitter, handleClose }) => {
  const [reasons, setReasons] = useState<string>("");
  const [errorMsgReasons, setErrorMsgReasons] = React.useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<ErrorReport | null>(null);
  const [voidedSent, setVoidedSent] = useState<boolean>(false);

  const generateJsonVoided = () => {
    return {
      tipoComprobante: "RA",
      fechaEmision: moment().format("YYYY-MM-DD"),
      fechaReferencia: moment(toVoid.issue_date).format("YYYY-MM-DD"),
      emisor: {
        numeroDocumento: emitter.ruc,
        tipoDocumento: "6",
        nombre: emitter.legal_name,
        direccion: {
          direccionCompleta: emitter.address_line,
        },
      },
      documentoAfectado: {
        tipoComprobante: toVoid.doc_type,
        serie: toVoid.serie,
        numeroComprobante: toVoid.number,
        razonBaja: reasons,
      },
    };
  };

  const sendVoidedDocument = async () => {
    if (reasons.trim() === "") {
      setErrorMsgReasons("Especifique el motivo de la anulación");
      return;
    } else {
      setLoading(true);
      const json = generateJsonVoided();
      let { data } = await axios.post("/api/emission/send", json);
      setLoading(false);
      if (data.procesado) {
        setVoidedSent(true);
        fetch(["emission"]);
        setResponseError(null);
      } else {
        setResponseError({
          title: "Ha ocurrido un problema con la anulación",
          message: data.mensaje,
        });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!voidedSent && (
        <DialogTitle id="alert-dialog-title">
          {`Anular documento: ${toVoid.serie}-${toVoid.number}`}
        </DialogTitle>
      )}
      <DialogContent style={{ minWidth: 500 }}>
        {voidedSent ? (
          <Slide direction="left" in={voidedSent}>
            <div>
              <Typography
                variant="h4"
                style={{
                  color: Colors.textOlive,
                  marginBottom: 20,
                }}
              >
                Anulación enviada
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: Colors.textOlive,
                }}
              >
                Se ha emitido una Comunicación de Baja para el documento:{" "}
                <strong>
                  {toVoid.serie}-{toVoid.number}
                </strong>
                . Una vez que se confirme la anulación se actualizará el estado del documento.
              </Typography>

              <div style={{ textAlign: "center" }}>
                <img
                  src={documentSent}
                  style={{ paddingTop: 20, height: 120 }}
                  alt="correo enviado"
                />
              </div>
            </div>
          </Slide>
        ) : (
          <div>
            {responseError && (
              <span style={{ color: "red" }}>
                {responseError.title} / {responseError.message}
              </span>
            )}
            <DialogContentText id="alert-dialog-description">
              Ingrese el motivo de la anulación:
            </DialogContentText>
            <RTextField
              id="void-reasons"
              multiline
              rows={4}
              style={{ width: "100%" }}
              helperText={errorMsgReasons}
              error={errorMsgReasons !== null}
              value={reasons}
              autoFocus
              onChange={(e) => {
                setReasons(e.target.value);
                if (errorMsgReasons && e.target.value.trim() !== "") {
                  setErrorMsgReasons(null);
                }
              }}
              variant="outlined"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        {!voidedSent && (
          <RButtonSecondary onClick={handleClose} color="primary">
            Cancelar
          </RButtonSecondary>
        )}

        <RButton
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={voidedSent ? handleClose : sendVoidedDocument}
        >
          {loading ? (
            <>
              <CircularProgress variant="indeterminate" size={14} style={{ marginRight: 5 }} />
              Enviando...
            </>
          ) : voidedSent ? (
            "Cerrar"
          ) : (
            "Anular"
          )}
        </RButton>
      </DialogActions>
    </Dialog>
  );
};
