import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { display } from "@material-ui/system";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoadingReport = () => {
  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  ];

  return (
    <div>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <div style={{ textAlign: "center" }}>
              <Skeleton />
            </div>
            <div style={{ textAlign: "center", marginTop: "8px" }}>
              <Skeleton width={100} height={30} />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} style={{ marginTop: "23px" }}>
            <Button style={{ marginRight: 10 }}>
              <Skeleton height={30} width={160} />
            </Button>
            <Skeleton circle width={50} height={50} />
          </Grid>
        </Grid>
      </Grid>

      {/* CARDS */}
      <Grid container spacing={2} className="padd">
        <Grid item xs={12} sm={6}>
          <Skeleton height={80} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Skeleton height={80} />
        </Grid>
      </Grid>

      <br />
      <br />
      <Grid container spacing={2} className="padd">
        <Grid item xs={12} sm={6}>
          <Skeleton height={80} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton height={80} />
        </Grid>
      </Grid>

      <Grid container spacing={2} className="padd">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            <Skeleton />
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            <Skeleton />
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoadingReport;
