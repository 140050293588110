import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { siteKeyRecaptcha } from "src/infrastructure/static/util";

interface IRecaptchaProps {
  onChange: (value: any) => void;
  error: boolean;
  helpertext: string;
}

const Recaptcha: React.FC<IRecaptchaProps> = ({
  onChange,
  error,
  helpertext,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "15px 0px",
      }}
    >
      <ReCAPTCHA
        sitekey={siteKeyRecaptcha}
        onChange={onChange}
        theme="light"
        size="normal"
        hl="es"
      />
      {error && <p>{helpertext}</p>}
    </div>
  );
};

export default Recaptcha;
