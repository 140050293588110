import { ISearchDocument, ISearchDocumentError } from "src/domain/models/Comun";
import { IPaymentForm, IPaymentFormError } from "src/domain/models/Payment";
import {
  IUserForgotPassword,
  IUserForgotPasswordError,
  IUserLogin,
  IUserLoginError,
  IUserRegister,
  IUserRegisterError,
  IUserVerification,
  IUserVerificationError,
} from "../../domain/models/User";
import { validateEmail } from "./util";

export const validatorLogin = (login: IUserLogin, error: IUserLoginError) => {
  let { emailError, emailErrorText, passwordError, passwordErrorText } = error;
  const { email, password } = login;

  if (email === "") {
    emailError = true;
    emailErrorText = "Ingrese email";
  } else if (!validateEmail(email)) {
    emailError = true;
    emailErrorText = "Ingrese email válido";
  } else {
    emailError = false;
    emailErrorText = "";
  }

  if (password === "") {
    passwordError = true;
    passwordErrorText = "Ingrese contraseña";
  } else if (password.length < 8) {
    passwordError = true;
    passwordErrorText =
      "Debe usar una contraseña válida, al menos 8 caracteres";
  } else {
    passwordError = false;
    passwordErrorText = "";
  }

  if (emailError || passwordError || (passwordError && emailError)) {
    return { emailError, passwordError, passwordErrorText, emailErrorText };
  } else {
    return false;
  }
};

export const validatorForgotPassword = (
  forgot: IUserForgotPassword,
  error: IUserForgotPasswordError
) => {
  let { emailForgotPasswordError, emailForgotPasswordErrorText } = error;
  const { emailForgotPassword } = forgot;

  if (emailForgotPassword === "") {
    emailForgotPasswordError = true;
    emailForgotPasswordErrorText = "Ingrese email";
  } else if (!validateEmail(emailForgotPassword)) {
    emailForgotPasswordError = true;
    emailForgotPasswordErrorText = "Ingrese un email valido";
  } else {
    emailForgotPasswordError = false;
    emailForgotPasswordErrorText = "";
  }

  if (emailForgotPasswordError) {
    return { emailForgotPasswordError, emailForgotPasswordErrorText };
  } else {
    return false;
  }
};

export const validatorRegister = (
  register: IUserRegister,
  error: IUserRegisterError
) => {
  let {
    emailError,
    emailErrorText,
    passwordError,
    passwordErrorText,
    nameError,
    nameErrorText,
    phoneError,
    phoneErrorText,
  } = error;
  const { email, name, password, phone } = register;

  if (email === "") {
    emailError = true;
    emailErrorText = "Ingrese email";
  } else if (!validateEmail(email)) {
    emailError = true;
    emailErrorText = "Ingrese email válido";
  } else {
    emailError = false;
    emailErrorText = "";
  }

  if (password === "") {
    passwordError = true;
    passwordErrorText = "Ingrese contraseña";
  } else if (password.length < 8) {
    passwordError = true;
    passwordErrorText =
      "Debe usar una contraseña válida, al menos 8 caracteres";
  } else {
    passwordError = false;
    passwordErrorText = "";
  }

  if (name === "") {
    nameError = true;
    nameErrorText = "Ingrese nombre";
  } else {
    nameError = false;
    nameErrorText = "";
  }

  if (phone === "") {
    phoneError = true;
    phoneErrorText = "Ingrese número de celular";
  } else if (phone.length !== 9) {
    phoneError = true;
    phoneErrorText = "Especifique un número celular con 9 dígitos";
  } else {
    phoneError = false;
    phoneErrorText = "";
  }

  if (
    emailError ||
    passwordError ||
    nameError ||
    phoneError ||
    (passwordError && emailError && nameError && phoneError)
  ) {
    return {
      emailError,
      passwordError,
      passwordErrorText,
      emailErrorText,
      nameError,
      nameErrorText,
      phoneError,
      phoneErrorText,
    };
  } else {
    return false;
  }
};

export const validatorVerification = (
  verification: IUserVerification,
  error: IUserVerificationError
) => {
  let { codeError, codeErrorText } = error;
  const { code } = verification;

  if (code === "") {
    codeError = true;
    codeErrorText = "Ingrese código";
  } else if (code.length > 6) {
    codeError = true;
    codeErrorText = "Ingrese un código de maximo 6 caracteres";
  } else {
    codeError = false;
    codeErrorText = "";
  }

  if (codeError) {
    return { codeError, codeErrorText };
  } else {
    return false;
  }
};

export const validatorSearchDocument = (
  data: ISearchDocument,
  error: ISearchDocumentError
) => {
  let {
    rucEmitterError,
    rucEmitterErrorText,
    typeDocError,
    typeDocErrorText,
    serieError,
    serieErrorText,
    correlativeError,
    correlativeErrorText,
    rucReceptorError,
    rucReceptorErrorText,
    totalAmountError,
    totalAmountErrorText,
    emissionDateError,
    emissionDateErrorText,
    recaptchaError,
    recaptchaErrorText,
  } = error;
  const {
    rucEmitter,
    typeDoc,
    serie,
    correlative,
    rucReceptor,
    totalAmount,
    emissionDate,
    hasContingency,
    recaptcha,
  } = data;

  if (rucEmitter.length === 0 || rucEmitter.length < 11) {
    rucEmitterError = true;
    rucEmitterErrorText = "Ingrese un número de RUC valido";
  } else {
    rucEmitterError = false;
    rucEmitterErrorText = "";
  }

  if (typeDoc === "" || !typeDoc) {
    typeDocError = true;
    typeDocErrorText = "Ingrese el tipo de documento";
  } else {
    typeDocError = false;
    typeDocErrorText = "";
  }

  if (serie === "" || serie.length < 4) {
    serieError = true;
    serieErrorText = "Ingrese un número de serie valido";
  } else {
    serieError = false;
    serieErrorText = "";
  }

  if (typeDoc && typeDoc !== "" && !hasContingency) {
    if (typeDoc === "01") {
      if (serie === "" || serie.length < 4) {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido";
      } else if (serie.toUpperCase().charAt(0) !== "F") {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido Ej:F001";
      } else {
        serieError = false;
        serieErrorText = "";
      }
      if (rucReceptor) {
        if (rucReceptor.length < 11) {
          rucReceptorError = true;
          rucReceptorErrorText = "Ingrese un número de RUC valido";
        } else {
          rucReceptorError = false;
          rucReceptorErrorText = "";
        }
      }
    }
    if (typeDoc === "03") {
      if (serie === "" || serie.length < 4) {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido";
      } else if (serie.toUpperCase().charAt(0) !== "B") {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido Ej:B001";
      } else {
        serieError = false;
        serieErrorText = "";
      }
    }
    if (typeDoc === "07" || typeDoc === "08") {
      if (serie === "" || serie.length < 4) {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido";
      } else if (
        serie.toUpperCase().charAt(0) !== "F" ||
        serie.toUpperCase().charAt(0) !== "B"
      ) {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido Ej:F001 o B001";
      } else {
        serieError = false;
        serieErrorText = "";
      }

      if (serie.toUpperCase().charAt(0) === "F") {
        if (rucReceptor) {
          if (rucReceptor.length < 11) {
            rucReceptorError = true;
            rucReceptorErrorText = "Ingrese un número de RUC valido";
          } else {
            rucReceptorError = false;
            rucReceptorErrorText = "";
          }
        }
      }
    }
    if (typeDoc === "09") {
      if (serie === "" || serie.length < 4) {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido";
      } else if (serie.toUpperCase().charAt(0) !== "T") {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido Ej:T001";
      } else {
        serieError = false;
        serieErrorText = "";
      }
      if (rucReceptor) {
        if (rucReceptor.length < 11) {
          rucReceptorError = true;
          rucReceptorErrorText = "Ingrese un número de RUC valido";
        } else {
          rucReceptorError = false;
          rucReceptorErrorText = "";
        }
      }
    }
    if (typeDoc === "20") {
      if (serie === "" || serie.length < 4) {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido";
      } else if (serie.toUpperCase().charAt(0) !== "R") {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido Ej:T001";
      } else {
        serieError = false;
        serieErrorText = "";
      }
      if (rucReceptor) {
        if (rucReceptor.length < 11) {
          rucReceptorError = true;
          rucReceptorErrorText = "Ingrese un número de RUC valido";
        } else {
          rucReceptorError = false;
          rucReceptorErrorText = "";
        }
      }
    }
    if (typeDoc === "40") {
      if (serie === "" || serie.length < 4) {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido";
      } else if (serie.toUpperCase().charAt(0) !== "T") {
        serieError = true;
        serieErrorText = "Ingrese un número de serie valido Ej:T001";
      } else {
        serieError = false;
        serieErrorText = "";
      }
    }
  }

  if (typeDoc && typeDoc !== "" && hasContingency) {
    if (serie === "" || serie.length < 4) {
      serieError = true;
      serieErrorText = "Ingrese un número de serie valido";
    } else if (
      serie.toUpperCase().charAt(0) !== "0" ||
      !/^[0-9]*$/.test(serie)
    ) {
      serieError = true;
      serieErrorText = "Ingrese un número de serie valido Ej: 0001";
    } else {
      serieError = false;
      serieErrorText = "";
    }
  }

  if (correlative === "") {
    correlativeError = true;
    correlativeErrorText = "Ingrese un número correlativo valido";
  } else {
    correlativeError = false;
    correlativeErrorText = "";
  }

  if (totalAmount === "") {
    totalAmountError = true;
    totalAmountErrorText = "Ingrese el monto total";
  } else {
    totalAmountError = false;
    totalAmountErrorText = "";
  }

  if (emissionDate === "") {
    emissionDateError = true;
    emissionDateErrorText = "Ingrese la fecha de emisión";
  } else {
    emissionDateError = false;
    emissionDateErrorText = "";
  }

  if (!recaptcha) {
    recaptchaError = true;
    recaptchaErrorText = "Aceptar la validación";
  } else {
    recaptchaError = false;
    recaptchaErrorText = "";
  }

  if (
    rucEmitterError ||
    typeDocError ||
    serieError ||
    correlativeError ||
    rucReceptorError ||
    totalAmountError ||
    emissionDateError ||
    recaptchaError ||
    (rucEmitterError &&
      typeDocError &&
      serieError &&
      correlativeError &&
      rucReceptorError &&
      totalAmountError &&
      emissionDateError &&
      recaptchaError)
  ) {
    return {
      rucEmitterError,
      rucEmitterErrorText,
      typeDocError,
      typeDocErrorText,
      serieError,
      serieErrorText,
      correlativeError,
      correlativeErrorText,
      rucReceptorError,
      rucReceptorErrorText,
      totalAmountError,
      totalAmountErrorText,
      emissionDateError,
      emissionDateErrorText,
      recaptchaError,
      recaptchaErrorText,
    };
  } else {
    return false;
  }
};

export const validatorPaymentForm = (
  form: IPaymentForm,
  error: IPaymentFormError
) => {
  let {
    cardCVCError,
    cardCVCErrorText,
    cardExpiryError,
    cardExpiryErrorText,
    cardNameError,
    cardNameErrorText,
    cardNumberError,
    cardNumberErrorText,
  } = error;
  const { cardCVC, cardExpiry, cardName, cardNumber } = form;

  if (!window.OpenPay.card.validateCardNumber(cardNumber)) {
    cardNumberError = true;
    cardNumberErrorText = "Número de tarjeta no válido";
  } else {
    cardNumberError = false;
    cardNumberErrorText = "";
  }

  if (cardName.trim() === "") {
    cardNameError = true;
    cardNameErrorText = "Nombre de tarjeta no válido";
  } else {
    cardNameError = false;
    cardNameErrorText = "";
  }

  if (
    !window.OpenPay.card.validateExpiry(
      cardExpiry.split("/")[0],
      cardExpiry.split("/")[1]
    )
  ) {
    cardExpiryError = true;
    cardExpiryErrorText = "Fecha de expiración no válido";
  } else {
    cardExpiryError = false;
    cardExpiryErrorText = "";
  }

  if (
    window.OpenPay.card.cardType(cardNumber) === "American Express" &&
    cardCVC.length === 4
  ) {
    cardCVCError = false;
    cardCVCErrorText = "";
  } else {
    if (
      cardCVC.length === 3 &&
      window.OpenPay.card.cardType(cardNumber) !== "American Express"
    ) {
      cardCVCError = false;
      cardCVCErrorText = "";
    } else {
      cardCVCError = false;
      cardCVCErrorText = "Código CVC no válido";
    }
  }

  if (
    cardNumberError ||
    cardCVCError ||
    cardExpiryError ||
    cardNameError ||
    (cardNumberError && cardCVCError && cardExpiryError && cardNameError)
  ) {
    return {
      cardCVCError,
      cardCVCErrorText,
      cardExpiryError,
      cardExpiryErrorText,
      cardNameError,
      cardNameErrorText,
      cardNumberError,
      cardNumberErrorText,
    };
  } else {
    return false;
  }
};
