import { store } from "./configureStore";

export const flushStorage = (autologout: boolean) => {
  // Se deben limpiar solo algunas variables
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { token: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { loading: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { workspace: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { userName: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { email: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { emissionStartDate: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { emissionEndDate: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { emittedFilterSerie: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { emittedFilterInitSeq: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { emittedFilterEndSeq: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { emittedPageCurrent: 0 } });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { autologout: autologout ? "true" : "false" },
  });

  store.dispatch({ type: "RESET_EMITTED_DOCS", payload: {} });
  store.dispatch({ type: "RESET_RECEIVED_DOCS", payload: {} });
  store.dispatch({ type: "RESET_UNADJUDICATED_DOCS", payload: {} });
  store.dispatch({ type: "RESET_COMPANIES", payload: {} });
  store.dispatch({ type: "RESET_REPORT_DOCS", payload: {} });

  ///
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { emittedClientId: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { emittedClientReport: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { receptionProveedorId: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { reportStartDate: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { reportEndDate: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { receptionStartDate: null } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { receptionEndDate: null } });

};
