import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { RButton, RButtonSecondary } from "../ratifikaElements";

interface Props {
  open: boolean;
  title?: string;
  body?: string;
  acceptBtnText?: string;
  handleClose: () => void;
  handleAccept: () => void;
}

export const ConfirmDialog: React.FC<Props> = ({
  open,
  title,
  body,
  acceptBtnText,
  handleClose,
  handleAccept,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {body && (
        <DialogContent style={{ minWidth: 500 }}>
          <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions style={{ padding: 20 }}>
        <RButtonSecondary onClick={handleClose} color="primary">
          Cancelar
        </RButtonSecondary>
        <RButton onClick={handleAccept} color="primary" variant="contained" autoFocus>
          {acceptBtnText || "Aceptar"}
        </RButton>
      </DialogActions>
    </Dialog>
  );
};
