import "./noteBox.css";

export enum NoteType {
  Warning = "warning",
  Error = "error",
  Info = "info",
  Successful = "successful",
  Transparent = "transparent",
}

interface Props {
  type: NoteType;
  title: string;
  body: string;
  details?: Array<string>;
}

export const NoteBox: React.FC<Props> = ({ type, title, body, details }) => {
  return (
    <div className={`note-box box-${type}`}>
      <div className={`box-container line-${type}`}>
        <span className="box-title">{title}</span>
        <span className="box-body">{body}</span>
        {details && (
          <ul className="box-detail">
            {details.map((d) => (
              <li className="box-body">{d}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
