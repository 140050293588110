import { Colors, Sizes } from "../../static/colors";
import styled from "styled-components";

export const ButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IconMenu = styled.div`
  display: none;
  @media ${Sizes.TABLET} {
    display: block;
    margin-right: -20px;
    cursor: pointer;
  }
`;

export const ItemsHeaderMobile = styled.div`
  p {
    font-size: 20px;
    margin: 20px 10px;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.2s ease;
    color: ${Colors.TEXT};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    text-align: end;
  }
`;

export const ItemsHeader = styled.div`
  display: flex;
  background: ${Colors.WHITE};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 50px;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 16px;
    margin: 0px 10px;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.2s ease;
    color: ${Colors.TEXT};
    display: flex;
    align-items: center;
    height: 100%;
  }
  @media ${Sizes.TABLET} {
    display: none;
  }
`;
