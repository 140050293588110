import React, { useEffect } from "react";
import { LocationOn, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Box,
  Slide,
  Typography,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  TextField,
  Paper,
  Card,
  CardContent,
  FormControlLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  RTextField,
  RButton,
  RButtonSecondary,
  RFormControl,
  RTab,
  RSwitch,
} from "../ratifikaElements";
import { useSelector } from "react-redux";
import { Colors } from "../../static/colors";
import { direcciones } from "../../static/ubigeo";
import { isValidTelephoneNumber } from "../../static/util";
import { CompanyInfo } from "../../store/types/companies";
import { ConfirmDialog } from "../confirmDialog";
import axios from "axios";
import { store } from "../../store/configureStore";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface Props {
  isOpen: boolean;
  close: (success: boolean) => void;
}
interface Ubicacion {
  code: string;
  departamento: string;
  provincia: string;
  distrito: string;
}
export const ConfigurationModal: React.FC<Props> = ({ isOpen, close }) => {
  const [companyRUC, setCompanyRUC] = React.useState("");
  const [legalName, setLegalName] = React.useState<string>("");
  const [tradeName, setTradeName] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [addressLine, setAddressLine] = React.useState<string>("");
  const [userSOL, setUserSOL] = React.useState<string>("");
  const [passwordSOL, setPasswordSOL] = React.useState<string>("");
  const [showSOLPassword, setShowSOLPassword] = React.useState(false);
  const [merchantId, setMerchantId] = React.useState<string>("");
  const [privateKey, setPrivateKey] = React.useState<string>("");
  const [publicKey, setPublicKey] = React.useState<string>("");
  const [currency, setCurrency] = React.useState<string>("PEN");
  const [showPrivateKey, setShowPrivateKey] = React.useState(false);
  const [showPublicKey, setShowPublicKey] = React.useState(false);

  const [errorMsgLegalName, setErrorMsgLegalName] = React.useState<
    string | null
  >(null);
  const [errorMsgTradeName, setErrorMsgTradeName] = React.useState<
    string | null
  >(null);
  const [errorMsgPhone, setErrorMsgPhone] = React.useState<string | null>(null);
  const [errorMsgAddressLine, setErrorMsgAddressLine] = React.useState<
    string | null
  >(null);

  const [hasLegalNameErrors, setHasLegalNameErrors] = React.useState(false);
  const [hasTradeNameErrors, setHasTradeNameErrors] = React.useState(false);
  const [hasPhoneErrors, setHasPhoneErrors] = React.useState(false);
  const [hasAddressLineErrors, setHasAddressLineErrors] = React.useState(false);

  const [hasUserSolErrors, setHasUserSolErrors] = React.useState(false);
  const [hasPassSolErrors, setHasPassSolErrors] = React.useState(false);
  const [errorMsgUserSOL, setErrorMsgUserSOL] = React.useState<string | null>(
    null
  );
  const [errorMsgPassSOL, setErrorMsgPassSOL] = React.useState<string | null>(
    null
  );

  const [successMsgTryingInfoUpdate, setSuccessMsgTryingInfoUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingInfoUpdate, setErrorMsgTryingInfoUpdate] =
    React.useState<string | null>(null);
  const [successMsgTryingSOLUpdate, setSuccessMsgTryingSOLUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingSOLUpdate, setErrorMsgTryingSOLUpdate] = React.useState<
    string | null
  >(null);
  const [successMsgTryingOpenPayUpdate, setSuccessMsgTryingOpenPayUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingOpenPayUpdate, setErrorMsgTryingOpenPayUpdate] =
    React.useState<string | null>(null);
  const [
    successMsgTryingEnableOpenPayUpdate,
    setSuccessMsgTryingEnableOpenPayUpdate,
  ] = React.useState<string | null>(null);
  const [
    errorMsgTryingEnableOpenPayUpdate,
    setErrorMsgTryingEnableOpenPayUpdate,
  ] = React.useState<string | null>(null);

  const [openConfirmDialogInfo, setOpenConfirmDialogInfo] =
    React.useState<boolean>(false);
  const [openConfirmDialogSOL, setOpenConfirmDialogSOL] =
    React.useState<boolean>(false);
  const [openConfirmDialogOpenPay, setOpenConfirmDialogOpenPay] =
    React.useState<boolean>(false);
  const [loadingInfo, setLoadingInfo] = React.useState<boolean>(false);
  const [loadingSOL, setLoadingSOL] = React.useState<boolean>(false);
  const [loadingOpenPay, setLoadingOpenPay] = React.useState<boolean>(false);
  const [disableSwitchOpenPay, setDisableSwitchOpenPay] =
    React.useState<boolean>(false);

  const [direccionUbigeo, setDireccionUbigeo] = React.useState<
    string | undefined
  >("");
  const [selectUbigeo, setSelectUbigeo] = React.useState<Ubicacion | null>(
    null
  );
  const [openConfirmDialogEnableOpenPay, setOpenConfirmDialogEnableOpenPay] =
    React.useState<boolean>(false);
  const [hasMerchantIdError, setHasMerchantIdErrors] = React.useState(false);
  const [errorMsgMerchantId, setErrorMsgMerchantId] = React.useState<
    string | null
  >(null);
  const [hasPrivateKeyError, setHasPrivateKeyErrors] = React.useState(false);
  const [errorMsgPrivateKey, setErrorMsgPrivateKey] = React.useState<
    string | null
  >(null);
  const [hasPublicKeyError, setHasPublicKeyErrors] = React.useState(false);
  const [errorMsgPublicKey, setErrorMsgPublicKey] = React.useState<
    string | null
  >(null);

  const saveInfo = async () => {
    if (!loadingInfo) {
      // close confirm dialog
      setOpenConfirmDialogInfo(false);
      // set loading
      setLoadingInfo(true);
      // clear messages status
      clearMsgs();

      if (
        checkLegalName() &&
        checkTradeName() &&
        checkPhoneNumber() &&
        checkAddressLine() &&
        direccionUbigeo
      ) {
        const body: any = {
          ruc: companyRUC,
          legal_name: legalName,
          trade_name: tradeName,
          phone_number: phoneNumber,
          address: addressLine,
          ubigeo: direccionUbigeo,
        };
        let { data } = await axios.post("/api/company/update", body);
        if (data.result) {
          let company: CompanyInfo = companies.find(
            (c: CompanyInfo) => c.ruc === body.ruc
          );
          company.legal_name = body.legal_name;
          company.trade_name = body.trade_name;
          company.phone_number = body.phone_number;
          company.address_line = body.address;
          company.ubigeo = body.ubigeo;
          store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
          setSuccessMsgTryingInfoUpdate("Información actualizada");
        } else {
          setErrorMsgTryingInfoUpdate(
            `Ha ocurrido un error inesperado: ${data.msg}`
          );
        }
      } else {
        setErrorMsgTryingInfoUpdate(
          "Debe llenear todos los campos correctamente"
        );
      }

      setLoadingInfo(false);
    }
  };

  const saveSOL = async () => {
    if (!loadingSOL) {
      // close confirm dialog
      setOpenConfirmDialogSOL(false);
      // set loading
      setLoadingSOL(true);
      // clear messages status
      clearMsgs();
      // validate user & pass
      if (checkUserSOL() && checkPasswordSOL()) {
        // axios data
        let body: any = {};
        body["ruc"] = companyRUC;
        body["user_sol"] = userSOL;
        body["password_sol"] = passwordSOL;
        // axios request
        let { data } = await axios.post(
          "/api/company/solCredentials/update",
          body
        );
        // check axios result
        if (data.result) {
          let company: CompanyInfo = companies.find(
            (c: CompanyInfo) => c.ruc === body.ruc
          );
          company.user_sol = body.user_sol;
          store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
          setSuccessMsgTryingSOLUpdate("Credenciales actualizadas");
        } else {
          setErrorMsgTryingSOLUpdate(
            `Ha ocurrido un error inesperado: ${data.msg}`
          );
        }
      } else {
        setErrorMsgTryingSOLUpdate(
          "Las credenciales SOL no tienen un formato correcto"
        );
      }

      setLoadingSOL(false);
    }
  };

  const saveOpenPay = async () => {
    if (!loadingOpenPay) {
      // close confirm dialog
      setOpenConfirmDialogOpenPay(false);
      // set loading
      setLoadingOpenPay(true);
      // clear messages status
      clearMsgs();
      // axios data
      let body: any = {};
      body["ruc"] = companyRUC;
      body["currency"] = currency;
      body["merchantId"] = merchantId;
      body["privateKey"] = privateKey;
      body["publicKey"] = publicKey;
      let { data } = await axios.post(
        "/api/company/openpayCredentials/update",
        body
      );

      // check axios result
      if (data.result) {
        let company: CompanyInfo = companies.find(
          (c: CompanyInfo) => c.ruc === body.ruc
        );
        if (!company.credentials_openpay) {
          company.credentials_openpay = {
            PEN: { merchantId: "", privateKey: "", publicKey: "" },
            USD: { merchantId: "", privateKey: "", publicKey: "" },
          };
        }
        company.credentials_openpay[body.currency]["merchantId"] =
          body.merchantId;
        company.credentials_openpay[body.currency]["privateKey"] =
          body.privateKey;
        company.credentials_openpay[body.currency]["publicKey"] =
          body.publicKey;
        store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
        setSuccessMsgTryingOpenPayUpdate("Credenciales actualizadas");
      } else {
        setErrorMsgTryingOpenPayUpdate(
          `Ha ocurrido un error inesperado: ${data.msg}`
        );
      }

      setLoadingOpenPay(false);
    }
  };

  const saveEnableOpenPay = async () => {
    let body: any = {};
    body["ruc"] = companyRUC;
    body["property"] = "openPay";
    body["value"] = "YES";
    let { data } = await axios.post("/api/company/customize/update", body);
    // check axios result
    if (data.result) {
      let company: CompanyInfo = companies.find(
        (c: CompanyInfo) => c.ruc === body.ruc
      );
      company.customize["openPay"] = "YES";
      store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
      setDisableSwitchOpenPay(true);
      setSuccessMsgTryingEnableOpenPayUpdate("Credenciales habilitadas");
    } else {
      setErrorMsgTryingEnableOpenPayUpdate(
        `Ha ocurrido un error inesperado: ${data.msg}`
      );
    }
    setOpenConfirmDialogEnableOpenPay(false);
  };

  const handleCurrencyChange = (event: React.ChangeEvent<{ value: any }>) => {
    let company: CompanyInfo = companies.find(
      (c: CompanyInfo) => c.ruc.toString() === companyRUC
    );
    setCurrency(event.target.value);
    if (company.credentials_openpay) {
      setMerchantId(
        company.credentials_openpay[event.target.value]["merchantId"]
      );
      setPrivateKey(
        company.credentials_openpay[event.target.value]["privateKey"]
      );
      setPublicKey(
        company.credentials_openpay[event.target.value]["publicKey"]
      );
    }
  };

  const handleClickShowSOLPassword = () => {
    setShowSOLPassword(!showSOLPassword);
  };

  const handleMouseDownSOLPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPrivateKey = () => {
    setShowPrivateKey(!showPrivateKey);
  };

  const handleMouseDownPrivateKey = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPublicKey = () => {
    setShowPublicKey(!showPublicKey);
  };

  const handleMouseDownPublicKey = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setCompanyData(companies[newValue]);
  };

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenConfirmDialogEnableOpenPay(event.target.checked);
  };

  const setCompanyData = (company: CompanyInfo) => {
    // clear messages status
    clearMsgs();
    // companyRUC
    setCompanyRUC(company.ruc.toString());
    // legal name
    setLegalName(company.legal_name);
    // trade name
    setTradeName(company.trade_name);
    // phone number
    setPhoneNumber(company.phone_number);
    // address
    setAddressLine(company.address_line);
    //Ubigeo

    const codeUbigeo = direcciones.find((e) => e.code === company.ubigeo);
    if (codeUbigeo) {
      setSelectUbigeo(codeUbigeo);
      setDireccionUbigeo(codeUbigeo.code);
    }

    // SOL
    if (!company.ose) {
      // user sol
      setUserSOL(company.user_sol);
      // password
      setPasswordSOL("");
      setErrorMsgPassSOL(null);
      setHasPassSolErrors(false);
    }
    // credentials OPENPAY
    if (company.credentials_openpay) {
      setMerchantId(company.credentials_openpay[currency]["merchantId"]);
      setPrivateKey(company.credentials_openpay[currency]["privateKey"]);
      setPublicKey(company.credentials_openpay[currency]["publicKey"]);
    }
  };

  const checkLegalName = () => {
    // clear messages status
    clearMsgs();
    if (legalName.trim() === "" || legalName.trim().length <= 3) {
      setHasLegalNameErrors(true);
      setErrorMsgLegalName("Debes especificar un Nombre Legal correcto");
      return false;
    } else {
      setErrorMsgLegalName(null);
      setHasLegalNameErrors(false);
      return true;
    }
  };

  const checkTradeName = () => {
    // clear messages status
    clearMsgs();
    if (tradeName.trim() === "" || tradeName.trim().length <= 3) {
      setHasTradeNameErrors(true);
      setErrorMsgTradeName("Debes especificar un Nombre Comercial correcto");
      return false;
    } else {
      setErrorMsgTradeName(null);
      setHasTradeNameErrors(false);
      return true;
    }
  };

  const checkPhoneNumber = () => {
    // clear messages status
    clearMsgs();
    if (phoneNumber.trim() === "" || !isValidTelephoneNumber(phoneNumber)) {
      setHasPhoneErrors(true);
      setErrorMsgPhone("Debes especificar un número de contacto correcto");
      return false;
    } else {
      setErrorMsgPhone(null);
      setHasPhoneErrors(false);
      return true;
    }
  };

  const checkAddressLine = () => {
    // clear messages status
    clearMsgs();
    if (addressLine.trim() === "" || addressLine.trim().length <= 3) {
      setHasAddressLineErrors(true);
      setErrorMsgAddressLine("Debes especificar un Domicilio fiscal correcto");
      return false;
    } else {
      setErrorMsgAddressLine(null);
      setHasAddressLineErrors(false);
      return true;
    }
  };

  const checkUserSOL = () => {
    // clear messages status
    clearMsgs();
    if (userSOL.trim() === "" || userSOL.length !== 8) {
      setHasUserSolErrors(true);
      setErrorMsgUserSOL("El Usuario SOL debe contener 8 caracteres");
      return false;
    } else {
      setErrorMsgUserSOL(null);
      setHasUserSolErrors(false);
      return true;
    }
  };

  const checkPasswordSOL = () => {
    // clear messages status
    clearMsgs();
    if (
      passwordSOL.trim() === "" ||
      passwordSOL.length < 8 ||
      passwordSOL.length > 12
    ) {
      setHasPassSolErrors(true);
      setErrorMsgPassSOL("La Clave SOL debe contener entre 8 y 12 caracteres");
      return false;
    } else {
      setErrorMsgPassSOL(null);
      setHasPassSolErrors(false);
      return true;
    }
  };

  const checkMerchantId = () => {
    // clear messages status
    clearMsgs();
    if (merchantId.trim() === "") {
      setHasMerchantIdErrors(true);
      setErrorMsgMerchantId(
        "Debes especificar un identificador de OPENPAY correcto"
      );
      return false;
    } else {
      setHasMerchantIdErrors(false);
      setErrorMsgMerchantId(null);
      return true;
    }
  };

  const checkPrivateKey = () => {
    // clear messages status
    clearMsgs();
    if (privateKey.trim() === "") {
      setHasPrivateKeyErrors(true);
      setErrorMsgPrivateKey(
        "Debes especificar una llave privada de OPENPAY correcto"
      );
      return false;
    } else {
      setHasPrivateKeyErrors(false);
      setErrorMsgPrivateKey(null);
      return true;
    }
  };

  const checkPublicKey = () => {
    // clear messages status
    clearMsgs();
    if (publicKey.trim() === "") {
      setHasPublicKeyErrors(true);
      setErrorMsgPublicKey(
        "Debes especificar una llave publica de OPENPAY correcto"
      );
      return false;
    } else {
      setHasPublicKeyErrors(false);
      setErrorMsgPublicKey(null);
      return true;
    }
  };

  const clearMsgs = () => {
    setSuccessMsgTryingInfoUpdate("");
    setErrorMsgTryingInfoUpdate("");
    setSuccessMsgTryingSOLUpdate("");
    setErrorMsgTryingSOLUpdate("");
    setSuccessMsgTryingOpenPayUpdate("");
    setErrorMsgTryingOpenPayUpdate("");
  };

  const companies = useSelector((state: any) => state.companiesInfo);
  const workspace = useSelector((state: any) => state.config.workspace);

  useEffect(() => {
    if (!companyRUC) {
      const current_company = companies.find(
        (c: CompanyInfo) => c.ruc === workspace
      );
      if (current_company) {
        setCompanyData(current_company);
      }
    }
  });

  function a11yProps(index: any) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <Dialog
      open={isOpen}
      scroll={"paper"}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => close(true)}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-slide-title" key={Date.now() + 2}>
        {"Configuración"}
      </DialogTitle>
      <DialogContent dividers={true} style={{ height: "39em", padding: 5 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            style={{ borderRight: `1px solid ${Colors.border}` }}
          >
            {companies.map((company: CompanyInfo, index: number) => (
              <RTab
                label={company.legal_name}
                /* wrapped */
                style={{ textAlign: "left", justifyContent: "flex-start" }}
                {...a11yProps(index)}
                key={index}
              />
            ))}
          </Tabs>

          {companies.map((company: CompanyInfo, index: number) => (
            <TabPanel value={value} index={index} key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 600,
                }}
              >
                <h3
                  style={{
                    margin: 0,
                    fontWeight: "normal",
                    fontSize: 20,
                  }}
                >
                  Informaci&oacute;n de la empresa
                </h3>
                <Typography
                  style={{ marginBottom: 20 }}
                  color="textSecondary"
                  variant="caption"
                >
                  Agrega los datos requeridos para la facturación
                </Typography>
                <RTextField
                  id="company-name"
                  label="Nombre legal"
                  variant="outlined"
                  size="small"
                  required
                  autoComplete="company"
                  style={{ marginBottom: 20 }}
                  helperText={errorMsgLegalName}
                  error={hasLegalNameErrors}
                  value={legalName}
                  onChange={(e) => {
                    setLegalName(e.target.value);
                    checkLegalName();
                  }}
                />
                <RTextField
                  id="company-comercial-name"
                  label="Nombre comercial"
                  variant="outlined"
                  size="small"
                  required
                  autoComplete="company"
                  style={{ marginBottom: 15 }}
                  helperText={
                    errorMsgTradeName ||
                    "Es el nombre que usaremos para presentarte ante tus clientes"
                  }
                  error={hasTradeNameErrors}
                  value={tradeName}
                  onChange={(e) => {
                    setTradeName(e.target.value);
                    checkTradeName();
                  }}
                />
                <RTextField
                  id="company-phone"
                  label="Teléfono de contacto"
                  variant="outlined"
                  size="small"
                  required
                  autoComplete="phone"
                  style={{ marginBottom: 20, width: 250 }}
                  helperText={errorMsgPhone}
                  error={hasPhoneErrors}
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    checkPhoneNumber();
                  }}
                />
                <RTextField
                  id="company-address"
                  label="Domicilio fiscal"
                  variant="outlined"
                  size="small"
                  required
                  autoComplete="address"
                  style={{ marginBottom: 25 }}
                  helperText={errorMsgAddressLine}
                  error={hasAddressLineErrors}
                  value={addressLine}
                  onChange={(e) => {
                    setAddressLine(e.target.value);
                    checkAddressLine();
                  }}
                />
                <div style={{ paddingBottom: "10px" }}>
                  <Autocomplete
                    id="combo-box"
                    options={direcciones}
                    getOptionLabel={(option) =>
                      `${option.departamento.toUpperCase()}-${option.provincia.toUpperCase()}-${option.distrito.toUpperCase()}`
                    }
                    fullWidth={true}
                    popupIcon={<LocationOn />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ubigeo"
                        placeholder="Buscar..."
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        <Paper style={{ width: "100%" }}>
                          <div style={{ padding: "5px" }}>
                            {option.departamento.toUpperCase()}-
                            {option.provincia.toUpperCase()}-
                            {option.distrito.toUpperCase()}
                            <div>
                              <small style={{ color: "#777" }}>
                                {option.code}
                              </small>
                            </div>
                          </div>
                        </Paper>
                      </React.Fragment>
                    )}
                    PaperComponent={({ children }) => (
                      <Card style={{ background: "#f3f3f3" }}>
                        <CardContent>{children}</CardContent>
                      </Card>
                    )}
                    size="small"
                    value={selectUbigeo}
                    onChange={(event, value) => {
                      setSelectUbigeo(value);
                      setDireccionUbigeo(value?.code);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {(errorMsgTryingInfoUpdate !== "" ||
                    successMsgTryingInfoUpdate !== "") && (
                    <InputLabel
                      style={{
                        color:
                          errorMsgTryingInfoUpdate !== "" ? "#f44336" : "green",
                        fontSize: "0.75rem",
                        marginLeft: 14,
                        marginTop: 8,
                      }}
                    >
                      {errorMsgTryingInfoUpdate}
                      {successMsgTryingInfoUpdate}
                    </InputLabel>
                  )}
                  <RButton
                    variant="contained"
                    onClick={() => {
                      if (
                        checkLegalName() &&
                        checkTradeName() &&
                        checkPhoneNumber() &&
                        checkAddressLine() &&
                        direccionUbigeo
                      ) {
                        setOpenConfirmDialogInfo(true);
                      }
                    }}
                    style={{ marginLeft: 10 }}
                    disabled={loadingInfo}
                  >
                    {!loadingInfo ? (
                      "Guardar"
                    ) : (
                      <>
                        <CircularProgress
                          variant="indeterminate"
                          size={14}
                          style={{ marginRight: 5 }}
                        />
                        Actualizando ...
                      </>
                    )}
                  </RButton>
                </div>
              </div>
              {!company.ose ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 600,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      height: 1,
                      marginBottom: 20,
                      marginTop: 15,
                    }}
                  ></div>
                  <h3
                    style={{
                      margin: 0,
                      fontWeight: "normal",
                      fontSize: 20,
                    }}
                  >
                    Clave SOL
                  </h3>
                  <Typography
                    style={{ marginBottom: 20 }}
                    color="textSecondary"
                    variant="caption"
                  >
                    Agrega el usuario/clave SOL asociado a la empresa
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ marginRight: 10, marginBottom: 20 }}>
                      <RTextField
                        id="user-sol"
                        label="Usuario SOL"
                        required
                        variant="outlined"
                        size="small"
                        autoComplete="name"
                        style={{ marginBottom: 25, width: 300 }}
                        helperText={errorMsgUserSOL}
                        error={hasUserSolErrors}
                        value={userSOL}
                        onChange={(e) => {
                          setUserSOL(e.target.value);
                          checkUserSOL();
                        }}
                      />
                    </div>
                    <div>
                      <RFormControl variant="outlined">
                        <InputLabel
                          htmlFor="outlined-sol-password"
                          style={{
                            marginTop: -5,
                            paddingRight: 10,
                            paddingBottom: 10,
                            backgroundColor: "white",
                          }}
                        >
                          Clave SOL *
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-sol-password"
                          type={showSOLPassword ? "text" : "password"}
                          style={{ height: 45, width: 300 }}
                          error={hasPassSolErrors}
                          value={passwordSOL}
                          onChange={(e) => {
                            setPasswordSOL(e.target.value);
                            checkPasswordSOL();
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowSOLPassword}
                                onMouseDown={handleMouseDownSOLPassword}
                                edge="end"
                              >
                                {showSOLPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </RFormControl>
                      {errorMsgPassSOL !== "" && (
                        <InputLabel
                          style={{
                            color: "#f44336",
                            fontSize: "0.75rem",
                            marginLeft: 14,
                            marginTop: 8,
                          }}
                        >
                          {errorMsgPassSOL}
                        </InputLabel>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {(errorMsgTryingSOLUpdate !== "" ||
                      successMsgTryingSOLUpdate !== "") && (
                      <InputLabel
                        style={{
                          color:
                            errorMsgTryingSOLUpdate !== ""
                              ? "#f44336"
                              : "green",
                          fontSize: "0.75rem",
                          marginLeft: 14,
                          marginTop: 8,
                        }}
                      >
                        {errorMsgTryingSOLUpdate}
                        {successMsgTryingSOLUpdate}
                      </InputLabel>
                    )}
                    <RButton
                      variant="contained"
                      onClick={() => {
                        if (checkUserSOL() && checkPasswordSOL()) {
                          setOpenConfirmDialogSOL(true);
                        }
                      }}
                      style={{ marginLeft: 10 }}
                      disabled={loadingSOL}
                    >
                      {!loadingSOL ? (
                        "Guardar"
                      ) : (
                        <>
                          <CircularProgress
                            variant="indeterminate"
                            size={14}
                            style={{ marginRight: 5 }}
                          />
                          Actualizando ...
                        </>
                      )}
                    </RButton>
                  </div>
                </div>
              ) : null}
              {company.customize.openPay === "YES" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 600,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      height: 1,
                      marginBottom: 20,
                      marginTop: 15,
                    }}
                  ></div>
                  <h3
                    style={{
                      margin: 0,
                      fontWeight: "normal",
                      fontSize: 20,
                    }}
                  >
                    Clave OPENPAY
                  </h3>
                  <Typography
                    style={{ marginBottom: 20 }}
                    color="textSecondary"
                    variant="caption"
                  >
                    Agrega el identificador/llaves OPENPAY asociado a la empresa
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <RFormControl variant="outlined">
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          marginTop: -5,
                          paddingRight: 10,
                          paddingBottom: 10,
                          backgroundColor: "white",
                        }}
                      >
                        Tipo de Moneda
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currency}
                        onChange={handleCurrencyChange}
                        style={{ width: 300, marginRight: 10, height: 45 }}
                      >
                        <MenuItem value="PEN">Soles</MenuItem>
                        <MenuItem value="USD">Dolares</MenuItem>
                      </Select>
                    </RFormControl>
                    <RTextField
                      id="identificador-openpay"
                      label="Identificador OPENPAY"
                      required
                      variant="outlined"
                      size="small"
                      autoComplete="name"
                      style={{ height: 45, width: 300 }}
                      helperText={errorMsgMerchantId}
                      error={hasMerchantIdError}
                      value={merchantId}
                      onChange={(e) => {
                        setMerchantId(e.target.value);
                        checkMerchantId();
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <div style={{ marginRight: 10 }}>
                      <RFormControl variant="outlined">
                        <InputLabel
                          htmlFor="outlined-openpay-private-key"
                          style={{
                            marginTop: -5,
                            paddingRight: 10,
                            paddingBottom: 10,
                            backgroundColor: "white",
                          }}
                        >
                          Llave privada OPENPAY *
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-openpay-private-key"
                          type={showPrivateKey ? "text" : "password"}
                          style={{ height: 45, width: 300 }}
                          error={hasPrivateKeyError}
                          value={privateKey}
                          onChange={(e) => {
                            setPrivateKey(e.target.value);
                            checkPrivateKey();
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPrivateKey}
                                onMouseDown={handleMouseDownPrivateKey}
                                edge="end"
                              >
                                {showPrivateKey ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </RFormControl>
                      {errorMsgPrivateKey !== "" && (
                        <InputLabel
                          style={{
                            color: "#f44336",
                            fontSize: "0.75rem",
                            marginLeft: 14,
                            marginTop: 8,
                          }}
                        >
                          {errorMsgPrivateKey}
                        </InputLabel>
                      )}
                    </div>
                    <div>
                      <RFormControl variant="outlined">
                        <InputLabel
                          htmlFor="outlined-openpay-public-key"
                          style={{
                            marginTop: -5,
                            paddingRight: 10,
                            paddingBottom: 10,
                            backgroundColor: "white",
                          }}
                        >
                          Llave publica OPENPAY *
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-openpay-publica-key"
                          type={showPublicKey ? "text" : "password"}
                          style={{ height: 45, width: 300 }}
                          error={hasPublicKeyError}
                          value={publicKey}
                          onChange={(e) => {
                            setPublicKey(e.target.value);
                            checkPublicKey();
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPublicKey}
                                onMouseDown={handleMouseDownPublicKey}
                                edge="end"
                              >
                                {showPublicKey ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </RFormControl>
                      {errorMsgPublicKey !== "" && (
                        <InputLabel
                          style={{
                            color: "#f44336",
                            fontSize: "0.75rem",
                            marginLeft: 14,
                            marginTop: 8,
                          }}
                        >
                          {errorMsgPublicKey}
                        </InputLabel>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {(errorMsgTryingOpenPayUpdate !== "" ||
                      successMsgTryingOpenPayUpdate !== "") && (
                      <InputLabel
                        style={{
                          color:
                            errorMsgTryingOpenPayUpdate !== ""
                              ? "#f44336"
                              : "green",
                          fontSize: "0.75rem",
                          marginLeft: 14,
                          marginTop: 8,
                        }}
                      >
                        {errorMsgTryingOpenPayUpdate}
                        {successMsgTryingOpenPayUpdate}
                      </InputLabel>
                    )}
                    <RButton
                      variant="contained"
                      onClick={() => {
                        if (
                          checkMerchantId() &&
                          checkPrivateKey() &&
                          checkPublicKey()
                        ) {
                          setOpenConfirmDialogOpenPay(true);
                        }
                      }}
                      style={{ marginLeft: 10 }}
                      disabled={loadingOpenPay}
                    >
                      {!loadingOpenPay ? (
                        "Guardar"
                      ) : (
                        <>
                          <CircularProgress
                            variant="indeterminate"
                            size={14}
                            style={{ marginRight: 5 }}
                          />
                          Actualizando ...
                        </>
                      )}
                    </RButton>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      height: 1,
                      marginBottom: 20,
                      marginTop: 15,
                    }}
                  ></div>
                  <FormControlLabel
                    value="end"
                    control={
                      <RSwitch
                        color="primary"
                        checked={openConfirmDialogEnableOpenPay}
                        onChange={handleChangeChecked}
                        disabled={disableSwitchOpenPay}
                      />
                    }
                    label="Habilitar credenciales OPENPAY"
                    labelPlacement="end"
                  />
                  {(errorMsgTryingEnableOpenPayUpdate !== "" ||
                    successMsgTryingEnableOpenPayUpdate !== "") && (
                    <InputLabel
                      style={{
                        color:
                          errorMsgTryingEnableOpenPayUpdate !== ""
                            ? "#f44336"
                            : "green",
                        fontSize: "0.75rem",
                        marginLeft: 14,
                        marginTop: 8,
                      }}
                    >
                      {errorMsgTryingEnableOpenPayUpdate}
                      {successMsgTryingEnableOpenPayUpdate}
                    </InputLabel>
                  )}
                </div>
              )}
            </TabPanel>
          ))}
        </div>
        <ConfirmDialog
          title="¿Desea actualizar esta información?"
          body="Los datos asociados a esta empresa serán modificados"
          acceptBtnText="Confirmar"
          open={openConfirmDialogInfo}
          handleClose={() => setOpenConfirmDialogInfo(false)}
          handleAccept={saveInfo}
        />
        <ConfirmDialog
          title="¿Desea actualizar las credenciales de SOL?"
          body="Las credenciales de SOL de esta empresa serán modificadas"
          acceptBtnText="Confirmar"
          open={openConfirmDialogSOL}
          handleClose={() => setOpenConfirmDialogSOL(false)}
          handleAccept={saveSOL}
        />
        <ConfirmDialog
          title="¿Desea actualizar las credenciales de OPENPAY?"
          body="Las credenciales de OPENPAY de esta empresa serán modificadas"
          acceptBtnText="Confirmar"
          open={openConfirmDialogOpenPay}
          handleClose={() => setOpenConfirmDialogOpenPay(false)}
          handleAccept={saveOpenPay}
        />
        <ConfirmDialog
          title="¿Desea habilitar las credenciales de OPENPAY?"
          body="Las credenciales de OPENPAY de esta empresa serán habilitadas"
          acceptBtnText="Confirmar"
          open={openConfirmDialogEnableOpenPay}
          handleClose={() => setOpenConfirmDialogEnableOpenPay(false)}
          handleAccept={saveEnableOpenPay}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <RButtonSecondary onClick={() => close(true)}>Cerrar</RButtonSecondary>
      </DialogActions>
    </Dialog>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      key={value}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
