import React from "react";
import PublicFooter from "../components/publicFooter";
import PublicHeader from "../components/publicHeader";
import TableConsultResult from "../components/tableConsultResult";
import { Colors } from "../static/colors";

const ConsultResult = () => {
  return (
    <div style={{ position: "relative" }}>
      <PublicHeader color={Colors.GREEN} viewMenu={false} />
      <TableConsultResult />
      <PublicFooter color={Colors.GREEN} />
    </div>
  );
};

export default ConsultResult;
