import styled from "styled-components";
import { Colors, Sizes } from "../../static/colors";

export const ListFiles = styled.ul`
  overflow-y: scroll;
  max-height: 100px;
  margin: 10px 0px;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
`;

export const ExistLabel = styled.span`
  color: #af6262;
  font-size: smaller;
  font-style: italic;
`;

export const ResultDetails = styled.li`
  color: #8f8f8f;
  font-size: small;
  list-style: none;
`;

export const FileItem = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px 10px 0px;
`;

export const Dropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border: 2px dashed #7e7e7e;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  padding: 30px 20px;
  cursor: pointer;
  height: 270px;
  margin-top: 5px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1em;
`;

export const ContainerCard = styled.div`
  padding: 30px;
  width: 400px;
  margin: 30px;
  min-height: 340px;
  background: ${Colors.GREY_1};
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  position: absolute;
  top: 15%;
  left: 1%;
  z-index: 999;

  @media ${Sizes.MOBILE} {
    width: 300px;
    margin: 30px;
    min-height: 350px;
    top: 10%;
  }
`;
