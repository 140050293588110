import { Sizes } from "../../static/colors";
import styled from "styled-components";

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerInfoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const ContainerCard = styled.div`
  max-width: 900px;
  margin: 20px auto;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 4px 8px #679b60;
  border-radius: 10px;

  @media ${Sizes.TABLET} {
    box-shadow: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
`;
