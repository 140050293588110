import React from "react";
import { Menu, MenuItem, ListItemText } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { setConfigParam } from "../../store/actions/config";
import { CompanyInfo } from "../../store/types/companies";
import { fetch } from "../../store/fetch";

interface Props {
  anchorEl: null | HTMLElement;
  onCloseMenu: () => void;
}

export const CompaniesMenu: React.FC<Props> = ({ anchorEl, onCloseMenu }) => {
  const companies = useSelector((state: any) => state.companiesInfo);
  
  const config = useSelector((state: any) => state.config);
  const dispatch = useDispatch();

  const handleSelectCompany = (event: React.MouseEvent<HTMLElement>, workspace: number) => {
    dispatch(setConfigParam("workspace", workspace));
    onCloseMenu();
    dispatch(setConfigParam("emittedPageCurrent", 0)); // Se resetea el paginado
    fetch(["reception", "emission","reports"]);
  };

  return (
    <Menu
      id="lock-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onCloseMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {companies.map((c: CompanyInfo) => (
        <MenuItem
          key={c.ruc}
          disabled={c.status === "NEW"}
          selected={c.ruc === config.workspace}
          onClick={(event) => handleSelectCompany(event, c.ruc)}
        >
          {c.status === "NEW" ? (
            <ListItemText
              secondary={<span style={{ fontStyle: "italic" }}>Pendiente de validación</span>}
              primary={c.legal_name}
            />
          ) : (
            <ListItemText secondary={c.ruc} primary={c.legal_name} />
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};
