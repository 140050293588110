import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Slide,
  InputLabel,
  CircularProgress,
  Card,
  CardContent,
  useMediaQuery,
  Grid,
  CardActions,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { useSelector } from "react-redux";
import { Colors, Sizes } from "../../static/colors";
import { CompanyInfo } from "../../../domain/store/types/companies";
import ConfirmDialog from "../confirmDialog";
import axios from "axios";
import { store } from "../../../domain/store/configureStore";
import TextStyle from "../textStyle";
import { GreenRadio, InputNumber, InputPassword, InputText } from "../input";
import { ButtonPrimary, ButtonSecondary } from "../button";
import CustomSelect from "../select";
import CustomSwitch from "../switch";
import logoOpenpay from "../../assets/imgs/logoopenpay.jpg";
import { useForm } from "src/infrastructure/hooks/useForm";
import countries from "../../assets/data/paises.json";

interface Props {
  activeIndex: number;
}

export const CompanyIntegrations: React.FC<Props> = ({ activeIndex }) => {
  const [companyRUC, setCompanyRUC] = React.useState("");
  const [userSOL, setUserSOL] = React.useState<string>("");
  const [passwordSOL, setPasswordSOL] = React.useState<string>("");
  const [credentialOpenpay, setCredentialOpenpay] = React.useState("");
  const [credentialInfobip, setCredentialInfobip] = React.useState("");
  const [senderList, setSenderList ] = React.useState([]);
  const [merchantId, setMerchantId] = React.useState<string>("");
  const [privateKey, setPrivateKey] = React.useState<string>("");
  const [publicKey, setPublicKey] = React.useState<string>("");
  const [baseUrl, setBaseUrl] = React.useState<string>("");
  const [apiKeyName, setApiKeyName] = React.useState<string>("");
  const [infobipUsername, setInfobipUsername] = React.useState<string>("");
  const [infobipPassword, setInfobipPassword] = React.useState<string>("");
  const [apiKey, setApiKey] = React.useState<string>("");
  const [currency, setCurrency] = React.useState<string>("PEN");
  const [hasUserSolErrors, setHasUserSolErrors] = React.useState(false);
  const [hasPassSolErrors, setHasPassSolErrors] = React.useState(false);
  const [errorMsgUserSOL, setErrorMsgUserSOL] = React.useState<string | null>(
    null
  );
  const [errorMsgPassSOL, setErrorMsgPassSOL] = React.useState<string | null>(
    null
  );
  const [hasClientIdSunatErrors, setHasClientIdSunatErrors] =
    React.useState(false);
  const [errorMsgClientIdSunat, setErrorMsgClientIdSunat] = React.useState<
    string | null
  >(null);
  const [hasClientSecretSunatErrors, setHasClientSecretSunatErrors] =
    React.useState(false);
  const [errorMsgClientSecretSunat, setErrorMsgClientSecretSunat] =
    React.useState<string | null>(null);
  const [successMsgTryingSOLUpdate, setSuccessMsgTryingSOLUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingSOLUpdate, setErrorMsgTryingSOLUpdate] = React.useState<
    string | null
  >(null);
  const [successMsgTryingGUIAUpdate, setSuccessMsgTryingGUIAUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingGUIAUpdate, setErrorMsgTryingGUIAUpdate] =
    React.useState<string | null>(null);
  const [successMsgTryingOpenPayUpdate, setSuccessMsgTryingOpenPayUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingOpenPayUpdate, setErrorMsgTryingOpenPayUpdate] =
    React.useState<string | null>(null);
  const [successMsgTryingInfobipUpdate, setSuccessMsgTryingInfobipUpdate] =
    React.useState<string | null>(null);
  const [errorMsgTryingInfobipUpdate, setErrorMsgTryingInfobipUpdate] =
    React.useState<string | null>(null);
  const [
    successMsgTryingEnableOpenPayUpdate,
    setSuccessMsgTryingEnableOpenPayUpdate,
  ] = React.useState<string | null>(null);
  const [
    errorMsgTryingEnableOpenPayUpdate,
    setErrorMsgTryingEnableOpenPayUpdate,
  ] = React.useState<string | null>(null);
  const [
    successMsgTryingEnableInfobipUpdate,
    setSuccessMsgTryingEnableInfobipUpdate,
  ] = React.useState<string | null>(null);
  const [
    errorMsgTryingEnableInfobipUpdate,
    setErrorMsgTryingEnableInfobipUpdate,
  ] = React.useState<string | null>(null);
  const [
    successMsgTryingEnableGuiasUpdate,
    setSuccessMsgTryingEnableGuiasUpdate,
  ] = React.useState<string | null>(null);
  const [errorMsgTryingEnableGuiasUpdate, setErrorMsgTryingEnableGuiasUpdate] =
    React.useState<string | null>(null);
  const [
    successMsgTryingGenerateApiKeyInfobip,
    setSuccessMsgTryingGenerateApiKeyInfobip,
  ] = React.useState<string | null>(null);
  const [errorMsgTryingGenerateApiKeyInfobip, setErrorMsgTryingGenerateApiKeyInfobip] =
    React.useState<string | null>(null);
  const [successMsgTryingAddNewPhoneNumber,setSuccessMsgTryingAddNewPhoneNumber] = React.useState<string | null>(null);
    const [errorMsgTryingAddNewPhoneNumber, setErrorMsgTryingAddNewPhoneNumber] = React.useState<string | null>(null);
    
  const [openConfirmDialogSOL, setOpenConfirmDialogSOL] =
    React.useState<boolean>(false);
  const [openConfirmDialogGUIA, setOpenConfirmDialogGUIA] =
    React.useState<boolean>(false);
  const [openConfirmDialogOpenPay, setOpenConfirmDialogOpenPay] =
    React.useState<boolean>(false);
  const [openConfirmDialogInfobip, setOpenConfirmDialogInfobip] =
    React.useState<boolean>(false);
  const [openConfirmDialogGenerateApiKey, setOpenConfirmDialogGenerateApiKey] =
  React.useState<boolean>(false);
  const [openConfirmDialogAddNewPhoneNumber, setOpenConfirmDialogAddNewPhoneNumber] =
  React.useState<boolean>(false);
  const [loadingSOL, setLoadingSOL] = React.useState<boolean>(false);
  const [loadingGUIA, setLoadingGUIA] = React.useState<boolean>(false);
  const [loadingOpenPay, setLoadingOpenPay] = React.useState<boolean>(false);
  const [loadingInfobip, setLoadingInfobip] = React.useState<boolean>(false);
  const [loadingGenerateApiKeyInfobip, setLoadingGenerateApiKeyInfobip] = React.useState<boolean>(false);
  const [loadingAddNewPhoneNumber, setLoadingAddNewPhoneNumber] = React.useState<boolean>(false);
  const [disableSwitchOpenPay, setDisableSwitchOpenPay] =
    React.useState<boolean>(false);
  const [disableSwitchInfobip, setDisableSwitchInfobip] =
    React.useState<boolean>(false);
  const [disableSwitchGuias, setDisableSwitchGuias] =
    React.useState<boolean>(false);
  const [openConfirmDialogEnableOpenPay, setOpenConfirmDialogEnableOpenPay] =
    React.useState<boolean>(false);
  const [openConfirmDialogEnableInfobip, setOpenConfirmDialogEnableInfobip] =
    React.useState<boolean>(false);
  const [openConfirmDialogEnableGuias, setOpenConfirmDialogEnableGuias] =
    React.useState<boolean>(false);
  const [hasMerchantIdError, setHasMerchantIdErrors] = React.useState(false);
  const [errorMsgMerchantId, setErrorMsgMerchantId] = React.useState<
    string | null
  >(null);
  const [hasPrivateKeyError, setHasPrivateKeyErrors] = React.useState(false);
  const [errorMsgPrivateKey, setErrorMsgPrivateKey] = React.useState<
    string | null
  >(null);
  const [hasPublicKeyError, setHasPublicKeyErrors] = React.useState(false);
  const [errorMsgPublicKey, setErrorMsgPublicKey] = React.useState<
    string | null>(null);
  const [hasBaseUrlError, setHasBaseUrlErrors] = React.useState(false);
  const [errorMsgBaseUrl, setErrorMsgBaseUrl] = React.useState<
    string | null>(null);
    const [hasApiKeyNameError, setHasApiKeyNameErrors] = React.useState(false);
  const [errorMsgApiKeyName, setErrorMsgApiKeyName] = React.useState<
    string | null>(null);
    const [hasNewPhoneNumberError, setHasNewPhoneNumberError] = React.useState(false);
  const [errorMsgNewPhoneNumber, setErrorMsgNewPhoneNumber] = React.useState<
    string | null>(null);
  const [hasInfobipUsernameError, setHasInfobipUsernameErrors] = React.useState(false);
  const [errorMsgInfobipUsername, setErrorMsgInfobipUsername] = React.useState<
    string | null>(null);
  const [hasInfobipPasswordError, setHasInfobipPasswordErrors] = React.useState(false);
  const [errorMsgInfobipPassword, setErrorMsgInfobipPassword] = React.useState<
    string | null>(null);
  const [hasApiKeyError, setHasApiKeyErrors] = React.useState(false);
  const [errorMsgApiKey, setErrorMsgApiKey] = React.useState<
    string | null>(null);
  const [openConfigOpenPay, setOpenConfigOpenPay] = React.useState(false);
  const [openConfigInfobip, setOpenConfigInfobip] = React.useState(false);
  const [openConfigSunat, setOpenConfigSunat] = React.useState(false);
  const [ose, setOse] = React.useState(false);
  const [credentialGuias, setCredentialGuias] = React.useState("");
  const [clientIdSunat, setClientIdSunat] = React.useState("");
  const [clientSecretSunat, setClientSecretSunat] = React.useState("");
  const [infobipIntegrationType, setInfobipIntegrationType] = React.useState("");
  const [openAddNewPhoneNumber, setOpenAddNewPhoneNumber] = React.useState(false);
  const [openAddNewApiKey, setOpenAddNewApiKey] = React.useState(false);
  
  const {
    form: formWsp,
    onChange: onChangeWsp,
    onChangeSelect,
  } = useForm({
    numberWsp: "",
    code: "+51",
  });
  const [infobipApiKeyList,setInfobipApiKeyList] = React.useState<any[]>([]);

  const saveSOL = async () => {
    if (!loadingSOL) {
      // close confirm dialog
      setOpenConfirmDialogSOL(false);
      // set loading
      setLoadingSOL(true);
      // clear messages status
      clearMsgs();
      // validate user & pass
      if (checkUserSOL() && checkPasswordSOL()) {
        // axios data
        let body: any = {};
        body["ruc"] = companyRUC;
        body["user_sol"] = userSOL;
        body["password_sol"] = passwordSOL;
        // axios request
        let { data } = await axios.post(
          "/api/company/solCredentials/update",
          body
        );
        // check axios result
        if (data.result) {
          let company: CompanyInfo = companies.find(
            (c: CompanyInfo) => c.ruc === body.ruc
          );
          company.user_sol = body.user_sol;
          store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
          setSuccessMsgTryingSOLUpdate("Credenciales actualizadas");
        } else {
          setErrorMsgTryingSOLUpdate(
            `Ha ocurrido un error inesperado: ${data.msg}`
          );
        }
      } else {
        setErrorMsgTryingSOLUpdate(
          "Las credenciales SOL no tienen un formato correcto"
        );
      }

      setLoadingSOL(false);
    }
  };

  const saveGUIA = async () => {
    if (!loadingGUIA) {
      // close confirm dialog
      setOpenConfirmDialogGUIA(false);
      // set loading
      setLoadingGUIA(true);
      // clear messages status
      clearMsgs();
      // validate user & pass
      if (checkClientIdSunat() && checkClientSecretSunat()) {
        // axios data
        let body: any = {};
        body["ruc"] = companyRUC;
        body["clientIdSunat"] = clientIdSunat;
        body["clientSecretSunat"] = clientSecretSunat;
        // axios request
        let { data } = await axios.post(
          "/api/company/greCredentials/update",
          body
        );
        // check axios result
        if (data.result) {
          let company: CompanyInfo = companies.find(
            (c: CompanyInfo) => c.ruc === body.ruc
          );
          company.client_id_sunat = clientIdSunat;
          company.client_secret_sunat = clientSecretSunat;
          store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
          setSuccessMsgTryingGUIAUpdate("Credenciales actualizadas");
        } else {
          setErrorMsgTryingGUIAUpdate(
            `Ha ocurrido un error inesperado: ${data.msg}`
          );
        }
      } else {
        setErrorMsgTryingGUIAUpdate(
          "Las credenciales GUIA no tienen un formato correcto"
        );
      }

      setLoadingGUIA(false);
    }
  };

  const saveOpenPay = async () => {
    if (!loadingOpenPay) {
      // close confirm dialog
      setOpenConfirmDialogOpenPay(false);
      // set loading
      setLoadingOpenPay(true);
      // clear messages status
      clearMsgs();
      // axios data
      let body: any = {};
      body["ruc"] = companyRUC;
      body["currency"] = currency;
      body["merchantId"] = merchantId;
      body["privateKey"] = privateKey;
      body["publicKey"] = publicKey;
      let { data } = await axios.post(
        "/api/company/openpayCredentials/update",
        body
      );

      // check axios result
      if (data.result) {
        let company: CompanyInfo = companies.find(
          (c: CompanyInfo) => c.ruc === body.ruc
        );
        if (!company.credentials_openpay) {
          company.credentials_openpay = {
            PEN: { merchantId: "", privateKey: "", publicKey: "" },
            USD: { merchantId: "", privateKey: "", publicKey: "" },
          };
        }
        company.credentials_openpay[body.currency]["merchantId"] =
          body.merchantId;
        company.credentials_openpay[body.currency]["privateKey"] =
          body.privateKey;
        company.credentials_openpay[body.currency]["publicKey"] =
          body.publicKey;
        store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
        setSuccessMsgTryingOpenPayUpdate("Credenciales actualizadas");
      } else {
        setErrorMsgTryingOpenPayUpdate(
          `Ha ocurrido un error inesperado: ${data.msg}`
        );
      }

      setLoadingOpenPay(false);
    }
  };

  const saveInfobip = async () => {
    if (!loadingInfobip) {
      // close confirm dialog
      setOpenConfirmDialogInfobip(false);
      // set loading
      setLoadingInfobip(true);
      // clear messages status
      clearMsgs();
      // axios data
      let body: any = {};
      body["ruc"] = companyRUC;
      body["type"] = infobipIntegrationType;
      if (infobipIntegrationType === "PRIVATE") {
        body["username"] = infobipUsername;
        body["password"] = infobipPassword;
        body["baseUrl"] = baseUrl;
      }

      let { data } = await axios.post(
        "/api/company/infobipCredentials/update",
        body
      );

      // check axios result
      if (data.result) {
        let company: CompanyInfo = companies.find(
          (c: CompanyInfo) => c.ruc === body.ruc
        );
        company.customize["waba"] = infobipIntegrationType;

        // company.credentials_openpay[body.currency]["merchantId"] =
        //   body.merchantId;
        // company.credentials_openpay[body.currency]["privateKey"] =
        //   body.privateKey;
        // company.credentials_openpay[body.currency]["publicKey"] =
        //   body.publicKey;
        console.log(companies);
        store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
        setSuccessMsgTryingInfobipUpdate("Credenciales actualizadas");
      } else {
        setErrorMsgTryingInfobipUpdate(
          `Ha ocurrido un error inesperado: ${data.msg}`
        );
      }

      setLoadingInfobip(false);
    }
  };
  
  const generateNewApiKeyInfobip = async () => {
    if (!loadingGenerateApiKeyInfobip) {
      // close confirm dialog
      setOpenConfirmDialogGenerateApiKey(false);
      setOpenAddNewApiKey(false);
      // set loading
      setLoadingGenerateApiKeyInfobip(true);
      // clear messages status
      clearMsgs();
      let body: any = {};
      body["ruc"] = companyRUC;
      body["name"] = apiKeyName;
      let { data } = await axios.post(
        `/api/infobip/apikey/generate`,
        body
      );
      
      // check axios result
      if (data.result) {

        setInfobipApiKeyList([...infobipApiKeyList,{api_key_name: data.jsonResponse["name"],api_key: data.jsonResponse["apiKeySecret"]}]);
        // store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
        setSuccessMsgTryingGenerateApiKeyInfobip("Nueva Api Key generada correctamente");
      } else {
        setErrorMsgTryingGenerateApiKeyInfobip(
          `Ha ocurrido un error inesperado: ${data.msg}`
        );
      }

      setLoadingGenerateApiKeyInfobip(false);
    }
  };

  const saveEnableOpenPay = async () => {
    let body: any = {};
    body["ruc"] = companyRUC;
    body["property"] = "openPay";
    body["value"] = "YES";
    let { data } = await axios.post("/api/company/customize/update", body);
    // check axios result
    if (data.result) {
      let company: CompanyInfo = companies[activeIndex];
      company.customize["openPay"] = "YES";
      store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
      setDisableSwitchOpenPay(true);
      setCredentialOpenpay("YES");
      setSuccessMsgTryingEnableOpenPayUpdate("Credenciales habilitadas");
    } else {
      setErrorMsgTryingEnableOpenPayUpdate(
        `Ha ocurrido un error inesperado: ${data.msg}`
      );
    }
    setOpenConfirmDialogEnableOpenPay(false);
  };

  const saveEnableInfobip = async () => {
    let body: any = {};
    body["ruc"] = companyRUC;
    body["property"] = "waba";
    body["value"] = "YES";
    let { data } = await axios.post("/api/company/customize/update", body);
    // check axios result
    if (data.result) {
      let company: CompanyInfo = companies[activeIndex];
      company.customize["waba"] = "YES";
      store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
      setDisableSwitchInfobip(true);
      setCredentialInfobip("YES");
      setSuccessMsgTryingEnableInfobipUpdate("Credenciales habilitadas");
    } else {
      setErrorMsgTryingEnableInfobipUpdate(
        `Ha ocurrido un error inesperado: ${data.msg}`
      );
    }
    setOpenConfirmDialogEnableInfobip(false);
  };

  const addNewPhoneNumberInfobip = async () => {
    
    if (!loadingAddNewPhoneNumber) {
      // close confirm dialog
      setOpenConfirmDialogAddNewPhoneNumber(false);
      setOpenAddNewPhoneNumber(false);
        
      // set loading
      setLoadingAddNewPhoneNumber(true);
      // clear messages status
      clearMsgs();
      
      if (checkAddNewPhoneNumber()) {
        // axios data
        let body: any = {};
        body["ruc"] = companyRUC;
        body["number"] = formWsp.code.substr(1)+formWsp.numberWsp;
        body["state"] = "0";
        
        // axios request
        let { data } = await axios.put(
          "/api/company/senders",
          body
        );
        // check axios result
        if (data.result) {
          let company: CompanyInfo = companies.find(
            (c: CompanyInfo) => c.ruc === body.ruc
          );
          company.senders = data.senders;
          store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
          setSuccessMsgTryingAddNewPhoneNumber("Nuevo número registrado");
          setSenderList(data.senders);
        } else {
          setErrorMsgTryingAddNewPhoneNumber(
            `Ha ocurrido un error inesperado: ${data.msg}`
          );
        }
      } else {
        setErrorMsgTryingAddNewPhoneNumber(
          "El nuevo número no tienen un formato correcto"
        );
      }
  
      setLoadingAddNewPhoneNumber(false);
    }
    
  }

  const saveEnableGuias = async () => {
    let body: any = {};
    body["ruc"] = companyRUC;
    body["property"] = "envioGuias";
    body["value"] = "YES";
    let { data } = await axios.post("/api/company/customize/update", body);
    // check axios result
    if (data.result) {
      let company: CompanyInfo = companies[activeIndex];
      company.customize["envioGuias"] = "YES";
      store.dispatch({ type: "SET_COMPANY_INFO_LIST", payload: companies });
      setDisableSwitchGuias(true);
      setCredentialGuias("YES");
      setSuccessMsgTryingEnableGuiasUpdate("Credenciales habilitadas");
    } else {
      setErrorMsgTryingEnableGuiasUpdate(
        `Ha ocurrido un error inesperado: ${data.msg}`
      );
    }
    setOpenConfirmDialogEnableGuias(false);
  };

  const handleCurrencyChange = (event: React.ChangeEvent<{ value: any }>) => {
    let company: CompanyInfo = companies.find(
      (c: CompanyInfo) => c.ruc.toString() === companyRUC
    );
    setCurrency(event.target.value);
    if (company.credentials_openpay) {
      setMerchantId(
        company.credentials_openpay[event.target.value]["merchantId"]
      );
      setPrivateKey(
        company.credentials_openpay[event.target.value]["privateKey"]
      );
      setPublicKey(
        company.credentials_openpay[event.target.value]["publicKey"]
      );
    }
  };

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenConfirmDialogEnableOpenPay(event.target.checked);
  };

  const handleChangeCheckedInfobip = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenConfirmDialogEnableInfobip(event.target.checked);
  };

  const handleChangeCheckedOse = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => { };

  const handleChangeCheckedGuias = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOpenConfirmDialogEnableGuias(event.target.checked);
  };

  const setCompanyData = (company: CompanyInfo) => {
    // clear messages status
    clearMsgs();
    // companyRUC
    setCompanyRUC(company.ruc.toString());
    // ose
    setOse(company.ose);

    // SOL
    if (!company.ose) {
      // user sol
      setUserSOL(company.user_sol);
      // password
      setPasswordSOL("");
      setErrorMsgPassSOL(null);
      setHasPassSolErrors(false);
    } else {
      // user sol
      setUserSOL("");
      // password
      setPasswordSOL("");
      setErrorMsgPassSOL(null);
      setHasPassSolErrors(false);
    }
    // credentials OPENPAY
    if (company.customize["openPay"]) {
      setCredentialOpenpay(company.customize["openPay"]);
    }
    if (company.credentials_openpay) {
      setMerchantId(company.credentials_openpay[currency]["merchantId"]);
      setPrivateKey(company.credentials_openpay[currency]["privateKey"]);
      setPublicKey(company.credentials_openpay[currency]["publicKey"]);
    } else {
      setMerchantId("");
      setPrivateKey("");
      setPublicKey("");
    }
    // credential Guias
    if (company.customize["envioGuias"]) {
      setCredentialGuias(company.customize["envioGuias"]);
    }
    // credential Infobip
    if (company.customize["waba"]) {
      setCredentialInfobip(company.customize["waba"]);
      setInfobipIntegrationType(company.customize["waba"]);
    }
    else{
      setCredentialInfobip("");
      setInfobipIntegrationType("PUBLIC");
    }
    if (company.client_id_sunat) {
      setClientIdSunat(company.client_id_sunat);
    } else {
      setClientIdSunat("");
    }
    if (company.client_secret_sunat) {
      setClientSecretSunat(company.client_secret_sunat);
    } else {
      setClientSecretSunat("");
    }
    setDisableSwitchOpenPay(false);
    if(company.credentials_infobip){
      setInfobipUsername(company.credentials_infobip["username"]);
      setInfobipPassword(company.credentials_infobip["password"]);
      setBaseUrl(company.credentials_infobip["baseUrl"]);
    }
    else{
      setInfobipUsername("");
      setInfobipPassword("");
      setBaseUrl("");
    }
    if(company.senders){
      setSenderList(company.senders);
    }
    else{
      setSenderList([]);
    }
  };

  const checkUserSOL = () => {
    // clear messages status
    clearMsgs();
    if (userSOL.trim() === "" || userSOL.length !== 8) {
      setHasUserSolErrors(true);
      setErrorMsgUserSOL("El Usuario SOL debe contener 8 caracteres");
      return false;
    } else {
      setErrorMsgUserSOL(null);
      setHasUserSolErrors(false);
      return true;
    }
  };

  const checkPasswordSOL = () => {
    // clear messages status
    clearMsgs();
    if (
      passwordSOL.trim() === "" ||
      passwordSOL.length < 8 ||
      passwordSOL.length > 12
    ) {
      setHasPassSolErrors(true);
      setErrorMsgPassSOL("La Clave SOL debe contener entre 8 y 12 caracteres");
      return false;
    } else {
      setErrorMsgPassSOL(null);
      setHasPassSolErrors(false);
      return true;
    }
  };

  const checkClientIdSunat = () => {
    // clear messages status
    clearMsgs();
    if (clientIdSunat.trim() === "") {
      setHasClientIdSunatErrors(true);
      setErrorMsgClientIdSunat("Debe especificar el Client Id correcto");
      return false;
    } else {
      setHasClientIdSunatErrors(false);
      setErrorMsgClientIdSunat(null);
      return true;
    }
  };

  const checkClientSecretSunat = () => {
    // clear messages status
    clearMsgs();
    if (clientSecretSunat.trim() === "") {
      setHasClientSecretSunatErrors(true);
      setErrorMsgClientSecretSunat(
        "Debe especificar el Client Secret correcto"
      );
      return false;
    } else {
      setHasClientSecretSunatErrors(false);
      setErrorMsgClientSecretSunat(null);
      return true;
    }
  };

  const checkMerchantId = () => {
    // clear messages status
    clearMsgs();
    if (merchantId.trim() === "") {
      setHasMerchantIdErrors(true);
      setErrorMsgMerchantId(
        "Debes especificar un identificador de OPENPAY correcto"
      );
      return false;
    } else {
      setHasMerchantIdErrors(false);
      setErrorMsgMerchantId(null);
      return true;
    }
  };

  const checkPrivateKey = () => {
    // clear messages status
    clearMsgs();
    if (privateKey.trim() === "") {
      setHasPrivateKeyErrors(true);
      setErrorMsgPrivateKey(
        "Debes especificar una llave privada de OPENPAY correcto"
      );
      return false;
    } else {
      setHasPrivateKeyErrors(false);
      setErrorMsgPrivateKey(null);
      return true;
    }
  };

  const checkPublicKey = () => {
    // clear messages status
    clearMsgs();
    if (publicKey.trim() === "") {
      setHasPublicKeyErrors(true);
      setErrorMsgPublicKey(
        "Debes especificar una llave publica de OPENPAY correcto"
      );
      return false;
    } else {
      setHasPublicKeyErrors(false);
      setErrorMsgPublicKey(null);
      return true;
    }
  };

  const checkInfobipUsername = () => {
    // clear messages status
    clearMsgs();
    if (infobipUsername.trim() === "") {
      setHasInfobipUsernameErrors(true);
      setErrorMsgInfobipUsername(
        "Debes especificar el nombre de usuario de infobip correcto"
      );
      return false;
    } else {
      setHasInfobipUsernameErrors(false);
      setErrorMsgInfobipUsername(null);
      return true;
    }
  };

  const checkInfobipPassword = () => {
    // clear messages status
    clearMsgs();
    if (infobipPassword.trim() === "") {
      setHasInfobipPasswordErrors(true);
      setErrorMsgInfobipPassword(
        "Debes especificar el nombre de usuario de infobip correcto"
      );
      return false;
    } else {
      setHasInfobipPasswordErrors(false);
      setErrorMsgInfobipPassword(null);
      return true;
    }
  };

  const checkBaseUrl = () => {
    // clear messages status
    clearMsgs();
    if (baseUrl.trim() === "") {
      setHasBaseUrlErrors(true);
      setErrorMsgBaseUrl(
        "Debes especificar una base URL correcto"
      );
      return false;
    } else {
      setHasBaseUrlErrors(false);
      setErrorMsgBaseUrl(null);
      return true;
    }
  };

  const checkApiKeyName = () => {
    // clear messages status
    clearMsgs();
    if (apiKeyName.trim() === "") {
      setHasApiKeyNameErrors(true);
      setErrorMsgApiKeyName(
        "Debes especificar un nombre para la api key correcto"
      );
      return false;
    } else {
      setHasApiKeyNameErrors(false);
      setErrorMsgApiKeyName(null);
      return true;
    }
  };

  const checkAddNewPhoneNumber = () => {
    // clear messages status
    clearMsgs();
    if (formWsp.numberWsp.trim() === "" || formWsp.code.trim() === "") {
      setHasNewPhoneNumberError(true);
      setErrorMsgNewPhoneNumber(
        "Debes especificar un número correcto"
      );
      return false;
    } else {
      setHasNewPhoneNumberError(false);
      setErrorMsgNewPhoneNumber(null);
      return true;
    }
  };

  const checkApiKey = () => {
    // clear messages status
    clearMsgs();
    if (apiKey.trim() === "") {
      setHasApiKeyErrors(true);
      setErrorMsgApiKey(
        "Debes especificar un Api Key correcto"
      );
      return false;
    } else {
      setHasApiKeyErrors(false);
      setErrorMsgApiKey(null);
      return true;
    }
  };
  const clearMsgs = () => {
    setSuccessMsgTryingSOLUpdate("");
    setErrorMsgTryingSOLUpdate("");
    setSuccessMsgTryingOpenPayUpdate("");
    setErrorMsgTryingOpenPayUpdate("");
    setSuccessMsgTryingGUIAUpdate("");
    setErrorMsgTryingGUIAUpdate("");
    setSuccessMsgTryingInfobipUpdate("");
    setErrorMsgTryingInfobipUpdate("");
    setSuccessMsgTryingGenerateApiKeyInfobip("");
    setErrorMsgTryingGenerateApiKeyInfobip("");
    setSuccessMsgTryingAddNewPhoneNumber("");
    setErrorMsgTryingAddNewPhoneNumber("");
  };

  const companies = useSelector((state: any) => state.companiesInfo);
  const workspace = useSelector((state: any) => state.config.workspace);

  useEffect(() => {
    
    const current_company = companies[activeIndex];
    if (current_company) {
      setCompanyData(current_company);
      async function getInfobipApikeys(){
        const { data } = await axios.get(`/api/infobip/apikeys/${current_company.ruc}`);
        if(data.result){
          setInfobipApiKeyList(data.apiKeys)
        }
      }
      getInfobipApikeys()
    }
  }, [activeIndex]);

  

  const isMobile = useMediaQuery(Sizes.MOBILE);

  return (
    <>
      <div>
        {companies.map((company: CompanyInfo, index: number) => (
          <TabPanel value={activeIndex} index={index} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card variant="outlined" className="cardDireccion" style={{ minHeight: 205 }}>
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <img src={logoOpenpay} alt="" style={{ width: 100 }} />
                      <TextStyle bold={600} type="h2">
                        Integración con OpenPay
                      </TextStyle>
                    </div>
                    <TextStyle bold={400} type="h4" margin="15px">
                      Conecta tu cuenta de OpenPay para procesar pagos de forma
                      segura y eficiente.
                    </TextStyle>
                  </CardContent>
                  <CardActions style={{ justifyContent: "flex-end" }}>
                    {credentialOpenpay === "YES" ? (
                      <ButtonPrimary
                        background={Colors.GREEN}
                        color={Colors.WHITE}
                        size="small"
                        width="10rem"
                        onClick={() => setOpenConfigOpenPay(true)}
                      >
                        Configurar
                      </ButtonPrimary>
                    ) : (
                      <div style={{ display: "flex" }}>
                        {(errorMsgTryingEnableOpenPayUpdate !== "" ||
                          successMsgTryingEnableOpenPayUpdate !== "") && (
                            <InputLabel
                              style={{
                                color:
                                  errorMsgTryingEnableOpenPayUpdate !== ""
                                    ? "#f44336"
                                    : "green",
                                fontSize: "0.75rem",
                                marginLeft: 14,
                                marginTop: 8,
                              }}
                            >
                              {errorMsgTryingEnableOpenPayUpdate}
                              {successMsgTryingEnableOpenPayUpdate}
                            </InputLabel>
                          )}
                        <CustomSwitch
                          disabled={disableSwitchOpenPay}
                          checked={openConfirmDialogEnableOpenPay}
                          name="openConfirmDialogEnableOpenPay"
                          placeholder="Habilitar credenciales OPENPAY"
                          onChange={handleChangeChecked}
                          selectHandle={false}
                        />
                      </div>
                    )}
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card variant="outlined" className="cardDireccion">
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <img
                        src={
                          "https://proludica.com/wp-content/uploads/2019/10/SUNAT.png"
                        }
                        alt=""
                        style={{ width: 100 }}
                      />
                      <TextStyle bold={600} type="h2">
                        Integración con Sunat
                      </TextStyle>
                    </div>
                    <TextStyle bold={400} type="h4" margin="15px">
                      Conecta tu cuenta de SUNAT para emitir comprobantes de
                      forma segura y eficiente.
                    </TextStyle>
                  </CardContent>
                  <CardActions style={{ justifyContent: "flex-end" }}>
                    <ButtonPrimary
                      background={Colors.GREEN}
                      color={Colors.WHITE}
                      size="small"
                      width="10rem"
                      onClick={() => setOpenConfigSunat(true)}
                    >
                      Configurar
                    </ButtonPrimary>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card variant="outlined" className="cardDireccion" style={{ minHeight: 205 }}>
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <img src={"https://iconape.com/wp-content/png_logo_vector/infobip-logo.png"} alt="" style={{ width: 100 }} />
                      <TextStyle bold={600} type="h2">
                        Integración con Infobip
                      </TextStyle>
                    </div>
                    <TextStyle bold={400} type="h4" margin="15px">
                      Conecta tu cuenta de Infobip para potenciar tus comunicaciones.
                    </TextStyle>
                  </CardContent>
                  <CardActions style={{ justifyContent: "flex-end" }}>
                    {credentialInfobip === "YES" || credentialInfobip === "PUBLIC" || credentialInfobip === "EXIMIO" || credentialInfobip === "PRIVATE" ? (
                      <ButtonPrimary
                        background={Colors.GREEN}
                        color={Colors.WHITE}
                        size="small"
                        width="10rem"
                        onClick={() => setOpenConfigInfobip(true)}
                      >
                        Configurar
                      </ButtonPrimary>
                    ) : (
                      <div style={{ display: "flex" }}>
                        {(errorMsgTryingEnableInfobipUpdate !== "" ||
                          successMsgTryingEnableInfobipUpdate !== "") && (
                            <InputLabel
                              style={{
                                color:
                                  errorMsgTryingEnableInfobipUpdate !== ""
                                    ? "#f44336"
                                    : "green",
                                fontSize: "0.75rem",
                                marginLeft: 14,
                                marginTop: 8,
                              }}
                            >
                              {errorMsgTryingEnableInfobipUpdate}
                              {successMsgTryingEnableInfobipUpdate}
                            </InputLabel>
                          )}
                        <CustomSwitch
                          disabled={disableSwitchInfobip}
                          checked={openConfirmDialogEnableInfobip}
                          name="openConfirmDialogEnableInfobip"
                          placeholder="Habilitar credenciales Infobip"
                          onChange={handleChangeCheckedInfobip}
                          selectHandle={false}
                        />
                      </div>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
        ))}
      </div>
      <Dialog
        open={openConfigOpenPay}
        scroll={"paper"}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenConfigOpenPay(false)}
        fullWidth={false}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-slide-title" key={Date.now() + 2}>
          <TextStyle bold={700} type="h1">
            {"Configuración OpenPay"}
          </TextStyle>
        </DialogTitle>
        <DialogContent dividers={true} style={{ height: "39em", padding: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextStyle bold={600} type="h2">
              Clave OPENPAY
            </TextStyle>
            <br />
            <TextStyle bold={400} type="h4">
              Agrega el identificador/llaves OPENPAY asociado a la empresa
            </TextStyle>

            <CustomSelect
              value={currency}
              name="currency"
              selectHandle={false}
              onChange={handleCurrencyChange}
              placeholder="Tipo de Moneda"
              options={[
                {
                  id: "PEN",
                  text: "Soles",
                },
                {
                  id: "USD",
                  text: "Dolares",
                },
              ]}
            />
            <InputText
              value={merchantId}
              name="merchantId"
              onChange={(e) => {
                setMerchantId(e.target.value);
                checkMerchantId();
              }}
              placeholder="Identificador OPENPAY"
              helperText={errorMsgMerchantId}
              error={hasMerchantIdError}
            />
            <InputPassword
              value={privateKey}
              name="privateKey"
              onChange={(e) => {
                setPrivateKey(e.target.value);
                checkPrivateKey();
              }}
              placeholder="Llave privada OPENPAY "
              helperText={errorMsgPrivateKey}
              error={hasPrivateKeyError}
            />
            <InputPassword
              value={publicKey}
              name="publicKey"
              onChange={(e) => {
                setPublicKey(e.target.value);
                checkPublicKey();
              }}
              placeholder="Llave publica OPENPAY"
              helperText={errorMsgPublicKey}
              error={hasPublicKeyError}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {(errorMsgTryingOpenPayUpdate !== "" ||
                successMsgTryingOpenPayUpdate !== "") && (
                  <InputLabel
                    style={{
                      color:
                        errorMsgTryingOpenPayUpdate !== ""
                          ? Colors.RED
                          : Colors.GREEN,
                      fontSize: "0.75rem",
                      marginLeft: 14,
                      marginTop: 8,
                      marginRight: 10,
                    }}
                  >
                    {errorMsgTryingOpenPayUpdate}
                    {successMsgTryingOpenPayUpdate}
                  </InputLabel>
                )}
              <ButtonPrimary
                onClick={() => {
                  if (
                    checkMerchantId() &&
                    checkPrivateKey() &&
                    checkPublicKey()
                  ) {
                    setOpenConfirmDialogOpenPay(true);
                  }
                }}
                disabled={loadingOpenPay}
                size="14px"
                width="100px"
                background={Colors.GREEN}
                color={Colors.WHITE}
              >
                {!loadingOpenPay ? (
                  "Guardar"
                ) : (
                  <CircularProgress
                    variant="indeterminate"
                    size={14}
                    style={{ marginRight: 5, color: Colors.WHITE }}
                  />
                )}
              </ButtonPrimary>
            </div>
          </div>

          <ConfirmDialog
            title="¿Desea actualizar las credenciales de SOL?"
            body="Las credenciales de SOL de esta empresa serán modificadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogSOL}
            handleClose={() => setOpenConfirmDialogSOL(false)}
            handleAccept={saveSOL}
          />
          <ConfirmDialog
            title="¿Desea actualizar las credenciales de OPENPAY?"
            body="Las credenciales de OPENPAY de esta empresa serán modificadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogOpenPay}
            handleClose={() => setOpenConfirmDialogOpenPay(false)}
            handleAccept={saveOpenPay}
          />
          <ConfirmDialog
            title="¿Desea habilitar las credenciales de OPENPAY?"
            body="Las credenciales de OPENPAY de esta empresa serán habilitadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogEnableOpenPay}
            handleClose={() => setOpenConfirmDialogEnableOpenPay(false)}
            handleAccept={saveEnableOpenPay}
          />
          <ConfirmDialog
            title="¿Desea habilitar las credenciales de Infobip?"
            body="Las credenciales de Infobip de esta empresa serán habilitadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogEnableInfobip}
            handleClose={() => setOpenConfirmDialogEnableInfobip(false)}
            handleAccept={saveEnableInfobip}
          />
          <ConfirmDialog
            title="¿Desea habilitar las credenciales de GUIAS?"
            body="“Es posible que existan cargos adicionales a su plan actual. Se recomienda consultar con su ejecutivo de cuenta antes de activar las guías electrónicas, ya que están disponibles para planes corporativos. Al activar esta opción, automáticamente estará cambiando a plan corporativo”"
            acceptBtnText="Confirmar"
            open={openConfirmDialogEnableGuias}
            handleClose={() => setOpenConfirmDialogEnableGuias(false)}
            handleAccept={saveEnableGuias}
          />
        </DialogContent>
        <DialogActions
          style={{
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            width="100px"
            onClick={() => setOpenConfigOpenPay(false)}
          >
            Cerrar
          </ButtonSecondary>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfigInfobip}
        scroll={"paper"}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenConfigInfobip(false)}
        fullWidth={false}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-slide-title" key={Date.now() + 2}>
          <TextStyle bold={700} type="h1">
            {"Configuración Infobip"}
          </TextStyle>
        </DialogTitle>
        <DialogContent dividers={true} style={{ height: "39em", padding: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextStyle bold={600} type="h2">
              Clave Infobip
            </TextStyle>
            <br />
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ color: Colors.TEXT }}>Tipo de Integración</FormLabel>
              <RadioGroup row aria-label="position" name="position" defaultValue={infobipIntegrationType} onChange={(event) => setInfobipIntegrationType(event.target.value)}>
                <FormControlLabel
                  value="PUBLIC"
                  control={<GreenRadio />}
                  label="Publico"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="EXIMIO"
                  control={<GreenRadio />}
                  label="Eximio"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="PRIVATE"
                  control={<GreenRadio />}
                  label="Privado"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            {infobipIntegrationType === "PRIVATE" ?
              (
                <>
                  <TextStyle bold={400} type="h4">
                    Agrega las credenciales de Infobip asociado a la empresa
                  </TextStyle>
                  <InputText
                    value={infobipUsername}
                    name="username"
                    onChange={(e) => {
                      setInfobipUsername(e.target.value);
                      checkInfobipUsername();
                    }}
                    placeholder="Nombre de usuario"
                    helperText={errorMsgInfobipUsername}
                    error={hasInfobipUsernameError}
                  />
                  <InputPassword
                    value={infobipPassword}
                    name="password"
                    onChange={(e) => {
                      setInfobipPassword(e.target.value);
                      checkInfobipPassword();
                    }}
                    placeholder="Contraseña"
                    helperText={errorMsgInfobipPassword}
                    error={hasInfobipPasswordError}
                  />
                  <InputText
                    value={baseUrl}
                    name="base URL"
                    onChange={(e) => {
                      setBaseUrl(e.target.value);
                      checkBaseUrl();
                    }}
                    placeholder="base URL"
                    helperText={errorMsgBaseUrl}
                    error={hasBaseUrlError}
                  />

                  {/* <InputPassword
                value={apiKey}
                name="apiKey"
                onChange={(e) => {
                  setApiKey(e.target.value);
                  checkApiKey();
                }}
                placeholder="Api key"
                helperText={errorMsgApiKey}
                error={hasApiKeyError}
              /> 
               */}
                </>)
              : null
            }

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {(errorMsgTryingInfobipUpdate !== "" ||
                successMsgTryingInfobipUpdate !== "") && (
                  <InputLabel
                    style={{
                      color:
                        errorMsgTryingInfobipUpdate !== ""
                          ? Colors.RED
                          : Colors.GREEN,
                      fontSize: "0.75rem",
                      marginLeft: 14,
                      marginTop: 8,
                      marginRight: 10,
                    }}
                  >
                    {errorMsgTryingInfobipUpdate}
                    {successMsgTryingInfobipUpdate}
                  </InputLabel>
                )}
              <ButtonPrimary
                onClick={() => {
                  if (infobipIntegrationType !== "" &&
                    checkInfobipUsername() &&
                    checkInfobipPassword() && checkBaseUrl()
                  ) {
                    setOpenConfirmDialogInfobip(true);
                  }
                }}
                disabled={loadingInfobip}
                size="14px"
                width="100px"
                background={Colors.GREEN}
                color={Colors.WHITE}
              >
                {!loadingInfobip ? (
                  "Guardar"
                ) : (
                  <CircularProgress
                    variant="indeterminate"
                    size={14}
                    style={{ marginRight: 5, color: Colors.WHITE }}
                  />
                )}
              </ButtonPrimary>
            </div>
            {
              infobipIntegrationType === "PRIVATE" ?
                (
                  <>
                    <div>
                      <TextStyle bold={600} type="h2">
                        Lista de ApiKeys generadas
                      </TextStyle>
                      <br />
                      <TableContainer component={Paper}>
                        <Table aria-label="simple Table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Nro</TableCell>
                              <TableCell>Nombre</TableCell>
                              <TableCell>Api Key</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              infobipApiKeyList.map((apiKey: any, index)=>(
                                <TableRow>
                                  <TableCell>{index+1}</TableCell>
                                  <TableCell>{apiKey.api_key_name}</TableCell>
                                  <TableCell>{(apiKey.api_key).substr(0,20)+"..."}</TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {(errorMsgTryingGenerateApiKeyInfobip !== "" ||
                          successMsgTryingGenerateApiKeyInfobip !== "") && (
                            <InputLabel
                              style={{
                                color:
                                  errorMsgTryingGenerateApiKeyInfobip !== ""
                                    ? Colors.RED
                                    : Colors.GREEN,
                                fontSize: "0.75rem",
                                marginLeft: 14,
                                marginTop: 8,
                                marginRight: 10,
                              }}
                            >
                              {errorMsgTryingGenerateApiKeyInfobip}
                              {successMsgTryingGenerateApiKeyInfobip}
                            </InputLabel>
                          )}
                        <ButtonPrimary
                          onClick={() => {
                              setOpenAddNewApiKey(true);
                          }}
                          disabled={loadingGenerateApiKeyInfobip}
                          size="14px"
                          width="100px"
                          background={Colors.GREEN}
                          color={Colors.WHITE}
                        >
                          {!loadingGenerateApiKeyInfobip ? (
                            "Generar ApiKey"
                          ) : (
                            <CircularProgress
                              variant="indeterminate"
                              size={14}
                              style={{ marginRight: 5, color: Colors.WHITE }}
                            />
                          )}
                        </ButtonPrimary>
                      </div>
                    </div>
                    <div>
                    <TextStyle bold={600} type="h2">
                        Lista de Números
                      </TextStyle>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple Table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Nro</TableCell>
                              <TableCell>Número de remitente</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              senderList.map((sender, index)=>(
                                <TableRow>
                                  <TableCell>{index+1}</TableCell>
                                  <TableCell>{sender["number"]}</TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {(errorMsgTryingAddNewPhoneNumber !== "" ||
                          successMsgTryingAddNewPhoneNumber !== "") && (
                            <InputLabel
                              style={{
                                color:
                                  errorMsgTryingAddNewPhoneNumber !== ""
                                    ? Colors.RED
                                    : Colors.GREEN,
                                fontSize: "0.75rem",
                                marginLeft: 14,
                                marginTop: 8,
                                marginRight: 10,
                              }}
                            >
                              {errorMsgTryingAddNewPhoneNumber}
                              {successMsgTryingAddNewPhoneNumber}
                            </InputLabel>
                          )}
                        <ButtonPrimary
                          onClick={() => {
                            setOpenAddNewPhoneNumber(true)
                          }}
                          disabled={loadingAddNewPhoneNumber}
                          size="14px"
                          width="100px"
                          background={Colors.GREEN}
                          color={Colors.WHITE}
                        >
                          {!loadingAddNewPhoneNumber ? (
                            "Nuevo"
                          ) : (
                            <CircularProgress
                              variant="indeterminate"
                              size={14}
                              style={{ marginRight: 5, color: Colors.WHITE }}
                            />
                          )}
                        </ButtonPrimary>
                      </div>
                    </div>
                  </>
                )
                : null
            }
          </div>

          <ConfirmDialog
            title="¿Desea actualizar las credenciales de SOL?"
            body="Las credenciales de SOL de esta empresa serán modificadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogSOL}
            handleClose={() => setOpenConfirmDialogSOL(false)}
            handleAccept={saveSOL}
          />
          <ConfirmDialog
            title="¿Desea actualizar las credenciales de OPENPAY?"
            body="Las credenciales de OPENPAY de esta empresa serán modificadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogOpenPay}
            handleClose={() => setOpenConfirmDialogOpenPay(false)}
            handleAccept={saveOpenPay}
          />
          <ConfirmDialog
            title="¿Desea habilitar las credenciales de OPENPAY?"
            body="Las credenciales de OPENPAY de esta empresa serán habilitadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogEnableOpenPay}
            handleClose={() => setOpenConfirmDialogEnableOpenPay(false)}
            handleAccept={saveEnableOpenPay}
          />
          <ConfirmDialog
            title="¿Desea actualizar las credenciales de Infobip?"
            body="Las credenciales de Infobip de esta empresa serán modificadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogInfobip}
            handleClose={() => setOpenConfirmDialogInfobip(false)}
            handleAccept={saveInfobip}
          />
          <ConfirmDialog
            title="¿Desea habilitar las credenciales de Infobip?"
            body="Las credenciales de Infobip de esta empresa serán habilitadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogEnableInfobip}
            handleClose={() => setOpenConfirmDialogEnableInfobip(false)}
            handleAccept={saveEnableInfobip}
          />
          <ConfirmDialog
            title="¿Desea habilitar las credenciales de GUIAS?"
            body="“Es posible que existan cargos adicionales a su plan actual. Se recomienda consultar con su ejecutivo de cuenta antes de activar las guías electrónicas, ya que están disponibles para planes corporativos. Al activar esta opción, automáticamente estará cambiando a plan corporativo”"
            acceptBtnText="Confirmar"
            open={openConfirmDialogEnableGuias}
            handleClose={() => setOpenConfirmDialogEnableGuias(false)}
            handleAccept={saveEnableGuias}
          />
          <ConfirmDialog
            title="¿Desea generar una nueva ApiKey de infobip?"
            body="Se generara una nueva ApiKey de Infobip de esta empresa"
            acceptBtnText="Confirmar"
            open={openConfirmDialogGenerateApiKey}
            handleClose={() => setOpenConfirmDialogGenerateApiKey(false)}
            handleAccept={generateNewApiKeyInfobip}
          />
          <ConfirmDialog
            title="¿Desea agregar nuevo número remitente para infobip?"
            body="Se generara una nuevo número para infobip"
            acceptBtnText="Confirmar"
            open={openConfirmDialogAddNewPhoneNumber}
            handleClose={() => setOpenConfirmDialogAddNewPhoneNumber(false)}
            handleAccept={addNewPhoneNumberInfobip}
          />
        </DialogContent>
        <DialogActions
          style={{
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            width="100px"
            onClick={() => setOpenConfigInfobip(false)}
          >
            Cerrar
          </ButtonSecondary>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfigSunat}
        scroll={"paper"}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenConfigSunat(false)}
        fullWidth={false}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-slide-title" key={Date.now() + 2}>
          <TextStyle bold={700} type="h1">
            {"Integración con Sunat"}
          </TextStyle>
        </DialogTitle>
        <DialogContent dividers={true} style={{ padding: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextStyle bold={600} type="h2">
                Clave SOL
              </TextStyle>
              <br />
              <TextStyle bold={400} type="h4">
                Agrega el usuario/clave SOL asociado a la empresa
              </TextStyle>
              <div>
                <InputText
                  value={userSOL}
                  name="userSOL"
                  onChange={(e) => {
                    setUserSOL(e.target.value);
                    checkUserSOL();
                  }}
                  placeholder="Usuario SOL"
                  helperText={errorMsgUserSOL}
                  error={hasUserSolErrors}
                />

                <div>
                  <InputPassword
                    name="passwordSOL"
                    onChange={(e) => {
                      setPasswordSOL(e.target.value);
                      checkPasswordSOL();
                    }}
                    placeholder="Clave SOL"
                    error={hasPassSolErrors}
                    helperText={errorMsgPassSOL}
                    value={passwordSOL}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {(errorMsgTryingSOLUpdate !== "" ||
                  successMsgTryingSOLUpdate !== "") && (
                    <InputLabel
                      style={{
                        color:
                          errorMsgTryingSOLUpdate !== ""
                            ? Colors.RED
                            : Colors.GREEN,
                        fontSize: "0.75rem",
                        marginLeft: 14,
                        marginTop: 8,
                      }}
                    >
                      {errorMsgTryingSOLUpdate}
                      {successMsgTryingSOLUpdate}
                    </InputLabel>
                  )}
                <ButtonPrimary
                  onClick={() => {
                    if (checkUserSOL() && checkPasswordSOL()) {
                      setOpenConfirmDialogSOL(true);
                    }
                  }}
                  disabled={loadingSOL}
                  background={Colors.GREEN}
                  color={Colors.WHITE}
                  size="14px"
                  width="100px"
                >
                  {!loadingSOL ? (
                    "Guardar"
                  ) : (
                    <CircularProgress
                      variant="indeterminate"
                      size={14}
                      style={{ marginRight: 5, color: Colors.WHITE }}
                    />
                  )}
                </ButtonPrimary>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomSwitch
                  disabled={false}
                  checked={true}
                  name="OSE"
                  placeholder="OSE"
                  onChange={handleChangeCheckedOse}
                  selectHandle={false}
                />
              </div>
            </div>

            {credentialGuias === "YES" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                    height: 1,
                    marginBottom: 20,
                    marginTop: 15,
                  }}
                ></div>
                <TextStyle bold={600} type="h2">
                  Clave GUIA
                </TextStyle>
                <br />
                <TextStyle bold={400} type="h4">
                  Agrega el client id/client secret asociado a la empresa
                </TextStyle>
                <div>
                  <InputPassword
                    name="clientId"
                    onChange={(e) => {
                      setClientIdSunat(e.target.value);
                      checkClientIdSunat();
                    }}
                    placeholder="Client Id"
                    error={hasClientIdSunatErrors}
                    helperText={errorMsgClientIdSunat}
                    value={clientIdSunat}
                  />

                  <div>
                    <InputPassword
                      name="Client Secret"
                      onChange={(e) => {
                        setClientSecretSunat(e.target.value);
                        checkClientSecretSunat();
                      }}
                      placeholder="Client Secret"
                      error={hasClientSecretSunatErrors}
                      helperText={errorMsgClientSecretSunat}
                      value={clientSecretSunat}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {(errorMsgTryingGUIAUpdate !== "" ||
                    successMsgTryingGUIAUpdate !== "") && (
                      <InputLabel
                        style={{
                          color:
                            errorMsgTryingGUIAUpdate !== ""
                              ? Colors.RED
                              : Colors.GREEN,
                          fontSize: "0.75rem",
                          marginLeft: 14,
                          marginTop: 8,
                        }}
                      >
                        {errorMsgTryingGUIAUpdate}
                        {successMsgTryingGUIAUpdate}
                      </InputLabel>
                    )}
                  <ButtonPrimary
                    onClick={() => {
                      if (checkClientIdSunat() && checkClientSecretSunat()) {
                        setOpenConfirmDialogGUIA(true);
                      }
                    }}
                    disabled={loadingGUIA}
                    background={Colors.GREEN}
                    color={Colors.WHITE}
                    size="14px"
                    width="100px"
                  >
                    {!loadingGUIA ? (
                      "Guardar"
                    ) : (
                      <CircularProgress
                        variant="indeterminate"
                        size={14}
                        style={{ marginRight: 5, color: Colors.WHITE }}
                      />
                    )}
                  </ButtonPrimary>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                    height: 1,
                    marginBottom: 20,
                    marginTop: 15,
                  }}
                ></div>
                {(errorMsgTryingEnableGuiasUpdate !== "" ||
                  successMsgTryingEnableGuiasUpdate !== "") && (
                    <InputLabel
                      style={{
                        color:
                          errorMsgTryingEnableGuiasUpdate !== ""
                            ? "#f44336"
                            : "green",
                        fontSize: "0.75rem",
                        marginLeft: 14,
                        marginTop: 8,
                      }}
                    >
                      {errorMsgTryingEnableGuiasUpdate}
                      {successMsgTryingEnableGuiasUpdate}
                    </InputLabel>
                  )}
                <CustomSwitch
                  disabled={disableSwitchGuias}
                  checked={openConfirmDialogEnableGuias}
                  name="Guias"
                  placeholder="GUIAS"
                  onChange={handleChangeCheckedGuias}
                  selectHandle={false}
                />
              </div>
            )}
          </div>

          <ConfirmDialog
            title="¿Desea actualizar las credenciales de SOL?"
            body="Las credenciales de SOL de esta empresa serán modificadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogSOL}
            handleClose={() => setOpenConfirmDialogSOL(false)}
            handleAccept={saveSOL}
          />
          <ConfirmDialog
            title="¿Desea actualizar las credenciales de GUIA?"
            body="Las credenciales de GUIA de esta empresa serán modificadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogGUIA}
            handleClose={() => setOpenConfirmDialogGUIA(false)}
            handleAccept={saveGUIA}
          />
          <ConfirmDialog
            title="¿Desea actualizar las credenciales de OPENPAY?"
            body="Las credenciales de OPENPAY de esta empresa serán modificadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogOpenPay}
            handleClose={() => setOpenConfirmDialogOpenPay(false)}
            handleAccept={saveOpenPay}
          />
          <ConfirmDialog
            title="¿Desea habilitar las credenciales de OPENPAY?"
            body="Las credenciales de OPENPAY de esta empresa serán habilitadas"
            acceptBtnText="Confirmar"
            open={openConfirmDialogEnableOpenPay}
            handleClose={() => setOpenConfirmDialogEnableOpenPay(false)}
            handleAccept={saveEnableOpenPay}
          />
        </DialogContent>
        <DialogActions
          style={{
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            size="14px"
            width="100px"
            onClick={() => setOpenConfigSunat(false)}
          >
            Cerrar
          </ButtonSecondary>
        </DialogActions>
      </Dialog>
      <Dialog open={openAddNewPhoneNumber} onClose={() => setOpenAddNewPhoneNumber(false)} maxWidth="xs">
        <DialogTitle>
          <TextStyle
            color={Colors.GREEN}
            type="h1"
            textAlign="center"
            bold={700}
          >
            Agregar nuevo número
          </TextStyle>
        </DialogTitle>
        <DialogContent>
          <CustomSelect
            name="code"
            placeholder="Escoger código de país"
            value={formWsp.code}
            options={countries}
            onChange={onChangeSelect}
          />
          <InputNumber
            maxLength={10}
            name="numberWsp"
            value={formWsp.numberWsp}
            onChange={onChangeWsp}
            placeholder="Número"
            error={hasNewPhoneNumberError}
            helperText={errorMsgNewPhoneNumber}
          />

          <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            onClick={() => setOpenAddNewPhoneNumber(false)}
            size="16px"
          >
            Cancelar
          </ButtonSecondary>

          <ButtonPrimary
            onClick={() => {
              if(checkAddNewPhoneNumber()){
                setOpenConfirmDialogAddNewPhoneNumber(true);
              }
            }
            }
            background={Colors.GREEN}
            color={Colors.WHITE}
            size="16px"
          >
            Agregar
          </ButtonPrimary>

        </DialogContent>
      </Dialog>
      <Dialog open={openAddNewApiKey} onClose={() => setOpenAddNewApiKey(false)} maxWidth="xs">
        <DialogTitle>
          <TextStyle
            color={Colors.GREEN}
            type="h1"
            textAlign="center"
            bold={700}
          >
            Agregar nuevo ApiKey
          </TextStyle>
        </DialogTitle>
        <DialogContent>
          <InputText
            value={apiKeyName}
            name="apiKeyName"
            onChange={(e) => {
              setApiKeyName(e.target.value);
              checkApiKeyName();
            }}
            placeholder="Nombre de la apiKey"
            helperText={errorMsgApiKeyName}
            error={hasApiKeyNameError}
          />
          <ButtonSecondary
            border={Colors.GREEN}
            color={Colors.GREEN}
            onClick={() => setOpenAddNewApiKey(false)}
            size="16px"
          >
            Cancelar
          </ButtonSecondary>

          <ButtonPrimary
            onClick={() => {
              if(checkApiKeyName()){
                setOpenConfirmDialogGenerateApiKey(true)
              }
            }
            }
            background={Colors.GREEN}
            color={Colors.WHITE}
            size="16px"
          >
            Agregar
          </ButtonPrimary>

        </DialogContent>
      </Dialog>
    </>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      key={value}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
