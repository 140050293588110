import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { Colors } from "../../static/colors";
import { Container } from "./styled";
import TextStyle from "../textStyle";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: 5,
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: Colors.WHITE,
        "& + $track": {
          backgroundColor: Colors.GREEN,
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: Colors.GREEN,
        border: `6px solid ${Colors.TEXT}`,
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${Colors.TEXT}`,
      backgroundColor: `${Colors.RED}`,
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

interface ICustomSwitchProps {
  checked: boolean;
  onChange: any;
  placeholder: string;
  name: string;
  disabled?: boolean;
  selectHandle?: boolean;
}

const CustomSwitch: React.FC<ICustomSwitchProps> = ({
  checked,
  name,
  onChange,
  placeholder,
  disabled,
  selectHandle = true,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, event);
  };

  return (
    <Container>
      <TextStyle bold={500} type="h4">
        {placeholder}
      </TextStyle>
      <IOSSwitch
        checked={checked}
        onChange={(e) => (selectHandle ? handleChange(e) : onChange(e))}
        name={name}
        disabled={disabled}
      />
    </Container>
  );
};

export default CustomSwitch;
