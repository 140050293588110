import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";
import "swiper/modules/autoplay/autoplay.min.css";

import { Autoplay, EffectFade } from "swiper";
import { Container, ContainerPublicity, Image, Information } from "./styled";

export interface IItemsPublicity {
  background: string;
  title: string;
  img: string;
  colorLogo: string;
}

interface IPublicityProps {
  items: IItemsPublicity[];
  onChangeSlider: (swiper: any) => void;
}

const Publicity: React.FC<IPublicityProps> = ({ items, onChangeSlider }) => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={2000}
        modules={[EffectFade, Autoplay]}
        className="mySwiper"
        onSlideChange={(swiper) => onChangeSlider(swiper)}
        onSwiper={(swiper) => {}}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <ContainerPublicity background={item.background}>
              <Container>
                <Information>
                  <Image src={item.img} alt={item.title} />
                </Information>
              </Container>
            </ContainerPublicity>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Publicity;
