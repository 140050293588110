import React from "react";
import { LocationOn, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Stepper,
  StepLabel,
  StepContent,
  Typography,
  Link,
  Paper,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { RTextField, RButton, RButtonSecondary, RStep, RFormControl, RRadio } from "../ratifikaElements";
import { Colors } from "../../static/colors";
import newCompanyIllust from "../../imgs/new.office.png";
import welcomeIllust from "../../imgs/check.list.png";
import {direcciones } from "../../static/ubigeo";
import { isValidTelephoneNumber, isValidRUC, validateEmail } from "../../static/util";
import { PrivacyPolicy } from "../privacyPolicy";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import Autocomplete from "@material-ui/lab/Autocomplete";


interface Props {
  isOpen: boolean;
  openWelcome: boolean;
  close: (success: boolean) => void;
}
interface Company {
  addresses: Ubicacion;
  number_type: string;
  number: string;
  legal_name: string;
  email_address: string;
  is_new?: boolean;
  address_line: string
}
interface Ubicacion {
  code: string;
  departamento: string;
  provincia: string;
  distrito: string;
}

function getSteps() {
  return ["RUC", "Datos de la empresa", "Credenciales SUNAT", "Confirmar"];
}

export const RegisterCompanyModal: React.FC<Props> = ({ isOpen, openWelcome, close }) => {
  const [isOpenWelcome, setIsOpenWelcome] = React.useState(openWelcome);
  const [activeStep, setActiveStep] = React.useState(0);
  const [companyRUC, setCompanyRUC] = React.useState("");
  const [legalName, setLegalName] = React.useState<string>("");
  const [tradeName, setTradeName] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [webSite, setWebSite] = React.useState<string>("");
  const [emailAdress, setEmailAdress] = React.useState<string>("");
  const [addressLine, setAddressLine] = React.useState<string>("");
 
  const [userSOL, setUserSOL] = React.useState<string>("");
  const [passwordSOL, setPasswordSOL] = React.useState<string>("");
  const [showSOLPassword, setShowSOLPassword] = React.useState(false);

  const [errorMsgRUC, setErrorMsgRUC] = React.useState<string | null>(null);
  const [errorMsgLegalName, setErrorMsgLegalName] = React.useState<string | null>(null);
  const [errorMsgTradeName, setErrorMsgTradeName] = React.useState<string | null>(null);
  const [errorMsgPhone, setErrorMsgPhone] = React.useState<string | null>(null);
  const [errorMsgAddressLine, setErrorMsgAddressLine] = React.useState<string | null>(null);
  const [errorMsgEmail, seterrorMsgEmail] = React.useState<string | null>(null);

  const [hasLegalNameErrors, setHasLegalNameErrors] = React.useState(false);
  const [hasTradeNameErrors, setHasTradeNameErrors] = React.useState(false);
  const [hasPhoneErrors, setHasPhoneErrors] = React.useState(false);
  const [hasAddressLineErrors, setHasAddressLineErrors] = React.useState(false);
  const [hasEmailErrors, sethasEmailErrors] = React.useState(false);
 
  const [hasUserSolErrors, setHasUserSolErrors] = React.useState(false);
  const [hasPassSolErrors, setHasPassSolErrors] = React.useState(false);
  const [errorMsgUserSOL, setErrorMsgUserSOL] = React.useState<string | null>(null);
  const [errorMsgPassSOL, setErrorMsgPassSOL] = React.useState<string | null>(null);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [errorMsgTryingRegister, setErrorMsgTryingRegister] = React.useState<string | null>(null);
  ///
  const [autocompleteCompany, setAutocompleteCompany] = React.useState<Array<Company>>([]);
  const [focusComponent, setFocusComponent] = React.useState<boolean>(false);
  const [freezeBuyerInfo, setFreezeBuyerInfo] = React.useState<boolean>(false); // usado para bloquear la info de la company en caso de autocomplete
  const [showCompanyInfo, setShowCompanyInfo] = React.useState<boolean>(false);
  const [direccionUbigeo, setDireccionUbigeo] = React.useState<string | undefined>("");
  const [selectUbigeo, setSelectUbigeo] = React.useState<Ubicacion | null>(null);
  const [credencialSUNAT, setCredencialSUNAT] = React.useState<string|null>(null);

  const steps = getSteps();

  const handleNext = async () => {
    // Validate RUC
    if (activeStep === 0) {
      let hasCompanyErrors = false;
      if (companyRUC.trim() === "") {
        setErrorMsgRUC("Debes especificar un número de RUC");
        return;
      }
      const validStructure: boolean = isValidRUC(companyRUC);
      if (!validStructure) {
        setErrorMsgRUC("Número de RUC incorrecto");
        return;
      }
      const { data } = await axios.get(`/api/company/exist/${companyRUC}`);
      if (!data.result) {
        setErrorMsgRUC("No se ha podido validar el Número de RUC, intente más adelante");
        return;
      }
      if (data.exist) {
        setErrorMsgRUC(
          data.belongs
            ? "Ya tienes una empresa registrada con ese número de RUC"
            : "Ya existe una empresa registrada con ese número de RUC"
        );
        return;
      }
     /*  if (data.companyInfo) {
        // Si existe info de la empresa se agrega al formulario
        setLegalName(data.companyInfo.legal_name || "");
        setTradeName(data.companyInfo.trade_name || "");
        setAddressLine(data.companyInfo.address_line || ""); */
      /*   if (data.companyInfo.ubigeo && ubigeos.find((u) => u.number === data.companyInfo.ubigeo)) {
         
        } */
      //}
      ////Validacion de nombre comp
      if (legalName.trim() === "") {
        setHasLegalNameErrors(true);
        hasCompanyErrors = true;
      } else if (legalName.trim().length <= 3) {
        setHasLegalNameErrors(true);
        setErrorMsgLegalName("Debes especificar un Nombre Legal correcto");
        hasCompanyErrors = true;
      }
      if (tradeName.trim() === "") {
        setHasTradeNameErrors(true);
        hasCompanyErrors = true;
      } else if (tradeName.trim().length <= 3) {
        setHasTradeNameErrors(true);
        setErrorMsgTradeName("Debes especificar un Nombre Comercial correcto");
        hasCompanyErrors = true;
      }


      if (addressLine.trim() === "") {
        setHasAddressLineErrors(true);
        hasCompanyErrors = true;
      } else if (addressLine.trim().length <= 3) {
        setHasAddressLineErrors(true);
        setErrorMsgAddressLine("Debes especificar un Domicilio fiscal correcto");
        hasCompanyErrors = true;
      }

      setErrorMsgRUC(null);

      if(direccionUbigeo===""||direccionUbigeo===undefined){
        hasCompanyErrors=true;
      }

      if (!hasCompanyErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

    }
    // Validate Company Info
    if (activeStep === 1) {
      let hasCompanyErrors = false;

      if (phoneNumber.trim() === "") {
        setHasPhoneErrors(true);
        hasCompanyErrors = true;
      } else if (!isValidTelephoneNumber(phoneNumber)) {
        setHasPhoneErrors(true);
        setErrorMsgPhone("Debes especificar un número de contacto correcto");
        hasCompanyErrors = true;
      }

      if (emailAdress.trim() === "") {
        sethasEmailErrors(true);
        hasCompanyErrors = true;
      } else if (!validateEmail(emailAdress)) {
        sethasEmailErrors(true);
        seterrorMsgEmail("Debes especificar un correo correcto");
        hasCompanyErrors = true;
      }


      if (!hasCompanyErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    // Validate SOL credentials
    if (activeStep === 2) {
      let hasCredencialsErrors = false;
      if(credencialSUNAT==='true'){       
        if (userSOL.trim() === "") {
          setHasUserSolErrors(true);
          hasCredencialsErrors = true;
        } else if (userSOL.length !== 8) {
          setHasUserSolErrors(true);
          setErrorMsgUserSOL("El Usuario SOL debe contener 8 caracteres");
          hasCredencialsErrors = true;
        }
        if (passwordSOL.trim() === "") {
          setHasPassSolErrors(true);
          hasCredencialsErrors = true;
        } else if (passwordSOL.length < 8 || passwordSOL.length > 12) {
          setHasPassSolErrors(true);
          setErrorMsgPassSOL("La Clave SOL debe contener entre 8 y 12 caracteres");
          hasCredencialsErrors = true;
        }
      }
      

      if (!hasCredencialsErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    // Finalizar
    if (activeStep === 3) {
      let json:any={        
          ruc: companyRUC,
          legal_name: legalName,
          trade_name: tradeName,
          phone_number: phoneNumber,
          address: addressLine,
          ubigeo:direccionUbigeo,
          user_sol: userSOL,
          password_sol: passwordSOL,
          email:emailAdress,
          pag_web: webSite         
      }
      if(credencialSUNAT==='false'){
        json={
          ...json,
          status:"VAL"
        }
      }
      let { data } = await axios.post("/api/company/add", json);
      if (data.result) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setErrorMsgTryingRegister(`Ha ocurrido un error inesperado: ${data.msg}`);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClickShowSOLPassword = () => {
    setShowSOLPassword(!showSOLPassword);
  };

  const handleMouseDownSOLPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  ///suggestion company
  const getCompanySuggestions = async (value: string): Promise<Company[]> => {
    const inputValue = value.trim();
    const inputLength = inputValue.length;
    let results: any[] = [];

    if (Number.isInteger(Number(inputValue))) {

      if (inputLength === 11) {
        /*Consulta datos en SUNAT */
        await axios.get<any>(`/api/info/by/ruc/?ruc=${Number(inputValue)}`).then(e => {
          const data = e.data
          if(!data.error){
            setErrorMsgRUC(null);
            results = [
              {
                legal_name: data.nombre,
                number: inputValue,
                number_type: data.tipoDocumento,
                address_line: data.direccion || null,
                addresses: {
                  "code": data.ubigeo || null,
                  "district": data.distrito || null,
                  "province": data.provincia || null,
                  "department": data.departamento || null
                },
                is_new: true,
              },
            ]
          }else{
            setErrorMsgRUC(data.error) 
          }
         });

      } 



    }
    return results;
  };
  const getSuggestionValue = (suggestion: Company) => {   
    if (suggestion !== null) {
      setAddressLine(suggestion.address_line);
      setCompanyRUC(suggestion.number);
      setLegalName(suggestion.legal_name);
      if (suggestion.addresses.code) {
        const codeUbigeo = direcciones.find(e => e.code === suggestion.addresses.code);
        if (codeUbigeo) {
          setSelectUbigeo(codeUbigeo);
          setDireccionUbigeo(codeUbigeo.code)
        }
      }
      setFocusComponent(true)
    }
    setShowCompanyInfo(true);
    if (errorMsgLegalName) {
      setErrorMsgLegalName(null);
    }
    /*  if (errorMsgIdentification) {
       setErrorMsgIdentification(null);
     } */
    if (errorMsgAddressLine) {
      setErrorMsgAddressLine(null);
    }
    return  suggestion.number;
  };

  const renderSuggestion = (suggestion: Company, { query }: any) => {
    if (suggestion.is_new) {
      return (
        <span>
          Registrar como: "<strong>{suggestion.legal_name|| suggestion.number}</strong>"
        </span>
      );
    } 
  };
  const onSuggestionsFetchRequested = async (param: any) => {
    setAutocompleteCompany(await getCompanySuggestions(param.value));
  };

  const onSuggestionsClearRequested = () => {
    setAutocompleteCompany([]);
  };

  return (
    <Dialog
      open={isOpen}
      scroll={"paper"}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => close(activeStep === steps.length)}
      fullWidth={true}
      maxWidth={"md"}
    >
      {isOpenWelcome ? (
        <>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              /* alignItems: "center",
              textAlign: "center", */
            }}
          >
            <Typography
              variant="h4"
              style={{
                color: Colors.textOlive,
                marginBottom: 20,
              }}
            >
              Bienvenido
            </Typography>
            <DialogContentText
              variant="subtitle1"
              style={{
                color: Colors.textOlive,
              }}
            >
              Antes de comenzar a emitir y recibir documentos es necesario que registres al menos
              una compañía. Puedes completar este paso más adelante, pero gran parte de las
              funcionalidades de Ratifica® estarán deshabilitada hasta que completes el proceso de
              registro.
            </DialogContentText>
            <div
              style={{
                display: "flex",
                padding: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={welcomeIllust} height={150} style={{ padding: 0 }} alt="bienvenido" />
            </div>
          </DialogContent>
          <DialogActions style={{ padding: 20 }}>
            <RButtonSecondary onClick={() => close(false)} color="primary">
              Registrar luego
            </RButtonSecondary>
            <RButton
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
              onClick={() => setIsOpenWelcome(false)}
            >
              Continuar
            </RButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-slide-title">{"Registrar nueva empresa"}</DialogTitle>
          <DialogContent dividers={true} style={{ height: "39em", padding: 5 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                style={{ display: "block", minWidth: 500 }}
              >
                {steps.map((label, index) => (
                  <RStep key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {index === 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 500,
                          }}
                        >
                          <Typography
                            style={{ marginBottom: 20 }}
                            color="textSecondary"
                            variant="caption"
                          >
                            Agrega los datos requeridos para la facturación
                          </Typography>
                          <div style={{
                            paddingBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "4px"
                          }}>
                            <Autosuggest
                              suggestions={autocompleteCompany}
                              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={onSuggestionsClearRequested}
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={{
                                placeholder: "Número de RUC",
                                value: companyRUC,
                                onChange: (
                                  event: React.FormEvent<any>,
                                  { newValue, method }: Autosuggest.ChangeEvent
                                ) => {
                                  setCompanyRUC(newValue);
                                  if (newValue === "") {
                                    setLegalName("");
                                    setCompanyRUC("");
                                    /* 
                                     setSelectedAddress(null);
                                     setFreezeBuyerInfo(false);
                                     setShowBuyerInfo(false); */
                                  }
                                },
                                ref: React.createRef<HTMLInputElement>(),
                              }}
                            />
                            {errorMsgRUC?(                              
                              <Typography variant="caption" color="error">
                                {errorMsgRUC}
                              </Typography>
                              
                            ):""}
                          </div>
                          {showCompanyInfo && (
                            <>
                              <RTextField
                                id="id-number"
                                label="Nombre Legal"
                                variant="outlined"
                                size="small"
                                required
                                autoComplete="off"
                                disabled={freezeBuyerInfo}
                                helperText={errorMsgLegalName}
                                error={hasLegalNameErrors}
                                value={legalName}
                                onChange={(e) => setLegalName(e.target.value)}
                                style={{ paddingBottom: "10px" }}
                              />

                              <RTextField
                                id="company-comercial-name"
                                autoFocus={focusComponent}
                                label="Nombre comercial"
                                variant="outlined"
                                size="small"
                                required
                                autoComplete="company"
                                style={{ marginBottom: 10 }}
                                helperText={
                                  errorMsgTradeName ||
                                  "Es el nombre que usaremos para presentarte ante tus clientes"
                                }
                                error={hasTradeNameErrors}
                                value={tradeName}
                                onChange={(e) => {
                                  setTradeName(e.target.value);
                                  if (e.target.value.trim().length > 3) {
                                    setErrorMsgTradeName(null);
                                    setHasTradeNameErrors(false);
                                  }
                                }}
                              />
                              <RTextField
                                id="company-address"
                                label="Domicilio fiscal"
                                variant="outlined"
                                size="small"
                                required
                                autoComplete="address"
                                style={{ marginBottom: 10 }}
                                helperText={errorMsgAddressLine}
                                error={hasAddressLineErrors}
                                value={addressLine}
                                onChange={(e) => {
                                  setAddressLine(e.target.value);
                                  if (e.target.value.trim().length > 5) {
                                    setErrorMsgAddressLine(null);
                                    setHasAddressLineErrors(false);
                                  }
                                }}
                              />

                              <Autocomplete
                                id="combo-box"
                                options={direcciones}
                                getOptionLabel={(option) => `${option.departamento.toUpperCase()}-${option.provincia.toUpperCase()}-${option.distrito.toUpperCase()}`}
                                fullWidth={true}
                                popupIcon={<LocationOn />}
                                renderInput={(params) => <TextField {...params} label="Ubigeo" placeholder="Buscar..." variant="outlined" />}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <Paper style={{ width: "100%" }}>
                                      <div style={{ padding: "5px" }}>
                                        {option.departamento.toUpperCase()}-{option.provincia.toUpperCase()}-{option.distrito.toUpperCase()}
                                        <div>
                                          <small style={{ color: "#777" }}>{option.code}</small>
                                        </div>
                                      </div>
                                    </Paper>

                                  </React.Fragment>
                                )}
                                PaperComponent={({ children }) => (
                                  <Card style={{ background: "#f3f3f3" }}>
                                    <CardContent>
                                      {children}
                                    </CardContent>
                                  </Card>
                                )}
                                size="small"
                                value={selectUbigeo}
                                onChange={(event, value) => {
                                  setSelectUbigeo(value)
                                  setDireccionUbigeo(value?.code)
                                }}
                              />
                              {(direccionUbigeo===""||direccionUbigeo===undefined)?
                                      <Typography variant="body2"
                                      color="error">
                                      Seleccionar Ubigeo* 
                                    </Typography>
                                :""    
                            }

                            </>
                          )}
                          {/* <RTextField
                            id="standard-basic"
                            label="Número de RUC"
                            variant="outlined"
                            size="small"
                            style={{ width: 150 }}
                            autoFocus={true}
                            autoComplete="number"
                            required
                            helperText={errorMsgRUC}
                            error={errorMsgRUC !== null}
                            value={companyRUC}
                            onChange={(e) => setCompanyRUC(e.target.value)}
                          /> */}
                        </div>
                      )}
                      {index === 1 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 500,
                          }}
                        >
                          <Typography
                            style={{ marginBottom: 20 }}
                            color="textSecondary"
                            variant="caption"
                          >
                            Agrega información adicional de tu empresa
                          </Typography>


                          <RTextField
                            id="company-email"
                            label="Correos"
                            variant="outlined"
                            size="small"
                            /*required*/
                            autoComplete="email"
                            style={{ marginBottom: 20, width: 250 }}
                            helperText={errorMsgEmail}
                            error={hasEmailErrors}
                            value={emailAdress}
                            onChange={(e) => {
                              setEmailAdress(e.target.value);
                              if (validateEmail(e.target.value)) {
                                seterrorMsgEmail(null);
                                sethasEmailErrors(false);
                              } 
                            }}
                          />
                          <RTextField
                            id="company-phone"
                            label="Teléfono de contacto"
                            variant="outlined"
                            size="small"
                            required
                            autoComplete="phone"
                            style={{ marginBottom: 20, width: 250 }}
                            helperText={errorMsgPhone}
                            error={hasPhoneErrors}
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                              if (isValidTelephoneNumber(e.target.value)) {
                                setErrorMsgPhone(null);
                                setHasPhoneErrors(false);
                              }
                            }}
                          />

                          <RTextField
                            id="company-web"
                            label="pagina web"
                            variant="outlined"
                            size="small"
                            autoComplete="address"
                            style={{ marginBottom: 20, width: 250 }}
                            helperText={errorMsgAddressLine}
                            error={hasAddressLineErrors}
                            value={webSite}
                            onChange={(e) => {
                              setWebSite(e.target.value);
                              /*  if (e.target.value.trim().length > 5) {
                                 setErrorMsgAddressLine(null);
                                 setHasAddressLineErrors(false);
                               } */
                            }}
                          />
                        </div>
                      )}
                      {index === 2 && (
                        
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 500,
                          }}
                        >
                          <Typography
                            style={{ marginBottom: 20 }}
                            color="textSecondary"
                            variant="caption"

                          >
                          Desea enviar documentos desde la Plataforma? 
                          </Typography>
                          <RadioGroup
                            row
                            value={credencialSUNAT}
                            onChange={(event: any) => {
                              setCredencialSUNAT(event.target.value);
                            }}
                          >
                            <FormControlLabel value="true" control={<RRadio color="primary" />} label="SI" />
                            <FormControlLabel value="false" control={<RRadio color="primary" />} label="NO" />
                          </RadioGroup>


                          {credencialSUNAT==='true' ?(
                            <>
                              <Typography
                                style={{ marginBottom: 20 }}
                                color="textSecondary"
                                variant="caption"
                              >
                                Agrega el usuario/clave SOL asociado a la empresa
                              </Typography>
                              <RTextField
                                id="user-sol"
                                label="Usuario SOL"
                                required
                                variant="outlined"
                                size="small"
                                autoComplete="name"
                                style={{ marginBottom: 25, width: 300 }}
                                helperText={errorMsgUserSOL}
                                error={hasUserSolErrors}
                                value={userSOL}
                                onChange={(e) => {
                                  setUserSOL(e.target.value);
                                  if (e.target.value.length === 8) {
                                    setErrorMsgUserSOL(null);
                                    setHasUserSolErrors(false);
                                  }
                                }}
                              />
                              <RFormControl variant="outlined">
                                <InputLabel
                                  htmlFor="outlined-sol-password"
                                  style={{
                                    marginTop: -5,
                                    paddingRight: 10,
                                    paddingBottom: 10,
                                    backgroundColor: "white",
                                  }}
                                >
                                  Clave SOL *
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-sol-password"
                                  type={showSOLPassword ? "text" : "password"}
                                  style={{ height: 45, width: 300 }}
                                  error={hasPassSolErrors}
                                  value={passwordSOL}
                                  onChange={(e) => {
                                    setPasswordSOL(e.target.value);
                                    if (e.target.value.length >= 8 && e.target.value.length <= 12) {
                                      setErrorMsgPassSOL(null);
                                      setHasPassSolErrors(false);
                                    }
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowSOLPassword}
                                        onMouseDown={handleMouseDownSOLPassword}
                                        edge="end"
                                      >
                                        {showSOLPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  labelWidth={70}
                                />
                              </RFormControl>

                              {errorMsgPassSOL !== "" && (
                                <InputLabel
                                  style={{
                                    color: "#f44336",
                                    fontSize: "0.75rem",
                                    marginLeft: 14,
                                    marginTop: 8,
                                  }}
                                >
                                  {errorMsgPassSOL}
                                </InputLabel>
                              )}
                            </>
                            ): ""
                          }

                        </div>
                      )}

                      {index === 3 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 500,
                          }}
                        >
                          <div style={{ marginTop: 20 }}>
                            <Typography
                              variant="h4"
                              style={{
                                color: Colors.textOlive,
                                marginBottom: 20,
                              }}
                            >
                              Confirmar
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                color: Colors.textOlive,
                              }}
                            >
                              Al continuar con el registro de la empresa, usted reconoce y acepta
                              nuestra{" "}
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                  setOpenPrivacyPolicy(true);
                                }}
                              >
                                política de privacidad
                              </Link>
                            </Typography>
                          </div>
                          {errorMsgTryingRegister !== "" && (
                            <InputLabel
                              style={{
                                color: "#f44336",
                                fontSize: "0.75rem",
                                marginLeft: 14,
                                marginTop: 8,
                              }}
                            >
                              {errorMsgTryingRegister}
                            </InputLabel>
                          )}
                        </div>
                      )}
                      <div style={{ marginBottom: 5, marginTop: 25 }}>
                        <div>
                          {activeStep !== 0 && (
                            <RButtonSecondary
                              onClick={handleBack}
                              style={{ marginTop: 5, marginRight: 10 }}
                            >
                              Atras
                            </RButtonSecondary>
                          )}
                          <RButton
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            style={{ marginTop: 5, marginRight: 3 }}
                          >
                            {activeStep === steps.length - 1
                              ? "Confirmar y Registrar"
                              : "Continuar"}
                          </RButton>
                        </div>
                      </div>
                    </StepContent>
                  </RStep>
                ))}
              </Stepper>
            </div>
            {activeStep === steps.length && (
              <Paper square elevation={0} style={{ paddingLeft: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 500,
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: Colors.textOlive,
                      marginBottom: 20,
                    }}
                  >
                    Registro exitoso!
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: Colors.textOlive,
                    }}
                  >
                    Se ha registrado exitosamente la empresa, por motivos de seguridad nuestro
                    equipo validará la cuenta a continuación. De ser necesario será contactado a fin
                    de validar su información.
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      padding: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={newCompanyIllust} style={{ height: 150 }} alt="nueva empresa" />
                  </div>
                </div>
              </Paper>
            )}
          </DialogContent>
          <DialogActions style={{ padding: 20 }}>
            {activeStep === steps.length ? (
              <RButtonSecondary onClick={() => close(true)}>Cerrar</RButtonSecondary>
            ) : (
              <RButtonSecondary onClick={() => close(false)} color="primary">
                Registrar luego
              </RButtonSecondary>
            )}
          </DialogActions>
        </>
      )}
      <PrivacyPolicy isOpen={openPrivacyPolicy} close={() => setOpenPrivacyPolicy(false)} />
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});