import { CompanyInfo, CompanyInfoActionTypes } from "../types/companies";

const initialState: CompanyInfo[] = [];

const companiesInfoReducer = (state = initialState, action: CompanyInfoActionTypes) => {
  switch (action.type) {
    case "SET_COMPANY_INFO_LIST":
      return action.payload;
    case "RESET_COMPANIES":
      return initialState;
    default:
      return state;
  }
};

export default companiesInfoReducer;
