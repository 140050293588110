import { receptionRepository } from "src/infrastructure/repositories/Reception.repository";
import { ReceivedDoc } from "../models/Reception";
import { store } from "../store/configureStore";

const getReceptionDocuments = async () => {
  const { config } = store.getState();
  let params = `${config.workspace}?dateOf=${
    config.receptionDateType
  }&startDate=${config.receptionStartDate}&endDate=${
    config.receptionEndDate
  }&startPage=${config.receptionPageCurrent || 0}&endPage=${
    config.receptionRowsPerPage
      ? (config.receptionPageCurrent + 1) * config.receptionRowsPerPage
      : 10
  }${
    config.receptionSerie
      ? `&serie=${config.receptionSerie}&seqInit=${config.receptionSequentialStart}&seqEnd=${config.receptionSequentialEnd}`
      : ""
  }${
    config.receptionClientId ? `&proveedorId=${config.receptionClientId}` : ""
  }${
    config.receptionDocumentType
      ? `&docType=${config.receptionDocumentType}`
      : ""
  }${
    config.receptionDocumentStatus
      ? `&docStatus=${
          config.receptionDocumentStatus.split(",").includes("1-3")
            ? config.receptionDocumentStatus.replace("1-3", "1,3")
            : config.receptionDocumentStatus
        }`
      : ""
  }`;
  const response = await receptionRepository.getReceptionDocuments(params);
  if (response.data.result) {
    store.dispatch({
      type: "SET_RECEIVED_DOCS",
      payload: response.data.receivedDocs as ReceivedDoc[],
    });
  } else {
    store.dispatch({
      type: "SET_RECEIVED_DOCS",
      payload: [],
    });
  }
  return response;
};

export const receptionService = {
  getReceptionDocuments,
};
