import { Colors } from "src/infrastructure/static/colors";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1440px;
  padding: 20px;
  margin: 30px auto;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${Colors.PURPLE};
  margin-top: 20px;
  padding: 30px 20px;
`;
