import { store } from "./configureStore";
import { fetch } from "./fetch";
import { subDays } from "date-fns";
import moment from "moment";

/** Actualiza la info del storage luego de hacer login */
export const afterLogin = async (
  token: string,
  userName: string,
  email: string,
  callback: any
) => {
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { token } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { userName } });
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { email } });
  ///////////////////////////////////////////////////////////////////
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { dateType: "uploaded" },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { typeDate: "emitted" },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { receptiondateType: "uploaded" },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: {
      emissionStartDate: moment(subDays(new Date(), 30)).format("YYYY-MM-DD"),
    },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { emissionEndDate: moment().format("YYYY-MM-DD") },
  });

  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: {
      receptionStartDate: moment(subDays(new Date(), 30)).format("YYYY-MM-DD"),
    },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { receptionEndDate: moment().format("YYYY-MM-DD") },
  });

  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: {
      reportStartDate: moment(subDays(new Date(), 1)).format("YYYY-MM-DD"),
    },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: {
      reportEndDate: moment(subDays(new Date(), 1)).format("YYYY-MM-DD"),
    },
  });

  ///////////////////////////////////////////////////////////////////////
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { emittedDateType: "uploaded" },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { reportsDateType: "emitted" },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { receptionDateType: "uploaded" },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: {
      emittedStartDate: moment(subDays(new Date(), 30)).format("YYYY-MM-DD"),
    },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { emittedEndDate: moment().format("YYYY-MM-DD") },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: {
      receptionStartDate: moment(subDays(new Date(), 30)).format("YYYY-MM-DD"),
    },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: { receptionEndDate: moment().format("YYYY-MM-DD") },
  });

  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: {
      reportsStartDate: moment(subDays(new Date(), 1)).format("YYYY-MM-DD"),
    },
  });
  store.dispatch({
    type: "SET_CONFIG_PARAM",
    payload: {
      reportsEndDate: moment(subDays(new Date(), 1)).format("YYYY-MM-DD"),
    },
  });
  await fetch(["all"]);
  store.dispatch({ type: "SET_CONFIG_PARAM", payload: { loading: false } });

  if (callback) {
    callback();
  }
};
