import React, { useEffect, useState } from "react";
import { FilterDialog } from "../../components/filterDialog";
import { EmittedDocList } from "../../components/emittedDocList";
import { HeaderTitle } from "../../components/headerTitle";
import {
  RButton,
  RRadio,
  RChip,
  RButtonSecondary,
} from "../../components/ratifikaElements";
import { ExcelDialog } from "../../components/excelDialog";
import { ConfirmDialog } from "../../components/confirmDialog";
import {
  Grid,
  Menu,
  Typography,
  RadioGroup,
  FormControlLabel,
  Button,
  Tooltip,
  CircularProgress,
  Hidden,
  Drawer,
  Snackbar,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import {
  ArrowRightAlt as ArrowDateIcon,
  Today as DateRangeIcon,
  Autorenew as RefreshIcon,
  AssignmentReturn,
  CloudDownload,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as emailReportIcon } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setConfigParam } from "../../store/actions/config";
import { fetch } from "../../store/fetch";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { DateRange } from "react-date-range";
import { es } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { subDays } from "date-fns";
import FileDownload from "js-file-download";
import "./Header.css";
import {
  getDocumentStatusDescription,
  getDocumentTypeDesc,
} from "../../static/util";
import "react-loading-skeleton/dist/skeleton.css";
import { setTimeout } from "timers";
import { Loading } from "../../components/skeleton/loading";
import Alert from "@material-ui/lab/Alert";
import { CompanyInfo } from "../../store/types/companies";
import { EmittedDoc } from "../../store/types/emittedDoc";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "src/static/colors";
import { toast, ToastContainer } from "react-toastify";

export const Emission: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const config = useSelector((state: any) => state.config);
  const companies = useSelector((state: any) => state.companiesInfo);
  const emittedDocs = useSelector((state: any) => state.emittedDocs);
  const [dateRange, setDateRange] = React.useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  let selectedDates: any = {
    startDate: dateRange[0].startDate,
    endDate: dateRange[0].endDate,
  };

  const [downloadingExcel, setDownloadingExcel] =
    React.useState<boolean>(false);
  const [blockDownloadExcel, setBlockDownloadExcel] =
    React.useState<boolean>(false);
  const [showDialogConfirmExcel, setShowDialogConfirmExcel] =
    React.useState<boolean>(false);
  const [showDialogBackendExcel, setShowDialogBackendExcel] =
    React.useState<boolean>(false);

  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [showUpdateAlert, setShowUpdateAlert] = React.useState<boolean>(true);
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );
  const [disabledBulkDownload, setDisabledBulkDownload] =
    useState<boolean>(true);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [openDialogBulkDownload, setOpenDialogBulkDownload] =
    useState<boolean>(false);
  const [openDialogRequestProcess, setOpenDialogRequestProcess] =
    useState<boolean>(false);
  const [selectOptionPDF, setSelectOptionPDF] = useState<boolean>(false);
  const [selectOptionXML, setSelectOptionXML] = useState<boolean>(false);
  const [selectOptionCDR, setSelectOptionCDR] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    setDisabledBulkDownload(true);
    if (companies.find((c: CompanyInfo) => c.ruc === config.workspace)) {
      if (
        companies.find((c: CompanyInfo) => c.ruc === config.workspace).customize
          .descargaMasiva === "YES"
      ) {
        setDisabledBulkDownload(false);
      }
    }
  }, [config.workspace, companies]);

  const handleOpenMenuDates = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenuDates = () => {
    setAnchorElement(null);
  };

  const handleDownloadExcel = async () => {
    if (config.emittedPageTotal <= 10000) {
      // 10 mil
      setDownloadingExcel(true);
      setBlockDownloadExcel(true);
      let { data } = await axios.get(
        `/api/emission/download/excel/${config.workspace}?dateOf=${
          config.dateType
        }&startDate=${config.emissionStartDate}&endDate=${
          config.emissionEndDate
        }${
          config.emittedFilterSerie
            ? `&serie=${config.emittedFilterSerie}&seqInit=${config.emittedFilterInitSeq}&seqEnd=${config.emittedFilterEndSeq}`
            : ""
        }${config.docType ? `&docType=${config.docType}` : ""}${
          config.docState
            ? `&docStatus=${
                config.docState.split(",").includes("1-3")
                  ? config.docState.replace("1-3", "1,3")
                  : config.docState
              }`
            : ""
        }`,
        {
          responseType: "blob",
        }
      );
      FileDownload(
        new Blob([data]),
        `docs_emitidos_${config.workspace}_${config.emissionStartDate}_${config.emissionEndDate}.xlsx`
      );
      setDownloadingExcel(false);
      setTimeout(() => setBlockDownloadExcel(false), 3000);
    } else if (config.emittedPageTotal <= 30000) {
      // mostrar dialog de que son bastante y se puede demorar
      setShowDialogConfirmExcel(true);
    } else {
      // TODO: mostrar mensaje de que el reporte se le enviara mas adelante por email
      setShowDialogBackendExcel(true);
    }
  };

  // abrir filter click event
  const handleOpenFilterClick = () => {
    setShowFilters(true);
  };
  const handleCloseFilters = () => {
    setShowFilters(false);
  };

  const handleCalendarChange = (item: any) => {
    setDateRange([item.selection]);
    selectedDates = item.selection;
  };

  const handleCalendarSelected = (e: any) => {
    const [start, end] = e;
    if (start === 0 && end === 0) {
      setAnchorElement(null);
      dispatch(
        setConfigParam(
          "emissionStartDate",
          moment(selectedDates.startDate).format("YYYY-MM-DD")
        )
      );
      dispatch(
        setConfigParam(
          "emissionEndDate",
          moment(selectedDates.endDate).format("YYYY-MM-DD")
        )
      );
      dispatch(setConfigParam("emittedPageCurrent", 0));
      fetch(["emission"]);
      setShowUpdateAlert(true);
    }
  };

  const handleDateTypeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setConfigParam("dateType", (event.target as HTMLInputElement).value)
    );
    dispatch(setConfigParam("emittedPageCurrent", 0));
    fetch(["emission"]);
    setShowUpdateAlert(true);
  };
  ////ADD
  const handleShowAlert = (value: boolean) => {
    setShowUpdateAlert(value);
  };

  const openPopOver = () => {
    setShowAlert(true);
  };
  const handleSelectbulkDownload = () => {
    setOpenDialogBulkDownload(true);
  };
  const handleCloseDialogBulkDownload = () => {
    setOpenDialogBulkDownload(false);
  };
  const handleCloseDialogRequestProcess = () => {
    setOpenDialogRequestProcess(false);
  };

  const generateBulkDownload = async () => {
    setLoading(true);
    const allDocument = await axios.get(
      `/api/emission/documents/all/${config.workspace}?dateOf=${
        config.dateType
      }&startDate=${config.emissionStartDate}&endDate=${
        config.emissionEndDate
      }&receiverId=${config.emittedClientId}:""}${
        config.emittedFilterSerie
          ? `&serie=${config.emittedFilterSerie}&seqInit=${config.emittedFilterInitSeq}&seqEnd=${config.emittedFilterEndSeq}`
          : ""
      }${config.docType ? `&docType=${config.docType}` : ""}${
        config.docState
          ? `&docStatus=${
              config.docState.split(",").includes("1-3")
                ? config.docState.replace("1-3", "1,3")
                : config.docState
            }`
          : ""
      }`
    );

    if (allDocument.data.result) {
      let body = allDocument.data.emittedDocs;
      let downloadLimit = await axios.get(`/api/bulkDownload/files/limit`);
      if (
        downloadLimit.data.result &&
        body.length <= downloadLimit.data.limit
      ) {
        let { data } = await axios.post(
          `/api/bulkDownload/emission/${config.workspace}/${selectOptionPDF}/${selectOptionXML}/${selectOptionCDR}`,
          body,
          {
            responseType: "blob",
          }
        );
        FileDownload(
          new Blob([data]),
          `DM-Doc-Emitidos-${config.workspace}.zip`
        );
        setLoading(false);
        handleCloseDialogBulkDownload();
      } else {
        setLoading(false);
        setOpenDialogRequestProcess(true);
        handleCloseDialogBulkDownload();
        let { data } = await axios.post(
          `/api/bulkDownload/emission/${config.workspace}/${selectOptionPDF}/${selectOptionXML}/${selectOptionCDR}`,
          body
        );
        if (data.result) {
          toast.info(
            "El processo de su ultima consultada de descarga masiva  ya fue generada"
          );
        }
      }
    }
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={7000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/*  <Snackbar
        open={showUpdateAlert}
        autoHideDuration={500}
        onClose={() => setShowUpdateAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="Cargando..." />        
 */}

        <Grid container>
          <Hidden only={["xs", "sm", "md"]}>
            <Grid item xs={2} sm={2} className="linea">
              <div className="header-container">
                <HeaderTitle title="Documentos Emitidos" />
                {/* Fechas*/}
                <div>
                  <RButton
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      fontSize: 14,
                      height: 32,
                    }}
                    className="btn-date"
                    disabled={!config.workspace}
                    onClick={handleOpenMenuDates}
                  >
                    <DateRangeIcon
                      style={{
                        fontSize: 18,
                        paddingRight: 5,
                        color: "white",
                      }}
                    />
                    <span style={{ color: "white" }}>
                      {moment(dateRange[0].startDate)
                        .locale("es")
                        .format("MMM DD")}
                    </span>
                    <ArrowDateIcon
                      style={{
                        fontSize: 22,
                        paddingLeft: 5,
                        paddingRight: 5,
                        color: "white",
                      }}
                    />
                    <span style={{ color: "white" }}>
                      {moment(dateRange[0].endDate)
                        .locale("es")
                        .format("MMM DD")}
                    </span>
                  </RButton>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElement}
                    keepMounted
                    open={Boolean(anchorElement)}
                    onClose={handleCloseMenuDates}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <div>
                      <DateRange
                        onChange={handleCalendarChange}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        months={2}
                        direction="horizontal"
                        locale={es}
                        rangeColors={["rgb(28, 108, 54)"]}
                        showMonthAndYearPickers={true}
                        maxDate={new Date()}
                        monthDisplayFormat="MMMM yyyy"
                        onRangeFocusChange={handleCalendarSelected}
                      />
                    </div>
                    <div>
                      <Grid style={{ marginLeft: 20 }}>
                        <RadioGroup
                          row
                          value={config.dateType}
                          onChange={handleDateTypeSelect}
                        >
                          <FormControlLabel
                            value="uploaded"
                            control={<RRadio color="primary" />}
                            label={
                              <Typography style={{ fontSize: 14 }}>
                                Fecha de subida
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="emitted"
                            control={<RRadio color="primary" />}
                            label={
                              <Typography style={{ fontSize: 14 }}>
                                Fecha de emisión
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>
                    </div>
                  </Menu>
                </div>
                {/* End: Fechas */}
                {/* Filtros */}
                <div>
                  <FilterDialog showAlert={handleShowAlert} />
                </div>
                {/* End: Filtros */}
              </div>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Drawer open={showFilters} onClose={handleCloseFilters}>
              <div style={{ marginLeft: "1rem", marginTop: "4rem" }}>
                <FilterDialog showAlert={handleShowAlert} />
              </div>
            </Drawer>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={10}
            className="padding-general"
          >
            {showUpdateAlert ? (
              (setTimeout(() => setShowUpdateAlert(false), 1500), (<Loading />))
            ) : (
              <>
                <Hidden lgUp>
                  <div
                    style={{
                      paddingBottom: "10px",
                      textAlign: "center",
                      marginTop: "0px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <HeaderTitle title="Documentos Emitidos" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        {/* Fechas*/}
                        <div style={{ paddingBottom: "5px" }}>
                          <RButton
                            style={{
                              alignContent: "center",
                              justifyContent: "center",
                              fontSize: 14,
                              height: 30,
                              marginRight: ".7rem",
                              width: "210px",
                            }}
                            className="btn-date btnSizeLetra"
                            disabled={!config.workspace}
                            onClick={handleOpenMenuDates}
                          >
                            <DateRangeIcon
                              style={{
                                fontSize: 18,
                                paddingRight: 5,
                                color: "white",
                              }}
                            />
                            <span style={{ color: "white" }}>
                              {moment(dateRange[0].startDate)
                                .locale("es")
                                .format("MMM DD")}
                            </span>
                            <ArrowDateIcon
                              style={{
                                fontSize: 22,
                                paddingLeft: 5,
                                paddingRight: 5,
                                color: "white",
                              }}
                            />
                            <span style={{ color: "white" }}>
                              {moment(dateRange[0].endDate)
                                .locale("es")
                                .format("MMM DD")}
                            </span>
                          </RButton>
                          <Menu
                            id="lock-menu"
                            anchorEl={anchorElement}
                            keepMounted
                            open={Boolean(anchorElement)}
                            onClose={handleCloseMenuDates}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <div>
                              <DateRange
                                onChange={handleCalendarChange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                months={2}
                                direction="horizontal"
                                locale={es}
                                rangeColors={["rgb(28, 108, 54)"]}
                                showMonthAndYearPickers={true}
                                maxDate={new Date()}
                                monthDisplayFormat="MMMM yyyy"
                                onRangeFocusChange={handleCalendarSelected}
                              />
                            </div>
                            <div>
                              <Grid style={{ marginLeft: 20 }}>
                                <RadioGroup
                                  row
                                  value={config.dateType}
                                  onChange={handleDateTypeSelect}
                                >
                                  <FormControlLabel
                                    value="uploaded"
                                    control={<RRadio color="primary" />}
                                    label={
                                      <Typography style={{ fontSize: 14 }}>
                                        Fecha de subida
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value="emitted"
                                    control={<RRadio color="primary" />}
                                    label={
                                      <Typography style={{ fontSize: 14 }}>
                                        Fecha de emisión
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </Grid>
                            </div>
                          </Menu>
                        </div>
                        {/* End: Fechas hidden */}
                        <div
                          style={{ textAlign: "center", paddingBottom: "5px" }}
                        >
                          <Button
                            style={{
                              alignContent: "center",
                              justifyContent: "center",
                              fontSize: 14,
                              height: 30,
                              backgroundColor: "#e6e6e6",
                              marginRight: ".7rem",
                              width: "210px",
                            }}
                            className="btnSizeLetra"
                            onClick={handleOpenFilterClick}
                          >
                            <FontAwesomeIcon
                              icon={faFilter}
                              style={{
                                fontSize: 13,
                                paddingLeft: 10,
                                paddingRight: 10,
                                color: "rgba(0, 0, 0, 0.54)",
                              }}
                            />
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              style={{ fontSize: 13 }}
                            >
                              Filtrar
                            </Typography>
                          </Button>
                        </div>

                        {config.workspace && (
                          <>
                            <Tooltip title="Descargar reporte en Excel" arrow>
                              <div style={{ height: 30, paddingBottom: "5px" }}>
                                <Button
                                  style={{
                                    alignContent: "center",
                                    justifyContent: "center",
                                    fontSize: 14,
                                    height: 30,
                                    backgroundColor: "#e6e6e6",
                                    marginRight: 10,
                                    width: "210px",
                                  }}
                                  className="btnSizeLetra"
                                  disabled={
                                    blockDownloadExcel ||
                                    config.emittedPageTotal === 0
                                  }
                                  onClick={handleDownloadExcel}
                                >
                                  {downloadingExcel ? (
                                    <CircularProgress
                                      variant="indeterminate"
                                      size={14}
                                      style={{ marginRight: 5 }}
                                    />
                                  ) : (
                                    <AssignmentReturn
                                      style={{
                                        fontSize: 16,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        color:
                                          blockDownloadExcel ||
                                          config.emittedPageTotal === 0
                                            ? "rgba(0, 0, 0, 0.2)"
                                            : "rgba(0, 0, 0, 0.54)",
                                      }}
                                    />
                                  )}
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{ fontSize: 13 }}
                                  >
                                    Descargar reporte
                                  </Typography>
                                </Button>
                              </div>
                            </Tooltip>

                            <div style={{ height: 30, paddingBottom: "5px" }}>
                              <Tooltip title="Refrescar" arrow>
                                <Button
                                  style={{
                                    alignContent: "center",
                                    justifyContent: "center",
                                    fontSize: 14,
                                    height: 30,
                                    backgroundColor: "#e6e6e6",
                                    marginRight: ".7rem",
                                    width: "210px",
                                  }}
                                  className="btnSizeLetra"
                                  onClick={() => {
                                    fetch(["emission"]);
                                    setShowUpdateAlert(true);
                                  }}
                                >
                                  <RefreshIcon
                                    style={{
                                      fontSize: 22,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      color: "rgba(0, 0, 0, 0.54)",
                                    }}
                                  />
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{ fontSize: 13 }}
                                  >
                                    Refrescar
                                  </Typography>
                                </Button>
                              </Tooltip>
                            </div>
                            <div
                              style={{
                                height: 30,
                                paddingBottom: "5px",
                                visibility: disabledBulkDownload
                                  ? "hidden"
                                  : "visible",
                              }}
                            >
                              <Tooltip title="Descarga Masiva" arrow>
                                <Button
                                  style={{
                                    alignContent: "center",
                                    justifyContent: "center",
                                    fontSize: 14,
                                    height: 30,
                                    backgroundColor: "#e6e6e6",
                                    marginRight: ".7rem",
                                    width: "210px",
                                  }}
                                  className="btnSizeLetra"
                                  onClick={
                                    disabledBulkDownload
                                      ? openPopOver
                                      : handleSelectbulkDownload
                                  }
                                >
                                  <CloudDownload
                                    style={{
                                      fontSize: 22,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      color: "rgba(0, 0, 0, 0.54)",
                                    }}
                                  />
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{ fontSize: 13 }}
                                  >
                                    Descarga Masiva
                                  </Typography>
                                </Button>
                              </Tooltip>
                            </div>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Hidden>
                <Hidden only={["xs", "sm", "md"]}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      paddingBottom: 8,
                    }}
                    className="btnRefrescar"
                  >
                    {config.workspace && (
                      <>
                        <Tooltip title="Descargar reporte en Excel" arrow>
                          <div style={{ height: 30 }}>
                            <Button
                              style={{
                                alignContent: "center",
                                justifyContent: "center",
                                fontSize: 14,
                                height: 30,
                                backgroundColor: "#e6e6e6",
                                marginRight: 10,
                              }}
                              className="btnSizeLetra"
                              disabled={
                                blockDownloadExcel ||
                                config.emittedPageTotal === 0
                              }
                              onClick={handleDownloadExcel}
                            >
                              {downloadingExcel ? (
                                <CircularProgress
                                  variant="indeterminate"
                                  size={14}
                                  style={{ marginRight: 5 }}
                                />
                              ) : (
                                <AssignmentReturn
                                  style={{
                                    fontSize: 16,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    color:
                                      blockDownloadExcel ||
                                      config.emittedPageTotal === 0
                                        ? "rgba(0, 0, 0, 0.2)"
                                        : "rgba(0, 0, 0, 0.54)",
                                  }}
                                />
                              )}
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ fontSize: 13 }}
                              >
                                Descargar reporte
                              </Typography>
                            </Button>
                          </div>
                        </Tooltip>
                        <Tooltip title="Refrescar" arrow>
                          <Button
                            style={{
                              alignContent: "center",
                              justifyContent: "center",
                              fontSize: 14,
                              height: 30,
                              backgroundColor: "#e6e6e6",
                              marginRight: ".7rem",
                            }}
                            className="btnSizeLetra"
                            onClick={() => {
                              fetch(["emission"]);
                              setShowUpdateAlert(true);
                            }}
                          >
                            <RefreshIcon
                              style={{
                                fontSize: 22,
                                paddingLeft: 10,
                                paddingRight: 10,
                                color: "rgba(0, 0, 0, 0.54)",
                              }}
                            />
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              style={{ fontSize: 13 }}
                            >
                              Refrescar
                            </Typography>
                          </Button>
                        </Tooltip>
                        <div
                          style={{
                            visibility: disabledBulkDownload
                              ? "hidden"
                              : "visible",
                          }}
                        >
                          <Tooltip title="Descarga Masiva" arrow>
                            <Button
                              style={{
                                alignContent: "center",
                                justifyContent: "center",
                                fontSize: 14,
                                height: 30,
                                backgroundColor: "#e6e6e6",
                                marginRight: ".7rem",
                              }}
                              className="btnSizeLetra"
                              onClick={
                                disabledBulkDownload
                                  ? openPopOver
                                  : handleSelectbulkDownload
                              }
                            >
                              <CloudDownload
                                style={{
                                  fontSize: 22,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  color: "rgba(0, 0, 0, 0.54)",
                                }}
                              />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ fontSize: 13 }}
                              >
                                Descarga Masiva
                              </Typography>
                            </Button>
                          </Tooltip>
                        </div>
                      </>
                    )}
                    <div
                      style={{ display: "flex", marginTop: 3, marginLeft: 10 }}
                    >
                      {config.emittedFilterSerie && (
                        <RChip
                          icon={
                            <FontAwesomeIcon
                              icon={faFilter}
                              style={{
                                fontSize: 12,
                                paddingLeft: 8,
                                color: "white",
                              }}
                            />
                          }
                          label={`${config.emittedFilterSerie} / ${config.emittedFilterInitSeq}~${config.emittedFilterEndSeq}`}
                          /* onDelete={() => {
                          dispatch(setConfigParam("emittedFilterSerie", null));
                          dispatch(setConfigParam("emittedFilterInitSeq", null));
                          dispatch(setConfigParam("emittedFilterEndSeq", null));
                          fetch(["emission"]);
                          setShowUpdateAlert(true);
                        } } */
                          color="secondary"
                          size="small"
                          style={{ marginRight: 5 }}
                        />
                      )}
                      {config.docType &&
                        config.docType
                          .split(",")
                          .map((t: string, index: number) => (
                            <RChip
                              key={index}
                              icon={
                                <FontAwesomeIcon
                                  icon={faFilter}
                                  style={{
                                    fontSize: 12,
                                    paddingLeft: 8,
                                    color: "white",
                                  }}
                                />
                              }
                              label={getDocumentTypeDesc(t)}
                              /*  onDelete={() => {
                             dispatch(
                               setConfigParam(
                                 "docType",
                                 config.docType.replace(`${t},`, "").replace(`,${t}`, "").replace(t, "")
                               )
                             );
                             fetch(["emission"]);
                             setShowUpdateAlert(true);
                           } } */
                              color="secondary"
                              size="small"
                              style={{ marginRight: 5 }}
                            />
                          ))}

                      {config.docState &&
                        config.docState
                          .split(",")
                          .map((s: string, index: number) => (
                            <RChip
                              key={index}
                              icon={
                                <FontAwesomeIcon
                                  icon={faFilter}
                                  style={{
                                    fontSize: 12,
                                    paddingLeft: 8,
                                    color: "white",
                                  }}
                                />
                              }
                              label={getDocumentStatusDescription(
                                s === "1-3" ? "1" : s
                              )}
                              /*  onDelete={() => {
                             dispatch(
                               setConfigParam(
                                 "docState",
                                 config.docState.replace(`${s},`, "").replace(`,${s}`, "").replace(s, "")
                               )
                             );
                             fetch(["emission"]);
                             setShowUpdateAlert(true);
                           } } */
                              color="secondary"
                              size="small"
                              style={{ marginRight: 5 }}
                            />
                          ))}
                      {config.emittedClientName && (
                        <RChip
                          icon={
                            <FontAwesomeIcon
                              icon={faFilter}
                              style={{
                                fontSize: 12,
                                paddingLeft: 8,
                                color: "white",
                              }}
                            />
                          }
                          label={`${config.emittedClientName}`}
                          color="secondary"
                          size="small"
                          style={{ marginRight: 5 }}
                        />
                      )}
                    </div>
                  </div>
                </Hidden>

                <EmittedDocList />
              </>
            )}
          </Grid>
        </Grid>

        <ExcelDialog
          key={Date.now() + 5}
          open={showDialogConfirmExcel}
          filterData={config}
          handleClose={() => setShowDialogConfirmExcel(false)}
        />
        <ConfirmDialog
          title="Cantidad no soportada aun"
          body={`Para generar un reporte de ${config.emittedPageTotal} documentos, debe contactar a soporte momentaneamente`}
          acceptBtnText="Listo"
          open={showDialogBackendExcel}
          handleClose={() => setShowDialogBackendExcel(false)}
          handleAccept={() => setShowDialogBackendExcel(false)}
        />
      </React.Fragment>

      <Backdrop
        open={showAlert}
        onClick={() => {
          setShowAlert(false);
        }}
        style={{ color: "#fff" }}
        className={classes.backdrop}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showAlert}
          onClose={() => {
            setShowAlert(false);
          }}
          autoHideDuration={6000}
          style={{ marginTop: "50px" }}
        >
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity="warning"
          >
            ¡Lo sentimos!
            <br />
            Parece que esta funcionalidad no está incluida en su plan actual. Contacte con un ejecutivo de EXIMIO para más información.
          </Alert>
        </Snackbar>
      </Backdrop>
      <Dialog
        open={openDialogBulkDownload}
        onClose={handleCloseDialogBulkDownload}
      >
        <DialogTitle>Seleccione los tipos de archivos a descargar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormGroup
              row
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionPDF(!selectOptionPDF)}
                    checked={selectOptionPDF}
                  />
                }
                label="PDF"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionXML(!selectOptionXML)}
                    checked={selectOptionXML}
                  />
                }
                label="XML"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: Colors.primary }}
                    onChange={() => setSelectOptionCDR(!selectOptionCDR)}
                    checked={selectOptionCDR}
                  />
                }
                label="CDR"
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <RButtonSecondary
            onClick={handleCloseDialogBulkDownload}
            color="primary"
          >
            Cancelar
          </RButtonSecondary>
          <RButton
            onClick={() => {
              if (selectOptionPDF || selectOptionXML || selectOptionCDR) {
                generateBulkDownload();
              }
            }}
            autoFocus
            disabled={loading}
          >
            {loading ? (
              <>
                <CircularProgress
                  variant="indeterminate"
                  size={14}
                  style={{ marginRight: 5 }}
                />
                Cargando...
              </>
            ) : (
              <>CONFIRMAR</>
            )}
          </RButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogRequestProcess}
        onClose={handleCloseDialogRequestProcess}
      >
        <DialogTitle>SOLICITUD POR PROCESAR</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Puedes continuar usando el sistema de Ratifika mientras tu reporte
            se procesa. Te aparecerá una notificación cuando esté listo para
            descargar desde Mis Reportes
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 999,
      color: "#fff",
    },
  })
);
