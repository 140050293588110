import { UnadjudicatedDoc, UnadjudicatedDocsActionTypes } from "../types/unadjudicatedDoc";

const initialState: UnadjudicatedDoc[] = [];

const receivedDocsReducer = (state = initialState, action: UnadjudicatedDocsActionTypes) => {
  switch (action.type) {
    case "SET_UNADJUDICATED_DOCS":
      return action.payload;
    case "RESET_UNADJUDICATED_DOCS":
      return initialState;
    default:
      return state;
  }
};

export default receivedDocsReducer;
