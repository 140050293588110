import { Container, Drawer, IconButton } from "@material-ui/core";
import React from "react";
import { AboutUs } from "../aboutUs";
import { LoginForm } from "../loginForm";
import { RegisterForm } from "../registerForm";
import {
  ButtonClose,
  IconMenu,
  ItemsHeader,
  ItemsHeaderMobile,
} from "./styled";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

interface IPublicMenuProps {
  color: string;
}

const PublicMenu: React.FC<IPublicMenuProps> = ({ color }) => {
  const history = useHistory();

  const [openMenu, setOpenMenu] = React.useState<number>(0);

  const [openOptions, setOpenOptions] = React.useState<boolean>(false);

  return (
    <div>
      <ItemsHeader>
        <p onClick={() => history.push("/consultar")}>Consultar</p>
        <p onClick={() => setOpenMenu(2)}>Nosotros</p>
        <p onClick={() => setOpenMenu(3)}>Registrarse</p>
        <p onClick={() => setOpenMenu(4)}>Ingresar</p>
      </ItemsHeader>
      <IconMenu onClick={() => setOpenOptions(!openOptions)}>
        <svg
          width="27"
          height="20"
          viewBox="0 0 27 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="27" height="4" rx="2" fill={color} />
          <rect y="8" width="27" height="4" rx="2" fill={color} />
          <rect y="16" width="27" height="4" rx="2" fill={color} />
        </svg>
      </IconMenu>
      <Drawer
        anchor="right"
        open={openOptions}
        onClose={() => setOpenOptions(!openOptions)}
      >
        <Container maxWidth="xs">
          <ButtonClose>
            <IconButton onClick={() => setOpenOptions(!openOptions)}>
              <CloseIcon />
            </IconButton>
          </ButtonClose>
          <ItemsHeaderMobile>
            <p onClick={() => history.push("/consultar")}>Consultar</p>
            <p onClick={() => setOpenMenu(2)}>Nosotros</p>
            <p onClick={() => setOpenMenu(3)}>Registrarse</p>
            <p onClick={() => setOpenMenu(4)}>Ingresar</p>
          </ItemsHeaderMobile>
        </Container>
      </Drawer>
      <Drawer
        anchor="right"
        open={openMenu !== 0}
        onClose={() => setOpenMenu(0)}
      >
        <Container maxWidth="xs">
          <ButtonClose>
            <IconButton onClick={() => setOpenMenu(0)}>
              <CloseIcon />
            </IconButton>
          </ButtonClose>
          {openMenu === 2 && <AboutUs />}
          {openMenu === 3 && (
            <RegisterForm openLoginForm={() => setOpenMenu(4)} />
          )}
          {openMenu === 4 && (
            <LoginForm openRegisterForm={() => setOpenMenu(3)} />
          )}
        </Container>
      </Drawer>
    </div>
  );
};

export default PublicMenu;
